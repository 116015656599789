/**
 * <p>Url utilities module</p>
 *
 * @module utils/url
 */

/**
 * Remove concrete parameter from url when contains any
 *
 * @param {String} url - Url address
 * @param {String} parameter - Concrete URL parameter to remove
 * @return {String} Url without parameter
 */
export function removeURLParameter(url, parameter) {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    const joinedPars = pars.length > 0 ? `?${pars.join('&')}` : '';
    url = `${urlparts[0]}${joinedPars}`;
    return url;
  }
  return url;
}

/**
 * Parse all query strings into object with properly URL-decoding
 *
 * @param {String} url - The querystring part of a URL
 * @return {Object} Object with all query params
 */
export function getQueryParams(qs) {
  qs = qs.split('+').join(' ');

  const params = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;
  let tokens;

  function getNext() {
    return (tokens = re.exec(qs));
  }

  while (getNext() !== null) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

/**
 * Create or update url patameter in the URL
 *
 * @param {String} url - Url to update
 * @param {String} urlParamKey - New URL parameter key
 * @param {String} urlParamValue - New URL parameter value
 * @return {String} URL with new or updated query string
 */
export function getUrlWithQueryParam(url, urlParamKey, urlParamValue) {
  const newUrl = new URL(url);
  newUrl.searchParams.set(urlParamKey, urlParamValue);
  return newUrl;
}
