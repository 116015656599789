import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class AutoplayNextSwitchHeading extends Component {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      className: 'vjs-an-heading vjs-settings-heading',
      innerHTML: this.player_.localize('Autoplay next'),
    });
  }
}
Component.registerComponent('AutoplayNextSwitchHeading', AutoplayNextSwitchHeading);
export default AutoplayNextSwitchHeading;
