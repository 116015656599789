import { videojs } from 'global/window';

import './quality-menu';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The quality button can be clicked to reset adaptive streaming selection
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityAutoInfoButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.parent_ = options.parent;
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-icon-info ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {});
  }

  hideQualityMenuPlugin() {
    const menu = this.player_.getChild('QualityMenu');
    if (typeof menu !== 'undefined') {
      if (!menu.hasClass('vjs-hidden')) {
        menu.addClass('vjs-hidden');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }

  /**
   * Handle click on auto quality info button
   */
  handleClick() {
    const cssClass = 'vjs-quality-auto-info-showing';
    if (this.player_.hasClass(cssClass)) {
      this.player_.removeClass(cssClass);
      this.player_.play();
    } else {
      this.player_.addClass(cssClass);
      this.player_.pause();
    }
    this.hideQualityMenuPlugin();
  }
}
// Register the new component
Component.registerComponent('QualityAutoInfoButton', QualityAutoInfoButton);
export default QualityAutoInfoButton;
