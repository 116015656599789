import { videojs } from 'global/window';
import log from '../../../log';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Switch button for UHD Profile
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class UhdProfileButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.registerUhdSwitch();
  }

  createEl() {
    return super.createEl('span', {
      className: 'vjs-an-switch vjs-settings-content',
      innerHTML: `<label class="switch"><input class="vjs-uhd-checkbox" type="checkbox"${
        false ? ' checked=""' : ''
      }><span class="slider round"></span></label>`,
    });
  }

  updateCheckedValue(checked) {
    this.el_.getElementsByClassName('vjs-uhd-checkbox')[0].checked = checked;
  }

  getCheckedValue() {
    return this.el_.getElementsByClassName('vjs-uhd-checkbox')[0].checked;
  }

  handleClick(e) {
    e.preventDefault();
    // toggle checked attribut
    this.updateCheckedValue(!this.getCheckedValue());
    log(`[UHD switch button] handleclick - checked ${this.getCheckedValue()}`);

    this.player_.trigger({
      type: 'ott-uhdprofile-switch',
      enable: this.getCheckedValue(),
    });

    log('[UHD switch button] triggered ott-uhdprofile-switch');
  }

  registerUhdSwitch() {
    this.player_.on('ott-uhdprofile-control', (e) => {
      this.updateCheckedValue(e.uhdActive);
      log(
        `[UHD switch button] - recived uhd control event. UHD active: ${e.uhdActive} -> uhd button ${
          e.uhdActive ? 'enable' : 'disable'
        }`,
      );
    });
  }
}

export default UhdProfileButton;

Component.registerComponent('UhdProfileButton', UhdProfileButton);
