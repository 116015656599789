import { videojs } from 'global/window';

// Get a component to subclass
const DurationDisplay = videojs.getComponent('DurationDisplay');
const Component = videojs.getComponent('Component');

class PlaylistDurationDisplay extends DurationDisplay {
  /**
   * Builds the default DOM `className`.
   *
   * @return {string}
   *         The DOM `className` for this object.
   */
  buildCSSClass() {
    return 'vjs-playlist-duration';
  }

  /**
   * Update duration time display.
   * ! Extend method changed for playlist duration summary !
   * @param {EventTarget~Event} [event]
   *        The `durationchange`, `timeupdate`, or `loadedmetadata` event that caused
   *        this function to be called.
   *
   * @listens Player#durationchange
   * @listens Player#timeupdate
   * @listens Player#loadedmetadata
   */
  updateContent() {
    // total playlist time duration
    let duration = this.player_.playlist.playlistData.totalDuration;

    if (typeof this.player_.ads === 'object' && this.player_.ads.isAdPlaying()) {
      duration = this.player_.duration();
    }

    if (duration && this.duration_ !== duration) {
      this.duration_ = duration;
      this.updateTextNode_(duration);
    }
  }
}

Component.registerComponent('PlaylistDurationDisplay', PlaylistDurationDisplay);
export default PlaylistDurationDisplay;
