import log from '../log';

const ADD_QUALITY_LEVEL = 'addqualitylevel';

// when the qualityLevels plugin loads representations, check if they have width (audio only have width and height undefined)
export function disableAudioOnly(player) {
  player.qualityLevels().on(ADD_QUALITY_LEVEL, ({ qualityLevel }) => {
    const { bitrate, height, width } = qualityLevel;

    if (width) {
      qualityLevel.enabled = true;
      log(`[quality-levels] enabled qualityLevel ${width}×${height} - ${Number(bitrate).toLocaleString()}bit/s`);
    } else {
      qualityLevel.enabled = false;
      log('[quality-levels] disabled audio only qualityLevel');
    }
  });
}
