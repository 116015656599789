import log from '../log';
import propTest from '../property-tester';

// import adEvents from './measuring/shared/ad-events';
/* eslint-disable */
import availableEvents from './measuring/shared/available-events';
/* eslint-enable */

/**
 * SeekBack plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 * @param {Number} opts.backTimeSec - Time in seconds
 */

class SeekBack {
  constructor(player, opts = {}) {
    // reference of ott-player object
    this.player = player;
    this.seekBackSec = opts.backTimeSec || 3;
    this.snapshotChanged = false;
    log(`[seekBack] seekBackTime set to ${this.seekBackSec}`);
  }

  adStarted = () => {
    if (!propTest(() => this.player.ads.snapshot.currentTime)) {
      log.error('[seekback] change snapshot failed');
      return;
    }
    const seekBackTime = Math.floor(this.player.ads.snapshot.currentTime) - this.seekBackSec;
    log(`[seekBack] change snapshot, seek back from ${this.player.ads.snapshot.currentTime} to ${seekBackTime}`);
    this.player.ads.snapshot.currentTime = seekBackTime;
    this.snapshotChanged = true;
  };

  registerEvents() {
    this.player.on(availableEvents.AD_PLAY, ({ adStreamInfo }) => {
      if (adStreamInfo.adType.includes('midroll') && !this.snapshotChanged) {
        this.adStarted();
      }
    });

    this.player.on(availableEvents.ADS_BLOCK_END, () => {
      this.snapshotChanged = false;
    });
  }
}

/**
 * playedPercentual plugin initialization
 *
 */
export const seekBackPlugin = function seekBackPlugin(opts) {
  const seekBack = new SeekBack(this, opts);
  seekBack.registerEvents();
  log('[seekBack] plugin initialized');
};
