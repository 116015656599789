import { videojs } from 'global/window';

import SettingsMenu from '../menu';

const Component = videojs.getComponent('Component');

/**
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class InfoMenu extends SettingsMenu {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.el_.className = 'vjs-info-menu vjs-custom-menu vjs-hidden';
  }

  createContent() {
    const { settings } = this.player_.options_.plugins;
    const qualityControlOpts = this.player_.options_?.responsiveControl?.quality;
    const dropdownSettings = this.player_.options_?.styleControl?.dropdownSettings;

    if ((!dropdownSettings && qualityControlOpts) || (dropdownSettings && qualityControlOpts)) {
      this.registerAudioChannelsSwitch(settings, { dropdownSettings });
    }
    this.registerAutoplaySwitch(settings);
    this.registerNielsenMeasurementPlugin(settings);
    if (this.player_.options_?.plugins?.measuring?.services?.mux) {
      const { mux } = this.player_.options_.plugins.measuring.services;
      this.registerQualityMeasurementSwitch(mux);
    }
    this.registerPlayerVersionInfo();
  }
}

// Register the new component
Component.registerComponent('InfoMenu', InfoMenu);
export default InfoMenu;
