/**
 * Update volume control bar manually. (Player doesn't update volume component when ad is playing)
 * @param  {Object} player The player Object
 */
export const updateVolumeControl = (player) => {
  const volumePanel = player.controlBar.getChild('VolumePanel');
  const volumeControl = volumePanel.getChild('VolumeControl');

  const muteToggle = volumePanel.getChild('MuteToggle');
  const volumeBar = volumeControl.getChild('VolumeBar');

  muteToggle.update();
  volumeBar.update();
};

/**
 * Firefox, Edge and Safari ignoring muted: true in the init data
 * @param {Object} player The player
 * @param {Object} data   Player initialization data
 */
export const setMutedState = (player, data) => {
  if (typeof data.muted !== 'undefined' && player.muted() !== data.muted) {
    player.muted(data.muted);
  }

  // HACK: HLS live streams (chrome) have muted state set to true before the lines of code above are executed
  if (player.muted()) {
    // Preserve volume state even when defaultMuted is set to true
    const volumeChanged = () => {
      if (((player.tech_ || {}).el_ || {}).defaultMuted) {
        player.off(['advolumechange', 'volumechange'], volumeChanged);
        ((player.tech_ || {}).el_ || {}).defaultMuted = false;
      }
    };

    const videoLoaded = () => {
      player.off(['adloadeddata', 'loadeddata'], videoLoaded);
      player.one(['advolumechange', 'volumechange'], volumeChanged);
    };

    player.one(['adloadeddata', 'loadeddata'], videoLoaded);

    // HACK: If HLS live stream is muted by options, the UI is not rendered properly
    updateVolumeControl(player);
  }
};
