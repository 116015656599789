/* eslint-disable */
import { GemiusPlayer } from 'global/window';
import log from '../../log';
import {
  MEA_GEMIUS_MISSING,
  MEA_GEMIUS_INVALID_CONFIGURATION,
  MEA_GEMIUS_INVALID_PROGRAM_INFO,
  MEA_GEMIUS_INVALID_AD_INFO,
} from '../../errors';
import availableEvents from '../measuring/shared/available-events';
/* eslint-enable */

/**
 * Options for Gemius object (GemiusPlayer class).
 *
 * @typedef {object} GemiusOptions
 * @property {string} playerID                           - Brand of the player used to aggregate results of all
 *                                                       measured instances of the player with the single brand.
 * @property {string} accountID                          - Tag identifier (provided by Gemius), assigned to
 *                                                       unique gemiusPrism™ project, on which is recorded
 *                                                       conducted measurement result of given player.
 * @property {object} additionalParameters               - Dictionary with additional parameters describing
 *                                                       the player and its default settings.
 * @property {string} additionalParameters.currentDomain - Set name of domain in which given player were to be
 *                                                       embedded. Used in syndication in case of multi layered
 *                                                       frame and iframe embedding.
 * @property {number} additionalParameters.volume        - Pre-set % value of volume (range between 0 and 100).
 *                                                       In case of mute option, value should be set to -1.
 * @property {string} additionalParameters.resolution    - Pre-set value of player resolution (e.g. 1024x768).
 *                                                       Actual, physical size of player component (default size).
 */

/**
 * Options for Gemius newProgram event.
 * It can also contain additional attributes of a material. Its names and values are defined by the study participant.
 *
 * @typedef {object} NewProgramOptions
 * @property {string} programName             - Obligatory Title of the content broadcasted in the net parts of the programme.
 *                                            E.g. “The North Remembers” – title of the episode of the “Game of Thrones” series.
 * @property {number} programDuration         - Obligatory The total duration of the content broadcasted in the net parts of the
 *                                            programme in seconds. When it is not possible to evaluate duration (e.g. in live
 *                                            streaming, like direct streaming of TV channel) the value of programDuration
 *                                            should be set to -1.
 * @property {string} programType             - Obligatory Type of content. Allowed values are: ‘Audio’, ‘Video’.
 * @property {number} transmissionType        - Type of transmission:
 *                                            - 1 = On demand. The content is played only when user chooses to watch it.
 *                                            - 2 = Broadcast / Live program/ Time shift viewing TSV. The content is played live
 *                                            or according to Publishers schedule, e.g. TV materials.
 *                                            - Please send transmissionChannel and transmissionStartTime when transmissionType = 2 (TV/radio channel broadcast).
 *                                            - „On demand” is used when transmissionType is not set.
 * @property {string} transmissionChannel     - Name of TV or radio channel. Use this parameter when transmissionType = 2 (broadcast).
 *                                            - Max. 64 characters; allowed are: a-z, A-Z and national characters, 0-9, and special characters:
 *                                            _ . ! @ # * ( ) -/ ? : ; ~ $ ,).
 *                                            - E.g. BBC One.
 * @property {string} transmissionStartTime   - Transmission start time.
 *                                            - Use this parameter when transmissionType = 2 (broadcast).
 *                                            - Should be passed as a timstamp - the number of seconds that have elapsed since 00:00:00 UTC on 1 January 1970.
 *                                            - More information about usage of the parameter are available in Live streaming (broadcast) scriptingchapter.
 * @property {number} programGenre            - Program genre.
 *                                            - Allowed values are:
 *                                            1 = Live program (transmissionType = 2)
 *                                            2 = Film
 *                                            3 = Series, vlog
 *                                            4 = Program
 *                                            5 = Music (music video, song)
 *                                            6 = Trailer, teaser, part of the program
 * @property {string} programThematicCategory - Thematic Category. The list of available values is market-specific.
 *                                            - The use of this parameter is described in the additional document.
 * @property {string} series                  - Optional Hierarchical description of Series or other content broadcastedin
 *                                            Series/Season model, slash separated.
 *                                            E.g. ‘Game of Thrones/Season 1’ or ‘Champions League/Season 2014-2015’
 * @property {string} programSeason           - Name of season - set of episodes that run under the same title
 *                                            (max. 64 characters; allowed are: a-z, A-Z and national characters, 0-9,
 *                                            and special characters: _ . ! @ # * ( ) - / ? : ; ~ $ , ).
 *                                            - E.g. ‘season 1’ or ‘ Season 2014-2015.
 * @property {string} programPartialName      - Name  of  program  part  (max.  64  characters;  allowed are: a-z, A-Z and
 *                                            national characters, 0-9, and special characters: _ . ! @ # * ( ) - / ? : ; ~ $ , ).
 *                                            - E.g.  if ProgramName = “Poland – France UEFA  European Championship ”then ProgramPartialName = "Highlights".
 * @property {string} programProducer         - Name of the program producer (max. 64 characters.
 *                                            - Allowed are: a-z, A-Z and national characters, 0-9, and special characters:
 *                                            _ . ! @ # * ( ) - / ? : ; ~ $ , ).
 *                                            - E.g. Warner Bros
 * @property {string} typology                - Optional hierarchical categorization of the content, which can be common for the
 *                                            market aligned to TV study provider (i.e. like Nielsen’s: Sport/Football, Movie/Class B).
 * @property {string} premiereDate            - Optional Date of the first publication of the program content on client web site
 *                                            (YYYYMMDD format).
 * @property {string} externalPremiereDate    - Optional Date of the first publication out-site the service. For example can be used
 *                                            for pre-premiere analysis of TV series or movie market publication frame analysis,
 *                                            how long it takes from cinema to Internet (YYYYMMDD format).
 * @property {string} quality                 - Pre-set value (e.g. 1920x1080) of loaded material’s quality. Can be further adjusted
 *                                            by the internet user.
 * @property {string} resolution              - Pre-set value of loaded material’s resolution, which may alter default or user settings
 *                                            of player container. (e.g. 1280x720)
 * @property {number} volume                  - Pre-set value of loaded material’s volume, which may alter default or user settings
 *                                            of volume.
 */

/**
 * Options for Gemius newAd event.
 *
 * @typedef {object} NewAdOptions
 * @property {string} adName                 - Title of the advertisement (i.e. ‘Ferrari test drive’). Strongly advised to define it,
 *                                           to not rely only on separation via adID.
 * @property {number} adDuration             - Total length of the advertisement in seconds, integer value.
 * @property {string} adType                 - Type of advertisement. Proposed values are: ‘promo’, ‘spot’, ‘sponsor’.
 * @property {string} campaignClassification - Hierarchical classification of the campaign, including: campaign name, brand, producer
 *                                           (slash separated).
 * @property {number} adFormat               - Ad format. Allowed values are:
 *                                           - 1 -video
 *                                           - 2 -audio
 *                                           - "1” is used when adFormat is not set.
 * @property {string} quality                - Pre-set value (e.g. 1920x1080) of loaded commercial’s quality. Can be further adjusted
 *                                           by the internet user.
 * @property {string} resolution             - Pre-set value of loaded advertisement’s resolution, which may alter default or user
 *                                           settings of player container. (e.g. 1280x720)
 * @property {number} volume                 - Pre-set value of loaded advertisement’s volume, which may alter default or user settings
 *                                           of volume.
 */

/**
 * Transforms width and height into resolution string.
 * @private
 */
const getResolution = function getResolution(videoInfo) {
  return `${videoInfo.width}x${videoInfo.height}`;
};

/**
 * Transforms volume into Gemius format.
 * @private
 */
const getVolume = function getVolume(volume) {
  if (volume === 0) {
    return -1;
  }

  return Math.round(volume * 100);
};

/**
 * Gemius measuring service
 *
 * @param {object}        player            - The player instance
 * @param {GemiusOptions} gemiusOptions     - Gemius options object
 * @param {object}        resolution
 * @param {number}        resolution.width  - Physical width of the player
 * @param {number}        resolution.height - Physical heightof the player
 * @param {number}        volume            - Default volume of the player
 */
class Gemius {
  constructor(player, gemiusOptions, resolution, volume) {
    if (typeof GemiusPlayer === 'undefined') {
      log.error(MEA_GEMIUS_MISSING.code, MEA_GEMIUS_MISSING.message);
      return;
    }

    const { playerID, accountID, ...other } = gemiusOptions;

    if (typeof playerID === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_CONFIGURATION.code, MEA_GEMIUS_INVALID_CONFIGURATION.message, 'playerID');
    }

    if (typeof accountID === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_CONFIGURATION.code, MEA_GEMIUS_INVALID_CONFIGURATION.message, 'accountID');
    }

    const additionalParameters = {
      resolution: getResolution(resolution),
      volume: getVolume(volume),
      ...other,
    };

    this.player = player;
    this.gemiusObject = new GemiusPlayer(playerID, accountID, additionalParameters);
    this.watching = false;

    log('[GEMIUS] Object initialized.', playerID, accountID, additionalParameters);

    this.registerEvents();
  }

  onProgramLoaded({ streamInfo, videoInfo, duration, deviceType }) {
    if (typeof streamInfo === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_PROGRAM_INFO.code, MEA_GEMIUS_INVALID_PROGRAM_INFO.message, 'streamInfo');
      return;
    }

    const { programID, ...programInfo } = streamInfo;

    if (typeof programID === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_PROGRAM_INFO.code, MEA_GEMIUS_INVALID_PROGRAM_INFO.message, 'programID');
      return;
    }

    const requiredParameters = ['programName', 'programType'];
    let missingProp;
    requiredParameters.forEach((param) => {
      if (typeof programInfo[param] === 'undefined') missingProp = param;
    });
    if (missingProp) {
      log.error(MEA_GEMIUS_INVALID_PROGRAM_INFO.code, MEA_GEMIUS_INVALID_PROGRAM_INFO.message, missingProp);
      return;
    }

    if (typeof programInfo.programDuration === 'undefined') {
      if (typeof duration !== 'undefined') {
        programInfo.programDuration = duration;
      } else {
        log.error(MEA_GEMIUS_INVALID_PROGRAM_INFO.code, MEA_GEMIUS_INVALID_PROGRAM_INFO.message, 'programDuration');
        return;
      }
    }
    programInfo.volume = getVolume(videoInfo.volume);
    programInfo.deviceType = deviceType;

    // transmissionType of type "2" is a "broadcast" or "live"
    if (streamInfo.transmissionType === Number(2)) {
      programInfo.transmissionStartTime = new Date().getTime().toString(); // current timestamp as string
    }

    this.newProgram(programID, programInfo);
  }

  // FIXME: probably rewrite will be needed
  onAdLoaded({
    // streamInfo,
    adStreamInfo,
    videoInfo,
  }) {
    if (typeof adStreamInfo === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_AD_INFO.code, MEA_GEMIUS_INVALID_AD_INFO.message, 'adStreamInfo');
      return;
    }

    const { adID, ...adInfo } = adStreamInfo;

    if (typeof adID === 'undefined') {
      log.error(MEA_GEMIUS_INVALID_AD_INFO.code, MEA_GEMIUS_INVALID_AD_INFO.message, 'adID');
      return;
    }

    adInfo.quality = videoInfo.quality;
    adInfo.resolution = getResolution(videoInfo);
    adInfo.volume = getVolume(videoInfo.volume);
    adInfo.adFormat = 1;

    // if (this.player.adstate.currentAdType) {

    //   this.programEventBreak(streamInfo.programID, this.player.ads?.snapshot?.currentTime);
    // }

    this.newAd(adID, adInfo);
  }

  /**
   * Simple helper for event firing.
   * @private
   */
  onProgramEvent(method, { streamInfo }) {
    const timeShiftSeek = this.player.timeShiftState?.seekTime || 0;
    const offset = this.player.isLivePlayer
      ? Math.floor(Date.now() / 1000 - timeShiftSeek)
      : Math.floor(this.player.currentTime());
    this[method](streamInfo.programID, offset);
  }

  /**
   * Simple helper for event firing.
   * @private
   */
  onAdEvent(method, { streamInfo, adStreamInfo = {} }) {
    this[method](streamInfo.programID, Math.floor(this.player.currentTime()), adStreamInfo.adID);
  }

  registerEvents() {
    // fire newProgram Gemius event
    this.player.on(availableEvents.PROGRAM_LOADED, (e) => this.onProgramLoaded(e));

    // fire newAd Gemius event
    this.player.on(availableEvents.AD_LOADED, (e) => this.onAdLoaded(e));

    // fire programEvent[play] event
    this.player.on(availableEvents.PLAY, ({ streamInfo, currentTime, videoInfo, autoplay, partID }) => {
      this.programEventPlay(streamInfo.programID, currentTime, {
        autoPlay: autoplay,
        partID,
        resolution: getResolution(videoInfo),
        volume: getVolume(videoInfo.volume),
      });
    });

    // fire programEvent[play] event
    this.player.on(
      availableEvents.AD_PLAY,
      ({ streamInfo, adStreamInfo, currentTime, videoInfo, autoplay, adPosition, breakSize }) => {
        this.adEventPlay(
          streamInfo.programID,
          currentTime,
          {
            autoPlay: autoplay,
            adPosition,
            breakSize,
            resolution: getResolution(videoInfo),
            volume: getVolume(videoInfo.volume),
          },
          adStreamInfo.adID,
        );
      },
    );

    this.player.on(availableEvents.ADS_BLOCK_START, (e) => this.onProgramEvent('programEventBreak', e));

    // fire programEvent[stop] event
    this.player.on(availableEvents.STOP, (e) => this.onProgramEvent('eventStop', e));

    // fire programEvent[pause] event
    this.player.on(availableEvents.PAUSE, (e) => this.onProgramEvent('eventPause', e));

    // fire adEvent[pause] event
    this.player.on(availableEvents.AD_PAUSE, (e) => this.onAdEvent('eventPause', e));

    // fire programEvent[buffer] event
    this.player.on(availableEvents.BUFFER, (e) => this.onProgramEvent('eventBuffer', e));

    // fire programEvent[seek] event
    this.player.on(availableEvents.SEEK, (e) => this.onProgramEvent('eventSeek', e));

    // fire programEvent[complete] event
    this.player.on(availableEvents.AD_COMPLETE, (e) => this.onAdEvent('eventComplete', e));

    // fire ad[complete] event
    this.player.on(availableEvents.COMPLETE, (e) => this.onProgramEvent('eventComplete', e));

    // fire programEvent[close] event
    this.player.on(availableEvents.CLOSE, (e) => this.onProgramEvent('eventClose', e));

    // fire programEvent[skip] event
    this.player.on(availableEvents.SKIP, (e) => this.onAdEvent('eventSkip', e));

    // fire programEvent[next] event
    this.player.on(availableEvents.NEXT, ({ streamInfo, currentTime, listID }) => {
      this.programEventNext(streamInfo.programID, currentTime, {
        listID,
      });
    });

    // fire programEvent[prev] event
    this.player.on(availableEvents.PREV, ({ streamInfo, currentTime, listID }) => {
      this.programEventPrev(streamInfo.programID, currentTime, {
        listID,
      });
    });

    // fire programEvent[chngRes] event
    this.player.on(
      availableEvents.PLAYER_RESOLUTION_CHANGED,
      ({ streamInfo, adStreamInfo, currentTime, resolution }) => {
        this.eventChngRes(
          streamInfo.programID,
          currentTime,
          {
            resolution: getResolution(resolution),
          },
          typeof adStreamInfo !== 'undefined' ? adStreamInfo.adID : undefined,
        );
      },
    );

    // fire programEvent[chngVol] event
    this.player.on(availableEvents.VOLUME_CHANGED, ({ streamInfo, adStreamInfo, currentTime, volume }) => {
      this.eventChngVol(
        streamInfo.programID,
        currentTime,
        {
          volume: getVolume(volume),
        },
        typeof adStreamInfo !== 'undefined' ? adStreamInfo.adID : undefined,
      );
    });

    // fire programEvent[chngQual] event
    this.player.on(availableEvents.QUALITY_CHANGED, ({ streamInfo, adStreamInfo, currentTime, quality }) => {
      this.eventChngQual(
        streamInfo.programID,
        currentTime,
        {
          quality,
        },
        typeof adStreamInfo !== 'undefined' ? adStreamInfo.adID : undefined,
      );
    });
  }

  /**
   * Enable watching
   */
  startWatching() {
    this.watching = true;
  }

  /**
   * Sends program information.
   *
   * After streaming content is loaded into the player, it is necessary to pass its description to
   * gemiusPrism™. For this purpose, newProgram function of the player object has to be called.
   * This has to be done before and regardless of whether or not actual emission of content or
   * advertising spots started (be it via user’s action or auto-play function).
   *
   * @param {string}                programID            - The unique identifier of the content broadcasted
   *                                                     in the net parts of the programme (maximum 64
   *                                                     alpha-numeric characters in length).
   *                                                     E.g. “123456” for the “Game of Thrones”, season 2, episode 1
   * @param {NewProgramOptions}     additionalParameters - Dictionary with additional parameters describing the
   *                                                     material loaded to the player and its settings.
   */
  newProgram(programID, additionalParameters) {
    this.gemiusObject.newProgram(programID, additionalParameters);
    log('[GEMIUS] Event "newProgram" has been fired. Parameters: ', programID, additionalParameters);
  }

  /**
   * Sends ad information.
   *
   * @param {string}       adID                 - A unique identifier (maximum 64 alpha-numeric
   *                                            characters) of an advertisement.
   * @param {NewAdOptions} additionalParameters - Dictionary with additional parameters describing
   *                                            the advertisement and its settings.
   */
  newAd(adID, additionalParameters) {
    this.gemiusObject.newAd(adID, additionalParameters);
    log('[GEMIUS] Event "newAd" has been fired. Parameters: ', adID, additionalParameters);
  }

  /**
   * Fires a Gemius program or ad event with specified parameters
   *
   * @param {string} programID            - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset               - Offset in content in seconds where the event occurs.
   * @param {string} type                 - Type of the event
   * @param {object} additionalParameters - Additional parameters of event
   * @param {string} adID                 - A unique advertising spot identifier declared in the earlier newAd function call.
   *                                      If specified "adEvent" will be called otherwise "programEvent"
   */
  event(programID, offset, type, additionalParameters, adID) {
    if (typeof adID === 'undefined') {
      this.gemiusObject.programEvent(programID, offset, type, additionalParameters);
      log('[GEMIUS] Event "programEvent" has been fired. Parameters: ', programID, offset, type, additionalParameters);
    } else {
      this.gemiusObject.adEvent(programID, adID, offset, type, additionalParameters);
    }
  }

  /**
   * This method must be called just before playing a program.
   *
   * @param {string} programID                       - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                          - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters            - Additional parameters of event
   * @param {bool}   additionalParameters.autoPlay   - Information on mode in which material is being started.  Allowed
   *                                                 values are: ‘true’, ‘false’.
   * @param {number} additionalParameters.partID     - Position of the partial in program from 1..n. If due to configuration
   *                                                 of the service, user can start program from the middle of second partial
   *                                                 this number is 2 – absolute position of the part viewed.
   * @param {string} additionalParameters.resolution - Resolution to which player auto-switched on start of viewing the material.
   * @param {number} additionalParameters.volume     - Volume to which player auto-switched on start of viewing the material.
   */
  programEventPlay(programID, offset, additionalParameters) {
    this.event(programID, offset, 'play', additionalParameters);
  }

  /**
   * This method must be called just before playing a program.
   *
   * @param {string} programID                       - A unique program identifier declared in the earlier newProgram function call.
   * @param {string} adID                            - A unique advertising spot identifier declared in the earlier newAd function call.
   * @param {number} offset                          - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters            - Additional parameters of event
   * @param {bool}   additionalParameters.autoPlay   - Information on mode in which material is being started.  Allowed
   *                                                 values are: ‘true’, ‘false’.
   * @param {number} additionalParameters.adPosition - Position of advertisement in break.
   * @param {number} additionalParameters.breakSize  - Number of ads in break given ad belongs to.
   * @param {string} additionalParameters.resolution - Resolution to which player auto-switched on start of viewing the advertisement.
   * @param {number} additionalParameters.volume     - Volume to which player auto-switched on start of viewing the advertisement.
   */
  adEventPlay(programID, offset, additionalParameters, adID) {
    this.event(programID, offset, 'play', additionalParameters, adID);
  }

  /**
   * User pressed ‘stop’ button on the player or took an action with result equal to it; view of material
   * ceased and progress returned to the start of the material.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventStop(programID, offset, adID) {
    this.event(programID, offset, 'stop', null, adID);
  }

  /**
   * User pressed ‘pause’ button on the player or took an action with result equal to it; viewing of
   * material temporarily ceased and progress remains at position this event occurred.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventPause(programID, offset, adID) {
    // Player for some internal reasons emit pause when it loads new media (ad/content) and when it ends, so this filters that out
    if (this.player.duration() !== this.player.currentTime() && this.player.currentTime() !== 0) {
      this.event(programID, offset, 'pause', null, adID);
    }
  }

  /**
   * Buffering – user did not take any action but player concluded playing already loaded chunk of
   * material and attempts to load another chunk from the server before resuming emission.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventBuffer(programID, offset, adID) {
    this.event(programID, offset, 'buffer', null, adID);
  }

  /**
   * Break – user did not take any action but player ceased playing loaded material in order to emit
   * commercial block, after which material emission resumes (next part is played or programme
   * concludes in case of post-roll).
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   */
  programEventBreak(programID, offset) {
    this.event(programID, offset, 'break', null);
  }

  /**
   * Seeking – user switched to inconsequent time point of material, e.g. clicked in progress bar or took
   * an action with result equal to it, attempting to skip part of material or return to earlier portion of it in
   * comparison to current position in material.
   * @todo Is property offset an old offset before seeking or new offset after seeking?
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventSeek(programID, offset, adID) {
    this.event(programID, offset, 'seek', null, adID);
  }

  /**
   * Completion – last second of material or ad has been emitted.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventComplete(programID, offset, adID) {
    this.event(programID, offset, 'complete', null, adID);
  }

  /**
   * Close - conclusion of material viewing by closing a browser window. Event close has to be
   * reported only when the material is not yet finished and it was changed to another material or player
   * window closed.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventClose(programID, offset, adID) {
    this.event(programID, offset, 'close', null, adID);
  }

  /**
   * Skip – user used a skip button or took an action with results equal to it, which moved emission to
   * next part of material or point in progress within the single material.
   *
   * @param {string} programID - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset    - Offset in content in seconds where the event occurs.
   * @param {string} [adID]    - A unique advertising spot identifier declared in the earlier newAd function call.
   *                           If specified "adEvent" will be called otherwise "programEvent"
   */
  eventSkip(programID, offset, adID) {
    this.event(programID, offset, 'skip', null, adID);
  }

  /**
   * Next – user used a next button or took an action with result equal to it, which changes played
   * material to pre-set new material ( next item on the list).
   *
   * @param {string} programID                   - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                      - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters        - Additional parameters of event
   * @param {number} additionalParameters.listID - Unique identifier of the list which items were used to navigate the content.
   */
  programEventNext(programID, offset, additionalParameters) {
    this.event(programID, offset, 'next', additionalParameters);
  }

  /**
   * Previous – user used a previous button or took an action with result equal to it, which changes
   * played material to pre-set new material (previous item on the list).
   *
   * @param {string} programID                   - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                      - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters        - Additional parameters of event
   * @param {number} additionalParameters.listID - Unique identifier of the list which items were used to navigate the content.
   */
  programEventPrev(programID, offset, additionalParameters) {
    this.event(programID, offset, 'prev', additionalParameters);
  }

  /**
   * Resolution change – user changed resolution of the player or took an action with results equal to
   * it, during emission of an ad or material.
   *
   * @param {string} programID                       - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                          - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters            - Additional parameters of event
   * @param {number} additionalParameters.resolution - Value of player resolution (e.g. 1024x768) set by the user. Actual, physical size
   *                                                 of player window.
   * @param {string} [adID]                          - A unique advertising spot identifier declared in the earlier newAd function call.
   *                                                 If specified "adEvent" will be called otherwise "programEvent"
   */
  eventChngRes(programID, offset, additionalParameters, adID) {
    this.event(programID, offset, 'chngRes', additionalParameters, adID);
  }

  /**
   * Volume change – user used a change volume slider, mute button or took an action with results
   * equal to it, adjusting player’s volume.
   *
   * @param {string} programID                   - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                      - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters        - Additional parameters of event
   * @param {number} additionalParameters.volume - % value of set volume (range between 0 and 100) by the user. In case of mute,
   *                                             value should be set to -1.
   * @param {string} [adID]                      - A unique advertising spot identifier declared in the earlier newAd function call.
   *                                             If specified "adEvent" will be called otherwise "programEvent"
   */
  eventChngVol(programID, offset, additionalParameters, adID) {
    this.event(programID, offset, 'chngVol', additionalParameters, adID);
  }

  /**
   * Volume change – user used a change volume slider, mute button or took an action with results
   * equal to it, adjusting player’s volume.
   *
   * @param {string} programID                    - A unique program identifier declared in the earlier newProgram function call.
   * @param {number} offset                       - Offset in content in seconds where the event occurs.
   * @param {object} additionalParameters         - Additional parameters of event
   * @param {number} additionalParameters.quality - Value (e.g. 1920x1080) of content quality set by the user.
   * @param {string} [adID]                       - A unique advertising spot identifier declared in the earlier newAd function call.
   *                                              If specified "adEvent" will be called otherwise "programEvent"
   */
  eventChngQual(programID, offset, additionalParameters, adID) {
    this.event(programID, offset, 'chngQual', additionalParameters, adID);
  }
}

export default Gemius;
