import { videojs } from 'global/window';
import { EVT_NOPREROLL } from '../../constants';
import log from '../../log';
import propTest from '../../property-tester';
// No ES6 import availabale. Videojs is bundled separately

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Shows play progress
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Button
 */
class TimeShiftCancelButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.urlParam = options.urlParam;
    this.disabled = options.disabled || false;
    this.controlTextEl_.innerHTML = options.name;
    options.cssClass?.forEach((value) => this.el_.classList.add(value));
  }

  buildCSSClass() {
    return `vjs-ts-cancel-button ${super.buildCSSClass()}`;
  }

  /**
   * Reset time-shift state when cancel button clicked
   */
  handleClick() {
    if (!propTest(() => this.player_.options_.plugins.liveRestartButton.eventsOnly)) {
      this.player_.one('contentchanged', () => {
        this.player_.trigger(EVT_NOPREROLL);
      });
      if (!this.disabled) {
        this.player_.timeshiftSeek(0);
      }
    }

    this.player_.trigger('ott-backtolive-click');
    log('[timeshift] ott-backtolive-click triggered');
  }
}
// Register the new component
Component.registerComponent('TimeShiftCancelButton', TimeShiftCancelButton);
export default TimeShiftCancelButton;
