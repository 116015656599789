import log from '../../log';
import avlAdType from './shared/ad-type';
import getInventory from './shared/get-inventory';
import Cascade from './cascade';
import { registerCompanionTracking } from './shared/vast-tracker';
import { PLAYER_ID, COMPANION_SUPPORTED_TYPES } from '../../constants';

/**
 * Cares about all companion process
 *
 * @param {Object} adstate  Advertisement state container object
 * @param {Object} player   Video.js player instance
 */
class AdsCompanion {
  constructor(adstate, player) {
    this.adstate = adstate;
    this.player = player;
    this.compWrapperId = 'companion-wrapper';
    this.closeWrapper = 'companion-close-wrapper';
    this.originalBodyStyle = '';
    this.relocatePlayer = null;
  }

  /**
   * Remove image companion from the DOM
   */
  removeImageCompanion() {
    // When companion wrapper exist remove it
    const compWrapper = document.getElementById(this.compWrapperId);
    if (compWrapper) {
      // Remove window.resize listener
      window.removeEventListener(['resize', 'playerresize'], this.relocatePlayer);

      compWrapper.parentNode.removeChild(compWrapper);

      // Removes fullscreen state on the body
      document.body.className = document.body.className.replace(/\bis-fullscreenad\b/, '');
      // Removes body companion styles
      if (this.originalBodyStyle) {
        document.body.setAttribute('style', this.originalBodyStyle);
      } else {
        document.body.removeAttribute('style');
      }

      // Removes wrapper location
      document.getElementById(PLAYER_ID).removeAttribute('style');

      // Reset companion adstate
      this.adstate.currentAdtype = '';
    }
  }

  /**
   * Get VAST asset height if exists or is higher than innerHeight
   *
   * @retunrs {Number} Window height (overflow-y)
   */
  getAssetHeight() {
    let height = innerHeight;
    if (this.companion) {
      const { assetHeight } = this.companion;
      if (assetHeight && assetHeight > innerHeight) {
        height = assetHeight;
      }
    }
    return height;
  }

  /**
   * Append image companion to the body of the document
   *
   * @private
   * @param {Object} companion - Image companion object
   */
  appendImageCompanion(companion) {
    const docElement = document.createElement('div');
    const aCompanionAsset = document.createElement('a');
    const aCloseElement = document.createElement('a');
    const divCloseElement = document.createElement('div');
    const that = this;
    let xPosition = 0;
    let yPosition = 0;
    let windowWidth;
    let bgColor = '';

    const assetWidth = companion.assetWidth || 1920;

    // Read available adParameters
    if (companion.adParameters.backgroundColor) {
      bgColor = `background-color: ${companion.adParameters.backgroundColor};`;
    }
    if (companion.adParameters.xPosition) {
      xPosition = companion.adParameters.xPosition;
    }
    if (companion.adParameters.yPosition) {
      yPosition = companion.adParameters.yPosition;
    }

    // Set companion asset attributes and values
    // FIXME the behavior of multiple sources is not defined
    aCompanionAsset.setAttribute(
      'style',
      `background: url(${companion.staticResources[0].url}) center 0 no-repeat; height: 100%; display: block;`,
    );
    aCompanionAsset.setAttribute('target', '_blank');
    aCompanionAsset.setAttribute('id', 'companion-link');
    aCompanionAsset.href = companion.companionClickThroughURLTemplate;

    const aCompanionClickEvt = function aCompanionClickEvt(e) {
      e.preventDefault();
      // pause player when click on the ad link
      that.player.pause();
      // Trigger companion click throug
      that.player.trigger({
        type: 'companionclickthrough',
        companion,
      });
      // call measurement pause event
      that.player.trigger('pauseclicked');
      window.open(companion.companionClickThroughURLTemplate);
    };
    aCompanionAsset.onclick = aCompanionClickEvt;

    // Set close button attributes and values
    aCloseElement.setAttribute('id', 'companion-close');
    aCloseElement.setAttribute('title', this.player.localize('Close Ad'));
    aCloseElement.className = 'vjs-icon-close';
    aCloseElement.href = '#';

    // Set close wrapper attributes
    divCloseElement.setAttribute('id', this.closeWrapper);

    // Call companion ended event when user click on the close button
    aCloseElement.onclick = function closeElementOnClick(e) {
      e.preventDefault();
      that.player.trigger({
        type: 'companionended',
        skipped: true,
        companion,
      });
    };

    // Set div attributes and values
    docElement.setAttribute('id', this.compWrapperId);
    docElement.setAttribute(
      'style',
      `${bgColor} position: absolute; top: 0; bottom: 0;` +
        `left: 0; right: 0; width: 100%; height: ${this.getAssetHeight()}px;`,
    );

    // Set style to the body element
    const originalStyle = document.body.getAttribute('style');
    if (originalStyle) {
      this.originalBodyStyle = originalStyle;
    }
    // eslint-disable-next-line
    document.body.setAttribute(
      'style',
      `overflow-y: hidden; height: ${this.getAssetHeight()}px; ${this.originalBodyStyle}`,
    );

    // Add fullscreen class to the player wrapper
    const playerWrapper = document.getElementById(PLAYER_ID);

    // window.resize callback function
    this.relocatePlayer = function relocatePlayer() {
      // Read current window width/height
      windowWidth = document.documentElement.clientWidth;
      let leftPos = Math.floor(xPosition - (assetWidth - windowWidth) / 2);
      let topPos = Math.floor(yPosition);

      // const style = playerWrapper.getAttribute('style');
      playerWrapper.setAttribute('style', `top: ${topPos}px; left: ${leftPos}px;`);

      // Synchronize position with current CSS settings (remove parrent padding)
      leftPos = leftPos + leftPos - (playerWrapper.offsetLeft - playerWrapper.scrollLeft + playerWrapper.clientLeft);
      topPos = topPos + topPos - (playerWrapper.offsetTop - playerWrapper.scrollTop + playerWrapper.clientTop);
      playerWrapper.setAttribute('style', `top: ${topPos}px; left: ${leftPos}px;`);
    };

    // lListen for window.resize
    window.addEventListener(['resize', 'playerresize'], this.relocatePlayer);

    // Set fullscreen state to the body element
    document.body.className += ' is-fullscreenad';

    // Append elements to the DOM
    docElement.appendChild(aCompanionAsset);
    divCloseElement.appendChild(aCloseElement);
    docElement.appendChild(divCloseElement);
    document.body.appendChild(docElement);

    // call once to initialize page
    this.relocatePlayer();
  }

  /**
   * Register events for the image companion and append it to the DOM
   *
   * @private
   * @param {object} ad - current ad
   * @param {object} variation - Concrete creative variation
   */
  registerImageCompanion(ad, creative, variation) {
    // Set that we are currently showing companion
    this.adstate.currentAdtype = avlAdType.companion;

    // Register tracking events
    registerCompanionTracking(this.player, ad, creative, variation);

    // When state set to nonLinear trigger this event
    this.player.trigger('companioncanplay');

    // Preserve this for the event
    const that = this;

    // When companion have AdParameters value try to parse it as JSON
    if (variation.adParameters) {
      try {
        variation.adParameters = JSON.parse(variation.adParameters);
      } catch (error) {
        variation.adParameters = {};
      }
    } else {
      variation.adParameters = {};
    }

    // Save current companion as local scope object
    this.companion = variation;

    this.appendImageCompanion(variation);

    this.player.trigger({
      type: 'companionstarted',
      height: that.getAssetHeight(),
      companion: variation,
    });

    // Handle ended event
    this.player.one('companionended', function companionended() {
      that.removeImageCompanion();
      // Reset companion adstate
      this.adstate.currentAdtype = '';
    });
  }

  /**
   * Show appropriate companion ad
   *
   * @param {Int} adi - Used when break inventory contains more than 1 object
   */
  showAd(adi) {
    // Reset previous cascade
    Cascade.resetShownCascade(this.player, this.adstate.currentAdtype);

    // Select break inventory (this function is defined in the integration plugin)
    const compInventory = getInventory(this.adstate, avlAdType.companion, adi);

    // Loop through ads
    for (let adIdx = 0, adLen = compInventory.ads.length; adIdx < adLen; adIdx++) {
      // Selected ad
      const ad = compInventory.ads[adIdx];
      // Loop through creatives
      for (let creaIdx = 0, creaLen = ad.creatives.length; creaIdx < creaLen; creaIdx++) {
        // Selected creative
        const creative = ad.creatives[creaIdx];
        if (creative.type === 'companion') {
          // Loop through variations
          for (let cpIdx = 0, cpLen = creative.variations.length; cpIdx < cpLen; cpIdx++) {
            // Selected companion
            const variation = creative.variations[cpIdx];
            const variationStaticResources = variation.staticResources || [];
            // FIXME the behavior of multiple sources is not defined
            const type = variationStaticResources[0] ? variationStaticResources[0].creativeType.toLowerCase() : null;

            if (COMPANION_SUPPORTED_TYPES.includes(type)) {
              this.registerImageCompanion(ad, creative, variation);
            } else {
              log.warn('[companion] type of companion not supported', type);
            }
          }
        }
      }
    }
  }
}

export default AdsCompanion;
