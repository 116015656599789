import is from 'is_js';

export function matchedPreferredTech(preferredTech = []) {
  const preferredTechInfo = preferredTech.find((tech) => {
    const techOs = tech.os.toLowerCase();
    const techBrowser = tech.browser.toLowerCase();

    const matchOs = is[techOs]();
    let matchBrowser = is[techBrowser]();

    // is_js doesn't work with new Edge browser
    if (techBrowser === 'edge') {
      matchBrowser = videojs.browser.IS_EDGE;
    }

    return matchOs && matchBrowser;
  });

  return preferredTechInfo || {};
}
