import { videojs } from 'global/window';
import log from '../../log';

// Get a component to subclass
const MenuItem = videojs.getComponent('MenuItem');
const Component = videojs.getComponent('Component');

class SeekHintsMenuItem extends MenuItem {
  constructor(player, options = {}) {
    super(player, options);
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-hints-item',
      innerHTML: `
        <div class="time">${videojs.formatTime(this.options_.time)}</div>
        <div class="title">${this.options_.label}</div>
      `,
    });
  }

  handleClick() {
    const player = this.player_;
    player.currentTime(this.options_.time);

    // Trigger playertick event on every played second
    player.trigger({
      type: 'ott-hints-seek',
      currentTime: this.options_.time,
    });
    log('[Seek hints plugin] currentTime is set to ', this.options_.time);
  }
}

Component.registerComponent('SeekHintsMenuItem', SeekHintsMenuItem);
export default SeekHintsMenuItem;
