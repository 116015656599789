import PersistSettings from '../settings/persist-settings';

/**
 * Try to find if autoplay plugin is enabled from the previous user state
 * @param  {Object}  player The player instance
 * @return {Boolean}        True when autoplay plugin should be enabled, false otherwise
 */
export function isPersistAutoplayNext(player) {
  let persistAutoplayNext = true;
  // Try to read persisted stored language
  const options = (player.options_.plugins.settings || {}).persistSettings;
  // Check if persist settings is enabled for this plugin
  if (options && options.persistAutoplayNext) {
    const persistSettings = new PersistSettings(player, options);
    // Set autoplay state from the persist settings
    const persistValue = persistSettings.isPersistAutoplayNext();
    persistAutoplayNext = typeof persistValue === 'boolean' ? persistValue : true;
  }

  return persistAutoplayNext;
}
