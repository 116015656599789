import { percentToSec, hhmmssToSec } from './plugins/adservice/shared/time-helper';

/**
 * Markers helper class. E.g. reset markers, ..
 *
 * @param {Object} player   Player object
 * @param {Object} adstate  Advertisement state container object
 */
export default class BaseMarkers {
  constructor(player) {
    this.player = player;
    this.options = this.player.options_;
    this.totalDuration = this.player.duration();
    this.seekHints = this.options.plugins.seekHints || [];
    this.adMarkers = [];
  }

  /**
   * Reset player timeline markers
   */
  reset() {
    return new Promise((resolve) => {
      this.generateMarkers();

      if (typeof this.player.markers === 'function') {
        const nonAdMarkers = this.seekHints.map((ad) => this.adMarkerObj(ad, 'vjs-marker-hint', this.totalDuration));
        const adMarkers = this.player.adstate.inventory.midrolls
          .filter((ad) => !ad.requested)
          .map((ad) => this.adMarkerObj(ad, 'vjs-marker-ad', this.totalDuration));

        this.player.markers({ markers: [...adMarkers, ...nonAdMarkers] });
        return;
      }

      const nonAdMarkers = this.seekHints.map((ad) => this.adMarkerObj(ad, 'vjs-marker-hint', this.totalDuration));

      this.player.markers.reset(this.adMarkers.concat(nonAdMarkers));
      resolve();
    });
  }

  generateMarkers() {
    // this method is intended for exchange in descendants
    this.adMarkers = [];
  }

  adMarkerObj(ad, cls, totalDuration) {
    let timeOffset = typeof ad === 'object' ? ad.time : ad;

    if (timeOffset.toString().includes('%')) {
      timeOffset = percentToSec(timeOffset, totalDuration);
    } else if (timeOffset.toString().includes(':')) {
      timeOffset = hhmmssToSec(timeOffset);
    } else {
      timeOffset = ad.time || ad;
    }

    return {
      time: timeOffset,
      class: cls,
    };
  }
}
