import { videojs } from 'global/window';
import SeekHintsMenuItem from './menu-item';
import SeekHintsMenuHeader from './menu-heading';
import log from '../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const Menu = videojs.getComponent('Menu');

class SeekHintsMenu extends Component {
  constructor(player, options = {}) {
    super(player, options);

    this.update();

    this.enabled_ = true;
  }

  update() {
    const header = new SeekHintsMenuHeader(this.player_);
    const menu = this.createMenu();

    if (this.menu) {
      this.removeChild(this.menu);
    }

    this.menu = menu;
    this.addChild(header);
    this.addChild(menu);

    this.el_.className = 'vjs-hints-menu vjs-custom-menu vjs-hidden';
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createMenu() {
    const menu = new Menu(this.player_);

    this.items = this.createItems();
    this.createHintsMarkers();
    if (this.items) {
      // Add menu items to the menu
      for (let i = 0; i < this.items.length; i++) {
        menu.addItem(this.items[i]);
      }
    }

    return menu;
  }

  // init hints markers on timeline
  // required markers plugin on player
  createHintsMarkers() {
    const hints = this.player_.options_.plugins.seekHints;
    const hintsMarkers = [];
    hints.forEach((item) => {
      hintsMarkers.push({
        time: item.time,
        class: 'vjs-marker-hint',
      });
    });

    this.player_.ready(() => {
      if (typeof this.player_.markers.add === 'function') {
        this.player_.markers.add(hintsMarkers);
        log('[Seek hints] hints markers added to timeline');
      } else {
        log('[Seek hints] hints markers NOT added to timeline. Markers plugin not initialized!');
      }
    });
  }

  createItems(items = []) {
    const hints = this.player_.options_.plugins.seekHints;
    hints.forEach((item) => {
      items.push(
        new SeekHintsMenuItem(this.player_, {
          label: item.text,
          time: item.time,
        }),
      );
    });

    return items;
  }
}

// Register the new component
Component.registerComponent('SeekHintsMenu', SeekHintsMenu);
export default SeekHintsMenu;
