import { videojs } from 'global/window';
import './quality-menu-item';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream qualities buttons wrapper
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityButtonWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.createContent();
    this.el_.className = 'vjs-quality-content vjs-settings-content';
  }

  /*
   * Register 'QualityMenuItem' for each quality
   *
   * @param {Array} qualities - Array of qualities
   */
  createContent() {
    const sortedQualities = [...this.player_.qualityLevels().levels_]
      .map((item, index) => ({ ...item, index }))
      .sort((a, b) => b.height - a.height);

    sortedQualities
      .filter((level) => !!level.width)
      .forEach((quality) => {
        this.addChild('QualityMenuItem', {
          qualitySelector: this.options.qualitySelector,
          qualities: [...this.player_.qualityLevels().levels_],
          quality,
        });
      });
  }
}

Component.registerComponent('QualityButtonWrapper', QualityButtonWrapper);
export default QualityButtonWrapper;
