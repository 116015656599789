/**
 * <p>UI menu helper module</p>
 *
 * @module utils/cancel-active-buttons
 */

/**
 * Array of all custom controlBar components
 */
export const controlBarItems = Object.freeze([
  'QualityButton',
  'LanguagesButton',
  'SubtitlesButton',
  'SettingsButton',
  'InfoButton',
]);

/**
 * Will reset state of buttons in controlBar
 *
 * @param {Object} player - Player instance
 * @param {Array.<String>=} controlBar - Array of all custom player controlBar components
 */
const cancelActiveButtons = (player, controlBar = controlBarItems) => {
  controlBar.forEach((item) => {
    const controlBarItem = player.controlBar.getChild(item);
    if (controlBarItem && controlBarItem.hasClass('vjs-button-active')) {
      controlBarItem.removeClass('vjs-button-active');
    }
  });
};

export default cancelActiveButtons;
