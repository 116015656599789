import { videojs } from 'global/window';
import cs from '../../dictionaries/cs.json';
import en from '../../dictionaries/en.json';
import log from './log';

export function registerLanguages() {
  log('[ott-localization] added localization .jsons');
  videojs.addLanguage('cs', cs);
  videojs.addLanguage('en', en);
}
