/**
 * <p>Creates a click screen div to handle custom code when user click on the player screen. <b>This plugin is initialized automatically.</b></p>
 *
 * <h3>Custom events tracking:</h3>
 * <b>clcikScreen-playToggle-on</b> - click screen functionality allowed
 * <b>clickScreen-playToggle-off</b> - click screen functionality disallowed
 *
 * @module plugins/ClickScreen
 *
 */
import { videojs } from 'global/window';
import hideOpenMenus from '../utils/hide-menus';
import log from '../log';

// Get a component to subclass
const Component = videojs.getComponent('ClickableComponent');

class ClickScreen extends Component {
  constructor(player, opts) {
    super(player, { name: 'ClickScreen', ...opts });
    this.playToggleEnabled = true;
    this.registerEvents();
  }

  handleClick(e) {
    const player = this.player_;

    player.trigger('ott-pause-screen-click');

    if (!hideOpenMenus(player) && this.playToggleEnabled) {
      log(`[clickscreen] clicked: ${player.paused() ? 'play' : 'pause'}`);
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    }

    e.stopImmediatePropagation();
    player.trigger('click-screen-clicked');
  }

  registerEvents() {
    this.player_.on('clickScreen-playToggle-on', () => {
      this.playToggleEnabled = true;
    });
    this.player_.on('clickScreen-playToggle-off', () => {
      this.playToggleEnabled = false;
    });
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-click-screen',
    });
  }
}

videojs.registerPlugin('ClickScreen', ClickScreen);
export default ClickScreen;
