// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;
const Component = videojs.getComponent('Component');

/**
 * Shows play progress
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Component
 */
class LiveHorizontalProgressBar extends Component {
  constructor(player, options) {
    super(player, options);
    this.updateDataAttr();
    this.on(player, 'timeupdate', this.updateDataAttr);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-play-progress vjs-live-horizontal-bar vjs-slider-bar',
      innerHTML: `<span class="vjs-control-text"><span>${this.localize('Progress')}</span>: 0%</span>`,
    });
  }

  updateDataAttr() {
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    this.el_.setAttribute('data-current-time', videojs.formatTime(time, this.player_.duration()));
  }
}

Component.registerComponent('LiveHorizontalProgressBar', LiveHorizontalProgressBar);
export default LiveHorizontalProgressBar;
