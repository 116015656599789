import { videojs } from 'global/window';
/* eslint-disable */
import LanguagesHeading from './languages-heading';
import LanguagesButtonsWrapper from './languages-buttons-wrapper';
import LanguagesButton from './languages-button';
import './languages-select/languages-select-wrapper';
/* eslint-enable */
import propTest from '../../../property-tester';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class LanguagesControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.languagesControlOpts = propTest(() => this.player.options_.responsiveControl.languages);

    if (this.languagesControlOpts) {
      Object.entries(this.languagesControlOpts).forEach((entry) => {
        // send appropriate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'visible';
          if (entry[0] === 'alone') visibility = 'hidden';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }

    // default render in settings
    this.addChild('LanguagesHeading');
    if (this.options_.dropdownSettings) {
      this.addChild('LanguagesSelectWrapper', options);
    } else {
      this.addChild('LanguagesButtonsWrapper', options);
    }

    // when configuration includes special rules, render also in controlBar
    if (this.languagesControlOpts) {
      this.player.controlBar.addChild('LanguagesButton', options);
    }
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-languages-control vjs-settings-item',
    });
  }
}

export default LanguagesControl;
