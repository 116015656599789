import window, { document } from 'global/window';
import log from '../../log';
import availableEvents from './shared/available-events';

class CerebroadManager {
  constructor(player, options = {}, streamInfo) {
    this.player = player;
    this.cid = options.cid || null;
    this.streamInfo = streamInfo;
    this.adStreamInfo = null;
    this.createPageScript();
    this.registerControlEvents();

    this.onePercentTime = null;
    this.mainSourceProgressControl = {
      firstQuartile: false,
      midpoint: false,
      thirdQuartile: false,
    };
    this.adSourceProgressControl = this.createAdProgressObject();

    this.createCelebroadObject = this.createCelebroadObject.bind(this);

    if (this.cid === null) {
      log.error('[Cerebroad] CID not set!');
    }

    window.celebroadManager = this;
  }

  sendCerebroadEvent(eventName, eventData) {
    if (window.Cerebrolytics) {
      window.Cerebrolytics(this.createCelebroadObject(eventName, eventData));
      log(`[Cerebroad] send event ${eventName}`);
    } else {
      log.error('[Cerebroad] Error! Celebrolytics global function not found.');
    }
  }

  createCelebroadObject(eventName, eventData = {}) {
    return {
      cid: this.cid,
      customId: eventData,
      event: `${eventName}`,
      eventOffset: this.player.currentTime(),
    };
  }

  createAdProgressObject() {
    return {
      start: false,
      firstQuartile: false,
      midpoint: false,
      thirdQuartile: false,
    };
  }

  registerControlEvents() {
    /* MAIN SOURCE EVENTS */
    this.player.on(availableEvents.PLAY, (e) => {
      if (e.firstPlay) {
        this.sendCerebroadEvent('start', this.streamInfo);
        this.onePercentTime = this.player.duration() / 100;
      }
    });

    this.player.one(availableEvents.COMPLETE, () => {
      this.sendCerebroadEvent('complete', this.streamInfo);
    });

    this.player.on('ott-playertick', () => {
      const timeInPercent = Math.round(this.player.currentTime() / this.onePercentTime);
      if (
        timeInPercent === 25 &&
        !this.mainSourceProgressControl.firstQuartile &&
        !this.mainSourceProgressControl.midpoint &&
        !this.mainSourceProgressControl.thirdQuartile
      ) {
        this.mainSourceProgressControl.firstQuartile = true;
        this.sendCerebroadEvent('firstQuartile', this.streamInfo);
      }

      if (
        timeInPercent === 50 &&
        !this.mainSourceProgressControl.midpoint &&
        !this.mainSourceProgressControl.thirdQuartile
      ) {
        this.mainSourceProgressControl.midpoint = true;
        this.sendCerebroadEvent('midpoint', this.streamInfo);
      }

      if (timeInPercent === 75 && !this.mainSourceProgressControl.thirdQuartile) {
        this.mainSourceProgressControl.thirdQuartile = true;
        this.sendCerebroadEvent('thirdQuartile', this.streamInfo);
      }
    });
    /* MAIN SOURCE EVENTS - END */
    this.player.one(availableEvents.COMPLETE, () => {
      this.sendCerebroadEvent('complete', this.streamInfo);
    });

    /* AD SOURCE EVENTS */
    this.player.on(availableEvents.AD_PLAY, (e) => {
      // store ad stream info
      this.adStreamInfo = e.adStreamInfo;
      log('[Cerebroad] update adStreamInfo', this.adStreamInfo, e);

      if (!this.adSourceProgressControl.start) {
        this.sendCerebroadEvent('start', this.adStreamInfo);
        this.adSourceProgressControl.start = true;

        this.player.on(availableEvents.AD_FIRSTQUARTILE, () => {
          this.sendCerebroadEvent('firstQuartile', this.adStreamInfo);
          this.adSourceProgressControl.firstQuartile = true;
        });

        this.player.on(availableEvents.AD_MIDPOINT, () => {
          this.sendCerebroadEvent('midpoint', this.adStreamInfo);
          this.adSourceProgressControl.midpoint = true;
        });

        this.player.on(availableEvents.AD_THIRDQUARTILE, () => {
          this.sendCerebroadEvent('thirdQuartile', this.adStreamInfo);
          this.adSourceProgressControl.thirdQuartile = true;
        });

        this.player.on('linearclickthrough', this.sendEventClickTroughtAdSource);
      }
    });

    this.player.on(availableEvents.ADS_BLOCK_END, () => {
      this.sendCerebroadEvent('complete', this.adStreamInfo);
      this.adSourceProgressControl = this.createAdProgressObject();

      this.player.off('linearclickthrough', this.sendEventClickTroughtAdSource);
    });

    this.player.on(availableEvents.SKIP, () => {
      this.sendCerebroadEvent('skip', this.adStreamInfo);
      this.adSourceProgressControl = this.createAdProgressObject();
    });
  }

  // TODO maybe make separate function for all ads events (midpoint, thirdqu... etc) as protection for multiple call. Solution .off() after complete
  sendEventClickTroughtAdSource = () => {
    this.sendCerebroadEvent('impression', this.adStreamInfo);
  };

  createPageScript() {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML =
      "(function(a,b,c,d,e,f,g){ a['CerebrolyticsObject']=e, a[e]=a[e]||function(){(a[e].q=a[e].q||[]).push(arguments[0])},f=b.createElement(c),g=b.getElementsByTagName(c)[0];f.async=1;f.src=d;g.parentNode.insertBefore(f,g)})(window, document,'script', '//i.cerebroad.com/custom/lib.js', 'Cerebrolytics')";
    document.body.appendChild(scriptElement);
    log('[Cerebroad] - created page script');
  }
}

export default CerebroadManager;
