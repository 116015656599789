import availableEvents from './shared/available-events';
import { extend } from '../../utils/object-helpers';
import GoogleTagManager from './gtm';
import { timeToPercent } from './shared/helpers';
import { getVideoQuality } from '../../utils/quality-helper';
import log from '../../log';

/**
 * Google Tag Manager measuring service. The complex plugin measures all available events.
 *
 * @param {Object} player           - The player instance
 */
class GTMBasic extends GoogleTagManager {
  constructor(player, options = {}) {
    super(player);

    const defaultEventNames = {
      videoInit: 'video.init',
      videoPlay: 'video.play',
      videoAutoplay: 'video.autoplay',
      videoPause: 'video.pause',
      videoResume: 'video.resume',
      videoSeeking: 'video.seeking',
      videoBuffering: 'video.buffering',
      videoEnd: 'video.end',
      videoFullscreenOn: 'video.fullscreen.on',
      videoFullscreenOff: 'video.fullscreen.off',
      videoError: 'video.error',
      videoInventoryUpdate: 'video.inventory.update',
      videoPercentProgress: 'video.progress',
      videoPlay60s: 'video.play.60s',
      adClick: 'video.ads.click',
      adImpression: 'video.ads.impression',
      adSkip: 'video.ads.skip',
      adPlay: 'video.ads.play',
      adResume: 'video.ads.resume',
      adPause: 'video.ads.pause',
      adEnd: 'video.ads.end',
      adHalf: 'video.ads.half',
    };

    this.configData = options.data;
    this.playbackId = Date.now();
    // For watchng ticks
    this.ticks = 0;
    this.lastTick = 0;

    this.event = extend(false, defaultEventNames, options.eventNames);

    // Register events to watch
    this.registerEvents();
  }

  sendDataToGTMEvent(streamInfo) {
    // sendDataToGTM custom method that can be triggered outside of the player
    this.player.sendDataToGTM = (event, customData) => {
      log('[GTM complex]', 'called player.sendDataToGTM(event, customData) from outside');

      const data = this.getData({
        ...streamInfo,
        videoInfo: {
          quality: getVideoQuality(this.player),
        },
      });

      const gtmObj = {
        event,
        ...data,
        ...(customData && { customData }),
      };

      this.push(gtmObj);
      this.logGtmObj(gtmObj);
    };
  }

  logGtmObj(gtmObj) {
    log('[GTM complex]', gtmObj);
  }

  getData(obj) {
    const programDuration =
      obj.streamInfo.programDuration > 0
        ? obj.streamInfo.programDuration
        : Math.floor(this.player.durationOfActiveMedia());
    const currentTime = Math.floor(this.player.currentTimeOfActiveMedia());
    let data = {
      video: {
        player: {
          instanceId: this.player.instanceId,
          version: this.player.VERSION,
          type: obj.streamInfo.playerType,
        },
        content: {
          playbackId: this.playbackId,
          name: obj.streamInfo.programName,
          id: obj.streamInfo.videoID,
          genre: obj.streamInfo.typology,
          show: obj.streamInfo.series,
          season: obj.streamInfo.seasonNumber,
          episode: obj.streamInfo.episode,
          video_type: obj.streamInfo.videoType,
        },
        time: {
          length: programDuration,
          position: currentTime || 0,
          played: this.getWatchedTime(),
          percent: timeToPercent(programDuration, currentTime || 0),
        },
        inventory: {
          name: obj.inventory ? obj.inventory.name : null,
          ads_count: obj.inventory ? obj.inventory.ads_count : null,
        },
      },
    };

    if (obj.videoInfo) {
      data.quality = obj.videoInfo.quality;
    }

    if (obj.error) {
      data.error = obj.error;
    }

    if (this.configData) {
      data = extend(true, data, this.configData);
    }

    return data;
  }

  // to send ad data only when required
  getAdData(obj) {
    let data = {};
    if (obj.adStreamInfo) {
      const { adDuration, ...adStreamInfo } = obj.adStreamInfo;
      data = {
        video: {
          player: {
            instanceId: this.player.instanceId,
          },
          content: {
            playbackId: this.playbackId,
          },
        },
        ads: {
          ...adStreamInfo,
          time: {
            position: obj.currentAdTime,
            length: Math.floor(adDuration),
          },
          type: 'linear',
          kind: 'video',
        },
      };
    }

    if (this.configData) {
      data = extend(true, data, this.configData);
    }

    return data;
  }

  /**
   * Check ticks and count watched seconds from the last event
   *
   * @return {Number} Watched time in seconds
   */
  getWatchedTime() {
    const curTicks = this.ticks;
    const watched = curTicks - this.lastTick;
    this.lastTick = curTicks;

    return watched;
  }

  registerEvents() {
    const that = this;

    this.player.on('ott-playertick', (obj) => {
      this.ticks = obj.ticks;
    });

    function sendInit(obj) {
      // dataLayer init object pushed only once during playback
      if (!this.playerInitialized) {
        const gtmObj = {
          event: that.event.videoInit,
          ...that.getData(obj),
        };

        that.push(gtmObj);
        that.logGtmObj(gtmObj);

        this.playerInitialized = true;
      }
    }

    function sendPlay(obj) {
      let gtmObj;
      if (obj.firstPlay) {
        gtmObj = {
          event: that.event.videoPlay,
          ...that.getData(obj),
        };
      } else {
        gtmObj = {
          event: that.event.videoResume,
          ...that.getData(obj),
        };
      }

      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendAdPlay(obj) {
      const event = this.adResumed ? that.event.adResume : that.event.adPlay;
      const gtmObj = {
        event,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
      this.adResumed = true;
    }

    function sendAdHalf(obj) {
      const gtmObj = {
        event: that.event.adHalf,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    const sendAdPause = (obj) => {
      let currentTime;
      let duration;
      let adsManager;
      if (this.player.activePlugins_.adServiceIMA) {
        adsManager = this.player.adServiceIMA()?.adsManager;
      }
      if (adsManager) {
        currentTime = Math.floor(adsManager.currentAd_.data_.duration) - Math.floor(adsManager.remainingTime_);
        duration = Math.floor(adsManager.currentAd_.data_.duration);
      } else {
        currentTime = Math.floor(this.player.currentTimeOfActiveMedia());
        duration = Math.floor(this.player.durationOfActiveMedia());
      }
      if (currentTime !== duration) {
        const gtmObj = {
          event: that.event.adPause,
          ...that.getData(obj),
          ...that.getAdData(obj),
        };
        that.push(gtmObj);
        that.logGtmObj(gtmObj);
      }
    };

    function sendPause(obj) {
      const gtmObj = {
        event: that.event.videoPause,
        ...that.getData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendAdEnd(obj) {
      const gtmObj = {
        event: that.event.adEnd,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
      this.adResumed = false;
    }

    function sendEnded(obj) {
      const gtmObj = {
        event: that.event.videoEnd,
        ...that.getData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendSkip(obj) {
      const gtmObj = {
        event: that.event.adSkip,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
      this.adResumed = false;
    }

    function sendFullscreenChange(obj) {
      let gtmObj;
      if (obj.isFullscreen) {
        gtmObj = {
          event: that.event.videoFullscreenOn,
          ...that.getData(obj),
        };
      } else {
        gtmObj = {
          event: that.event.videoFullscreenOff,
          ...that.getData(obj),
        };
      }
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendBuffering(obj) {
      const gtmObj = {
        event: that.event.videoBuffering,
        ...that.getData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendSeek(obj) {
      const gtmObj = {
        event: that.event.videoSeeking,
        ...that.getData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendLinearImpression(obj) {
      const gtmObj = {
        event: that.event.adImpression,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    function sendLinearClickThrough(obj) {
      const gtmObj = {
        event: that.event.adClick,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    // not used
    // function sendError(obj) {
    //   that.push({
    //     event: that.event.videoError,
    //     ...that.getData(obj),
    //   });
    // }

    function sendInventoryUpdate(obj) {
      const gtmObj = {
        event: that.event.videoInventoryUpdate,
        ...that.getData(obj),
        ...that.getAdData(obj),
      };
      that.push(gtmObj);
      that.logGtmObj(gtmObj);
    }

    const sendPercentProgress = (obj) => {
      if (obj.secondsAbsolute) {
        const gtmObj = {
          event: that.event.videoPercentProgress,
          video: {
            player: {
              instanceId: this.player.instanceId,
            },
            content: {
              playbackId: this.playbackId,
            },
            time: {
              position: obj.secondsAbsolute,
              percent: obj.percentAbsolute,
            },
          },
        };
        that.push(gtmObj);
        that.logGtmObj(gtmObj);
        // that.push({
        //   event: that.event.videoPercentProgress,
        //   ...that.getData(obj),
        // });
      }
    };

    const sendVideoPlayed = (obj, time) => {
      if (time === 60) {
        const gtmObj = {
          event: that.event.videoPlay60s,
          ...that.getData(obj),
        };
        that.push(gtmObj);
        that.logGtmObj(gtmObj);
      }
    };

    /*
     * Send init data as soon as possible. During preroll ADS_BLOCK_START is before PROGRAM_LOADED
     * so better call it on both events and omit when data already sent.
     */
    this.player.on([availableEvents.PROGRAM_LOADED, availableEvents.ADS_BLOCK_START], sendInit);

    this.player.on(availableEvents.PLAY, sendPlay);
    this.player.on(availableEvents.PAUSE, sendPause);
    this.player.on(availableEvents.ENDED, sendEnded);
    this.player.on(availableEvents.FULLSCREEN_CHANGED, sendFullscreenChange);
    this.player.on(availableEvents.BUFFER, sendBuffering);
    this.player.on(availableEvents.SEEK, sendSeek);
    this.player.on(availableEvents.PLAYED_PERCENTUAL, sendPercentProgress);
    this.player.on(availableEvents.PLAY_60S, (obj) => sendVideoPlayed(obj, 60));

    this.player.on(availableEvents.AD_LOADED, sendLinearImpression);
    this.player.on(availableEvents.ADS_BLOCK_START, sendInventoryUpdate);
    this.player.on(availableEvents.AD_PLAY, sendAdPlay);
    this.player.on(availableEvents.AD_PAUSE, sendAdPause);
    this.player.on(availableEvents.AD_COMPLETE, sendAdEnd);
    this.player.on(availableEvents.AD_MIDPOINT, sendAdHalf);
    this.player.on(availableEvents.SKIP, sendSkip);
    this.player.on(availableEvents.LINEAR_CLICK_THROUGH, sendLinearClickThrough);
  }
}

export default GTMBasic;
