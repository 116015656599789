/**
 * @module plugins/MuteToggle
 *
 */
import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('MuteToggle');

class MuteToggleButton extends Component {
  constructor(player, options = {}) {
    super(player, { name: 'MuteToggle', ...options });
    this.player = player;
  }

  handleClick() {
    super.handleClick();
    this.player.trigger({ type: 'ott-mute-click', muted: this.player.muted() });
  }
}

videojs.registerPlugin('MuteToggleButton', MuteToggleButton);
export default MuteToggleButton;
