import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Shows quality level DIV
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityLevel extends Component {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-quality-level',
      innerHTML: `<span class="quality-info min">${this.player_.localize('MIN')}</span>
                  <span class="quality-info max">${this.player_.localize('MAX')}</span>`,
    });
  }
}

Component.registerComponent('QualityLevel', QualityLevel);
export default QualityLevel;
