/**
 * Application constants
 * @module constants
 */

/**
 * Force autoplay. Be careful this can be overriden by playSource parameter
 * @type {Boolean}
 */
export const PLAYER_AUTOPLAY = 'any';

/**
 * Player html video element ID
 * @type {String}
 */
export const PLAYER_ID = 'ott-video-player';

/**
 * logging query parameter. Logging is enabled when 1.
 * @type {String}
 */
export const LOG_QUERY_PARAM_NAME = 'pg';

/**
 * Name of the function which returns width of the window or parent window
 * @type {String}
 */
export const PLAYER_INNER_WIDTH_FUNC_NAME = 'parentInnerWidth';

/**
 * Show autoplay screen at player.duration() - offset (seconds).
 * @type {Number}
 */
export const AUTOPLAY_DEFAULT_COUNTDOWN = 8;

/**
 * Autoplay countdown default value.
 * @type {Number}
 */
export const AUTOPLAY_DEFAULT_START = 0;

/**
 * Autoplay state class name
 * @type {String}
 */
export const AUTOPLAY_STATE_CLASS_NAME = 'vjs-an-shown';

/**
 * Persist settings cookie name
 * @type {String}
 */
export const PERSIST_SETTINGS_COOKIE_NAME = 'ott_player_settings';

/**
 * Heartbeats - Insert class to the end of each element in matched elements when some erros status code occurs.
 * @type {String}
 */
export const HB_VISIBLE_CLASS_NAME = 'ott-visible';

/**
 * AdService - You can skip to video in [TIME]s
 * @type {String}
 */
export const AS_SKIP_DELAY_MODE = 'countdown';

/**
 * AdService - When VAST doesn't contain any value linear ad can be skipped after this time (fallback value only!).
 * @type {Number}
 */
export const AS_SKIP_DELAY_FALLBACK_TIME = 15;

/**
 * AdService - Used when current tech doesn't match any media source in the VAST
 * @type {String}
 */
export const AS_PREFERRED_FORMAT = 'video/mp4';

/**
 * AdService - Show companion ad when screen width is greater than or equal to this
 * @type {Number}
 */
export const AS_COMPANION_MIN_WIDTH = 1260;

/**
 * AdService - Promo is disabled by default
 * @type {String}
 */
export const AS_LINEAR_PREMIUM_MODE = 'disabled';

/**
 * AdService - Show premium url in new tab when '_blank' is set
 * @type {String}
 */
export const AS_LINEAR_PREMIUM_TARGET = '_blank';

/**
 * AdService - Promo button is in the top right corner by default
 * @type {String}
 */
export const AS_LINEAR_PREMIUM_POSITION = 'right';

/**
 * AdService - Show overlay ad when player window width is greater than or equal to this
 * @type {Number}
 */
export const AS_OVERLAY_MIN_WIDTH = 700;

/**
 * AdService - Notify about upcoming linear block on VAST.time - this value
 * @type {Number}
 */
export const AS_LINEAR_PRIOR_NOTIFY_TIME = 15;

/**
 * AdService -
 * @type {Number}
 */
export const AS_LINEAR_PRIOR_NOTIFY_MODE = 'disabled';

/**
 * AdService - The number of seconds the user has to watch at normal playback speed after one ad block is played to play the next one.
 * @type {Number}
 */
export const AS_LINEAR_WATCH_TRESHOLD = 0;

/**
 * AdService - When VAST contains mediFile with VPAID script we need to request it through iframe. This ID is used for the postMessage identification
 * @type {String}
 */
export const AS_VPAID_IFRAME_ID = 'VPAID_LOADED_PM';

/**
 * AdService - Element where VPAID ad is placed. Don't use names with 'AD, VAID, VAST, ...' or AdBlock will block this element automatically
 * @type {String}
 */
export const AS_VPAID_AD_ID = 'show-movie';

/**
 * Adaptive stream names
 * @enum
 * @readonly
 */
export const adaptiveStreams = Object.freeze({
  dash: 'application/dash+xml',
  hls: 'application/x-mpegurl',
});

/**
 * VPAID - Default z-index is used for the ad wrapper when no config value found
 * @type {String}
 */
export const AS_VPAID_IFRAME_ZINDEX = 99999;

/**
 * VPAID - The class name that is used when playing the VPAID ad
 * @type {String}
 */
export const AS_VPAID_PLAYING_CLASS_NAME = 'vjs-vpaid-playing';

/**
 * direct seek from query parameter.
 * @type {String}
 */
export const SEEK_QUERY_PARAM_NAME = 't';

/**
 * DASH seek offset after restart
 * @type {Number}
 */
export const DASH_SEEK_FIX = 0.1;

/**
 * Autoplay next placeholder img src
 * @type {String}
 */

export const AN_PLACEHOLDER = 'data:image/gif;base64,R0lGODdhAQABAPAAAAAAAMlFJiH5BAEAAAEALAAAAAABAAEAAAICRAEAOw==';

/**
 * nopostroll constant
 * @type {String}
 */
export const EVT_NOPOSTROLL = 'nopostroll';

/**
 * nopreroll constant
 * @type {String}
 */
export const EVT_NOPREROLL = 'nopreroll';

/**
 * Supported overlay content types
 * @type {Array<String>}
 */
export const OVERLAY_SUPPORTED_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/javascript',
  'application/x-javascript',
];

/**
 * Supported companion content types
 * @type {Array<String>}
 */
export const COMPANION_SUPPORTED_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const SKIP_COUNTDOWN_DISABLED = 'disabled';

export const DEFAULT_HIDDEN_CLASS = 'vjs-hidden';

/**
 * CACHEBUSTER constant
 * @type {String}
 */
export const CACHEBUSTER_PARAM_NAME = '[CACHEBUSTING]';

/**
 * Axinom DRM response header name for error message
 * @type {String}
 */
export const AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME = 'x-axdrm-errormessage';
