import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityAutoClose extends Button {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      className: 'vjs-icon-close-red vjs-quality-measurement-close',
      innerHTML: '',
    });
  }

  hideSettingsMenuPlugin() {
    const menu = this.player_.getChild('SettingsMenu');
    if (typeof menu !== 'undefined') {
      if (!menu.hasClass('vjs-hidden')) {
        menu.addClass('vjs-hidden');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }

  handleClick() {
    const cssClass = 'vjs-quality-auto-info-showing';
    if (this.player_.hasClass(cssClass)) {
      this.player_.removeClass(cssClass);
      this.player_.play();
    } else {
      this.player_.addClass(cssClass);
      this.player_.pause();
    }
    this.hideSettingsMenuPlugin();
  }
}
Component.registerComponent('QualityAutoClose', QualityAutoClose);
export default QualityAutoClose;
