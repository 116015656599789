import { videojs } from 'global/window';
import { isPersistAutoplayNext } from '../../autoplay-next/persist-reader';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Disable autoplay button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class AutoplayNextSwitchButton extends Button {
  createEl() {
    return super.createEl('span', {
      className: 'vjs-an-switch vjs-settings-content',
      innerHTML: `<label class="switch"><input class="vjs-an-checkbox" type="checkbox"${
        isPersistAutoplayNext(this.player_) ? ' checked=""' : ''
      }><span class="slider round"></span></label>`,
    });
  }

  updateCheckedValue(e) {
    this.el_.getElementsByClassName('vjs-an-checkbox')[0].checked = e.checked;
  }

  getCheckedValue() {
    return this.el_.getElementsByClassName('vjs-an-checkbox')[0].checked;
  }

  handleClick() {
    this.player_.trigger({
      type: 'ott-answitch-click',
      checked: this.getCheckedValue(),
    });

    // TODO: trigger an enabled/disabled
    // TODO: Read and save to the persist settings
  }
}
Component.registerComponent('AutoplayNextSwitchButton', AutoplayNextSwitchButton);
export default AutoplayNextSwitchButton;
