import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistTimeDivider extends Component {
  createEl() {
    return super.createEl('div', {
      className: 'vjs-playlist-time-divider',
      innerText: '/',
    });
  }
}

Component.registerComponent('PlaylistTimeDivider', PlaylistTimeDivider);
export default PlaylistTimeDivider;
