/**
 * <p>Plugin to play a segment of a video</p>
 *
 * <h3>Initialization</h3>
 * Initialize this plugin after Ad service plugin (when exist). See the example below to understand how to initialize this plugin.
 *
 * @example
{
  plugins: {
    offset: {
      start: 10,  // Start offset in seconds
      end: 40     // End offset in seconds
    }
  }
}
 *
 * @module plugins/offsetPlugin
 */
import log from '../log';
import { isAdPlaying } from './adservice/shared/ad-state';

/**
 * Offset plugin initialization
 *
 * @param {Object} options - Plugin initialization object
 * @param {Number} options.start - Offset start value
 * @param {Number} options.end - Offset end value
 */
export const offsetPlugin = function offsetPlugin(options) {
  this._offsetStart = options.start || 0;
  this._offsetEnd = options.end || 0;

  const player = this.constructor;
  if (!player.__super__ || !player.__super__.__offsetInit) {
    player.__super__ = {
      __offsetInit: true,
      duration: player.prototype.duration,
      currentTime: player.prototype.currentTime,
      bufferedPercent: player.prototype.bufferedPercent,
      remainingTime: player.prototype.remainingTime,
    };

    player.prototype.duration = (...args) => {
      if (isAdPlaying(this)) {
        return player.__super__.duration.apply(this, args);
      }
      if (this._offsetEnd > 0) {
        return this._offsetEnd - this._offsetStart;
      }
      return player.__super__.duration.apply(this, args) - this._offsetStart;
    };

    player.prototype.currentTime = (...args) => {
      const [seconds] = args;
      // FIXME BPo: isAdPlaying is false on 1 preroll
      if (isAdPlaying(this)) {
        return player.__super__.currentTime.apply(this, args);
      }
      if (seconds !== undefined) {
        return player.__super__.currentTime.call(this, seconds + this._offsetStart) - this._offsetStart;
      }
      return player.__super__.currentTime.apply(this, args) - this._offsetStart;
    };

    player.prototype.remainingTime = (...args) => {
      if (isAdPlaying(this)) {
        return player.__super__.remainingTime.apply(this, args);
      }

      let curr = this.currentTime();
      if (curr < this._offsetStart) {
        curr = 0;
      }
      return this.duration() - curr;
    };

    player.prototype.startOffset = () => this._offsetStart;

    player.prototype.endOffset = () => {
      if (this._offsetEnd > 0) {
        return this._offsetEnd;
      }
      return this.duration();
    };
  }

  this.on('timeupdate', () => {
    if (!isAdPlaying(this)) {
      const curr = this.currentTime();
      if (this._offsetEnd > 0 && curr > this._offsetEnd - this._offsetStart) {
        this.pause();
        this.currentTime(this._offsetEnd - this._offsetStart);
        // TODO: Show reload UI instead of paused state
      }
    }
  });

  log('offset plugin initialized');
  return this;
};
