import propTest from '../../../property-tester';
import log from '../../../log';

/**
 * Check if the creative does use SIMID framework
 *
 * @param {object} creative   ad creative
 * @returns {Boolean}
 */
export const hasSimid = (creative = {}) => {
  if (!propTest(() => creative.interactiveCreativeFile.apiFramework)) {
    return false;
  }

  const { apiFramework, fileURL } = creative.interactiveCreativeFile;
  if (apiFramework.toLowerCase() === 'simid' && fileURL) {
    log('[simid-utils] ad does contain creative object with simid');
    return true;
  }

  console.log("[simid-utils] ad doesn't contain interactiveCreativeFile object");
  return false;
};

/**
 * Check if the creative does use SIMID framework
 *
 * @param {object} adstate ad creative
 * @param {!number} cue index of nonlinear ad
 * @returns {boolean}
 */
export const hasNonlinearSimid = (adstate, cue) => {
  const containNonLinearSimid =
    propTest(() => adstate.inventory.cascade[cue].overlay.ads[0].creatives[0].variations[0].apiFramework) &&
    adstate.inventory.cascade[cue].overlay.ads[0].creatives[0].variations[0].apiFramework.toLowerCase() === 'simid';

  if (containNonLinearSimid) {
    log('[simid-utils] ad contains creative object with simid');
    return true;
  }

  log("[simid-utils] ad doesn't contain creative object with simid");
  return false;
};
