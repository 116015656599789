/**
 * The ad playback threshold watcher. Whenever an ad can be displayed, it is necessary to ensure that the treshold is emptied.
 */
class LinearWatchTreshold {
  constructor(player, watchTreshold) {
    this.player = player;
    this.timeout = undefined;
    this.available = true;
    /**
     * Watch treshold in millis
     * @type {Number}
     */
    this.watchTreshold = watchTreshold * 1000;
  }

  /**
   * Check if an ad can be displayed.
   * @return {Boolean} True when an ad is available, false otherwise
   */
  isAdAvailable() {
    return this.available;
  }

  /**
   * Clear the treshold and the availability state.
   */
  clearTreshold() {
    this.available = false;
    this.player.clearTimeout(this.timeout);
  }

  /**
   * Reset the treshold to the defaul and start new treshold watcher.
   */
  setTreshold() {
    this.clearTreshold();
    this.timeout = this.player.setTimeout(() => {
      this.available = true;
    }, this.watchTreshold);
  }
}

export default LinearWatchTreshold;
