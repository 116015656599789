import log from '../log';
import vmapPreprocessing from '../plugins/adservice/vmap-preprocessing';
import vmapTransform from '../plugins/adservice/vmap-transform';

const adMarkersUpdate = (markers, transformMarkers) => {
  if (markers && transformMarkers.length > 0) {
    if (!markers.markers) markers.markers = [];
    const cleanedMarkers = markers.markers.filter((marker) => marker.class !== 'vjs-marker-ad');
    markers.markers = [...cleanedMarkers, ...transformMarkers];
  }

  return markers;
};

const adServiceInitPreprocessing = (initData) => {
  const { adService } = initData.plugins || {};
  let timelineMarkers = initData.plugins?.markers || {};
  if (!adService) return null;

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (adService.vmapTransform && adService.vmapTransform.vmapUrl) {
      await vmapTransform(adService.vmapTransform.vmapUrl, initData.duration)
        .then(({ linear, cascade, markers }) => {
          adService.linear = linear;
          adService.cascade = cascade;
          timelineMarkers = adMarkersUpdate(timelineMarkers, markers);
        })
        .catch((e) => {
          log.error('[vmap-transform] error', e);
        });
    }

    // TODO: change path to adService.ima.vmapPreprocessing
    if (initData.duration && adService.vmapPreprocessing && adService.adTagUrl) {
      const { timeout } = adService.vmapPreprocessing;
      await vmapPreprocessing(adService.adTagUrl, initData.duration, timeout)
        .then((data) => {
          adService.adsResponse = data;
        })
        .catch((e) => {
          log.error('[vmap-preprocessing] error', e);
          adService.adsResponse = null;
        });
    }

    const resolveObj = {
      adService,
    };

    // check if not empty markers
    if (Object.keys(timelineMarkers).length !== 0) {
      resolveObj.markers = timelineMarkers;
    }

    log('[adservice-preprocessing] adservice, markers, finalObj', adService, timelineMarkers, resolveObj);

    resolve(resolveObj);
  });
};

export default adServiceInitPreprocessing;
