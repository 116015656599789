import xmljs from 'xml-js';
import { fetch } from 'global/window';
import { timeFormat } from './vmap-creator';
import log from '../../log';

const updateUrlWithMacros = (url) => {
  let updatedUrl = url;

  if (updatedUrl.includes('[CACHEBUSTING]')) {
    const hash = Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
    updatedUrl = updatedUrl.replace(/\[CACHEBUSTING\]/g, hash);
  }

  return updatedUrl;
};

const transformXMLtoJS = (xml) => {
  const options = { ignoreComment: true, alwaysChildren: true };
  return xmljs.xml2js(xml, options); // or xml2json
};

const transformJStoXML = (jsObj) => {
  const options = { compact: false, ignoreComment: true, spaces: 4 };
  return xmljs.js2xml(jsObj, options);
};

const updateTimes = (dataObject, totalDuration) => {
  const onePercentInSeconds = totalDuration / 100;

  if (dataObject.elements && dataObject.elements[0].elements) {
    dataObject.elements[0].elements.forEach((element) => {
      if (element.attributes && element.attributes.timeOffset && element.attributes.timeOffset.includes('%')) {
        const percent = parseInt(element.attributes.timeOffset, 10);
        const percentToSeconds = percent * onePercentInSeconds;
        element.attributes.timeOffset = timeFormat(percentToSeconds);
      }
    });
  }

  return dataObject;
};

const defaultTimeout = 5000;

// eslint-disable-next-line arrow-body-style
const vmapPreprocessing = (adTagUrl, totalDuration, timeout) => {
  return new Promise((resolve, reject) => {
    log('[vmap-processing] enabled');

    const processingTimeout = setTimeout(() => {
      log.error('[vmap-processing] timeout error');
      reject();
    }, timeout || defaultTimeout);

    fetch(updateUrlWithMacros(adTagUrl))
      .then(async (response) => {
        const text = await response.text();
        return text;
      })
      .then((data) => {
        const dataObject = transformXMLtoJS(data);
        const updatedDataObject = updateTimes(dataObject, totalDuration);
        const updatedVmap = transformJStoXML(updatedDataObject);
        clearTimeout(processingTimeout);
        resolve(updatedVmap);
      })
      .catch((e) => {
        log.error(`[vmap-processing] fetch error ${e.message}`, e);
        reject();
      });
  });
};

export default vmapPreprocessing;
