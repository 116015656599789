import { videojs } from 'global/window';
import log from './log';
/* eslint-disable */
import PlaylistMarker from './plugins/playlist/playlist-marker';
import PlaylistAdMarker from './plugins/playlist/playlist-ad-marker';
import PlaylistSeekBar from './plugins/playlist/playlist-seek-bar';
import PlaylistLoadProgress from './plugins/playlist/playlist-load-progress-bar';
import PlaylistThumbnails from './plugins/playlist/playlist-thumbnails';
import PlaylistControlButtons from './plugins/playlist/playlist-control-buttons';
import PlaylistTimeDisplay from './plugins/playlist/playlist-time-display';
import PlaylistSourceNameDisplay from './plugins/playlist/playlist-source-name-display';
/* eslint-enable */

export default class Playlist {
  /**
   * class Playlist constuctor
   * @param {object} player required actual player instance
   * @param {array} sources only for first initialization
   * @param {object} config for creating new instace from exist instance
   */
  constructor(player, sources = null, config = null) {
    // TODO what do if not sources or config
    this.player = player;
    this.playlistControlEvents = ['ott-playlist-next', 'ott-playlist-prev', 'ott-playlist-select'];
    this.playlistChangeEvent = 'ott-playlist-change-source';
    this.controlBar = videojs.getComponent('ControlBar');

    if (sources != null) {
      this.playlistData = this.createPlaylistData(sources);
    } else {
      this.playlistData = config;
    }
  }

  //
  checkEnableThumbnails(sources) {
    return sources.reduce((sourcesWithThumbs, source) => {
      if (source.thumbnailsPlaylist) {
        sourcesWithThumbs++;
      }
      return sourcesWithThumbs;
    }, 0);
  }

  /**
   * Playlist init method
   * added playlist instance to player
   * call registerPlaylistEvents method for playlist control
   * TODO - initialize progress line component (only when playlist data total totalDuration not null)
   */
  init() {
    this.player.addClass('ott-vjs-playlist');
    this.player.playlist = this;
    this.player.instanceId = this.playlistData.instanceId;
    this.registerPlaylistEvents();
    this.registerCompleteEvent();
    this.createPlaylistUI();
    if (this.playlistData.totalDuration !== null) {
      // TODO initialize progress line component
    }
    log('[playlist] initialization done !');
  }

  /**
   * Method create some UI player components for playlist
   */
  createPlaylistUI() {
    const playlistSettings =
      this.player.playlist.playlistData.sources[this.player.playlist.playlistData.actualIndex].playlist;

    // FIXME - make methods for some components, need for playlist type

    // remove duration display, current time display and time divider
    // create playlist time display (includes duration, current time and time divider)
    this.player.controlBar.removeChild('DurationDisplay');
    this.player.controlBar.removeChild('CurrentTimeDisplay');
    this.player.controlBar.removeChild('timeDivider');
    this.player.controlBar.addChild('PlaylistTimeDisplay');

    if (!playlistSettings || playlistSettings.showSourceName === true) {
      // create playlist source name
      this.player.controlBar.addChild('PlaylistSourceNameDisplay');
    }

    if (!playlistSettings || playlistSettings.controls === true) {
      // create playlist control button and index display
      this.player.controlBar.addChild('PlaylistControlButtons');
    }

    if (!playlistSettings || playlistSettings.type !== 'hidden') {
      // remove and create playlist seekbar
      // this tooltip removed because problem with undefined player
      this.player.controlBar.progressControl.seekBar.playProgressBar.removeChild('TimeTooltip');
      this.player.controlBar.progressControl.seekBar.removeChild('MouseTimeDisplay');

      // need dispose interval for update UI on disposed player
      this.player.controlBar.progressControl.getChild('SeekBar').hide();

      // create playlist thumbnails component
      if (this.playlistData.thumbnails) {
        this.player.controlBar.progressControl.addChild('PlaylistThumbnails');
      }

      // this.player.controlBar.progressControl.removeChild('SeekBar');
      this.player.controlBar.progressControl.addChild('PlaylistSeekBar', { enableThumbnails: this.enableThumbnails });

      // remove and create playlist buffer bar
      this.player.controlBar.progressControl.getChild('PlaylistSeekBar').removeChild('LoadProgressBar');
      this.player.controlBar.progressControl.getChild('PlaylistSeekBar').addChild('PlaylistLoadProgressBar');

      // create blue playlist markers to progress bar
      this.player.playlist.playlistData.sources.forEach((source, index) => {
        if (index !== 0) {
          this.player.controlBar.progressControl.addChild('PlaylistMarker', {
            sourceIndex: index,
          });
        }
      });

      // create playlist AD marker to progress bar
      this.player.playlist.playlistData.sources.forEach((source, sourceIndex) => {
        if (((source.adMarkerPlaylist || {}).markers || []).length > 0) {
          source.adMarkerPlaylist.markers.forEach((marker, markerIndex) => {
            if (marker.time <= source.duration) {
              this.player.controlBar.progressControl.addChild('PlaylistAdMarker', {
                durationBefore: source.durationBefore,
                markerTime: marker.time,
                markerKey: `padm-${sourceIndex}-${markerIndex}`,
              });
            }
          });
        }
      });

      // change markers position when resize player
      this.player.on(['fullscreenchange', 'playerresize'], () => {
        const components = this.player.controlBar.progressControl.children();
        components.forEach((component) => {
          if (component.name_ === 'PlaylistAdMarker' || component.name_ === 'PlaylistMarker') {
            component.el_.style.left = `${component.getMarkerPosition()}px`;
          }
        });
        log('[playlist] position components PlaylistMarker and PlaylistAdMarker updated');
      });
    }
  }

  /**
   * Method return playlist data
   * @returns {Object}
   */
  getPlaylistData() {
    return this.playlistData;
  }

  /**
   * Create playlistData object for playlist instance
   * @param {Array} sources
   * @returns {Object}
   */
  createPlaylistData(sources) {
    const playlistData = {
      sources: this.updateSourcesData(sources),
      totalLength: sources.length,
      totalDuration: this.getTotalDuration(sources),
      instanceId: this.player.instanceId || Date.now(),
      thumbnails: this.checkEnableThumbnails(sources) > 0,
      actualIndex: 0,
    };

    return playlistData;
  }

  /**
   * Method enriches source data
   * to some source in playlist array added
   * durationBefore => sum of all sources duration before source
   * seekRange => Object with interval of source due to total duration
   * remove ad marker plugin init data from sources
   * @param {Object} sources - playlist sources array
   * @returns {Object}
   */
  updateSourcesData(sources) {
    const totalDuration = this.getTotalDuration(sources);

    return sources.map((source, index) => {
      source.durationBefore = this.getTimeTotalDurationBeforeThisSource(sources, index);
      source.seekRange = this.getSourcePercentSeekRange(source, totalDuration);
      // remove standard videojs ad markers
      if (source.plugins.markers) {
        source.adMarkerPlaylist = source.plugins.markers;

        // ----------------------------------------
        // no idea why this line was already there
        // it caused an issue where the ad markers were not displayed
        // on the progress bar after the first video in the playlist
        // if "playlist: { type: 'hidden' }," IS defined in the config
        // more info: https://jira.zentity.com/browse/CRASUPP-708
        // commenting the line below fixed the problem
        // ----------------------------------------
        // delete source.plugins.markers;
      }

      // remove standard videojs thumbnails plugin
      if (source.plugins.thumbnails) {
        source.thumbnailsPlaylist = source.plugins.thumbnails;
        if (source?.playlist?.type !== 'hidden') {
          delete source.plugins.thumbnails;
        }
      }

      return source;
    });
  }

  /**
   * Method create source percentage interval source location
   * Using for seek on timeline. Check seek whether it was in source range
   * @param {Object} source - source data
   * @param {Number} totalDuration - total playlist duration (sum of all sources)
   * @returns {Object} - min (start), max (end), rangeLength (interval length)
   */
  getSourcePercentSeekRange(source, totalDuration) {
    const durationOnePercent = totalDuration / 100;

    const percentForMin = Math.floor(source.durationBefore / durationOnePercent);
    const percentForMax = Math.floor((source.durationBefore + source.duration) / durationOnePercent);

    return { min: percentForMin, max: percentForMax, rangeLength: percentForMax - percentForMin };
  }

  /**
   * Method get sum of sources durations before source with param sourceIndex
   * @param {Array} sources
   * @param {Number} sourceIndex
   * @return {Number} - seconds duration
   */
  getTimeTotalDurationBeforeThisSource(sources, sourceIndex) {
    let iterator = 0;
    return sources.reduce((previousDuration, source) => {
      if (iterator < sourceIndex) {
        iterator++;
        previousDuration += source.duration;
      }
      return previousDuration;
    }, 0);
  }

  /**
   * Method iterate all sources and count parameter duration
   * When some source havent parameter duration, method return null. Need for consistence data in UI timeline.
   * @param {Array} sources
   * @return {Int} playlist total time duration
   */
  getTotalDuration(sources) {
    let totalDuration = 0;

    sources.forEach((source) => {
      if (totalDuration != null) {
        if (source.duration) {
          totalDuration += source.duration;
        } else {
          totalDuration = null;
        }
      }
    });

    if (totalDuration != null) {
      log('[playlist] count sources total duration', totalDuration);
    } else {
      log.warn('[playlist] some source not have duration parameter');
    }

    return totalDuration;
  }

  /**
   * Register playlist control events on actual player instance
   */
  registerPlaylistEvents() {
    this.player.one(this.playlistControlEvents, (e, sourceIndex) => {
      switch (e.type) {
        case 'ott-playlist-next':
          this.changePlaylistSourceNext();
          break;
        case 'ott-playlist-prev':
          this.changePlaylistSourcePrev();
          break;
        case 'ott-playlist-select':
          this.changePlaylistSourceByIndex(sourceIndex, null);
          break;
      }
    });
    log('[playlist] register events', this.playlistControlEvents);
  }

  /**
   * Change playlist source after actual source ended and send event 'complete'
   */
  registerCompleteEvent() {
    this.player.one(['contentended', 'ended'], () => {
      this.changePlaylistSourceNext();
    });
  }

  playlistChangeNotification(sourceIndex, seekTime = null) {
    this.player.trigger({
      type: this.playlistChangeEvent,
      sourceIndex,
      seekTime,
    });
  }

  /**
   * Change player configuration to NEXT source in playlist array
   * After change call resetPlayer with new configuration
   * When next index not exist, back first index in playlist sources array
   */
  changePlaylistSourceNext() {
    if (
      this.playlistData.actualIndex < this.playlistData.totalLength &&
      this.playlistData.actualIndex !== this.playlistData.totalLength - 1
    ) {
      this.playlistData.actualIndex++;
      const config = this.playlistData.sources[this.playlistData.actualIndex];
      config.instanceId = this.playlistData.instanceId;
      this.playlistChangeNotification(this.playlistData.actualIndex);
      this.player.reset.resetPlayer(config);
      log(
        `[playlist]: source changed to index ${this.playlistData.actualIndex}`,
        this.player.reset.getResetData().data,
      );
    } else {
      log('[playlist] last source, repeat all tracks');
      // FIXME: [OTTS-1947] hotfix errors for double ended event
      setTimeout(() => {
        this.playlistData.actualIndex = 0;
        const config = this.playlistData.sources[this.playlistData.actualIndex];
        config.instanceId = this.playlistData.instanceId;
        this.player.reset.resetPlayer(config);
        log(
          `[playlist]: source changed to index ${this.playlistData.actualIndex}`,
          this.player.reset.getResetData().data,
        );
      }, 1000);
    }
  }

  /**
   * Change player configuration to PREV source in playlist array
   * After change call resetPlayer with new configuration
   * When prev index not exist or if its first index in playlist sources array, method nothing do
   */
  changePlaylistSourcePrev() {
    if (this.playlistData.actualIndex !== 0) {
      this.playlistData.actualIndex--;
      const config = this.playlistData.sources[this.playlistData.actualIndex];
      this.playlistChangeNotification(this.playlistData.actualIndex);
      this.player.reset.resetPlayer(config);
      log(`[playlist] source changed to index ${this.playlistData.actualIndex}`, this.player.reset.getResetData().data);
    } else {
      log.warn('[playlist] prev this is a first source in sources');
      this.registerPlaylistEvents(); // re-register playlist events since only .one() is used
    }
  }

  /**
   * Change player configuration to any source in playlist array
   * After change call resetPlayer with new configuration with selected source data
   */
  changePlaylistSourceByIndex(sourceIndex, seekTime) {
    if (this.playlistData.sources.length && (sourceIndex < 0 || sourceIndex >= this.playlistData.sources.length)) {
      log.warn(
        `[playlist] target index ${sourceIndex} is invalid, available indexes 0->${
          this.playlistData.sources.length - 1
        }`,
      );
      this.registerPlaylistEvents(); // re-register playlist events since only .one() is used
      return;
    }
    if (this.playlistData.actualIndex !== sourceIndex) {
      this.playlistData.actualIndex = sourceIndex;
      const config = this.playlistData.sources[this.playlistData.actualIndex];
      this.playlistChangeNotification(sourceIndex, seekTime);
      this.player.reset.resetPlayer(config, seekTime);
      log(`[playlist] source changed to index ${this.playlistData.actualIndex}`, this.player.reset.getResetData().data);
    } else {
      log.warn('[playlist] now played the same source index');
      this.registerPlaylistEvents(); // re-register playlist events since only .one() is used
    }
  }
}
