/**
 * <p>Adds new properties to basic player instance, mainly overrided currentTime method for updating without seeking</p>
 *
 * <h3>Custom events tracking</h3>
 * <b>faketime-on</b> currentTime starts working with fake time
 * <b>faketime-off</b> currentTime stops working with fake time and returns back to browser player time
 *
 */
import window from 'global/window';
import ExtendedErrorDisplay from './extendedErrorDisplay';
import ClickScreen from './plugins/click-screen';
import StillWatching from './plugins/still-watching';
import FlickeringPixel from './plugins/flickering-pixel';
import PlayToggleButton from './plugins/play-toggle-button';
import MuteToggleButton from './plugins/mute-toggle-button';
import VolumeControl from './plugins/volume-control';
import { isLiveNow, isLiveGlobal } from './utils/islive-check';
import log from './log';
import packageJson from '../../../package.json';

const options = {
  fakeTimeEnabled: false,
  fakeTime: undefined,
};

/**
 * Starts to use custom time instead of browser player time (equals to faketime-on event)
 *
 * @param {Object} player
 */
function setFakeTime(player) {
  options.fakeTime = player.currentTime();
  options.fakeTimeEnabled = true;
  player.trigger('timeupdate');
}

/**
 * Updates custom time
 *
 * @param {Object} player
 * @param {number} time
 */
function updateFakeTime(player, time) {
  options.fakeTimeEnabled = true;
  if (time) {
    options.fakeTime = time;
  }
  player.trigger('timeupdate');
  player.cache_.currentTime = options.fakeTime;
}

/**
 * Ends to use custom time and returns to browser player time (equals to faketime-off event)
 *
 * @param {Object} player
 */
function resetFakeTime(player) {
  options.fakeTimeEnabled = false;
  player.currentTime(options.fakeTime);
  options.fakeTime = undefined;
  player.trigger('timeupdate');
}

/**
 * Overrides native currentTime method for updating UI without seeking
 *
 * @param {Object} player
 */
function createFakeTimeSupport(player) {
  const origCurrentTimeFunc = player.currentTime.bind(player);
  player.currentTime = (time) => {
    if (options.fakeTimeEnabled) {
      if (time) {
        updateFakeTime(player, time);
      }
      return options.fakeTime;
    }
    return origCurrentTimeFunc(time);
  };

  player.on('faketime-on', () => setFakeTime(player));
  player.on('faketime-off', () => resetFakeTime(player));
}

/**
 *
 * @param {Object} options
 */
function disableFullscreenIniOS(player) {
  if (player.options_.disableFullscreenIniOS && window.videojs.browser.IS_IOS) {
    player.controlBar.fullscreenToggle.hide();
  }
}

/**
 * Enhances basic player instance with new properties
 * @param {Object} player
 */
function updatePlayerFunctionality(player) {
  if (player.options_.crossorigin) {
    player.tech({ IWillNotUseThisInPlugins: true }).setAttribute('crossorigin', player.options_.crossorigin);
  }
  // remove old ErrorDisplay component
  player.removeChild(player.getChild('ErrorDisplay'));
  // register ExtendedErrorDisplay component
  player.addChild(new ExtendedErrorDisplay(player));

  // if live stream set on global player object
  player.one('loadedmetadata', () => {
    if (isLiveNow(player)) {
      player.isLivePlayer = true;
      log('[isLivePlayer] set to true');
    }
  });

  // disable fullscreen toggle on iOS devices when defined in config
  disableFullscreenIniOS(player);

  /**
   * Remove default play toggle button component
   * Add custom play toggle button component to control bar if not exists
   */
  player.controlBar.removeChild('PlayToggle');
  if (!document.getElementById(`${player.id_}-play-toggle-button`)) {
    player.controlBar.el().prepend(new PlayToggleButton(player).el());
  }

  // Remove default mute toggle button component and Add custom mute toggle button component
  const volumePanel = player.controlBar.getChild('VolumePanel');
  volumePanel.removeChild('MuteToggle');
  volumePanel.addChild(new MuteToggleButton(player));
  // Remove default volume slider component and Add custom volume slider component
  volumePanel.removeChild('VolumeControl');
  let isInline = player.options_?.controlBar?.volumePanel?.inline;
  if (isInline === undefined) {
    isInline = true;
  }
  volumePanel.addChild(new VolumeControl(player, { vertical: !isInline }));

  // Add click sccreen component
  player.addChild(new ClickScreen(player));

  // add stillWatching component
  player.addChild(new StillWatching(player));

  /** Current software version. Defined in the webpack.DefinePlugin */
  player.VERSION = packageJson.version;

  // unique instanceId for gtm-complex
  player.instanceId = player.instanceId ? player.instanceId : Date.now();

  // TODO: revise fake times
  createFakeTimeSupport(player);

  // These methods should return current time and duration of video, which is visible to user (may be normal content or ad).
  // Could be overridden by ads plugins.
  player.currentTimeOfActiveMedia = (time) => player.currentTime(time);
  player.durationOfActiveMedia = () => player.duration();
  player.isInPausedState = () => player.paused();

  // Single transparent pixel div to stop fullscreen player flickering on iOS. See OTTS-1992 | CRA-3600
  player.addChild(new FlickeringPixel(player));

  // fix showing LIVE text
  // TODO: maybe delete this after testing
  player.on(['adend', 'loadedmetadata'], () => {
    if (isLiveGlobal(player) && player.controlBar.liveDisplay.hasClass('vjs-hidden')) {
      player.controlBar.liveDisplay.show();
      log('[custom-functionality] show LIVE tag');
    }
  });
}

export default updatePlayerFunctionality;
