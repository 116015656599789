const evPrefix = 'ott-mea-';

/**
 * Available player measurement events
 */
const availableEvents = Object.freeze({
  PAUSE: `${evPrefix}pause`,
  PLAY: `${evPrefix}play`,
  ENDED: `${evPrefix}ended`,
  COMPLETE: `${evPrefix}complete`,
  PROGRAM_LOADED: `${evPrefix}program-loaded`,
  STOP: `${evPrefix}stop`, // FIXME: NOT IMPLEMENTED
  BUFFER: `${evPrefix}buffer`, // FIXME: REVISION NEEDED
  BREAK: `${evPrefix}break`,
  SEEK: `${evPrefix}seek`,
  CLOSE: `${evPrefix}close`,
  NEXT: `${evPrefix}next`, // FIXME: NOT IMPLEMENTED
  PREV: `${evPrefix}prev`, // FIXME: NOT IMPLEMENTED
  PLAYER_RESOLUTION_CHANGED: `${evPrefix}player-resolution-changed`,
  VOLUME_CHANGED: `${evPrefix}volume-changed`,
  QUALITY_CHANGED: `${evPrefix}quality-changed`, // FIXME: REVISION NEEDED
  FULLSCREEN_CHANGED: `${evPrefix}fullscreen-changed`,
  ERROR: `${evPrefix}error`,
  REACHED_CREDITS: `${evPrefix}reached-credits`,
  PLAYED_PERCENTUAL: `${evPrefix}playedpercentual`,
  PLAY_60S: `${evPrefix}play-60s`,
  TIMEUPDATE: `${evPrefix}timeupdate`,

  /* AD EVENTS */
  ADS_BLOCK_START: `${evPrefix}ads-block-start`, // call when contrib ads start linear mode (old name AD_START)
  ADS_BLOCK_END: `${evPrefix}ads-block-end`, // call when contrib ads end linear mode (old name AD_END)
  AD_LOADED: `${evPrefix}ad-loaded`,
  AD_IMPRESSION: `${evPrefix}ad-impression`,
  AD_PLAY: `${evPrefix}ad-play`,
  AD_PAUSE: `${evPrefix}ad-pause`,
  AD_TIMEUPDATE: `${evPrefix}ad-timeupdate`,
  AD_COMPLETE: `${evPrefix}ad-complete`,
  AD_ERROR: `${evPrefix}ad-error`,
  AD_FIRSTQUARTILE: `${evPrefix}ad-firstquartile`,
  AD_MIDPOINT: `${evPrefix}ad-midpoint`,
  AD_THIRDQUARTILE: `${evPrefix}ad-thirdquartile`,
  SKIP: `${evPrefix}skip`,
  OVERLAY_CLICK_THROUGH: `${evPrefix}overlay-click-through`,
  LINEAR_CLICK_THROUGH: `${evPrefix}linear-click-through`,

  // not implemented now
  COMPANION_STARTED: `${evPrefix}companion-started`,
  COMPANION_CLICK_THROUGH: `${evPrefix}companion-click-through`,
  COMPANION_ENDED: `${evPrefix}companion-ended`,
  OVERLAY_CAN_PLAY: `${evPrefix}overlay-can-play`,
  OVERLAY_ENDED: `${evPrefix}overlay-ended`,
});

export default availableEvents;
