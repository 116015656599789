import { videojs } from 'global/window';
import log from '../log';
import BigUnmuteButton from './big-unmute-button/button';
import { isAdPlaying } from './adservice/shared/ad-state';

const Plugin = videojs.getPlugin('plugin');

export class UnmuteButtonController extends Plugin {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    const strictModeEnabled = this.isStrictMode();
    this.showButton = this.showButton.bind(this);

    if (strictModeEnabled === false || (strictModeEnabled && this.strictBrowserDetect())) {
      this.checkPlayerMuteState();
    } else {
      log('[Big unmute button controller] plugin initialized - big unmute button not be displayed');
    }
  }

  showButton() {
    if (!isAdPlaying(this.player)) {
      if (this.player.muted && this.player.muted()) {
        this.player.addChild(new BigUnmuteButton(this.player, this.options));
      }
      this.player.off('playing', this.showButton);
      log(
        `[Big unmute button controller] player mute state: ${
          this.player.muted() ? 'true - showing button' : 'false - button not show'
        }`,
      );
    }
  }

  checkPlayerMuteState() {
    this.player.on('playing', this.showButton);
  }

  isStrictMode() {
    // default mode is strict mode
    return this.options.strictMode === false ? this.options.strictMode : true;
  }

  strictBrowserDetect() {
    return videojs.browser.IS_SAFARI;
  }
}
