import Cookies from 'js-cookie';
import log, { isLoggingEnabled } from '../../log';
import { MEA_MUX_NO_KEY } from '../../errors';
import availableEvents from './shared/available-events';
import { getStreamInfo } from '../../utils/source';

// https://docs.mux.com/docs
export default class Mux {
  constructor(player, config) {
    this.player = player;
    this.config = config;
    this.logErrors = config.logErrors || null;
    this.ignoreErrors = config.ignoreErrors || [];
    this.handleProgramLoaded = this.handleProgramLoaded.bind(this);
    this.handleAdLoaded = this.handleAdLoaded.bind(this);
    this.handleCustomError = this.handleCustomError.bind(this);
    this.init();
  }

  init() {
    if (!this.config.property_key) {
      log.error(MEA_MUX_NO_KEY.code, MEA_MUX_NO_KEY.message);
      return;
    }

    const muxMeaId = Cookies.get('mux_mea_id');

    if (
      this.config.activationProbability != null &&
      Math.random() > this.config.activationProbability &&
      isLoggingEnabled() != true &&
      !muxMeaId
    ) {
      return;
    }

    const muxConfig = {
      data: {
        property_key: this.config.property_key,
        // player_init_time: playerInitTime // ex: 1451606400000
        player_name: 'OTT Video Player',
        player_version: this.player.VERSION,
        page_type: this.config.embedded ? 'iframe' : 'watchpage',
        ...this.getVideoInfo(getStreamInfo(this.player)),
      },
    };

    if (this.config.quality_measurement_switch === true) {
      muxConfig.quality_measurement_switch = this.config.quality_measurement_switch;
    }

    if (isLoggingEnabled()) {
      muxConfig.debug = true;
    }

    if (this.config.data) {
      Object.keys(this.config.data).forEach((key) => {
        muxConfig.data[key] = this.config.data[key];
      });
    }

    this.player.mux(muxConfig);

    this.player.on([availableEvents.AD_PLAY], (e) => {
      this.emitAdEvent('adplay', e);
    });

    this.player.on([availableEvents.AD_TIMEUPDATE], (e) => {
      this.emitAdEvent('adplaying', e);
    });

    this.player.on(availableEvents.AD_PAUSE, (e) => {
      this.emitAdEvent('adpause', e);
    });

    this.player.on(availableEvents.AD_COMPLETE, (e) => {
      this.emitAdEvent('adended', e);
    });

    this.player.on(availableEvents.AD_ERROR, (e) => {
      this.emitAdEvent('aderror', e);
    });

    this.player.on('adrequest', (data) => {
      this.player.mux.emit('adrequest', {
        ad_tag_url: data.vastUrl,
      });
    });

    this.player.on('adresponse', (data) => {
      this.player.mux.emit('adresponse', {
        ad_tag_url: data.vastUrl,
      });
    });

    // TODO: probably better events for this:
    this.player.on('ott-custom-error', this.handleCustomError);
  }

  emitAdEvent(type, e) {
    const ad = e.adStreamInfo;
    if (ad) {
      const data = {};

      // FIXME: Now no data for this https://github.com/googleads/videojs-ima/issues/845
      // if (inventory.vasts && inventory.vasts.length) {
      //   data.ad_tag_url = inventory.vasts[inventory.vasts.length-1];
      // }

      data.ad_asset_url = this.player.currentSrc();

      if (ad.id) data.ad_id = ad.adID;
      if (ad.title) data.ad_title = ad.adName;
      this.player.mux.emit(type, data);
    }
  }

  getVideoInfo(streamInfo) {
    const muxInfoObject = {
      video_id: streamInfo.videoId || streamInfo.programID,
      video_title: streamInfo.programName,
      video_stream_type: streamInfo.programDuration === -1 ? 'live' : 'vod',
      experiment_name: 'video',
    };

    if (streamInfo.programDuration !== -1) {
      muxInfoObject.video_duration = streamInfo.programDuration * 1000;
    }

    if (streamInfo.series) {
      muxInfoObject.video_series = streamInfo.series;
    }

    if (streamInfo.videoType) {
      muxInfoObject.video_content_type = streamInfo.videoType;
    }

    return muxInfoObject;
  }

  handleProgramLoaded({ streamInfo }) {
    this.player.mux.emit('videochange', this.getVideoInfo(muxInfoObject));
  }

  handleAdLoaded({ adStreamInfo }) {
    const muxInfoObject = {
      video_id: adStreamInfo.adID,
      video_title: adStreamInfo.adName,
      video_stream_type: 'vod',
      experiment_name: 'ad',
    };

    if (adStreamInfo.adDuration !== -1) {
      muxInfoObject.video_duration = adStreamInfo.adDuration * 1000;
    }

    if (adStreamInfo.adType) {
      muxInfoObject.video_content_type = adStreamInfo.adType;
    }

    this.player.mux.emit('videochange', muxInfoObject);
  }

  handleCustomError({ code, message }) {
    /*
     * currently supported errors:
     * - HB_PERFORM_REQUEST
     * - HB_RESPONSE_403
     * - HB_RESPONSE_404
     * - TS_NAN_SOURCE_CHANGE
     * - TS_SRC_INVALID
     * - TS_SEEK_INVALID_ARG
     * - LAN_NOT_FOUND
     * - TS_ARGS_URLPAR_CHECK
     * - TS_ARGS_H_NUMBER_CHECK
     * - TS_ARGS_V_NUMBER_CHECK
     */

    if ((this.logErrors == null || this.logErrors.indexOf(code) != -1) && this.ignoreErrors.indexOf(code) == -1) {
      this.player.mux.emit('error', {
        player_error_code: code,
        player_error_message: message,
      });
    }
  }
}
