import BaseMarkers from '../../../../base-markers';
import log from '../../../../log';

/**
 * Markers helper class. E.g. reset markers, ..
 *
 * @param {Object} player   Player object
 * @param {Object} adstate  Advertisement state container object
 */
export default class CustomMarkers extends BaseMarkers {
  constructor(player, adstate) {
    super(player);

    this.adstate = adstate;
    this.disableAdMarkers = player.options_.plugins.adService.disableAdMarkers || false;
  }

  generateMarkers() {
    if (!this.disableAdMarkers) {
      // add markers on player ready
      this.player.ready(() => this.addAdMarkers());

      this.player.on(['prerollended', 'midrollended'], () => {
        // get ad markers index
        this.adMarkersIndex = this.player.markers
          .getMarkers()
          .map((marker, index) => (marker.class === 'vjs-marker-ad' ? index : undefined))
          .filter((item) => item === 0 || item);

        // remove all ad markers by index
        this.player.markers.remove(this.adMarkersIndex);
        log('[Ad markers] all midroll ad markers removed from timeline');
        // add markers

        this.player.one('ott-mea-play', () => this.addAdMarkers());
      });
    }
  }

  addAdMarkers() {
    // get midroll ad markers
    this.markers = this.adstate.inventory.midrolls
      .filter((ad) => !ad.requested)
      .map((ad) => this.adMarkerObj(ad, 'vjs-marker-ad', this.totalDuration));

    // create only midroll ad markers
    this.player.markers.add(this.markers);
    log('[Ad markers] not requested midroll ad markers added to timeline');
  }
}
