import { videojs } from 'global/window';
import { playedPercentualPlugin } from './playedpercentual';
import { playerReportPlugin } from './playerreport';
import { playedTicksPlugin } from './playedticks';
import { UHDController } from './uhdController';
import { AudioChannelsController } from './audioChannelsController';
import { adServicePlugin } from './adservice-custom';
import { AdService } from './adservice-ima';
import { sentryReportsPlugin } from './sentry-reports';
import { MeasuringCustom } from './measuring-custom';
import { MeasuringIMA } from './measuring-ima';
import { offsetPlugin } from './offset';
import { heartBeatsPlugin } from './heartbeats';
import { timeShiftPlugin } from './time-shift';
import { thumbnailsPlugin } from './thumbnails';
import { settingsPlugin } from './settings';
import { seekHintsPlugin } from './seek-hints';
import { seekBackPlugin } from './seek-back';
import { autoplayNext } from './autoplay-next';
import { expandScreenPlugin } from './expand-screen';
import { helpButtonPlugin } from './help-button';
import { liveRestartButtonPlugin } from './live-restart-button';
import { productPlacementPlugin } from './product-placement';
import { mobileControls } from './mobile-controls';
import { OMIDManager } from './adservice/omid/omid-manager';
import { AdServiceImaDai } from './adservice-ima-dai';
import { MeasuringImaDai } from './measuring-ima-dai';
import { CSAI } from './adservice-csai/adservice-csai';
import { ViewableImpression } from './adservice/viewable-impression/viewable-impression';
import { UnmuteButtonController } from './big-unmute-button';
import { sharingScreenPlugin } from '../../play/plugins/sharing-screen';
import { AutoPause } from './autoPause';

export function registerPlugins() {
  // What is this? Could it be deleted? Exported function is really weird.
  videojs.registerPlugin('playerReport', playerReportPlugin);
  videojs.registerPlugin('playedTicks', playedTicksPlugin);
  // TODO: word percentual doesn't exist? Check that.
  videojs.registerPlugin('playedPercentual', playedPercentualPlugin);
  videojs.registerPlugin('uhdController', UHDController);
  videojs.registerPlugin('audioChannelsController', AudioChannelsController);
  videojs.registerPlugin('adService', adServicePlugin);
  videojs.registerPlugin('adServiceIMA', AdService);
  // is this used in any useful way? Could it be removed?
  videojs.registerPlugin('sentryReports', sentryReportsPlugin);
  videojs.registerPlugin('measuring', MeasuringCustom);
  videojs.registerPlugin('measuringIMA', MeasuringIMA);
  videojs.registerPlugin('offset', offsetPlugin);
  videojs.registerPlugin('heartBeats', heartBeatsPlugin);
  videojs.registerPlugin('timeShift', timeShiftPlugin);
  videojs.registerPlugin('thumbnails', thumbnailsPlugin);
  videojs.registerPlugin('settings', settingsPlugin);
  videojs.registerPlugin('seekHints', seekHintsPlugin);
  videojs.registerPlugin('seekBack', seekBackPlugin);
  videojs.registerPlugin('autoplayNext', autoplayNext);
  videojs.registerPlugin('expandScreen', expandScreenPlugin);
  videojs.registerPlugin('helpButton', helpButtonPlugin);
  videojs.registerPlugin('liveRestartButton', liveRestartButtonPlugin);
  videojs.registerPlugin('productPlacement', productPlacementPlugin);
  videojs.registerPlugin('mobileControls', mobileControls);
  videojs.registerPlugin('omidManager', OMIDManager);
  videojs.registerPlugin('adServiceImaDai', AdServiceImaDai);
  videojs.registerPlugin('measuringImaDai', MeasuringImaDai);
  videojs.registerPlugin('viewableImpressionTracker', ViewableImpression);
  videojs.registerPlugin('bigUnmuteButton', UnmuteButtonController);
  videojs.registerPlugin('sharingScreen', sharingScreenPlugin);
  videojs.registerPlugin('csai', CSAI);
  videojs.registerPlugin('autoPause', AutoPause);
}
