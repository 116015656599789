/**
 * Taken from omsdk examples at https://github.com/InteractiveAdvertisingBureau/Open-Measurement-JSClients/tree/master/reference-app-web
 *
 * The playback states of the ad media player.
 * @enum {string}
 * @public
 */
export const videoPlayerState = {
  /**
   * The player is collapsed in such a way that the video is hidden.
   * The video may or may not still be progressing in this state, and sound may
   * be audible. This refers specifically to the video player state on the
   * page, and not the state of the browser window.
   * Constant has value 'minimized'.
   */
  MINIMIZED: 'minimized',
  /**
   * The player has been reduced from its original size.
   * The video is still potentially visible.
   * Constant has value 'collapsed'.
   */
  COLLAPSED: 'collapsed',
  /**
   * The player's default playback size.
   * Constant has value 'normal'.
   */
  NORMAL: 'normal',
  /**
   * The player has expanded from its original size.
   * Constant has value 'expanded'.
   */
  EXPANDED: 'expanded',
  /**
   * The player has entered fullscreen mode.
   * Constant has value 'fullscreen'.
   */
  FULLSCREEN: 'fullscreen',
};

/**
 * The type of ad creative being measured in the ad session.
 * @enum {string}
 * @public
 */
export const creativeType = {
  /**
   * Creative type needs to be set by JavaScript session script.
   * Constant has value 'definedByJavaScript'.
   */
  DEFINED_BY_JAVASCRIPT: 'definedByJavaScript',
  /**
   * Creatives measured using display methodology that are trafficked as HTML.
   * Rendered in webview.
   * Verification scripts can wrap creative or be in resources.
   * Constant has value 'htmlDisplay'.
   */
  HTML_DISPLAY: 'htmlDisplay',
  /**
   * Creatives measured using display methodology that are trafficked as JSON
   * or other format for structured data.
   * Rendered by native code.
   * Verification scripts can be in resources only.
   * Constant has value 'nativeDisplay'.
   */
  NATIVE_DISPLAY: 'nativeDisplay',
  /**
   * Creatives measured using video methodology.
   * Rendered in many kinds of placements (such as instream, outstream, banner,
   * in-feed, etc.).
   * Verification scripts can be in resources only.
   * Constant has value 'video'.
   */
  VIDEO: 'video',
  /**
   * Creatives measured using audio methodology.
   * OMID does not provide any visibility data.
   * Verification scripts can be in resources only.
   * Constant has value 'audio'.
   */
  AUDIO: 'audio',
};
