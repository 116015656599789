import { videojs } from 'global/window';
/* eslint-disable */
import LanguagesMenu from './languages-menu';
/* eslint-enable */
import hideOpenMenus from '../../../utils/hide-menus';
import cancelActiveButtons from '../../../utils/cancel-active-buttons';
import propTest from '../../../property-tester';

// Get a component to subclass
const ClickableComponent = videojs.getComponent('ClickableComponent');
const Component = videojs.getComponent('Component');

/**
 * The subtitles button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class LanguagesButton extends ClickableComponent {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.player.addChild('LanguagesMenu', options);

    this.controlText(this.player_.localize('Language'));
    this.languagesControlOpts = propTest(() => this.player.options_.responsiveControl.languages);

    if (this.languagesControlOpts) {
      Object.entries(this.languagesControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'hidden';
          if (entry[0] === 'alone') visibility = 'visible';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-languages-control-button ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div');
  }

  /**
   * Handle click on help button.
   */
  handleClick() {
    const menu = this.player_.getChild('LanguagesMenu');
    if (typeof menu !== 'undefined') {
      if (menu.hasClass('vjs-hidden')) {
        hideOpenMenus(this.player_);
        cancelActiveButtons(this.player_);
        menu.removeClass('vjs-hidden');
        this.addClass('vjs-button-active');
        this.player_.addClass('vjs-popup-displayed');
      } else {
        menu.addClass('vjs-hidden');
        this.removeClass('vjs-button-active');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }
}
// Register the new component
Component.registerComponent('LanguagesButton', LanguagesButton);
export default LanguagesButton;
