import { videojs } from 'global/window';

const OptOutButton = videojs.getComponent('OptOutButton');
const Component = videojs.getComponent('Component');

/**
 * Stream language heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class OptOutClose extends OptOutButton {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return videojs.dom.createEl('span', {
      className: 'vjs-icon-close-red vjs-optout-close',
      innerHTML: '',
    });
  }
}

Component.registerComponent('OptOutClose', OptOutClose);
export default OptOutClose;
