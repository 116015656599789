import { videojs } from 'global/window';
// eslint-disable-next-line no-unused-vars
import QualityMeasurementClose from './quality-measurement-close';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class QualityMeasurementOverlay extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('QualityMeasurementClose');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    this.contentEl_ = super.createEl('div', {
      className: 'vjs-quality-measurement-overlay',
      innerHTML: `<div class="vjs-quality-measurement-overlay-inner">
          <div class="vjs-quality-measurement-overlay-text">
            <p class="dialog-title">${this.player_.localize('Measurement of playback quality')}</p>
            <p>
              ${this.player_.localize(
                'With the help of targeted quality measurement, we are able to diagnose a possible source of the problem directly on your device in case of problems with video playback.',
              )}
            </p>
            <p>
              ${this.player_.localize(
                'If you enable this option, our technical support staff will be able to assist you more effectively in resolving issues.',
              )}
            </p>
          </div>
        </div>`,
    });

    return this.contentEl_;
  }
}

Component.registerComponent('QualityMeasurementOverlay', QualityMeasurementOverlay);
export default QualityMeasurementOverlay;
