import { videojs } from 'global/window';
import AutoplayNextSwitchHeading from './autoplay-next-switch-heading';
import AutoplayNextSwitchButton from './autoplay-next-switch-button';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class AutoplayControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild(new AutoplayNextSwitchHeading(player, options));
    this.addChild(new AutoplayNextSwitchButton(player, options));
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-an-control vjs-settings-item',
    });
  }
}

export default AutoplayControl;
