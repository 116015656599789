import { isAdPlaying } from './adservice/shared/ad-state';
import log from '../log';
import { getStreamInfo } from '../utils/source';
import availableEvents from './measuring/shared/available-events';

class PlayedPercentual {
  constructor(player, opts = {}) {
    // reference of ott-player object
    this.player = player;
    // Treashold for triggering event, default value is 5%
    this.percentualTreashold = opts.percentualTreashold || 5;
    this.streamInfo = null;
    this.totalDuration = null;
    this.controlTreasholds = {};
    this.controlTreasholdsAbsolute = {};
  }

  registerEvents() {
    // TODO maybe player.ready?
    this.player.on('loadedmetadata', () => {
      // not set when first play is AD
      // TODO off watching event loadedmetadata after set controlTreasholds
      if (!isAdPlaying(this.player) && Object.keys(this.controlTreasholds).length === 0) {
        this.totalDuration = Math.round(this.player.duration());
        this.streamInfo = getStreamInfo(this.player);
        this.setControlTreasholds();

        // check playedTicks plugin initialized
        if (!this.player.options_.plugins.playedTicks) {
          log.error('[playedPercentual] ERROR! plugin playTicks is required');
        }
      }
    });

    this.player.on('ott-playertick', (e) => {
      let viewedData;
      let absoluteData;
      const currentTime = Math.floor(this.player.currentTime());
      if (this.controlTreasholds[e.ticks] && this.controlTreasholds[e.ticks].reported === false) {
        this.controlTreasholds[e.ticks].reported = true;
        viewedData = {
          percent: this.controlTreasholds[e.ticks].percent,
          seconds: this.controlTreasholds[e.ticks].seconds,
        };
        log('[playedPercentual] triggered playedpercentual', this.controlTreasholds[e.ticks]);
      }
      if (this.controlTreasholdsAbsolute[currentTime]) {
        this.controlTreasholdsAbsolute[currentTime].reported = true;
        absoluteData = {
          percentAbsolute: this.controlTreasholdsAbsolute[currentTime].percent,
          secondsAbsolute: this.controlTreasholdsAbsolute[currentTime].seconds,
        };
        log('[playedPercentual] triggered playedpercentual', this.controlTreasholdsAbsolute[currentTime]);
      }
      this.player.trigger({
        type: availableEvents.PLAYED_PERCENTUAL,
        ...viewedData,
        ...absoluteData,
        streamInfo: this.streamInfo,
      });
    });
  }

  setControlTreasholds() {
    const durationOnePercent = this.totalDuration / 100;
    for (let i = this.percentualTreashold; i <= 100; i += this.percentualTreashold) {
      const percentToSeconds = Math.round(i * durationOnePercent);
      this.controlTreasholds[percentToSeconds] = {
        reported: false,
        percent: i,
        seconds: percentToSeconds,
      };
      this.controlTreasholdsAbsolute[percentToSeconds] = {
        reported: false,
        percent: i,
        seconds: percentToSeconds,
      };
    }
    log(
      `[playedPercentual] totalDuration: ${this.totalDuration}, onePercent: ${durationOnePercent}, controlTreasholds:`,
      this.controlTreasholds,
    );
  }
}

/**
 * playedPercentual plugin initialization
 *
 */
export const playedPercentualPlugin = function playedPercentualPlugin(opts) {
  const playedPercentual = new PlayedPercentual(this, opts);
  playedPercentual.registerEvents();

  log('playedPercentual plugin initialized');
};
