/**
 * Enum of all available ad types
 *
 * @readonly
 * @enum {String}
 */
const avlAdType = Object.freeze({
  csaiMidrolls: 'csaiMidrolls',
  preroll: 'preroll',
  midrolls: 'midrolls',
  postroll: 'postroll',
  companion: 'companion',
  overlay: 'overlay',
  sklik: 'sklik',
});
export default avlAdType;
