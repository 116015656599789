import { videojs } from 'global/window';
import log from '../../../log';
import QualitySelector from '../../../utils/vhs-quality-selector';
import { qualityMapping } from '../../../utils/quality-helper';

import './quality-bar';
import './quality-auto-button-wrapper';
import './quality-button';
import './quality-button-wrapper';
import './quality-heading';
import './quality-select/quality-select-wrapper';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Quality control switch - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
export default class QualityControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    options.qualitySelector = new QualitySelector(player);

    this.qualityControlOpts = player.options_?.responsiveControl?.quality;
    const { levels_ } = player.qualityLevels();

    if (this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send appropriate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'visible';
          if (entry[0] === 'alone') visibility = 'hidden';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }

    // Send this component to the children
    this.addChild('QualityHeading', { ...options, parent: this });
    if (this.options_.dropdownSettings && levels_.length) {
      this.addChild('QualitySelectWrapper', { ...options, qualitySelector: options.qualitySelector, parent: this });
    } else {
      this.addChild('QualityBar', { ...options, qualitySelector: options.qualitySelector, parent: this });
      this.addChild('QualityAutoButtonWrapper', { ...options, qualitySelector: options.qualitySelector, parent: this });
    }

    // when configuration includes special rules, render also in controlBar
    if (this.qualityControlOpts) {
      // check needed for DRM tracks
      if (!player.controlBar?.getChild('QualityButton')) {
        player.controlBar.addChild('QualityButton', {
          ...options,
          qualitySelector: options.qualitySelector,
          parent: this,
        });
      }
    }

    this.registerUhdSwitch();

    player.qualityLevels().on('change', ({ selectedIndex }) => {
      if (this.qualityControlOpts) {
        // "this.player_" check needed for DRM tracks
        if (this.player_ && !this.player_.uhdController().uhdActive) {
          // add "active" class to selected quality
          const menuItems = [...this.player_.getChild('QualityMenu')?.getChild('QualityButtonWrapper').el_.childNodes];
          menuItems.forEach((item) => {
            item.classList.remove('active');
            if (Number(item.id) === selectedIndex) {
              item.classList.add('active');
            }
          });
        }

        // update selected quality label on quality icon in control bar
        const { height, bitrate } = levels_?.find((_, index) => index === selectedIndex);
        const selectedQualityTpl = `<span>${qualityMapping(height, bitrate).controlLabel}</span>`;
        const selectedQualityEl = document.getElementById('vjs-control-button-quality-selected');
        if (qualityMapping(height, bitrate).controlLabel) {
          selectedQualityEl.innerHTML = selectedQualityTpl;
          selectedQualityEl.classList.remove('vjs-hidden');
        }
      }

      log('[quality-levels]: quality changed');
    });
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-quality-control vjs-settings-item',
    });
  }

  registerUhdSwitch() {
    this.player_.on('ott-uhdprofile-control', (e) => {
      e.uhdActive ? this.show() : this.show();
      log(
        `[quality plugin] - recived uhd control event. UHD active: ${e.uhdActive} -> quality control ${
          e.uhdActive ? 'hide' : 'show'
        }`,
      );
    });
  }
}

Component.registerComponent('QualityControl', QualityControl);
