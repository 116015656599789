import { videojs } from 'global/window';

import QualitySelectMenu from './quality-select-menu';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Quality select wrapper.
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualitySelectWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.createContent();
    this.el_.className = 'vjs-quality-select-wrapper';
  }

  createContent() {
    this.addChild(new QualitySelectMenu(this.player_, { qualitySelector: this.options.qualitySelector }));
  }
}

Component.registerComponent('QualitySelectWrapper', QualitySelectWrapper);
export default QualitySelectWrapper;
