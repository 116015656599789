import { videojs } from 'global/window';
/* eslint-disable */
import AudioChannelsHeading from './audioChannels-heading';
import AudioChannelsButton from './audioChannels-button';
/* eslint-enable */
import log from '../../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Multichannels audio switch - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class AudioChannelsControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.hide();
    this.addChild('AudioChannelsHeading');
    this.addChild('AudioChannelsButton');
    this.registerAudioChannelsSwitch();

    this.qualityControlOpts = this.player_.options_?.responsiveControl?.quality;
    this.dropdownSettings = this.options_.dropdownSettings;

    if (this.dropdownSettings && this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'hidden';
          if (entry[0] === 'alone') visibility = 'visible';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }
  }

  registerAudioChannelsSwitch() {
    this.player_.on('ott-audiochannels-control', (e) => {
      if (e.audioChannelsAllowed) {
        this.show();
      } else {
        this.hide();
      }
      this.getChild('AudioChannelsButton').setInputCheckedState(e.multiChannelActive);

      log(
        `[audioChannels] [menu] switch control recived control event. Multichannels allowed: ${
          e.audioChannelsAllowed
        } -> switch button ${e.audioChannelsAllowed ? 'showed' : 'hidden'} | active: ${e.multiChannelActive}`,
      );
    });
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-an-control vjs-settings-item',
    });
  }
}

Component.registerComponent('AudioChannelsControl', AudioChannelsControl);
export default AudioChannelsControl;
