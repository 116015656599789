import avlAdType from './ad-type';

/**
 * Get appropriate inventory from the adstate player object
 *
 * @param  {Object} adState     Player advertisement state object
 * @param  {String} adType      Ad type (available through availableAdType enum)
 * @param  {Number} [adi=-1]    Used when break inventory contains more than 1 object
 * @param  {Number=} requested  Change requested flag when defined
 * @return {Object}             Selected ad inventory
 */
const getInventory = function getInventory(adState, adType, adi = -1, requested) {
  let adInventory;

  if (adi >= 0) {
    if (adType === avlAdType.midrolls) {
      adInventory = adState.inventory[adType][adi];
    } else {
      // Keep reqested flag on the cascade object as well
      if (requested) {
        adState.inventory.cascade[adi].requested = requested;
      }
      adInventory = adState.inventory.cascade[adi][adType];
    }
  } else {
    adInventory = adState.inventory[adType];
  }

  if (requested) {
    adInventory.requested = requested;
  }
  return adInventory;
};

export default getInventory;
