import { videojs } from 'global/window';
import hideOpenMenus from '../../utils/hide-menus';
import cancelActiveButtons from '../../utils/cancel-active-buttons';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

class SettingsButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;

    this.controlTextEl_.innerHTML = this.player_.localize('Settings');

    this.qualityControlOpts = this.player_.options_?.responsiveControl?.quality;
    const { levels_ } = player.qualityLevels();

    if (this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send approprate class to element
        let iconType;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') iconType = 'visible';
          if (entry[0] === 'alone' && levels_?.length) iconType = 'hidden';
          this.addClass(`vjs-bp-${entry[1][i]}-${iconType}`);
        }
      });
    }
  }

  buildCSSClass() {
    return `vjs-settings-button ${super.buildCSSClass()}`;
  }

  handleClick() {
    const menu = this.player_.getChild(this.options_.dropdownSettings ? 'SettingsMenuDropdown' : 'SettingsMenu');
    if (typeof menu !== 'undefined') {
      if (menu.hasClass('vjs-hidden')) {
        hideOpenMenus(this.player_);
        cancelActiveButtons(this.player_);
        menu.removeClass('vjs-hidden');
        this.addClass('vjs-button-active');
        this.player_.addClass('vjs-popup-displayed');
      } else {
        menu.addClass('vjs-hidden');
        this.removeClass('vjs-button-active');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }
}
// Register the new component
Component.registerComponent('SettingsButton', SettingsButton);
export default SettingsButton;
