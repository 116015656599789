import { videojs } from 'global/window';
import propTest from '../../property-tester';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistSourceNameDisplay extends Component {
  getSourceName() {
    const { playlistData } = this.player_.playlist;
    const measuring =
      playlistData.sources[playlistData.actualIndex].plugins.measuring ||
      playlistData.sources[playlistData.actualIndex].plugins.measuringIMA;

    if (propTest(() => measuring.streamInfo.programName)) {
      return measuring.streamInfo.programName;
    }

    return `Playlist ${playlistData.actualIndex + 1}`;
  }

  createEl() {
    const sourceName = this.getSourceName();

    return super.createEl('div', {
      className: `vjs-playlist-source-name-display ${sourceName.length > 15 ? 'playlist-source-name-animation' : ''}`,
      innerHTML: `<div class='nameBox'><div class="title">${sourceName}</div></div>`,
    });
  }
}

Component.registerComponent('PlaylistSourceNameDisplay', PlaylistSourceNameDisplay);
export default PlaylistSourceNameDisplay;
