import { videojs } from 'global/window';
import QualityControl from './quality/quality-control';
import LanguagesControl from './languages/languages-control';
import SubtitlesControl from './subtitles/subtitles-control';
import OptOutControl from './nielsen/opt-out-control';
import AutoplayNextSwitchControl from './autoplay-next-switch/autoplay-next-switch-control';
import QualityMeasurementControl from './quality-measurement/quality-measurement-control';
import VersionControl from './version/version-control';
import propTest from '../../property-tester';
// should this be imported this way? Should it be imported at all?
import './uhdProfile/uhdProfile-control';
import './audioChannels/audioChannels-control';

import log from '../../log';
import { adaptiveStreams } from '../../constants';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Registration component for all settings sub-plugin
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SettingsMenu extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.createWrapper();
    this.createContent();
    this.el_.className = `vjs-settings-menu vjs-custom-menu vjs-hidden ${
      this.options_.dropdownSettings ? 'vjs-settings-menu-dropdown' : ''
    }`;
    this.enabled_ = true;
  }

  /* create wrapper that enables scrolling */
  createWrapper() {
    this.wrapper = this.addChild('component');
    this.wrapper.el_.className = 'vjs-settings-menu--wrapper';
  }

  /**
   * Try to register all plugins
   */
  createContent() {
    const { settings } = this.player_.options_.plugins;
    this.registerQualityPlugin(settings);
    this.registerAudioChannelsSwitch(settings);
    this.registerUhdProfileSwitch(settings);
    this.registerLanguagesPlugin(settings);
    this.registerSubtitlesPlugin(settings);
    this.registerAutoplaySwitch(settings);
    this.registerNielsenMeasurementPlugin(settings);
    if (propTest(() => this.player_.options_.plugins.measuring.services.mux)) {
      const { mux } = this.player_.options_.plugins.measuring.services;
      this.registerQualityMeasurementSwitch(mux);
    }
    this.registerPlayerVersionInfo();
  }

  /**
   * Register qualities plugin
   */
  registerPlayerVersionInfo() {
    this.wrapper.addChild(new VersionControl(this.player_));
    log('[Menu] player version in menu initialized');
  }

  /**
   * Register qualities plugin
   */
  registerQualityPlugin(settings, options) {
    if (Object.prototype.hasOwnProperty.call(settings, 'qualities')) {
      // Initialize qualities sub-plugin

      // FIXME: hotfix after change ad => main source in FF TypeError: "this.player.tech(...).sourceHandler_ is undefined"
      try {
        const createNewQualityControl = () => new QualityControl(this.player_, { name: 'qualityControl', ...options });

        let qualityControl = createNewQualityControl();

        this.wrapper.addChild(qualityControl);

        // when there is change in qualityLevels, reset quality control
        this.player_.on('ott-auto-quality-set-via-keystatuschange', () => {
          qualityControl.dispose();
          const indexOfQualityControl = this.wrapper.children_.findIndex((child) => child.name_ === 'qualityControl');
          this.wrapper.children_.forEach((child) => {
            if (child.isDisposed_) {
              this.wrapper.removeChild(child);
            }
          });

          qualityControl = createNewQualityControl();
          this.wrapper.addChild(qualityControl, {}, indexOfQualityControl);
        });
      } catch (error) {
        log.error('[Menu] registerQualityPlugin error', error);
      }
      log('[Menu] qualities plugin initialized');
    }
  }

  /**
   * Remove all remoteTextTracks from the player instance
   */
  removeTextTracks() {
    this.player_.remoteTextTracks().tracks_.forEach((track) => {
      this.player_.removeRemoteTextTrack(track);
    });
  }

  /**
   * Remove all text tracks and reinit them registerLanguagesPlugin
   * @param  {array} subtitles
   */
  reinitSubtitles(subtitles) {
    if (this.player_.remoteTextTracks().tracks_.length === 0) {
      log('[Menu] Subtitles reinit called but any text track found.');
      return;
    }

    this.removeTextTracks();

    subtitles.forEach((caption) => {
      this.player_.addRemoteTextTrack(caption, true);
    });
  }

  /**
   * Register subtitles plugin
   */
  registerSubtitlesPlugin(settings, options) {
    if (Object.prototype.hasOwnProperty.call(settings, 'subtitles') && settings.subtitles.length > 0) {
      this.player_.on('ott-subtitles-reinit', () => this.reinitSubtitles(settings.subtitles));

      settings.subtitles.forEach((caption) => {
        this.player_.addRemoteTextTrack(caption, true);
      });

      // HACK: If we change the video source when playing HLS with Fairplay (on Safari), the default value is taken with each initialization.
      // This workaround keeps the previous selected version on/off
      this.player_.one('play', () => {
        const [...tracks] = this.player_.el().getElementsByTagName('video')[0].getElementsByTagName('track');
        tracks.forEach((track) => {
          track.removeAttribute('default');
        });
      });

      // Initialize subtitles sub-plugin
      this.wrapper.addChild(new SubtitlesControl(this.player_, options));
      log('[Menu] subtitles plugin initialized');
    }
  }

  /**
   * Register languages plugin
   */
  registerLanguagesPlugin(settings, options) {
    if (Object.prototype.hasOwnProperty.call(settings, 'languages')) {
      // We can ask for current type - this plugin is initialized after source
      // selecetion - when metadata loaded
      switch (this.player_.currentType().toLowerCase()) {
        case adaptiveStreams.dash:
          this.registerLanguagesControl(settings.languages.DASH, options);
          break;
        case adaptiveStreams.hls:
          this.registerLanguagesControl(settings.languages.HLS, options);
          break;
        case 'video/mp4':
          this.registerLanguagesControl(settings.languages.MP4, options);
          break;
      }
    }
  }

  /**
   * When languages array contains any element register languages plugin
   *
   * @param {Array=} languagesArr - Contains array of languages
   */
  registerLanguagesControl(languagesArr = [], options) {
    // Initialize languages sub-plugin only when DASH, HLS or MP4 contains languages
    if (languagesArr.length > 1) {
      this.wrapper.addChild(
        new LanguagesControl(this.player_, {
          ...options,
          languages: languagesArr,
        }),
      );
      log('[Menu] languages plugin initialized');
    }
  }

  registerNielsenMeasurementPlugin(settings) {
    if (Object.prototype.hasOwnProperty.call(settings, 'nielsenOptOut')) {
      // Initialize nielsen opt-out sub-plugin
      this.wrapper.addChild(new OptOutControl(this.player_));
      log('[Menu] Nielsen measurement plugin initialized');
    }
  }

  registerAutoplaySwitch(settings) {
    if (settings.autoplayNextSwitch) {
      // Initialize Autoplay sub-plugin
      this.wrapper.addChild(new AutoplayNextSwitchControl(this.player_));
      log('[Menu] autoplayNext switch plugin initialized');
    }
  }

  /**
   * Register audio multichannels switch 5.1 audio
   */
  registerAudioChannelsSwitch(_, options) {
    // Initialize Audio Channels sub-plugin
    this.wrapper.addChild('audioChannelsControl', options);
    log('[Menu] player audioChannels switch plugin initialized - not showed');
  }

  /**
   * Register UHD profile plugins
   */
  registerUhdProfileSwitch(_, options) {
    this.qualityControlOpts = this.player_.options_?.responsiveControl?.quality;

    this.wrapper.addChild('UhdProfileControl', options);
    log('[Menu] player UHD switch in menu initialized - not showed');

    if (this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'visible';
          if (entry[0] === 'alone') visibility = 'hidden';
          this.wrapper.getChild('UhdProfileControl').addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }
  }

  /**
   * Register quality measurement switch - mux
   */
  registerQualityMeasurementSwitch(mux) {
    if (Object.prototype.hasOwnProperty.call(mux, 'quality_measurement_switch')) {
      if (mux.quality_measurement_switch === true) {
        // Initialize Quality Measurement sub-plugin
        this.wrapper.addChild(new QualityMeasurementControl(this.player_));
        log('[Menu] qualityMeasurement switch plugin initialized');
      }
    }
  }
}

// Register the new component
Component.registerComponent('SettingsMenu', SettingsMenu);
export default SettingsMenu;
