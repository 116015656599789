/**
 * <p>This is a timeshift sub-plugin. Displays thumbnail images over the progress bar.</p>
 *
 * @example
{
  plugins: {
    timeShift: {
      liveThumbnails: {
        url: 'www.example.com/image.jpg'
      }
      // ...
    }
  }
}
 *
 * @module plugins/timeShift/liveThumbnails
 */

// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;

// Get a component to subclass
const Component = videojs.getComponent('Component');

class ThumbnailsHolder extends Component {
  constructor(player, opts) {
    super(player, opts);
    this.requestInterval = 100;
  }

  /**
   * Round and format T - seekTime as ZULU
   *
   * @param {Number} seekTime - current position on the seekbar in seconds
   * @return {String} Rounded and formatted date in ZULU (ISO date)
   */
  getFormattedDate(seekTime) {
    seekTime = (seekTime + 36) * 1000;
    const date = new Date(Date.now() - seekTime);
    date.setMilliseconds(0);
    date.setSeconds(Math.round(date.getSeconds() / 10) * 10);

    return date.toISOString();
  }

  /**
   * Format seek time to the negative format e.g. '-01:10:15'
   *
   * @param {Number} seekTime - Seek time in seconds
   * @return {Number} Formated seek time as negative value
   */
  getNegativeTime(seekTime) {
    seekTime *= 1000;
    const date = new Date(seekTime);
    const hours = date.getHours() - 1 + (date.getDate() - 1) * 24;
    const formattedTime = `${this.toTwoDigitFormat(date.getMinutes())}:${this.toTwoDigitFormat(date.getSeconds())}`;

    return hours > 0 ? `-${this.toTwoDigitFormat(hours)}:${formattedTime}` : `-${formattedTime}`;
  }

  /**
   * This will set the holder position
   *
   * @param {Number} x - x coordinate corresponding to the mouse position
   */
  setPosition(x) {
    const playerWidth = this.player_.el_.clientWidth;
    const elWidth = this.el_.getElementsByClassName('vjs-thumbnail-wrapper')[0].clientWidth;
    const shift = elWidth / 2;
    let posX = playerWidth * x - shift;
    if (posX > playerWidth - elWidth) {
      posX = playerWidth - elWidth;
    } else if (posX < 0) {
      posX = 0;
    }
    this.el_.setAttribute('style', `left: ${posX}px;`);
  }

  /**
   * Show number in two digit format. E.g. javascript date doesn't have two digit format option..
   *
   * @param {Number} number - Number to show in two digit format
   * @return {Number} Formated number in two digit format. E.g. when you pass 6 than you get 06 and vice versa
   */
  toTwoDigitFormat(number) {
    return `0${number}`.slice(-2);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-thumbnail-holder',
      innerHTML: `
         <div class="vjs-thumbnail-wrapper">
            <img src="" class="vjs-thumbnail">
            <p class="vjs-thumbnail-time"></p>
         </div>`,
    });
  }

  /**
   *
   *
   * @param {Number} seekTime - current position on the seekbar in seconds
   */
  showImage(seekTime) {
    this.src = `${this.options_.thumbnails.url}?time=${this.getFormattedDate(seekTime)}`;
    const elmSrc = this.el_.getElementsByTagName('img')[0].src;
    if (elmSrc !== this.src) {
      this.el_.getElementsByTagName('img')[0].src = this.src;
    }
  }

  /**
   * Change the thumbnail image source
   *
   * @param {Number} seekTime - current position on the seekbar in seconds
   * @param {Number} x        - x coordinate corresponding to the mouse position
   */
  updateImage(seekTime, x) {
    this.el_.getElementsByTagName('p')[0].innerHTML = `${this.getNegativeTime(seekTime)}`;
    if (typeof this.src === 'undefined') {
      // First thumbnail showing
      this.showImage(seekTime);
    }
    if (typeof this.timeout === 'undefined') {
      this.timeout = setTimeout(() => {
        this.showImage(seekTime);
        this.timeout = undefined;
      }, this.requestInterval);
    }
    this.setPosition(x);
  }
}
// Register the new component
Component.registerComponent('ThumbnailsHolder', ThumbnailsHolder);
export default ThumbnailsHolder;
