import * as Dom from '../../../utils/dom';
import * as TimeShiftState from '../ts-state';
import ThumbnailsHolder from './thumbnails-holder';
/* eslint-disable */
import LiveHorizontalProgressBar from './live-progressbar';
/* eslint-enable */
// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;
// Get a component to subclass
const Component = videojs.getComponent('Component');
const Slider = videojs.getComponent('Slider');

/**
 * Live horizontal seek Bar and holder for the progress bars
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Slider
 */
class LiveSeekBar extends Slider {
  constructor(player, options) {
    super(player, options);

    this.name_ = 'LiveHorizontalSeekBar';
    this.horizontalSec = options.horizontalSec;
    this.disabled = options.disabled || false;

    if ((options.thumbnails || {}).url) {
      this.thumbnailsHolder = new ThumbnailsHolder(player, options);
      this.addChild(this.thumbnailsHolder);
    }

    // Watch events
    this.on(player, 'timeupdate', this.updateProgress);
    this.on(player, 'ended', this.updateProgress);
    this.on(this.el_, 'mousemove', this.handleMouseMove);
    this.on(player, 'ott-seekablelive-seek', this.updateSeekbar);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl(
      'div',
      {
        className: 'vjs-live-progress-holder vjs-progress-holder',
      },
      {
        'aria-label': 'live progress bar',
      },
    );
  }

  /**
   * Update bar progress
   */
  updateProgress() {
    this.updateAriaAttributes(this.el_);
  }

  /**
   * Update ARIA accessibility attributes
   */
  updateAriaAttributes(el) {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    // machine readable value of progress bar (percentage complete)
    el.setAttribute('aria-valuenow', (this.getPercent() * 100).toFixed(2));
    // human readable value of progress bar (time complete)
    el.setAttribute('aria-valuetext', videojs.formatTime(time, this.player_.duration()));
  }

  /**
   * Get percentage of video played
   *
   * @return {Number} Percentage played
   */
  getPercent() {
    const percent = this.player_.currentTime() / this.player_.duration();
    return percent >= 1 ? 1 : percent;
  }

  /**
   * Round the seektime by the interval from the configuration
   *
   * @param {Number} x  - The x coordinate corresponding to the mouse position.
   * @return {Number}   Rounded seektime by the interval from the configuration (in seconds)
   */
  getRoundedSeekTime(x) {
    let seekTime = parseInt((this.horizontalSec * (1 - x)).toFixed(), 10);
    // Shift the seekTime (when user seeked on the vertical bar)
    seekTime += TimeShiftState.getShiftedTime(this.player_);
    seekTime = Math.ceil(seekTime / this.options_.horizontalInterval) * this.options_.horizontalInterval;

    return seekTime;
  }

  /**
   * Handle mouse move on seek bar
   */
  handleMouseMove(event) {
    if (!this.disabled) {
      const position = Dom.getPointerPosition(this.el_, event);
      if (position.x < 1) {
        const seekTime = this.getRoundedSeekTime(position.x);
        if (this.thumbnailsHolder) {
          this.thumbnailsHolder.updateImage(seekTime, position.x);
        }
      }
    }
  }

  /**
   * Handle mouse click on seek bar
   */
  handleClick(event) {
    if (!this.disabled) {
      const position = Dom.getPointerPosition(this.el_, event);
      if (position.x < 1) {
        const seekTime = this.getRoundedSeekTime(position.x);
        this.player_.timeshiftSeek(seekTime, true);
      }
    }
  }

  /**
   * Update seekbar's HTML/CSS based on new seekTime (X coordinate)
   */
  updateSeekbar({ seekTime }) {
    if (seekTime < this.horizontalSec) {
      const positionX = this.seekbarPositionFromSeektime(seekTime);
      this.children_[0].el_.style.width = `${(positionX * 100).toFixed(3)}%`;
    } else {
      this.children_[0].el_.style.width = '50%';
    }
  }

  seekbarPositionFromSeektime(seekTime) {
    // inverse function of getRoundedSeekTime(x)
    return 1 - (seekTime - TimeShiftState.getShiftedTime(this.player_)) / this.horizontalSec;
  }

  /* Handle mouse down on seek bar */
  handleMouseDown() {}

  /* Handle mouse up on seek bar */
  handleMouseUp() {}

  /* Handle on focus for slider */
  handleFocus() {}

  /* Handle on blur for slider */
  handleBlur() {}

  /* Move more quickly fast forward for keyboard-only users */
  stepForward() {}

  /* Move more quickly rewind for keyboard-only users */
  stepBack() {}
}

LiveSeekBar.prototype.options_ = {
  children: ['LiveHorizontalProgressBar'],
};

LiveSeekBar.prototype.playerEvent = 'timeupdate';

Component.registerComponent('LiveSeekBar', LiveSeekBar);
export default LiveSeekBar;
