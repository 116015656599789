import { videojs } from 'global/window';
import './quality-auto-button';
import './quality-auto-info-button';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream qualities buttons wrapper
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityAutoButtonWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.el_.className = 'vjs-quality-content vjs-quality-content-auto vjs-settings-content';
    this.addChild('QualityAutoButton', options);

    if (this.options.hasInfoOverlay) {
      this.addChild('QualityAutoInfoButton');
    }
  }
}

Component.registerComponent('QualityAutoButtonWrapper', QualityAutoButtonWrapper);
export default QualityAutoButtonWrapper;
