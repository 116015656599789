import { videojs } from 'global/window';
import log from '../../../log';
import availableEvents from '../../measuring/shared/available-events';
import { getPlayingAd } from '../shared/ad-state';
import { ImpressionTracker } from './impression-tracker';

const Plugin = videojs.getPlugin('plugin');

export class ViewableImpression extends Plugin {
  constructor(player, options) {
    super(player, options);
    log('[viewable-impression]: ViewableImpressionTracker plugin initialized');

    this.player = player;
    this.options = options;

    this.player.on(availableEvents.AD_LOADED, () => {
      const ad = getPlayingAd(this.player);
      const tracker = new ImpressionTracker(this.player, ad);
      tracker.init();
    });

    this.player.on('overlayready', ({ cue }) => {
      const ad = this.player.adstate.inventory.cascade[cue].overlay.ads[0];

      this.player.one('overlaystarted', () => {
        const tracker = new ImpressionTracker(this.player, ad);
        tracker.init();
      });
    });
  }
}
