import log from '../log';

const playlistPreprocessing = (playlistData, initData) => {
  if (
    (playlistData instanceof Array && !initData.playlist) ||
    (initData.playlist && initData.playlist.type !== 'hidden')
  ) {
    const { plugins } = initData || {};
    if (!plugins) return initData;

    if (plugins.markers) {
      initData.adMarkerPlaylist = initData.plugins.markers;

      // ----------------------------------------
      // no idea why this line was already there
      // it caused an issue where the ad markers were not displayed
      // on the progress bar of the first video in the playlist
      // if "playlist: { type: 'hidden' }," IS NOT defined in the config
      // more info: https://jira.zentity.com/browse/CRASUPP-708
      // commenting the line below fixed the problem
      // ----------------------------------------
      // delete initData.plugins.markers;
      log('[playlist-preprocessing] plugin markers updated');
    }

    playlistData.forEach((data) => {
      if (data?.playlist?.type !== 'hidden') {
        data.thumbnailsPlaylist = data.plugins.thumbnails;
        delete data.plugins.thumbnails;
      }

      log('[playlist-preprocessing] plugin thumbnails updated');
    });
  }

  return initData;
};

export default playlistPreprocessing;
