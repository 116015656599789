import { videojs } from 'global/window';

// Get a component to subclass
const LoadProgressBar = videojs.getComponent('LoadProgressBar');
const Component = videojs.getComponent('Component');

class PlaylistLoadProgressBar extends LoadProgressBar {
  constructor(player, options) {
    super(player, options);
    this.addClass('vjs-playlist-load-progress-bar');
  }

  getTimeTotalDurationBeforeThisSource() {
    const { playlistData } = this.player_.playlist;

    if (playlistData.actualIndex !== 0) {
      let iterator = 0;
      return playlistData.sources.reduce((previousDuration, source) => {
        if (iterator < playlistData.actualIndex) {
          iterator++;
          previousDuration += source.duration;
        }
        return previousDuration;
      }, 0);
    }

    return 0;
  }

  /**
   * Update progress bar
   *
   * @param {EventTarget~Event} [event]
   *        The `progress` event that caused this function to run.
   *
   * @listens Player#progress
   */
  update() {
    const buffered = this.player_.buffered();
    const duration = this.player_.playlist.playlistData.totalDuration;
    const bufferedEnd = this.player_.bufferedEnd() + this.getTimeTotalDurationBeforeThisSource();
    const children = this.partEls_;

    // get the percent width of a time compared to the total end
    const percentify = function (time, end) {
      // no NaN
      const percent = time / end || 0;

      return `${(percent >= 1 ? 1 : percent) * 100}%`;
    };

    // update the width of the progress bar
    this.el_.style.width = percentify(bufferedEnd, duration);
    children.length = buffered.length;
  }
}

Component.registerComponent('PlaylistLoadProgressBar', PlaylistLoadProgressBar);
export default PlaylistLoadProgressBar;
