import window, { videojs, google, document, navigator } from 'global/window';
import log from '../log';
import {
  AS_COMPANION_MIN_WIDTH,
  AS_OVERLAY_MIN_WIDTH,
  AS_SKIP_DELAY_MODE,
  AS_SKIP_DELAY_FALLBACK_TIME,
  AS_PREFERRED_FORMAT,
  AS_LINEAR_PREMIUM_MODE,
  AS_LINEAR_PREMIUM_TARGET,
  AS_LINEAR_PREMIUM_POSITION,
  AS_LINEAR_PRIOR_NOTIFY_TIME,
  AS_LINEAR_PRIOR_NOTIFY_MODE,
  AS_LINEAR_WATCH_TRESHOLD,
  PLAYER_AUTOPLAY,
} from '../constants';
import { extend } from '../utils/object-helpers';
import initializeIma from './adservice/ima';
import ImaMarkers from './adservice/shared/ima/ad-markers';
import AntiAdBlock from './adservice/shared/anti-adblock';
import adEvents from './measuring/shared/ad-events';
import DomOperations from './adservice/shared/dom-operations';
import { getAdBreakType } from './adservice/shared/ad-state';
import { CsaiEvents } from './adservice-csai/csai-events';
import availableEvents from './measuring/shared/available-events';

const Plugin = videojs.getPlugin('plugin');

/**
 * https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js
 */
export class AdService extends Plugin {
  constructor(player, options = {}) {
    super(player, options);
    const defaultSettings = {
      companion: {
        minWidth: AS_COMPANION_MIN_WIDTH,
      },
      overlay: {
        minWidth: AS_OVERLAY_MIN_WIDTH,
      },
      linear: {
        // defaultSkipDelay is not set by default
        skipDelay: {
          mode: AS_SKIP_DELAY_MODE,
          time: AS_SKIP_DELAY_FALLBACK_TIME,
        },
        preferredFormat: AS_PREFERRED_FORMAT,
        premium: {
          mode: AS_LINEAR_PREMIUM_MODE,
          target: AS_LINEAR_PREMIUM_TARGET,
          position: AS_LINEAR_PREMIUM_POSITION,
        },
        priorNotification: {
          time: AS_LINEAR_PRIOR_NOTIFY_TIME,
          mode: AS_LINEAR_PRIOR_NOTIFY_MODE,
        },
        watchTreshold: AS_LINEAR_WATCH_TRESHOLD,
      },
    };
    this.settings = extend(true, defaultSettings, options.settings);

    this.domOperations = new DomOperations(player, this.settings);
    this.adsManager = null;
    this.options = options;
    this.adContainerElement = null;

    this.startEvent = 'click';
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Android/i)
    ) {
      this.startEvent = 'touchend';
    }
    this.player.el().addEventListener(this.startEvent, this.initAdDisplayContainer);

    this.registerAdErrorCheck();

    if (this.options.csaiAdTagUrlBase || this.options.csaiPrerollAdTagUrl) {
      player.csai().initializeCsai(this.options.csaiJingle);

      player.one('ready', () => {
        this.initIma();

        if (player.options_.autoplayAllowed) {
          player.play();
          player.options_.autoplay = PLAYER_AUTOPLAY;
        }
      });

      this.player.on(CsaiEvents.CSAI_LIVE_AD_STARTED, ({ adLength }) => {
        const csaiDurationPlaceholder = '$$DURATION$$';

        const currentSource = this.player.currentSource();
        this.player.ima.changeAdTag(this.options.csaiAdTagUrlBase.replace(csaiDurationPlaceholder, adLength));
        this.player.ima.requestAds();
        this.player.on(availableEvents.ADS_BLOCK_END, () => {
          this.player.src(currentSource);
        });
      });
    } else {
      this.player.on('ready', () => {
        this.initIma();
      });
    }

    player.on('ott-ima-ready', () => {
      this.imaReady();
      this.player.removeClass('vjs-waiting-for-ima');
      log('[ima] init ima end - removed class 10% vjs-waiting-for-ima');
    });

    // Antiadblock init
    if (this.options.antiAd) {
      this.antiAdBlock = new AntiAdBlock(player, options.antiAd);
      // this.antiAdBlock.asyncCheck();
      this.registerCheckAdBlock();
    }

    if (this.settings.linear.premium) {
      this.createPremiumPromoLink();
    }

    log('[adservice-ima] initialized');
  }

  initIma() {
    initializeIma(this.player, this.options);
  }

  imaReady() {
    log('[adservice-ima] recieved ima ready event');
    this.registerImaEvents();

    this.adsManager = this.player.ima.getAdsManager();
    this.adContainerElement = this.player.ima.controller.getAdContainerDiv();

    this.registerAdMarkers();
    this.registerCompanionAds();
    this.registerPlayPauseCSSClassToggle();
    this.createBigPlayAdButton();
    this.registerOverlayContainerPausePlayer();
    this.registerAutoplayLive();
    this.disableFullscreenInIOS();
    this.postrollCheck();

    if (this.options.vpaidVolumeSync) {
      this.registerVpaidVolumeSync();
    }

    // play after IMA ready
    if (this.player.options_.autoplayAllowed) {
      this.player.play();
      this.player.options_.autoplay = PLAYER_AUTOPLAY;
    }

    if (this.settings.linear.priorNotification) {
      this.registerPriorNotification();
    }
  }

  /**
   * https://jira.etnetera.cz/browse/OTTS-1580
   * Moat Ads VPAID fired wrong volume change - not respect muted state on main player
   */
  registerVpaidVolumeSync() {
    log('[adservice-ima] VPAID volume sync enabled');
    this.player.on(adEvents.START, (e) => {
      if (e.isLinear && e.contentType === 'application/javascript') {
        const adVolume = this.adsManager.getVolume();
        const playerVolume = this.player.volume();

        if (adVolume !== 0 && this.player.muted()) {
          this.adsManager.setVolume(0);
          log.warn('[adservice-ima] make VPAID mute sync!');
        }

        if (!this.player.muted() && adVolume !== playerVolume) {
          this.adsManager.setVolume(playerVolume);
          log.warn('[adservice-ima] make VPAID volume level sync!');
        }
      }
    });
  }

  postrollCheck() {
    if (this.adsManager.getCuePoints().includes(-1)) {
      this.player.trigger(adEvents.HASPOSTROLL);
    }
  }

  disableFullscreenInIOS() {
    if (window.videojs.browser.IS_IOS) {
      // fullscreen workaround for iOS device, skip fullscreen
      this.player.on('adstart', () => {
        if (this.player.isFullscreen()) {
          this.player.exitFullscreen();
          log('[adservice-ima] iOS exit fullscreen mode');
        }
      });

      this.player.addClass('vjs-ios');
    }
  }

  /**
   * HOTFIX
   * play after preroll in live stream
   */
  registerAutoplayLive() {
    this.player.one(adEvents.CONTENTRESUMEREQUESTED, () => {
      if (this.player.duration() === Infinity && this.player.paused()) {
        log('[adservice-ima] live autoplay');
        this.player.play();
      }
    });
  }

  /**
   * Method initAdDisplayContainer
   * need for iOS autoplay
   * added user gesture to ad player
   */
  initAdDisplayContainer() {
    if (this.player.ima && this.player.ima.initializeAdDisplayContainer) {
      this.player.ima.initializeAdDisplayContainer();
      this.player.el().removeEventListener(this.startEvent, this.initAdDisplayContainer);
    }
  }

  /**
   * Method onAdEvent
   * modified data obtained from original ima events is sent via the method
   * basic information is available at the first level of the object
   * full list of events ad-events.js
   * @param {object} e // raw IMA event
   * triggering event including
    {
     isLinear,
        return boolean
        https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.Ad#isLinear
      breakType,
        return string (preroll, midroll, postroll) or null when no linear ads
      contentType,
        return content mime type
        https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.Ad#getContentType
      ad,
        return ad data
        https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.AdEvent#getAd
      adData,
        return ad data with extra data
        https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.AdEvent#getAdData
     }
   */
  onAdEvent(e) {
    const ad = e.getAd();
    const adData = e.getAdData();

    let contentType = null;
    if (e.getAd() && e.getAd().getContentType) {
      contentType = e.getAd().getContentType();
    }

    let isLinear = null;
    if (e.getAd() && e.getAd().isLinear) {
      isLinear = e.getAd().isLinear();
    }

    let breakType = null;
    if (isLinear && ad) {
      breakType = getAdBreakType(ad);
    }

    const adEventObj = {
      isLinear,
      breakType,
      contentType,
      ad,
      adData,
    };

    log(`[adservice-ima] ima fired event: ${e.type}`, adEventObj);

    this.player.trigger({
      type: `ott-ad-${e.type}`,
      ...adEventObj,
    });
  }

  registerCheckAdBlock() {
    this.player.on('ready', () => {
      if (typeof this.antiAdBlock !== 'undefined') {
        this.antiAdBlock.check(['html'], {
          http: {
            baitMode: 'import',
            baitUrl: this.antiAdBlock.customBaitUrl,
          },
        });
        log('[adservice] call antiAdBlock check function');
      }
    });
  }

  /**
   * Method registerAdErrorCheck
   * Ads playback errors occur through the adslog event.
   * If this event contains an error message, it is sent as an error event.
   */
  registerAdErrorCheck() {
    this.player.on('adslog', (e) => {
      if (e.data.AdError) {
        const ad = e.data.AdEvent.getAd();
        const errorEvent = e.data.AdEvent.getAdData();
        const error = errorEvent.adError;
        log.error(
          `[adservice-ima] aderror code:${error.getErrorCode()}, message: ${error.getMessage()}`,
          e.data.AdError,
          ad,
        );
        this.player.trigger({
          type: adEvents.ERROR,
          ad,
          error,
          message: `Error code: ${error.getErrorCode()} - ${error.getMessage()}`,
        });
        new ImaMarkers(this.player, this.adsManager.getCuePoints()).reset();
      }
    });
  }

  registerAdMarkers() {
    this.player.on(adEvents.CONTENTRESUMEREQUESTED, () => {
      new ImaMarkers(this.player, this.adsManager.getCuePoints()).reset();
    });

    this.player.on(adEvents.START, (e) => {
      if (e.isLinear === false) {
        new ImaMarkers(this.player, this.adsManager.getCuePoints()).reset();
      }
    });
  }

  // https://github.com/googleads/videojs-ima/issues/850
  registerOverlayContainerPausePlayer() {
    this.player.on(adEvents.START, (e) => {
      if (e.isLinear === false) {
        const nonLinearAdContainer = document.querySelector('.bumpable-ima-ad-container');
        if (nonLinearAdContainer) {
          log('[adservice-ima] register pause when user click on overlay container');
          nonLinearAdContainer.onclick = () => {
            if (!this.player.paused()) {
              this.player.pause();
            }
          };
        }
      }
    });
  }

  /**
   * Method registerCompanionAds
   * when the advertisement starts, the method invokes the advertising provider and tries to get companion ads for slots that come from the init object.
   * https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.CompanionAdSelectionSettings
   * adservice.companionSlots
   * {
   *  querySelector: "#position" // id or class identificator in HTML DOM
   *  width: 100 // in pixel
   *  height: 100 // in pixel
   * }
   */
  registerCompanionAds() {
    const selectionCriteria = new google.ima.CompanionAdSelectionSettings();
    selectionCriteria.resourceType = google.ima.CompanionAdSelectionSettings.ResourceType.STATIC;
    selectionCriteria.creativeType = google.ima.CompanionAdSelectionSettings.CreativeType.IMAGE;
    selectionCriteria.sizeCriteria = google.ima.CompanionAdSelectionSettings.SizeCriteria.SELECT_EXACT_MATCH;

    if (this.options.companionSlots && this.options.companionSlots.length > 0) {
      this.player.on(adEvents.START, (e) => {
        this.options.companionSlots.forEach((companion) => {
          const companionAds = e.ad.getCompanionAds(companion.width, companion.height, selectionCriteria);
          const companionAd = companionAds[0];

          if (companionAd) {
            const content = companionAd.getContent();
            const slots = document.querySelectorAll(companion.querySelector);
            slots.forEach((slotElement) => {
              slotElement.innerHTML = content;
              log(`[adservice-ima] companion content placed to ${companion.querySelector}`);
            });
          }
        });
      });
    } else {
      log('[adservice-ima] no companion slots defined');
    }
  }

  createBigPlayAdButton() {
    const button = this.domOperations.createAdBigPlayButton();
    button.onclick = () => {
      this.player.ima.resumeAd();
    };
    this.adContainerElement.appendChild(button);
  }

  /**
   * Method registerPlayPauseCSSClassToggle
   * enriches the element with status css classes when playing linear advertising
   * ima-ad-paused, ima-ad-playing
   */
  registerPlayPauseCSSClassToggle() {
    this.player.on(adEvents.COMPLETE, () => {
      this.adContainerElement.classList.remove('ima-ad-paused');
      this.adContainerElement.classList.remove('ima-ad-playing');
    });

    this.player.on([adEvents.START], () => {
      this.adContainerElement.classList.add('ima-ad-playing');
    });

    this.player.on([adEvents.PAUSE], () => {
      this.adContainerElement.classList.add('ima-ad-paused');
      this.adContainerElement.classList.remove('ima-ad-playing');
    });

    this.player.on([adEvents.RESUME], () => {
      this.adContainerElement.classList.remove('ima-ad-paused');
      this.adContainerElement.classList.add('ima-ad-playing');
    });
  }

  createPremiumPromoLink() {
    this.player.one('adstart', () => {
      const premiumClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.player.ima.pauseAd();
        this.player.trigger('promolinkclick');

        // Open video click through link
        if (this.settings.linear.premium.url) {
          window.open(this.settings.linear.premium.url);
        }
      };

      this.domOperations.createPremiumPromoUI(premiumClick);
    });
  }

  /**
   * Method registerPriorNotification
   * uses preload of ads using IMA enablePreloading, when ad servers are called 4 seconds before playing a linear block
   */
  registerPriorNotification() {
    this.player.on(adEvents.LOADED, (e) => {
      if (e.isLinear && e.breakType === 'midroll') {
        const adCount = e.ad.getAdPodInfo().getTotalAds();
        const time = e.ad.getAdPodInfo().getTimeOffset();
        const notifyUpcomingAd = () => {
          const currentTime = this.player.currentTime();
          if (currentTime >= time) {
            this.player.off('timeupdate', notifyUpcomingAd);
            this.domOperations.removeAdMessageWrapperHTML();
          } else {
            this.domOperations.updateUpcomingAdMessage(adCount, time - Math.floor(currentTime));
          }
        };

        const timeToShow = time - Math.floor(this.player.currentTime());

        if (timeToShow > 0) {
          this.domOperations.createUpcomingAdHTML(adCount, timeToShow);
        }

        this.player.on('timeupdate', notifyUpcomingAd);
        log('[adservice-ima] notify before ads');
      }
    });
  }

  registerImaEvents() {
    const eventsList = window.google.ima.AdEvent.Type;
    let registeredEvents = '';
    Object.keys(eventsList).forEach((name) => {
      registeredEvents += `${eventsList[name]},`;
      this.player.ima.addEventListener(eventsList[name], (e) => {
        this.onAdEvent(e);
      });
    });
    log('[adservice-ima] ima events registered', registeredEvents);
  }

  dispose() {
    super.dispose();
    log('[adservice-ima] plugin is being disposed');
  }
}

/* IMA EVENTS
[adservice-ima] register event contentResumeRequested
[adservice-ima] register event contentPauseRequested
[adservice-ima] register event click
[adservice-ima] register event durationChange
[adservice-ima] register event expandedChanged
[adservice-ima] register event start
[adservice-ima] register event impression
[adservice-ima] register event pause
[adservice-ima] register event resume
[adservice-ima] register event adProgress
[adservice-ima] register event adBuffering
[adservice-ima] register event firstQuartile
[adservice-ima] register event midpoint
[adservice-ima] register event thirdQuartile
[adservice-ima] register event complete
[adservice-ima] register event userClose
[adservice-ima] register event linearChanged
[adservice-ima] register event loaded
[adservice-ima] register event adCanPlay
[adservice-ima] register event adMetadata
[adservice-ima] register event adBreakReady
[adservice-ima] register event interaction
[adservice-ima] register event allAdsCompleted
[adservice-ima] register event skip
[adservice-ima] register event skippableStateChanged
[adservice-ima] register event log
[adservice-ima] register event viewable_impression
[adservice-ima] register event volumeChange
[adservice-ima] register event mute
*/
