import { videojs } from 'global/window';
import hideOpenMenus from '../../utils/hide-menus';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

class SeekHintsButton extends Button {
  constructor(player, options) {
    super(player, options);
    const hints = this.player_.options_.plugins.seekHints;
    if (hints.length <= 1) {
      this.hide();
    }
    this.controlTextEl_.innerHTML = this.player_.localize('Seek hints');
  }

  buildCSSClass() {
    return `vjs-hints-button ${super.buildCSSClass()}`;
  }

  handleClick() {
    const menu = this.player_.getChild('SeekHintsMenu');
    if (typeof menu !== 'undefined') {
      if (menu.hasClass('vjs-hidden')) {
        hideOpenMenus(this.player_);
        menu.removeClass('vjs-hidden');
        this.player_.addClass('vjs-popup-displayed');
      } else {
        menu.addClass('vjs-hidden');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }
}
// Register the new component
Component.registerComponent('SeekHintsButton', SeekHintsButton);
export default SeekHintsButton;
