import window from 'global/window';

import log from '../../log';
import { isVPAID } from './vpaid/utils';
import { getPlayingAd } from './shared/ad-state';
import DomOperations from './shared/dom-operations';

import DOMController from './adsPlayer/DOMController';
import propTest from '../../property-tester';

const CHECK_AD_CONDITION_TIMEOUT = 5000;
const HIDE_AD_PLAYER_TIMEOUT = 5000;
const VISIBLE_ADS_PLAYER_CSS_CLASS = 'x-vjs-vpaid-player-visible';
const PLAYING_ADS_PLAYER_CSS_CLASS = 'x-vjs-vpaid-playing';
const PAUSE_ADS_PLAYER_CSS_CLASS = 'x-vjs-vpaid-paused';

export default class adsPlayer {
  constructor(player, settings) {
    this.player = player;
    this.settings = settings;
    this.adsPlayerVisible = false;
    this.contentDuration = 0;

    // need for VPAID skip button
    this.domOperations = new DomOperations(player, settings);

    this.mainPlayerVideoEl = null;
    this.adsPlayerContainerEl = null;
    this.adsPlayerVideoEl = null;

    // props for check ad condition
    this.adConditionData = this.createClearAdConditionData();
    this.adConditionCheckTimeout = null;
    this.showSkipButtonTimeout = null;

    this.hideAdPlayerTimeout = null;

    this.adPause = this.adPauseChangeCssClass.bind(this);
    this.adPlay = this.adPlayChangeCssClass.bind(this);

    this.domController = null;
    this.eventsController = null;

    this.getVideoElement = this.getVideoElement.bind(this);
    this.playPromiseSuccess = this.playPromiseSuccess.bind(this);
    this.playPromiseError = this.playPromiseError.bind(this);
    this.playPromiseErrorAutoplayAny = this.playPromiseErrorAutoplayAny.bind(this);
    this.setContentDurationProperty = this.setContentDurationProperty.bind(this);

    this.player.on('ready', () => {
      this.logEventMainPlayer('ready');
      this.init();
    });

    window.adsPlayer = this;
  }

  getVideoElement() {
    return this.adsPlayerVideoEl;
  }

  initDOMElements() {
    this.domController = new DOMController(this.player);
    this.domController.appendElementsIntoMainPlayer();
    const domElements = this.domController.getElements();
    Object.keys(domElements).forEach((elementKey) => {
      this[elementKey] = domElements[elementKey];
    });
  }

  /**
   * This should be called, when videojs player is ready (in 'ready' event).
   */
  init() {
    this.initDOMElements();
    this.registerMainPlayerEvents();
    this.registerAdsPlayerEvents();
    this.overrideTimeMethods();
    this.syncVolumeFromMainToAdsPlayer();
    this.checkAdsPlayerSize();
    this.playlistTimeDisplay = this.player.controlBar.getChild('PlaylistTimeDisplay');
    log(`[adsPlayer] instance initialized on videoelement id: ${this.adsPlayerVideoEl.id}`);
  }

  overrideTimeMethods() {
    const originalCurrentTimeOfActiveMedia = this.player.currentTimeOfActiveMedia;
    const originalDurationOfActiveMedia = this.player.durationOfActiveMedia;
    const originalIsInPausedState = this.player.isInPausedState;

    this.player.currentTimeOfActiveMedia = (time) => {
      if (this.adsPlayerVisible) {
        if (typeof time === 'number') {
          this.adsPlayerVideoEl.currentTime = time;
        } else {
          return this.adsPlayerVideoEl.currentTime;
        }
      } else {
        return originalCurrentTimeOfActiveMedia();
      }
      return originalCurrentTimeOfActiveMedia();
    };

    this.player.durationOfActiveMedia = () => {
      if (this.adsPlayerVisible) {
        return this.adsPlayerVideoEl.duration || 0;
      }
      return originalDurationOfActiveMedia();
    };

    this.player.isInPausedState = () => {
      if (this.adsPlayerVisible) {
        return this.adsPlayerVideoEl.paused;
      }
      return originalIsInPausedState();
    };
  }

  setContentDurationProperty() {
    // FIXME: check value from player init data duration property, sometime save wrong value from befor ads duration
    if (!this.player.ads.inAdBreak()) {
      this.logEventMainPlayer('loadedmetadata');
      this.contentDuration = this.player.duration();
      log(`[adsPlayer] save main content duration ${this.contentDuration}`);
      this.player.off('loadedmetadata', this.setContentDurationProperty);
    }
  }

  logEventMainPlayer(event) {
    log(`[adsPlayer] [event MAIN player] ${event}`);
  }

  logEventAdsPlayer(event) {
    log(`[adsPlayer] [event ADS player] ${event}`);
  }

  pauseAdsPlayer() {
    if (!this.adsPlayerVideoEl.paused) {
      this.adsPlayerVideoEl.pause();
      log('[adsPlayer] ads video element paused');
    } else {
      log('[adsPlayer] the attempt to pause the player could not be executed. Player was already in pause state');
    }
  }

  setMainPlayerDuration() {
    log(`[adsPlayer] contentDuration:${this.contentDuration}, playerDuration:${this.player.duration()}`);
    if (this.contentDuration && Math.floor(this.player.duration()) !== this.contentDuration) {
      log(`[adsPlayer] reset duration for MAIN player from ${this.player.duration()} to ${this.contentDuration}`);
      this.player.duration(this.contentDuration);
    }
  }

  isAdsPlayerVisible() {
    switch (true) {
      case !this.adsPlayerVisible && this.player.hasClass(VISIBLE_ADS_PLAYER_CSS_CLASS) === false:
        return false;
      case this.adsPlayerVisible && this.player.hasClass(VISIBLE_ADS_PLAYER_CSS_CLASS) === true:
        return true;
      case this.adsPlayerVisible && this.player.hasClass(VISIBLE_ADS_PLAYER_CSS_CLASS) === false:
        // FIXME log.error for production
        // TODO fire event for state sync
        log('[adsPlayer] [3] player visible state not synchronized!');
        return false;
      case !this.adsPlayerVisible && this.player.hasClass(VISIBLE_ADS_PLAYER_CSS_CLASS) === true:
        // FIXME log.error for production
        // TODO fire event for state sync
        log('[adsPlayer] [4] player visible state not synchronized!');
        return true;
      default:
        break;
    }
  }

  clearSkipButtonTimeout() {
    clearTimeout(this.showSkipButtonTimeout);
    log('[adsPlayer] clear showSkipButtonTimeout');
  }

  tryCreateSkipButton(creative) {
    const { defaultSkipDelay } = this.settings.linear.vpaid;
    if (!creative.skipDelay && defaultSkipDelay) {
      creative.skipDelay = defaultSkipDelay;
      this.showSkipButtonTimeout = this.player.setTimeout(() => {
        this.domOperations.showVPAIDSkipButton(() => {
          this.sendEventToMainPlayer('adsplayer-button-skip');
        });
      }, parseInt(creative.skipDelay * 1000));
      log(`[adsPlayer] defaultSkipDelay show skip button -> show time: ${parseInt(creative.skipDelay * 1000)}ms`);
    }
  }

  sendEventToMainPlayer(event) {
    log('[adsPlayer] sendEventToMainPlayer', event);
    this.player.trigger(event);
  }

  setAdConditionData() {
    this.adConditionData = {
      duration: this.adsPlayerVideoEl.duration,
      src: this.adsPlayerVideoEl.src,
      ticks: 0,
    };
  }

  createConditionCheckTimeout() {
    this.adConditionCheckTimeout = this.player.setTimeout(this.checkAdCondition.bind(this), CHECK_AD_CONDITION_TIMEOUT);
  }

  /**
   * Method connect general player events with ads player
   * Need for using general player menu controls for ads player control (volume, fullscreen etc.)
   */
  registerMainPlayerEvents() {
    this.player.on('loadedmetadata', this.setContentDurationProperty);

    this.player.on('adend', () => {
      this.logEventMainPlayer('adend');
      this.pauseAdsPlayer();
      this.clearSkipButtonTimeout();

      // !Invasive method! workaround for not update content player duration when last ad is vpaid
      this.setMainPlayerDuration();

      // !Noninvasive method!
      this.playlistTimeDisplay = this.player.controlBar.getChild('PlaylistTimeDisplay');
      if (this.playlistTimeDisplay) {
        this.updatePlaylistDurationDisplay(this.player.playlist.playlistData.totalDuration);
      } else {
        this.updateDurationDisplay(this.contentDuration);
      }

      if (this.isAdsPlayerVisible()) {
        this.hideAdsPlayer(true);
      }
    });

    this.player.on('adpauseclicked', () => {
      this.logEventMainPlayer('adpauseclicked');
      this.pauseAdsPlayer();
    });

    this.player.on('adplay', () => {
      this.logEventMainPlayer('adplay');

      if (this.isAdsPlayerVisible()) {
        const ad = getPlayingAd(this.player);
        const creative = ad.creatives.find((creative) => creative.type === 'linear');

        if (!isVPAID(creative)) {
          // last ad was VPAID and now there is normal ad, hide ads player immediately
          this.hideAdsPlayer(true);
          return;
        }

        this.tryCreateSkipButton(creative);
      }
    });

    // When user click on skip AD button
    this.player.on('ott-vpaid-skip-button', () => {
      this.logEventMainPlayer('ott-vpaid-skip-button');
      this.pauseAdsPlayer();
      this.sendEventToMainPlayer({
        type: 'adended',
        skipped: true,
      });
    });

    // When VPAID ADUnit call method onStopped()
    this.player.on('ott-vpaid-ended', () => {
      this.logEventMainPlayer('ott-vpaid-ended');
      this.sendEventToMainPlayer('adended');
    });

    this.player.on('ott-mea-volume-changed', () => {
      this.logEventMainPlayer('ott-mea-volume-changed');
      this.syncVolumeFromMainToAdsPlayer();
    });

    // clear timeaout before reset player
    this.player.on('ott-before-reset', () => {
      this.logEventMainPlayer('ott-before-reset');
      this.disposeAdsPlayer();
    });

    // When user click on expand screen button, check ads player size
    this.player.on(['ott-player-expand-on', 'ott-player-expand-off'], this.checkAdsPlayerSize);
  }

  /**
   * Method register listeners on AD video element
   */
  registerAdsPlayerEvents() {
    this.adsPlayerVideoEl.addEventListener('play', () => {
      this.logEventAdsPlayer('play');
      this.showAdsPlayer();
      this.adPlayChangeCssClass();
      this.sendEventToMainPlayer('play');
    });

    this.adsPlayerVideoEl.addEventListener('playing', () => {
      if (this.player.hasClass('vjs-ended')) {
        this.player.removeClass('vjs-ended');
      }
      this.logEventAdsPlayer('playing');
      this.sendEventToMainPlayer('playing');
    });

    this.adsPlayerVideoEl.addEventListener('timeupdate', () => {
      this.sendEventToMainPlayer({
        type: 'adtimeupdate',
        currentTime: this.adsPlayerVideoEl.currentTime,
      });
    });

    this.adsPlayerVideoEl.addEventListener('ended', () => {
      this.player.addClass('vjs-ended');
      this.logEventAdsPlayer('ended');
      this.hideAdsPlayer();
      this.restoreConditionCheck();
      // JPk: not sure about this.player.trigger('ended')
      // this.player.trigger('adended');
    });

    this.adsPlayerVideoEl.addEventListener('error', (event) => {
      this.logEventAdsPlayer('error');
      log.error(`[adsPlayer] ERROR code:${event.target.error.code} ${event.target.error.message}`);
      this.hideAdsPlayer(true);
      // JPk: not sure about this.player.trigger('error')
      // this.player.trigger('aderror');

      // when vpaid ad corupt - call error
      if (this.player.paused()) {
        this.sendEventToMainPlayer('aderror');
        log.error('[adsPlayer] ERROR event on ad-player triggered aderror');
      }
    });

    this.adsPlayerVideoEl.addEventListener('loadedmetadata', () => {
      this.logEventAdsPlayer('loadedmetadata');
      this.setAdConditionData();
      this.registerConditionCheck();

      this.playlistTimeDisplay = this.player.controlBar.getChild('PlaylistTimeDisplay');
      if (this.playlistTimeDisplay) {
        this.updatePlaylistDurationDisplay(this.adsPlayerVideoEl.duration);
      } else {
        this.updateDurationDisplay(this.adsPlayerVideoEl.duration);
      }

      this.sendEventToMainPlayer('loadedmetadata');
    });

    this.adsPlayerVideoEl.addEventListener('loadstart', () => {
      this.logEventAdsPlayer('loadstart');
      this.restoreConditionCheck();
    });

    this.adsPlayerVideoEl.addEventListener('pause', (evt) => {
      this.logEventAdsPlayer('pause');
      this.adPauseChangeCssClass(evt);

      if (this.adsPlayerVideoEl.currentTime !== this.adsPlayerVideoEl.duration) {
        // There has to be adpause instead of pause, because pause won't be triggered, when main player is paused
        this.sendEventToMainPlayer('adpause');
      }
    });

    this.adsPlayerVideoEl.addEventListener('timeupdate', (evt) => {
      this.adConditionData.ticks += 1;

      this.playlistTimeDisplay = this.player.controlBar.getChild('PlaylistTimeDisplay');
      if (this.playlistTimeDisplay) {
        this.updatePlaylistCurrentTimeDisplay(this.adsPlayerVideoEl.currentTime);
      } else {
        // FIXME: when video element not have attributes controls fired error - controls=false
        this.updateCurrentTimeDisplay(this.adsPlayerVideoEl.currentTime);
      }
    });

    window.addEventListener(['resize', 'playerresize'], this.checkAdsPlayerSize.bind(this));
    this.player.el_.addEventListener(['resize', 'playerresize'], this.checkAdsPlayerSize.bind(this));

    const bigButton = this.player.$('.vjs-big-play-button');
    if (bigButton) {
      bigButton.addEventListener('click', this.bigPlayButtonClick.bind(this));
      bigButton.addEventListener('touchend', this.bigPlayButtonClick.bind(this));
    }
  }

  bigPlayButtonClick() {
    if (this.adsPlayerVisible) {
      log('[adsPlayer] bigPlayButton click or touchend event');
      this.player.trigger('vpaid-fake-play');
      this.adsPlayerVideoEl.play(); // workaround for iOS user gesture
    }
  }

  playPromiseSuccess() {
    log(`[adsPlayer] play promise success - register condition check timeout ${CHECK_AD_CONDITION_TIMEOUT}ms`);
    this.createConditionCheckTimeout();
  }

  playPromiseError(error) {
    log(`[adsPlayer] check play promise error: ${error}`);
    this.pauseAdsPlayer();

    if (propTest(() => this.player.autoplay()) === 'any') {
      this.tryAutoplayAny();
    } else {
      this.showAdsPlayer();
    }
  }

  playPromiseErrorAutoplayAny() {
    log(`[adsPlayer] [autoplay any] check play promise error: ${error}`);
    this.pauseAdsPlayer();

    if (this.settings.linear.adsPlayer && this.settings.linear.adsPlayer.skipPlayPromise) {
      this.reportError(901);
      log.error('[adsPlayer] skip after play promise error');
      this.sendEventToMainPlayer('ott-vpaid-player-error');
    } else {
      this.showAdsPlayer();
    }
  }

  tryAutoplayAny() {
    log('[adsPlayer] try autoplay any');
    this.setVolumeForAutoplay();
    const playPromise = this.adsPlayerVideoEl.play();

    if (playPromise && playPromise.then) {
      playPromise.then(this.playPromiseSuccess).catch(this.playPromiseErrorAutoplayAny);
    } else {
      // IE11 won't return a promise (TODO check somehow that play was successful)
      log('[adsPlayer] IE11 playPromise && playPromise.then not found! call play promise error');
      this.playPromiseErrorAutoplayAny();
    }
  }

  /**
   * Method register timeout for play and ad condition check
   */
  registerConditionCheck() {
    const playPromise = this.adsPlayerVideoEl.play();

    if (playPromise && playPromise.then) {
      playPromise.then(this.playPromiseSuccess).catch(this.playPromiseError);
    } else {
      // IE11 won't return a promise (TODO check somehow that play was successful)
      log('[adsPlayer] IE11 playPromise && playPromise.then not found! call play promise success');
      this.playPromiseSuccess();
    }
  }

  /**
   * Method transfer volume from general player to ads player.
   * Need for change with videojs controls panel
   */
  syncVolumeFromMainToAdsPlayer() {
    log(`[adsPlayer] sync volume: ${this.player.volume()} muted: ${this.player.muted()}`);
    this.adsPlayerVideoEl.volume = this.player.volume();
    this.adsPlayerVideoEl.muted = this.player.muted();
  }

  setVolumeForAutoplay() {
    log('[adsPlayer] set muted(true) for autoplay');
    this.player.muted(true);
    this.syncVolumeFromMainToAdsPlayer();
  }

  /**
   * Method check width and height on general player and when is difference, change ads player size
   * TODO: check zero value
   */
  checkAdsPlayerSize() {
    log(
      `[adsPlayer] check ads player size. Ads video element ${this.adsPlayerVideoEl.width}x${this.adsPlayerVideoEl.height}px | Video player element ${this.player.el_.offsetWidth}x${this.player.el_.offsetHeight}px`,
    );
    if (this.adsPlayerVideoEl.width && this.adsPlayerVideoEl.width !== this.player.el_.offsetWidth) {
      log(
        `[adsPlayer] method checkAdsPlayerSize() -> change ad-player width ${this.adsPlayerVideoEl.width}px => ${this.player.el_.offsetWidth}px`,
      );
      this.adsPlayerVideoEl.width = this.player.el_.offsetWidth;
    }
    if (this.adsPlayerVideoEl.height && this.adsPlayerVideoEl.height !== this.player.el_.offsetHeight) {
      log(
        `[adsPlayer] method checkAdsPlayerSize() -> change ad-player height ${this.adsPlayerVideoEl.height}px => ${this.player.el_.offsetHeight}px`,
      );
      this.adsPlayerVideoEl.height = this.player.el_.offsetHeight;
    }
  }

  /**
   * Method examine ad play process
   * Check AD duration
   * Check AD current play time
   * Check AD tick from timeupdate events
   * When check invalid fire event 'ott-vpaid-player-error' to vpaid ADUnit
   * TODO: method restoreCheckAdCondition : create check timeout specific for every one ads (because when skip ad before check, next ad check early)
   */
  checkAdCondition() {
    // individual points for condition control, in future possibility of expansion
    const conditionPoints = {
      duration: this.adConditionData.duration > 0 ? 1 : 0,
      ticks: this.adConditionData.ticks > 2 ? 1 : 0,
      src: this.adConditionData.src !== null ? 1 : 0,
    };

    // calculate summary for condition
    const conditionSummary = Object.keys(conditionPoints).reduce(
      (summary, prop) => (summary += conditionPoints[prop]),
      0,
    );

    if (conditionSummary >= 2) {
      log('[adsPlayer] check ad condition SUCCESS');
    } else {
      log.error(
        `[adsPlayer] check condition FAILED call skip AD event. Props duration: ${this.adConditionData.duration}, ticked: ${this.adConditionData.ticks}, src: ${this.adConditionData.src}`,
      );
      this.hideAdsPlayer(true);
      this.restoreConditionCheck();
      this.reportError(405); // code 405 "Problem displaying MediaFile."
      this.sendEventToMainPlayer('ott-vpaid-player-error');
    }

    if (!this.adsPlayerVisible) {
      this.pauseAdsPlayer();
      this.updateDurationDisplay(this.contentDuration);
      log('[adsPlayer] adsPlayer not visible, pause player and restore content duration');
    }
  }

  /**
   * Method restore timeout for check ad condition
   */
  restoreConditionCheck() {
    this.adConditionData = this.createClearAdConditionData();
    if (this.adConditionCheckTimeout) {
      clearTimeout(this.adConditionCheckTimeout);
      this.adConditionCheckTimeout = null;
    }
  }

  /**
   * Reset AD Data object after end or error
   */
  createClearAdConditionData() {
    return {
      duration: 0,
      ticks: 0,
      src: null,
    };
  }

  disposeAdsPlayer() {
    this.restoreConditionCheck();
    this.player.off(['ott-player-expand-on', 'ott-player-expand-off'], this.checkAdsPlayerSize);
    window.removeEventListener('resize', this.checkAdsPlayerSize);
    window.removeEventListener('playerresize', this.checkAdsPlayerSize);
  }

  /*  UI METHODS */

  /**
   * Method show vpaid ads player.
   */
  showAdsPlayer() {
    clearTimeout(this.hideAdPlayerTimeout);
    if (!this.adsPlayerVisible && this.player.ads.inAdBreak()) {
      this.adsPlayerVisible = true;
      this.player.addClass(VISIBLE_ADS_PLAYER_CSS_CLASS);
      // hide general video element
      this.mainPlayerVideoEl.style.display = 'none';
      log('[adsPlayer] show');
    }
  }

  /**
   * Method hide vpaid ads player.
   * Timeout fixed flashing general video element when next vpaid loading data
   * @param {Bool} forceHide - if true, hide player without timeout
   */
  hideAdsPlayer(forceHide = false) {
    log(`[adsPlayer] ${forceHide ? 'force ' : ''}hide`);

    const hide = () => {
      this.pauseAdsPlayer();
      this.adsPlayerVisible = false;
      // show general video element
      this.mainPlayerVideoEl.style.display = 'block';
      // remove special classes
      this.player.removeClass(VISIBLE_ADS_PLAYER_CSS_CLASS);
      this.player.removeClass(PAUSE_ADS_PLAYER_CSS_CLASS);
      this.player.removeClass(PLAYING_ADS_PLAYER_CSS_CLASS);
    };

    if (!forceHide) {
      this.hideAdPlayerTimeout = setTimeout(hide, HIDE_AD_PLAYER_TIMEOUT);
    } else {
      hide();
    }
  }

  /**
   * Report custom error to VAST tracker
   * @param {Number} errorCode
   */
  reportError(errorCode) {
    log(`[adsPlayer] send error code ${errorCode} to vastTracker`);
    if (this.player.vastTracker) {
      this.player.vastTracker.errorWithCode(errorCode);
    }
  }

  adPauseChangeCssClass() {
    // these x-vjs-vpaid-* classes should be temporary hack, optimally vjs-playing and vjs-paused
    // should be propagated to player to update UI
    this.player.removeClass(PLAYING_ADS_PLAYER_CSS_CLASS);
    this.player.addClass(PAUSE_ADS_PLAYER_CSS_CLASS);
  }

  adPlayChangeCssClass() {
    this.player.removeClass(PAUSE_ADS_PLAYER_CSS_CLASS);
    this.player.addClass(PLAYING_ADS_PLAYER_CSS_CLASS);
  }

  updateDurationDisplay(time) {
    this.player.controlBar.getChild('DurationDisplay').updateTextNode_(time);
  }

  updateCurrentTimeDisplay(time) {
    this.player.controlBar.getChild('CurrentTimeDisplay').updateTextNode_(time);
  }

  updatePlaylistDurationDisplay(time) {
    this.playlistTimeDisplay.playlistDurationDisplay.updateTextNode_(time);
  }

  updatePlaylistCurrentTimeDisplay(time) {
    this.playlistTimeDisplay.playlistCurrentTimeDisplay.updateTextNode_(time);
  }

  /**
   * Manually updates content of DurationDisplay to duration of active (played right now) media
   */
  updateDurationOfAd() {
    this.player.controlBar.getChild('DurationDisplay').updateTextNode_(this.player.durationOfActiveMedia());
  }
}
