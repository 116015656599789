import { window } from 'global/window';

/**
 * Google Tag Manager measuring service. The basic plugin measures only basic events such as play, pause, and end for video content.
 * If you need more complex measurements, use the complex plugin.
 *
 * @param {Object} player           - The player instance
 */
class GoogleTagManager {
  constructor(player) {
    this.gtmObjName = 'dataLayer';
    // Preserve arguments
    this.player = player;
    // Sending events is enabled when watching is set to true
    this.watching = false;
    // Register global Google Tag Manager array if not exist
    window[this.gtmObjName] = window[this.gtmObjName] || [];
  }

  /**
   * Enable watching
   */
  startWatching() {
    this.watching = true;
  }

  push(data) {
    if (this.watching) {
      window[this.gtmObjName].push(data);
    }
  }
}

export default GoogleTagManager;
