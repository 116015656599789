import { videojs } from 'global/window';
import SubtitlesMenuItem from './subtitles-menu-item';
import OffSubtitlesMenuItem from './off-subtitles-menu-item';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Subtitles buttons wrapper.
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SubtitlesButtonsWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent();
    this.el_.className = 'vjs-subtitles-content vjs-settings-content';
  }

  /**
   * Iterate through text tracks and register TextTrackMenuItem for each of them
   */
  createContent() {
    // OFF button
    this.addChild(
      new OffSubtitlesMenuItem(this.player_, {
        kind: 'captions',
        label: this.player_.localize('Subtitles off'),
      }),
    );

    // const tracks = (videojs.browser.IS_SAFARI || videojs.browser.IS_IOS)
    const tracks = videojs.browser.IS_IOS
      ? Object.values(this.player_.el().children[0].textTracks)
      : this.player_.remoteTextTracks().tracks_;

    // try to find language of default subtitles to check turn on default subtitles later on
    const defaultTrack = this.player_.options().plugins.settings.subtitles.find((subtitles) => subtitles.default);
    const defaultLanguage = defaultTrack && defaultTrack.srclang;

    tracks.forEach((track) => {
      // note: persisted subtitles are converted to default subtitles (ugh)
      if (defaultLanguage === track.language) {
        // preferred language found
        // Set showing to true
        track.mode = 'showing';
        // Send event for subtitle <enabled></enabled>
        this.player_.trigger({
          type: 'ott-subtitles-enabled',
          track,
        });
      } else {
        track.mode = 'hidden';
      }

      if (track.kind !== 'metadata' && track.language) {
        this.addChild(
          new SubtitlesMenuItem(this.player_, {
            // MenuItem is selectable
            selectable: true,
            track,
          }),
        );
      }
    });
  }
}

Component.registerComponent('SubtitlesButtonsWrapper', SubtitlesButtonsWrapper);
export default SubtitlesButtonsWrapper;
