import cloneDeep from 'lodash.clonedeep';
import { customOttErrors } from './error-messages';
import log from './log';

function removeQualityLevel(player, type, representation) {
  log(`[${type}] representation`, representation);

  // when the playlist is restricted, we need to blacklist it, but only for short time, so we can enable it again
  // in case the status would change again (repeated connection of external display, etc)
  if (type !== KEY_STATUS.USABLE_NO_MATCH) {
    player.tech_.vhs.masterPlaylistController_.blacklistCurrentPlaylist({
      playlist: representation.playlist,
      message: `DRM keystatus changed to ${type}. custom blacklisting of this representation.`,
      blacklistDuration: 1,
    });
  }

  const qualityLevel = player.qualityLevels().getQualityLevelById(representation.id);

  if (qualityLevel) {
    // restricted representation level is disabled for qualityLevel plugin to chose it
    qualityLevel.enabled = false;
    // quality level is removed from the user interface so the user can't chose it either
    player.qualityLevels().removeQualityLevel(qualityLevel);
    log(`Removing quality level from levels "${qualityLevel.id}" [${type}]`);
    // with restricted representation removed, player chose from other quality levels the most suitable one
    if (player.error_) {
      player.error(null);
      player.extendedErrorDisplay.close();
    }
    player.play();
  }
}

function addSetQualityToThePlayer(player) {
  const setQuality = (qualityId) => {
    const { levels_ } = player.qualityLevels();

    // enable only desired quality level so player is forced to chose it
    levels_.forEach((level) => {
      if (level.id === qualityId) {
        level.enabled = true;
        const { width, height, bitrate } = level;
        log(
          `[quality-selector] Manually selected quality quality - ${width}×${height} - ${Number(
            bitrate,
          ).toLocaleString()}bit/s`,
        );
      } else {
        level.enabled = false;
      }
    });
  };

  player.setQuality = setQuality;
}

function getWidevineKeyIdToHex(e) {
  return [...new Uint8Array(e.keyId)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
}

function getWidevinePsshToHex(representation) {
  if (!representation.playlist.contentProtection['com.widevine.alpha']) {
    return '';
  }

  return [...new Uint8Array(representation.playlist.contentProtection['com.widevine.alpha'].pssh.buffer)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
}

function getPlayreadyPsshToHex(representation) {
  if (!representation.playlist.contentProtection['com.microsoft.playready']) {
    return '';
  }

  return [...new Uint16Array(representation.playlist.contentProtection['com.microsoft.playready'].pssh.buffer)]
    .map((e) => e.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
}

function getKeyIdFromKeyStatusChangeEvent(event) {
  let binary = '';
  const bytes = new Uint8Array(event.keyId);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function getPlayreadyKeyIdToBase64ToHex(e) {
  let binary = '';
  const bytes = new Uint8Array(e.keyId);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  const base64KeyId = window.btoa(binary);
  let result = '';
  for (let j = 0; j < base64KeyId.length; j++) {
    result += base64KeyId.charCodeAt(j).toString(16);
  }
  return result.toUpperCase();
}

function collectRepresentations(allRepresentations, veryAllRepresentations, representationId, eventStatus) {
  if (allRepresentations.find((repre) => repre.id === representationId)) {
    allRepresentations = [
      ...allRepresentations.map((repre) =>
        repre.id === representationId && !repre.verified
          ? {
              id: repre.id,
              type: eventStatus,
              verified: true,
            }
          : { ...repre },
      ),
    ];
  } else {
    allRepresentations.push({
      id: representationId,
      type: eventStatus,
      verified: true,
    });
  }
  veryAllRepresentations.push({
    id: representationId,
    type: eventStatus,
  });
}

const KEY_STATUS = {
  PENDING: 'status-pending',
  OUTPUT_RESTRICTED: 'output-restricted',
  INTERNAL_ERROR: 'internal-error',
  USABLE: 'usable',
  USABLE_NO_MATCH: 'usable-no-match', // custom status
};

// eslint-disable-next-line prefer-const
let allRepresentations = [];
const veryAllRepresentations = [];
let licenserequestattempted = 0;

export function handleKeyChanges(player) {
  // on these platforms there is overrideNative set to false and videojs is out of the game
  if (videojs.browser.IS_SAFARI || videojs.browser.IS_IOS) {
    return;
  }

  let pausedByKeyStatusChange = false;

  addSetQualityToThePlayer(player);

  const keystatuschangeEvents = {};

  const keyStatusChangeHandler = (event) => {
    if (player.duration() > player.currentTime()) {
      log('ksc', event);
      log(`keyId "${getKeyIdFromKeyStatusChangeEvent(event)}" - keystatus: ${event.status}`);

      if (event.status === KEY_STATUS.PENDING) {
        player.tech_.vhs.representations().forEach((representation) => {
          collectRepresentations(allRepresentations, veryAllRepresentations, representation.id, event.status);
        });

        return;
      }

      if (event.status === KEY_STATUS.OUTPUT_RESTRICTED || event.status === KEY_STATUS.INTERNAL_ERROR) {
        // we need to stop event from getting to the eme so we can handle the situation on ourselves
        // it would do stuff like blacklist representation remove quality level and such otherwise
        event.stopImmediatePropagation();

        const widevineKeyId = getWidevineKeyIdToHex(event);
        const playreadyKeyId = getPlayreadyKeyIdToBase64ToHex(event);

        // we look through all representations if any of them has keyId matched to that extracted from the event
        // in that case we disable that representation
        player.tech_.vhs.representations().forEach((representation) => {
          const widevinePssh = getWidevinePsshToHex(representation);
          const playreadyPssh = getPlayreadyPsshToHex(representation);

          collectRepresentations(allRepresentations, veryAllRepresentations, representation.id, event.status);

          if (widevinePssh.includes(widevineKeyId) || playreadyPssh.includes(playreadyKeyId)) {
            removeQualityLevel(player, event.status, representation);
          }
        });

        // when there is no unrestricted representation of the source available the playback is paused
        // and the flag is pausedByKeyStatusChange so the player know if the pause was hit by a restriction
        // and not by user before restriction happened. It ensures that if user pause player and then restriction changes
        // video won't start playing but if was player paused by restrictions and they change back the player will start
        // playing by itself
        if (player.qualityLevels().levels_.length === 0) {
          player.error(customOttErrors.NO_DRM_USABLE_REPRESENTATION_FOUND_ERR);

          if (player.paused() === false) {
            pausedByKeyStatusChange = true;
            player.pause();
          }
        }
        //  check if there is any qulity level available and if not, throw error
      }

      if (event.status === KEY_STATUS.USABLE) {
        const widevineKeyId = getWidevineKeyIdToHex(event);
        const playreadyKeyId = getPlayreadyKeyIdToBase64ToHex(event);

        // we look through all representations if any of them has keyId matched to that extracted from the event
        // in that case we enable that representation
        player.tech_.vhs.representations().forEach((representation) => {
          const widevinePssh = getWidevinePsshToHex(representation);
          const playreadyPssh = getPlayreadyPsshToHex(representation);

          if (widevinePssh.includes(widevineKeyId) || playreadyPssh.includes(playreadyKeyId)) {
            collectRepresentations(allRepresentations, veryAllRepresentations, representation.id, event.status);

            log('usable representation', representation);

            const qualityLevelUnavailable = !player.qualityLevels().getQualityLevelById(representation.id);

            // if quality level is disabled we enable it manualy
            if (qualityLevelUnavailable) {
              const qualityLevel = player.qualityLevels().addQualityLevel(representation);
              // set quality level if it has width (removing audio only options and similar)
              qualityLevel.enabled = !!qualityLevel.width;
              log('Adding quality level to levels');
            }

            // in case there was an error, player will try to remove it and start the playback (in case pause was induced by DRM restrictions)
            // if the error was shown because of DRM everything will be fine, if there is some strange coincidence of another error being shown
            // while there is also problem with drm, that error should show up again when player try to start the playback
            // that situation should't really happen in this flow or is very unlikely
            if (player.error_) {
              player.error(null);
              player.extendedErrorDisplay.close();
              if (pausedByKeyStatusChange) {
                player.play();
              }
            }
          } else {
            collectRepresentations(
              allRepresentations,
              veryAllRepresentations,
              representation.id,
              KEY_STATUS.USABLE_NO_MATCH,
            );

            // we need to stop event from getting to the eme so we can handle the situation on ourselves
            event.stopImmediatePropagation();
          }
        });
      }

      log(`All unique representations: (${allRepresentations.length}) ${JSON.stringify(allRepresentations)}`);
      log(
        `All representations from the start: (${veryAllRepresentations.length}) ${JSON.stringify(
          veryAllRepresentations,
        )}`,
      );

      if (licenserequestattempted === player.tech_.vhs.representations().length) {
        if (allRepresentations.every((repre) => repre.type === KEY_STATUS.USABLE_NO_MATCH)) {
          return;
        }

        // filter only unique usable representations from all list of all representations from the start
        const allUniqueUsableRepresentations = veryAllRepresentations
          .filter((repre) => repre.type === KEY_STATUS.USABLE)
          .map((uniqueRepre) => uniqueRepre.id)
          .filter((x, i, a) => a.indexOf(x) === i);

        log(
          `All unique usable representations from the start: (${
            allUniqueUsableRepresentations.length
          }) ${JSON.stringify(allUniqueUsableRepresentations)}`,
        );

        allRepresentations.forEach((representation) => {
          if (
            allUniqueUsableRepresentations.length &&
            !allUniqueUsableRepresentations.find((repre) => repre === representation.id)
          ) {
            // remove quality levels that are not in the list of usable representation
            removeQualityLevel(player, KEY_STATUS.USABLE_NO_MATCH, representation);
          }
        });

        // all qualities needs to be updated so the adaptive stream can work again
        player.qualityLevels().levels_.forEach((level, index) => {
          if (player.qualityLevels().selectedIndex_ === index) {
            // if there is at least one usable representation, then we enable it
            if (veryAllRepresentations.find((item) => item.id === level.id && item.type === KEY_STATUS.USABLE)) {
              level.enabled = true;
            } else {
              // if there is no usable representation, then we have to find last one usable from all representations
              const lastUsableRepresentation = veryAllRepresentations.findLast(
                (item) => item.type === KEY_STATUS.USABLE,
              )?.id;
              log(`lastUsableRepresentation: ${JSON.stringify(lastUsableRepresentation)}`);
              // if there is last usable representation, we enable it
              if (lastUsableRepresentation) {
                if (player.qualityLevels().levels_.find((level) => level.id === lastUsableRepresentation)) {
                  player.qualityLevels().levels_.find((level) => level.id === lastUsableRepresentation).enabled = true;
                }
              } else {
                // if there is no last usable representation, we have to show the error
                player.error(customOttErrors.NO_DRM_USABLE_REPRESENTATION_FOUND_ERR);
              }
            }
          } else {
            level.enabled = false;
          }
        });
      }

      // this event handle disposal and new init of quality selector ui because it react
      // poorly to dynamic changes in number of qualities
      player.trigger('ott-auto-quality-set-via-keystatuschange');
    } else {
      event.stopImmediatePropagation();
      // this handle old events after disposal of player when the player resets itself and then it will check
      // all the old events that happens between hiting replay and resettning the player
      // without it the player would start again without knowing the state of DRM after restart
      keystatuschangeEvents[getKeyIdFromKeyStatusChangeEvent(event)] = cloneDeep(event);
      player.one('play', () => Object.values(keystatuschangeEvents).forEach((event) => keyStatusChangeHandler(event)));
    }
  };

  player.tech_.on('keystatuschange', keyStatusChangeHandler);

  player.tech_.on('licenserequestattempted', () => {
    licenserequestattempted++;
  });
}
