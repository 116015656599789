import {
  SimidProtocol,
  ProtocolMessage,
  MediaMessage,
  PlayerMessage,
  CreativeMessage,
  CreativeErrorCode,
  PlayerErrorCode,
  StopCode,
} from './simid-protocol';
import log from '../../../log';
import { registerOverlayTracking } from '../shared/vast-tracker';
import Cascade from '../cascade';
import avlAdType from '../shared/ad-type';

/**
 * All the logic for a simple SIMID player
 */
export default class SimidManager {
  constructor(player) {
    this.player = player;
    /**
     * The protocol for sending and receiving messages.
     * @protected {!SimidProtocol}
     */
    this.simidProtocol = new SimidProtocol();

    this.ad = null;
    this.creative = null;

    this.addListeners_();

    /**
     * A reference to the video player on the players main page
     * @private {!Element}
     */
    this.contentVideoElement_ = player.el();

    /**
     * A reference to a video player for playing ads.
     * @private {!Element}
     */
    this.adVideoElement_ = this.contentVideoElement_;

    /**
     * A reference to the iframe holding the SIMID creative.
     * @private {?Element}
     */
    this.simidIframe_ = null;

    /**
     * A reference to the promise returned when initialization was called.
     * @private {?Promise}
     */
    this.initializationPromise_ = null;

    /**
     * A function to execute on ad completion
     * @private {!Function}
     */

    /**
     * A number indicating when the non linear ad started.
     * @private {?number}
     */
    this.nonLinearStartTime_ = null;

    /**
     * The duration requested by the ad.
     * @private {number}
     */
    this.requestedDuration_ = null;

    /**
     * Resolution function for the session created message
     * @private {?Function}
     */
    this.resolveSessionCreatedPromise_ = null;

    /**
     * A promise that resolves once the creative creates a session.
     * @private {!Promise}
     */
    this.sessionCreatedPromise_ = new Promise((resolve) => {
      this.resolveSessionCreatedPromise_ = resolve;
    });

    /**
     * Resolution function for the ad being initialized.
     * @private {?Function}
     */
    this.resolveInitializationPromise_ = null;

    /**
     * Reject function for the ad being initialized.
     * @private {?Function}
     */
    this.rejectInitializationPromise_ = null;

    /**
     * An object containing the resized nonlinear creative's dimensions.
     * @private {?Object}
     */
    this.nonLinearDimensions_ = null;

    /** The unique ID for the interval used to compares the requested change
     *  duration and the current ad time.
     * @private {number}
     */
    this.durationInterval_ = null;

    /**
     * A promise that resolves once the creative responds to initialization with resolve.
     * @private {!Promise}
     */
    this.initializationPromise_ = new Promise((resolve, reject) => {
      this.resolveInitializationPromise_ = resolve;
      this.rejectInitializationPromise_ = reject;
    });

    this.adStartTime = null;
  }

  /**
   * Get { x, y } dimensions of the nonlinear creative variation
   * @returns {{ x: number, y: number }}
   */
  getNonlinearDimensions = () => {
    const { width, height } = this.creative;

    return {
      width,
      height,
    };
  };

  /**
   * Initializes an ad. This should be called before an ad plays.
   * Creates an iframe with the creative in it, then uses a promise
   * to call init on the creative as soon as the creative initializes
   * a session.
   */
  initializeAd(ad, isLinearAd = true) {
    log(`[simid-manager]: Initializing ${this.isLinearAd_ ? 'linear' : 'nonlinear'} add`);

    Cascade.resetShownCascade(this.player, 'overlay');

    this.isLinearAd_ = isLinearAd;
    this.ad = ad;

    // variation for nonlinear is the same as creative for linear
    // so in the creative variable there is actually variation in the nonlinear ad
    this.creative = isLinearAd ? ad.creatives[0] : ad.creatives[0].variations[0];

    if (!isLinearAd) {
      this.player.adstate.currentAdType = avlAdType.overlay;

      // there is player, ad, creative, variation, but variation is saved in the creative variable
      registerOverlayTracking(this.player, ad, ad.creatives[0], this.creative);

      this.proportionalAdWidth = `${(this.creative.width / this.player.currentWidth()) * 100}%`;
      this.proportionalAdHeight = `${(this.creative.height / this.player.currentHeight()) * 100}%`;

      this.adStartTime = this.player.currentTime();
      this.requestedDuration_ = this.creative.minSuggestedDuration > 0 ? this.creative.minSuggestedDuration : Infinity;

      this.player.trigger({
        type: 'overlaycanplay',
        overlay: this.creative, // variation in creative variable
      });
    }

    this.trackEventsOnAdVideoElement_();

    if (!this.isLinearAd_ && !this.isValidDimensions_(this.getNonlinearDimensions())) {
      log(
        '[simid-manager]: Unable to play a non-linear ad with dimensions bigger than the player. Please modify dimensions to a smaller size.',
      );
      return;
    }

    // After the iframe is created the player will wait until the ad
    // initializes the communication channel. Then it will call
    // sendInitMessage.
    this.simidIframe_ = this.createSimidIframe_(
      this.isLinearAd_ ? this.creative.interactiveCreativeFile.fileURL : this.creative.iframeResource,
    );

    this.displayNonlinearCreative_();

    if (!this.isLinearAd_) {
      if (this.player.options_.plugins.adService.omidManager) {
        this.player.omidManager().initAd({
          ad,
          type: 'nonlinear',
          slot: this.simidIframe_,
          hasSimid: true,
        });
      }
    }

    // Prepare for the case that init fails before sending
    // the init message. Initialization failing means abandoning
    // the ad.
    this.initializationPromise_.catch((e) => {
      this.onAdInitializedFailed_(e);
    });

    // Using a promise means that the init message will
    // send as soon as the session is created. If the session
    // is already created this will send the init message immediately.
    this.sessionCreatedPromise_.then(() => {
      // TODO: move this
      this.sendInitMessage_();
    });
  }

  /**
   * Plays a SIMID  creative once it has responded to the initialize ad message.
   */
  playAd() {
    // This example waits for the ad to be initialized, before playing video.
    // NOTE: Not all players will wait for session creation and initialization
    // before they start playback.
    this.initializationPromise_.then(() => {
      this.startCreativePlayback_();
    });
    log('[simid-manager]: Ad playing');
  }

  /**
   * Sets up an iframe for holding the simid element.
   *
   * @return {!Element} The iframe where the simid element lives.
   * @private
   */
  createSimidIframe_(iframeSrc) {
    log('[simid-manager]: Creating SIMID iframe');
    const playerDiv = this.player.el();

    const simidIframe = document.createElement('iframe');

    simidIframe.id = this.isLinearAd_ ? 'simid-iframe' : 'simid-iframe-nonlinear';

    simidIframe.style.top = this.isLinearAd_ ? 0 : 'unset';
    simidIframe.style.left = this.isLinearAd_ ? 0 : '50%';
    simidIframe.style.transform = this.isLinearAd_ ? 'unset' : 'translateX(-50%)';
    simidIframe.style.bottom = this.isLinearAd_ ? 'unset' : '15%';
    simidIframe.style.width = this.isLinearAd_ ? '100%' : `${this.creative.width}px`;
    simidIframe.style.height = this.isLinearAd_ ? '100%' : `${this.creative.height}px`;
    simidIframe.style.display = 'none';
    simidIframe.style.position = 'absolute';
    simidIframe.style.border = 0;
    // set the z-index above the share button (7)
    simidIframe.style.zIndex = 10;
    // set the control bar higher than creative
    this.player.controlBar.el().style.zIndex = 11;

    simidIframe.sandbox = 'allow-scripts allow-same-origin';

    // The target of the player to send messages to is the newly
    // created iframe
    // playerDiv.appendChild(simidIframe);

    if (!this.isLinearAd_) {
      playerDiv.appendChild(simidIframe);
      const serviceScript = document.createElement('script');
      serviceScript.src = '/js/omweb-v1.js';
      simidIframe.contentWindow.document.querySelector('head').appendChild(serviceScript);
    } else {
      playerDiv.appendChild(simidIframe);
    }

    if (this.isLinearAd_) {
      // Set up css to overlay the SIMID iframe over the entire video creative
      // only if linear. Non-linear ads will have dimension inputs for placement
      simidIframe.classList.add('linear_simid_creative');
    }

    // Set the iframe creative, this should be an html creative.
    // TODO: This sample does not show what to do when loading fails.
    try {
      simidIframe.src = iframeSrc;
    } catch (error) {
      log('[simid-manager]: ', error);
    }

    this.simidProtocol.setMessageTarget(simidIframe.contentWindow);
    simidIframe.setAttribute('allowFullScreen', '');
    simidIframe.setAttribute('allow', 'geolocation');
    return simidIframe;
  }

  /**
   * Listens to all relevant messages from the SIMID add.
   * @private
   */
  addListeners_() {
    this.simidProtocol.addListener(ProtocolMessage.CREATE_SESSION, this.onSessionCreated_);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_FULL_SCREEN, this.onRequestFullScreen);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_PLAY, this.onRequestPlay);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_PAUSE, this.onRequestPause);
    this.simidProtocol.addListener(CreativeMessage.FATAL_ERROR, this.onCreativeFatalError);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_SKIP, this.onRequestSkip);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_STOP, this.onRequestStop);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_CHANGE_AD_DURATION, this.onRequestChangeAdDuration);
    this.simidProtocol.addListener(CreativeMessage.GET_MEDIA_STATE, this.onGetMediaState);
    this.simidProtocol.addListener(CreativeMessage.LOG, this.onReceiveCreativeLog);
    this.simidProtocol.addListener(CreativeMessage.EXPAND_NONLINEAR, this.onExpandResize);
    this.simidProtocol.addListener(CreativeMessage.COLLAPSE_NONLINEAR, this.onCollapse);
    this.simidProtocol.addListener(CreativeMessage.REQUEST_RESIZE, this.onRequestResize);
  }

  /**
   * Resolves the session created promise.
   * @private
   */
  onSessionCreated_ = () => {
    // Anything that must happen after the session is created can now happen
    // since this promise is resolved.
    this.resolveSessionCreatedPromise_();
  };

  /**
   * Destroys the existing simid iframe.
   * @private
   */
  destroySimidIframe_() {
    if (this.simidIframe_) {
      this.simidIframe_.remove();
      this.simidIframe_ = null;
      this.simidProtocol.reset();
      // set z-index back to nothing so it gets it's original css value;
      this.player.controlBar.el().style.zIndex = '';
    }
    log('[simid-manager]: Destroyed SIMID iframe');
  }

  /**
   * Returns the full dimensions of an element within the player div.
   * @private
   * @return {!Object}
   */
  getFullDimensions_(elem) {
    const { width, height, x, y } = elem.getBoundingClientRect();
    return {
      x,
      y,
      width,
      height,
    };
  }

  /**
   * Checks whether the input dimensions are valid and fit in the player window.
   * @private
   * @param {!{ height: string, width: string }} dimensions A dimension that contains width & height fields.
   * @return {boolean} isDimensionValid
   */
  isValidDimensions_(dimensions) {
    const playerDiv = this.player.el();
    const playerRect = playerDiv.getBoundingClientRect();

    const heightFits = Number(dimensions.height) <= Number(playerRect.height);
    const widthFits = Number(dimensions.width) <= Number(playerRect.width);

    return heightFits && widthFits;
  }

  /**
   * Validates and displays the non-linear creative.
   * @private
   */
  displayNonlinearCreative_() {
    const newDimensions = this.getNonlinearDimensions();

    if (!this.isValidDimensions_(newDimensions)) {
      log(
        '[simid-manager]: Unable to play a non-linear ad with dimensions bigger than the player. Please modify dimensions to a smaller size.',
      );
      return;
    }

    // this.setSimidIframeDimensions_(newDimensions);
    // this.simidIframe_.style.position = "absolute";
    this.startCreativePlayback_();
    this.player.trigger('overlaystarted');
    log('[simid-manager]: Starting nonlinear creative');
  }

  /**
   * Changes the simid iframe dimensions to the given dimensions.
   * @private
   * @param {{ width: number, height: number }} resizeDimensions A dimension that contains an x,y,width & height fields.
   * @param {boolean} expand if the ad should expand
   */
  setSimidIframeDimensions_({ width, height }, expand = false) {
    this.simidIframe_.style.bottom = expand ? 0 : '15%';
    this.simidIframe_.style.height = `${height}px`;
    this.simidIframe_.style.width = `${width}px`;
  }

  /**
   * The creative wants to expand the ad.
   * @param {!Object} incomingMessage Message sent from the creative to the player
   */
  onExpandResize = (incomingMessage) => {
    if (this.isLinearAd_) {
      const errorMessage = {
        errorCode: CreativeErrorCode.EXPAND_NOT_POSSIBLE,
        message: 'Linear resize not yet supported.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
      log('[simid-manager]: ', errorMessage.message);
    } else {
      const fullDimensions = this.getFullDimensions_(this.contentVideoElement_);
      this.setSimidIframeDimensions_(fullDimensions, true);
      this.player.pause();
      this.simidProtocol.resolve(incomingMessage);
    }
  };

  /**
   * The creative wants to collapse the ad.
   * @param {!Object} incomingMessage Message sent from the creative to the player
   */
  onCollapse = (incomingMessage) => {
    const newDimensions = this.getNonlinearDimensions();

    if (this.isLinearAd_) {
      const errorMessage = {
        message: 'Cannot collapse linear ads.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
      log('[simid-manager]: ', errorMessage.message);
    } else if (!this.isValidDimensions_(newDimensions)) {
      const errorMessage = {
        message: 'Unable to collapse to dimensions bigger than the player. Please modify dimensions to a smaller size.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
      log('[simid-manager]: ', errorMessage.message);
    } else {
      this.setSimidIframeDimensions_(newDimensions, false);
      // this.simidIframe_.style.position = "absolute";

      this.player.play();
      this.simidProtocol.resolve(incomingMessage);
    }
  };

  /**
   * The creative wants to resize the ad.
   * @param {!Object} incomingMessage Message sent from the creative to the player.
   */
  onRequestResize = (incomingMessage) => {
    if (this.isLinearAd_) {
      const errorMessage = {
        errorCode: CreativeErrorCode.EXPAND_NOT_POSSIBLE,
        message: 'Linear resize not yet supported.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
      log('[simid-manager]: ', errorMessage.message);
    } else if (!this.isValidDimensions_(incomingMessage.args.creativeDimensions)) {
      const errorMessage = {
        errorCode: CreativeErrorCode.EXPAND_NOT_POSSIBLE,
        message:
          'Unable to resize a non-linear ad with dimensions bigger than the player. Please modify dimensions to a smaller size.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
      log('[simid-manager]: ', errorMessage.message);
    } else {
      this.nonLinearDimensions_ = incomingMessage.args.creativeDimensions;
      this.setSimidIframeDimensions_(incomingMessage.args.creativeDimensions);
      this.simidProtocol.resolve(incomingMessage);
    }
  };

  /**
   * Initializes the SIMID creative with all data it needs.
   * @private
   */
  sendInitMessage_ = () => {
    const videoDimensions = this.getFullDimensions_(this.contentVideoElement_);
    // Since the creative starts as hidden it will take on the
    // video element dimensions, so tell the ad about those dimensions.
    const creativeDimensions = this.isLinearAd_
      ? videoDimensions
      : { width: this.creative.width, height: this.creative.height };

    const environmentData = {
      videoDimensions,
      creativeDimensions,
      fullscreen: this.player.isFullscreen(),
      fullscreenAllowed: true,
      variableDurationAllowed: true,
      skippableState: 'adHandles', // This player does not render a skip button.
      siteUrl: document.location.host,
      appId: '', // This is not relevant on desktop
      // FIXME: where to find these two-options?
      useragent: '', // This should be filled in for sdks and players
      deviceId: '', // This should be filled in on mobile
      muted: this.player.muted(),
      volume: this.player.volume(),
    };

    const creativeData = {
      adParameters: JSON.parse(this.creative.adParameters),
      // These values should be populated from the VAST response.
      adId: this.ad.id,
      creativeId: this.creative.id,
      adServingId: this.ad.adServingId,
      clickThroughUrl: this.isLinearAd_
        ? this.creative.videoClickThroughURLTemplate.url
        : this.creative.nonlinearClickThroughURLTemplate,
      duration: this.creative.duration || -2,
    };

    const initMessage = {
      environmentData,
      creativeData,
    };

    const initPromise = this.simidProtocol.sendMessage(PlayerMessage.INIT, initMessage);

    initPromise
      .then((args) => {
        this.resolveInitializationPromise_(args);
      })
      .catch((args) => {
        this.rejectInitializationPromise_(args);
      });

    log('[simid-manager]: Init message sent');
  };

  /**
   * Called once the creative responds positively to being initialized.
   * @private
   */
  startCreativePlayback_ = () => {
    // Once the ad is successfully initialized it can start.
    // If the ad is not visible it must be made visible here.
    this.showSimidIFrame();
    this.simidProtocol.sendMessage(PlayerMessage.START_CREATIVE);
    // TODO: handle creative rejecting startCreative message.
  };

  /**
   * Called if the creative responds with reject after the player
   * initializes the ad.
   * @param {!Object} data
   * @private
   */
  onAdInitializedFailed_ = (data) => {
    log(`[simid-manager]: Ad init failed. ${JSON.stringify(data)}`);
    this.destroySimidIframe_();
  };

  /** @private */
  hideSimidIFrame_ = () => {
    this.simidIframe_.style.display = 'none';
    log('[simid-manager]: SIMID iframe is now hidden.');
  };

  /** @private */
  showSimidIFrame = () => {
    this.simidIframe_.style.display = 'block';
    this.iframeVisible = true;
    log('[simid-manager]: SIMID iframe is now visible');
  };

  /**
   * Tracks the events on the ad video element specified by the simid spec
   * @private
   */
  trackEventsOnAdVideoElement_ = () => {
    log('[simid-manager]: Adding event listeners');

    const linearEvents = {
      adpause: async () => {
        await this.simidProtocol.sendMessage(MediaMessage.PAUSE);
        this.player.bigPlayButton.hide();
      },
      adtimeupdate: () => {
        this.simidProtocol.sendMessage(MediaMessage.TIME_UPDATE, {
          currentTime: this.player.currentTime(),
        });
      },
      volumechange: () =>
        this.simidProtocol.sendMessage(MediaMessage.VOLUME_CHANGE, {
          volume: this.player.muted() ? 0 : this.player.volume(),
        }),
      // TODO: zjistit parametry
      error: (event) =>
        this.simidProtocol.sendMessage(MediaMessage.ERROR, {
          error: event.error,
          message: event.message,
        }),
      adend: () => {
        // videoComplete()
        this.simidProtocol.sendMessage(MediaMessage.ENDED);
        this.stopAd(StopCode.MEDIA_PLAYBACK_COMPLETE, false);
        // removeListeners();
        // this.destroySimidIframe_();
      },
      seeking: () => {
        this.simidProtocol.sendMessage(MediaMessage.SEEKING);
      },
      seeked: () => {
        this.simidProtocol.sendMessage(MediaMessage.SEEKING);
      },
      playing: () => {
        this.simidProtocol.sendMessage(MediaMessage.PLAYING);
      },
      adplay: () => {
        this.simidProtocol.sendMessage(MediaMessage.PLAY);
      },
      durationchange: () => {
        this.simidProtocol.sendMessage(MediaMessage.DURATION_CHANGE, {
          duration: this.player.duration(),
        });
      },
    };

    const nonlinearEvents = {
      overlayended: () => {
        if (this.overlayendedCalled) {
          return;
        }
        this.stopAd(StopCode.PLAYER_INITIATED, true);
      },
      durationChange: () => {
        this.simidProtocol.sendMessage(MediaMessage.DURATION_CHANGE, {
          duration: this.player.duration(),
        });
      },
      pause: () => {
        this.simidProtocol.sendMessage(MediaMessage.PAUSE);
        this.player.bigPlayButton.hide();
      },
      play: () => {
        this.player.bigPlayButton.show();
        this.simidProtocol.sendMessage(MediaMessage.PAUSE);
      },
      adend: () => {
        this.player.bigPlayButton.show();
        this.simidProtocol.sendMessage(MediaMessage.ENDED);
        this.stopAd(StopCode.MEDIA_PLAYBACK_COMPLETE, false, true);
      },
      timeupdate: () => {
        if (this.player.currentTime() - this.adStartTime > this.requestedDuration_) {
          this.stopAd(StopCode.NON_LINEAR_DURATION_COMPLETE, false, true);
        }
      },
      fullscreenchange: () => {
        const expanded = this.player.isFullscreen();
        this.simidProtocol.sendMessage(PlayerMessage.RESIZE);
        if (expanded && this.creative.scalable) {
          this.setSimidIframeDimensions_({
            height: this.proportionalAdHeight,
            width: this.proportionalAdWidth,
          });
        }
        if (!expanded) {
          const { height, width } = this.creative;
          this.setSimidIframeDimensions_({
            height,
            width,
          });
        }
      },
      adended: () => {
        removeListeners();
      },
    };

    const eventHandlers = this.isLinearAd_ ? linearEvents : nonlinearEvents;

    Object.keys(eventHandlers).forEach((event) => {
      this.player.on(event, eventHandlers[event]);
    });

    const removeListeners = () => {
      Object.keys(eventHandlers).forEach((event) => {
        this.player.off(event, eventHandlers[event]);
      });
    };
  };

  /**
   * Stops the ad and destroys the ad iframe.
   * @param {StopCode} reason The reason the ad will stop.
   */
  stopAd = (reason = StopCode.PLAYER_INITIATED, skipped = false) => {
    // The iframe is only hidden on ad stoppage. The ad might still request
    // tracking pixels before it is cleaned up.
    if (this.simidIframe_) {
      this.hideSimidIFrame_();
      const closeMessage = {
        code: reason,
      };
      // Wait for the SIMID creative to acknowledge stop and then clean
      // up the iframe.
      this.simidProtocol.sendMessage(PlayerMessage.AD_STOPPED).then(() => this.destroySimidIframe_());
    }
    this.player.trigger({
      type: 'adended',
      skipped, // TODO: should it be true?
    });

    if (!this.isLinearAd_) {
      this.overlayendedCalled = true;
      this.player.trigger('overlayended', { skipped });
    }
  };

  /**
   * Skips the ad and destroys the ad iframe.
   */
  skipAd = () => {
    // The iframe is only hidden on ad skipped. The ad might still request
    // tracking pixels before it is cleaned up.
    this.hideSimidIFrame_();
    // Wait for the SIMID creative to acknowledge skip and then clean
    // up the iframe.
    this.simidProtocol.sendMessage(PlayerMessage.AD_SKIPPED).then(() => this.destroySimidIframe_());

    this.player.trigger({
      type: 'adended',
      skipped: true,
    });
  };

  /** The creative wants to go full screen. */
  onRequestFullScreen = (incomingMessage) => {
    this.player.requestFullscreen();
    this.simidProtocol.resolve(incomingMessage);
  };

  /** The creative wants to play video. */
  onRequestPlay = (incomingMessage) => {
    if (this.isLinearAd_) {
      this.player
        .play()
        .then(() => this.simidProtocol.resolve(incomingMessage))
        .catch(() => {
          const errorMessage = {
            errorCode: PlayerErrorCode.VIDEO_COULD_NOT_LOAD,
            message: 'The SIMID media could not be loaded.',
          };
          this.simidProtocol.reject(incomingMessage, errorMessage);
        });
    } else {
      const errorMessage = {
        errorCode: CreativeErrorCode.PLAYBACK_AREA_UNUSABLE,
        message: 'Non linear ads do not play video.',
      };
      this.simidProtocol.reject(incomingMessage, errorMessage);
    }
  };

  /** The creative wants to pause video. */
  onRequestPause = (incomingMessage) => {
    this.player.pause();
    this.simidProtocol.resolve(incomingMessage);
  };

  /** The creative wants to stop with a fatal error. */
  onCreativeFatalError = (incomingMessage) => {
    this.simidProtocol.resolve(incomingMessage);
    this.stopAd(StopCode.CREATIVE_INITIATED, true);
  };

  /** The creative wants to skip this ad. */
  onRequestSkip = (incomingMessage) => {
    if (this.isLinearAd_) {
      this.player.trigger({
        type: 'adended',
        skipped: true,
      });
    } else {
      this.player.trigger('overlayended', { skipped: true });
    }
    this.simidProtocol.resolve(incomingMessage);
  };

  /** The creative wants to stop the ad early. */
  onRequestStop = (incomingMessage) => {
    this.simidProtocol.resolve(incomingMessage);
    this.stopAd(StopCode.CREATIVE_INITIATED, false);
  };

  /**
   * The player must implement sending tracking pixels from the creative.
   * This sample implementation does not show how to send tracking pixels or
   * replace macros. That should be done using the players standard workflow.
   */
  onReportTracking = (incomingMessage) => {
    const requestedUrlArray = incomingMessage.args.trackingUrls;
    log(`[simid-manager]: The creative has asked for the player to ping ${requestedUrlArray}`);
  };

  onGetMediaState = (incomingMessage) => {
    const mediaState = {
      currentSrc: this.player.src(),
      currentTime: this.player.currentTime(),
      duration: this.player.duration(),
      ended: this.player.ended(),
      muted: this.player.muted(),
      paused: this.player.paused(),
      volume: this.player.volume(),
      fullscreen: this.player.isFullscreen(),
    };
    this.simidProtocol.resolve(incomingMessage, mediaState);
  };

  onReceiveCreativeLog = (incomingMessage) => {
    const logMessage = incomingMessage.args.message;
    log(`[simid-manager]: Received message from creative: ${logMessage}`);
  };

  sendLog = (outgoingMessage) => {
    const logMessage = {
      message: outgoingMessage,
    };
    this.simidProtocol.sendMessage(PlayerMessage.LOG, logMessage);
  };
}
