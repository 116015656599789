// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Show time-shift time display
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Component
 */
class TimeShiftTimeDisplay extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.on(player, 'ott-seekablelive-seek', this.updateContent);
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-ts-time-display vjs-current-time-display',
      innerHTML: '',
    });
  }

  /**
   * Update current time display
   */
  updateContent(args) {
    const formattedTime = videojs.formatTime(args.seekTime);
    this.el_.innerHTML = `${formattedTime} ${this.player_.localize('delay')}`;
  }
}

Component.registerComponent('TimeShiftTimeDisplay', TimeShiftTimeDisplay);
export default TimeShiftTimeDisplay;
