import log from '../log';

const measuringAutoInit = (initData) => {
  const { measuring, playedTicks } = initData.plugins || {};
  if (measuring) return initData;

  initData.plugins.measuring = {
    autoInit: true,
    streamInfo: {},
  };
  log('[measuring-auto-init] added measuring plugin to init data');

  if (!playedTicks) {
    initData.plugins.playedTicks = {};
    log('[measuring-auto-init] added playedTicks plugin to init data');
  }

  return initData;
};

export default measuringAutoInit;
