import { videojs } from 'global/window';
/* eslint-disable */
import UhdProfileHeading from './uhdProfile-heading';
import UhdProfileButton from './uhdProfile-button';
/* eslint-enable */
import log from '../../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * UHD switch - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class UhdProfileControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.hide();
    this.addChild('UhdProfileHeading');
    this.addChild('UhdProfileButton');

    this.registerUhdSwitch();
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-an-control vjs-settings-item',
    });
  }

  registerUhdSwitch() {
    this.player_.on('ott-uhdprofile-control', (e) => {
      e.uhdAllowed ? this.show() : this.hide();
      log(
        `[UHD switch control] - recived uhd control event. UHD allowed: ${e.uhdAllowed} -> uhd switch ${
          e.uhdAllowed ? 'show' : 'hide'
        }`,
      );
    });
  }
}

export default UhdProfileControl;

Component.registerComponent('UhdProfileControl', UhdProfileControl);
