import { videojs } from 'global/window';

import SubtitlesSelectMenu from './subtitles-select-menu';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Subtitles select wrapper.
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SubtitlesSelectWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent();
    this.el_.className = 'vjs-subtitles-select-wrapper';
  }

  createContent() {
    this.addChild(new SubtitlesSelectMenu(this.player_));
  }
}

Component.registerComponent('SubtitlesSelectWrapper', SubtitlesSelectWrapper);
export default SubtitlesSelectWrapper;
