/**
 * @module plugins/PlayToggle
 *
 */
import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('PlayToggle');

class PlayToggleButton extends Component {
  constructor(player, options = {}) {
    super(player, { name: 'PlayToggle', ...options });
    this.player = player;
  }

  handleClick() {
    super.handleClick();

    if (this.player.paused()) {
      return this.player.trigger('ott-pause-toggle-click');
    }
    return this.player.trigger('ott-play-toggle-click');
  }

  createEl() {
    return super.createEl('button', {
      /**
       * id required to control inserting of play button to control bar
       * handled in custom-functionality.js
       */
      id: `${this.player_.id_}-play-toggle-button`,
    });
  }
}

videojs.registerPlugin('PlayToggleButton', PlayToggleButton);
export default PlayToggleButton;
