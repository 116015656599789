import xmljs from 'xml-js';
import log from '../../log';

/**
 * Method return formated time from seconds value
 * Format HH:MM:SS
 * @param {Number} sec // seconds
 * @returns {String} // if wrong sec returns 'notimedata'
 */
export const timeFormat = (sec) => {
  if (!sec) return 'notimedata';

  const secNum = parseInt(sec, 10);
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};

const getTimeOffsetValue = (adData) => {
  switch (true) {
    case adData.type === 'preroll':
      return 'start';
    case adData.type === 'postroll':
      return 'end';
    default:
      return adData.time;
  }
};

const createAdBreakObj = (adData) => {
  if (!adData.url || adData.time === 'notimedata') {
    log.warn(
      `[vmapcreator] ${adData.type} the ad will not be inserted due to missing data: ${!adData.url ? 'URL' : ''} ${
        adData.time === 'notimedata' ? 'TIMEOFFSET' : ''
      }`,
    );
    return null;
  }

  return {
    type: 'element',
    name: 'vmap:AdBreak',
    attributes: {
      timeOffset: getTimeOffsetValue(adData),
      breakType: adData.breakType ? adData.breakType : 'linear',
      breakId: adData.type,
    },
    elements: [
      {
        type: 'element',
        name: 'vmap:AdSource',
        attributes: {
          id: `${adData.type}-ad-${adData.index}`,
          allowMultipleAds: 'true',
          followRedirects: 'true',
        },
        elements: [
          {
            type: 'element',
            name: 'vmap:AdTagURI',
            attributes: {
              templateType: 'vast3',
            },
            elements: [
              {
                type: 'cdata',
                cdata: adData.url,
              },
            ],
          },
        ],
      },
    ],
  };
};

const prepareAdsData = (initData) => {
  const preparedAds = [];

  // console.log('[debug] ima initData vmapcreator', initData);

  if (initData.linear) {
    // PREROLLS
    const prerollsData = initData.linear.preroll || {};
    const prerolls = prerollsData.vasts || [];
    prerolls.forEach((ad, index) => {
      preparedAds.push({
        type: 'preroll',
        index: index + 1,
        time: null,
        url: ad,
      });
    });

    // MIDROLLS
    const midrolls = initData.linear.midrolls || [];
    midrolls.forEach((ad, indexParent) => {
      ad.vasts.forEach((vastUrl, indexChild) => {
        preparedAds.push({
          type: 'midroll',
          index: `${indexParent + 1}-${indexChild + 1}`,
          time: timeFormat(ad.time),
          url: vastUrl,
        });
      });
    });

    // POSTROLLS
    const postrollsData = initData.linear.postroll || {};
    const postrolls = postrollsData.vasts || [];
    postrolls.forEach((ad, index) => {
      preparedAds.push({
        type: 'postroll',
        index: index + 1,
        time: null,
        url: ad,
      });
    });
  }

  // CASCADE
  const cascade = initData.cascade || [];
  cascade.forEach((ad, index) => {
    // OVERLAY
    if (ad.overlay) {
      const { overlay } = ad;
      preparedAds.push({
        breakType: 'nonlinear',
        type: 'overlay',
        index: index + 1,
        time: timeFormat(ad.time),
        url: overlay.vast,
      });
    }

    // COMPANION
    if (ad.companion) {
      const { overlay } = ad;
      preparedAds.push({
        breakType: 'display',
        type: 'companion',
        index: index + 1,
        time: timeFormat(ad.time),
        url: overlay.vast,
      });
    }
  });

  return preparedAds;
};

const createVmap = (initData) => {
  const vmapMainWrapper = {
    elements: [
      {
        type: 'element',
        name: 'vmap:VMAP',
        attributes: {
          'xmlns:vmap': 'http://www.iab.net/videosuite/vmap',
          'version': '1.0',
        },
        elements: [],
      },
    ],
  };

  const ads = prepareAdsData(initData || {});
  ads.forEach((ad) => {
    const adStruct = createAdBreakObj(ad);
    if (adStruct) {
      vmapMainWrapper.elements[0].elements.push(adStruct);
    }
  });

  const options = { compact: false, ignoreComment: true, spaces: 4 };
  const result = xmljs.js2xml(vmapMainWrapper, options);
  log('[vmapcreator] XML VMAP result', result);

  return result;
};

export default createVmap;
