import { videojs } from 'global/window';
/* eslint-disable */
import SubtitlesHeading from './subtitles-heading';
import SubtitlesButtonsWrapper from './subtitles-buttons-wrapper';
import SubtitlesButton from './subtitles-button';
/* eslint-enable */
import './subtitles-select/subtitles-select-wrapper';
import propTest from '../../../property-tester';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Text tracks (subtitles) - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SubtitlesControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    options.parent = this;
    this.subtitlesControlOpts = propTest(() => this.player.options_.responsiveControl.subtitles);

    if (this.subtitlesControlOpts) {
      Object.entries(this.subtitlesControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'visible';
          if (entry[0] === 'alone') visibility = 'hidden';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }

    // default render in settings
    this.addChild('SubtitlesHeading');
    if (this.options_.dropdownSettings) {
      this.addChild('SubtitlesSelectWrapper');
    } else {
      this.addChild('SubtitlesButtonsWrapper');
    }

    // when configuration includes special rules, render also in controlBar
    if (this.subtitlesControlOpts) {
      this.player.controlBar.addChild('SubtitlesButton');
    }
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-subtitles-control vjs-settings-item',
    });
  }
}

Component.registerComponent('SubtitlesControl', SubtitlesControl);
export default SubtitlesControl;
