/**
 * Playback validation module
 * @module playback-validation.js
 */

import is from 'is_js';
import log from './log';
import {
  PV_ARGS_SOURCES_INVALID,
  PV_SOURCES_ORIGINAL_INVALID,
  PV_SOURCES_MATCHED_INVALID,
  PV_SOURCES_LOCALIZED_INVALID,
} from './errors';
import { customOttErrors } from './error-messages';

/**
 * Validate if any sources matched and if not throw appropriate error with UI user message
 *
 * @param {Object} player           - Player object
 * @param {Object} sources          - Object with all sources types
 * @param {Array} sources.original  - Original sources array (recieved from init data, before every matching)
 * @param {Array} sources.matched   - Matched sources array after browser technology detection
 * @param {Array} sources.localized - Localized sources array after language match.
 * @return {Boolean} True when playback is valid false otherwise
 */
function isPlaybackValid(player, sources = {}) {
  // Sources must be type of object
  const noSources = is.not.object(sources) || !sources.original || !sources.matched || !sources.localized;

  const isImaDai = !!player.options_.plugins.adServiceImaDai;

  if (noSources && isImaDai) {
    log(
      'There are no sources, but there is IMA DAI - source will be obtained through ima dai stream manager, but there will be no backup source if ima dai fails.',
    );
    return true;
  }

  if (noSources) {
    log.error(PV_ARGS_SOURCES_INVALID.code, PV_ARGS_SOURCES_INVALID.message);
    player.error(customOttErrors.NO_SOURCE_FOUND_ERR);
    return false;
  }

  // No source from the init object recieved
  const { HLS, DASH, MP4 } = sources.original;
  if (!HLS?.length && !DASH?.length && !MP4?.length) {
    log.error(PV_SOURCES_ORIGINAL_INVALID.code, PV_SOURCES_ORIGINAL_INVALID.message);
    player.error(customOttErrors.NO_SOURCE_FOUND_ERR);
    return false;
  }

  // Init data and browser ability check failed
  if (!sources?.matched?.length) {
    log.error(PV_SOURCES_MATCHED_INVALID.code, PV_SOURCES_MATCHED_INVALID.message);
    player.error(customOttErrors.NO_PLAYABLE_SOURCE_FOUND_ERR);
    return false;
  }

  // Should never happen case, when localization module return empty array
  if (!sources?.localized?.length) {
    // This case should not occur because the localization module returns original input when no language matched. Just for sure..
    log.error(PV_SOURCES_LOCALIZED_INVALID.code, PV_SOURCES_LOCALIZED_INVALID.message);
    // We are using same error for this case like the previous one
    player.error(customOttErrors.NO_PLAYABLE_SOURCE_FOUND_ERR);
    return false;
  }

  return true;
}

export default isPlaybackValid;
