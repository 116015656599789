const evPrefix = 'ott-ad-';

/**
 * Available IMA SDK events, registered listeners in adservice
 */
const adEvents = Object.freeze({
  CONTENTPAUSEREQUESTED: `${evPrefix}contentPauseRequested`,
  CONTENTRESUMEREQUESTED: `${evPrefix}contentResumeRequested`,
  CLICK: `${evPrefix}click`,
  DURATIONCHANGE: `${evPrefix}durationChange`,
  EXPANDEDCHANGED: `${evPrefix}expandedChanged`,
  START: `${evPrefix}start`,
  IMPRESSION: `${evPrefix}impression`,
  PAUSE: `${evPrefix}pause`,
  RESUME: `${evPrefix}resume`,
  ADPROGRESS: `${evPrefix}adProgress`,
  ADBUFFERING: `${evPrefix}adBuffering`,
  FIRSTQUARTILE: `${evPrefix}firstQuartile`,
  MIDPOINT: `${evPrefix}midpoint`,
  THIRDQUARTILE: `${evPrefix}thirdQuartile`,
  COMPLETE: `${evPrefix}complete`,
  USERCLOSE: `${evPrefix}userClose`,
  LINEARCHANGED: `${evPrefix}linearChanged`,
  LOADED: `${evPrefix}loaded`,
  ADCANPLAY: `${evPrefix}adCanPlay`,
  ADMETADATA: `${evPrefix}adMetadata`,
  ADBREAKREADY: `${evPrefix}adBreakReady`,
  INTERACTION: `${evPrefix}interaction`,
  ALLADSCOMPLETED: `${evPrefix}allAdsCompleted`,
  SKIP: `${evPrefix}skip`,
  SKIPPABLESTATECHANGED: `${evPrefix}skippableStateChanged`,
  LOG: `${evPrefix}log`,
  VIEWABLE_IMPRESSION: `${evPrefix}viewable_impression`,
  VOLUMECHANGE: `${evPrefix}volumeChange`,
  MUTE: `${evPrefix}mute`,
  ERROR: `${evPrefix}error`, // custom event parset from LOG event includes AdError data

  HASPOSTROLL: `${evPrefix}has-postroll`, // if player has postrolls - need for IMA
});

export default adEvents;

/*
RRa - is this needed?
EVENTS TIMING

adMetadata
contentPauseRequested

1 AD {
  loaded
  adCanPlay
  impression
  start
  adProgress ...
  firstQuartile
  midpoint
  thirdQuartile
  complete
}

contentResumeRequested
*/

/* EVENTS KEYS SORTED
ADBREAKREADY
ADBUFFERING
ADCANPLAY
ADMETADATA
ADPROGRESS
ALLADSCOMPLETED
CLICK
COMPLETE
CONTENTPAUSEREQUESTED
DURATIONCHANGE
ERROR
EXPANDEDCHANGED
FIRSTQUARTILE
IMPRESSION
INTERACTION
LINEARCHANGED
LOADED
LOG
MIDPOINT
MUTE
PAUSE
RESUME
SKIP
SKIPPABLESTATECHANGED
START
THIRDQUARTILE
USERCLOSE
VIEWABLE_IMPRESSION
VOLUMECHANGE
*/

/* ADPROGRESS EVENT INCLUDES
{
  adPosition: 1
  companions: []
  currentTime: 0.22327
  duration: 19.947
  totalAds: 1
}
*/

/* SOME AD EVENT INCLUDES
{
  adId: "7b4a3459-746c-4c3d-ae96-2b7a4f44d115"
  adPodInfo: {podIndex: 0, timeOffset: 0, totalAds: 1, adPosition: 1, isBumper: false, …}
  adQueryId: "859540011"
  adSystem: "iBillboard"
  adWrapperCreativeIds: []
  adWrapperIds: []
  adWrapperSystems: []
  advertiserName: ""
  apiFramework: null
  clickThroughUrl: "http://iprima.cz"
  companions: []
  contentType: "video/mp4"
  creativeAdId: ""
  creativeId: "97146aa1-3d48-454a-9761-c6e0355c6a27"
  dealId: ""
  description: "Test description"
  disableUi: false
  duration: 20
  height: 0
  linear: true
  mediaUrl: "https://prima-ott.ssl.cdn.cra.cz/vod/0025/1776/cze-sd1-Mw5kZi6w.mp4"
  minSuggestedDuration: 20
  skipTimeOffset: 2
  skippable: true
  surveyUrl: null
  title: "Https"
  traffickingParameters: ""
  uiElements: []
  universalAdIdRegistry: "unknown"
  universalAdIdValue: "unknown"
  universalAdIds: []
  vastMediaBitrate: 400
  vastMediaHeight: 780
  vastMediaWidth: 720
  vpaid: false
  width: 0
}
*/
