/**
 * Play source module
 * @module continue-watching
 */

import is from 'is_js';
import log from './log';
import { CW_ARGS_NUMBER_CHECK } from './errors';

/**
 * Try to continue watching. Call this module before play method
 *
 * @param {Object} player - The player instance
 * @param {Number} [time = 0] - A continue watching time
 */
const continueWatching = function continueWatching(player, time = 0) {
  if (is.not.number(time)) {
    log.error(CW_ARGS_NUMBER_CHECK.code, CW_ARGS_NUMBER_CHECK.message, time);
    // Any error necessary. Just play stream from beginning..
  }

  // Unfortunately, "CW" must be initialized on the "playing" event, because safari when using DASH is not able to respond to anything else (if they have previously played ads).
  player.one('playing', () => {
    // when player in playlist mode, not change current time
    if (!player.playlist) {
      player.currentTime(time);
      player.trigger('cw-seeked');
      log('Player start time is set to: ', time);
    }
  });
};

export default continueWatching;
