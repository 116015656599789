import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Quality measurement heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityMeasurementHeading extends Button {
  /**
   * Create the Button's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      className: 'vjs-an-heading vjs-optout-button vjs-settings-heading vjs-copy-clipboard-header',
      innerHTML: `<span>${this.player_.localize(
        'Measurement of playback quality',
      )}</span><span class="vjs-icon-info"></span>`,
    });
  }

  hideSettingsMenuPlugin() {
    const menu = this.player_.getChild('SettingsMenu');
    if (typeof menu !== 'undefined') {
      if (!menu.hasClass('vjs-hidden')) {
        menu.addClass('vjs-hidden');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }

  handleClick() {
    const cssClass = 'vjs-quality-measurement-showing';
    if (this.player_.hasClass(cssClass)) {
      this.player_.removeClass(cssClass);
      this.player_.play();
    } else {
      this.player_.addClass(cssClass);
      this.player_.pause();
    }
    this.hideSettingsMenuPlugin();
  }
}
Component.registerComponent('QualityMeasurementHeading', QualityMeasurementHeading);
export default QualityMeasurementHeading;
