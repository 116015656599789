import { videojs, window } from 'global/window';
import hideOpenMenus from '../utils/hide-menus';
import log from '../log';

const Component = videojs.getComponent('Component');

class StillWatching extends Component {
  constructor(player, opts) {
    super(player, opts);
    this.player = player;

    // creates public stillwatching function
    player.stillWatching = () => {
      if (!player.paused()) {
        player.pause();
      }
      hideOpenMenus(player);
      player.addClass('vjs-still-watching');
      player.trigger('ott-still-watching');
      log('[still-watching] ott-still-watching triggered');
    };

    window.hideContinueWatchingOverlay = () => {
      this.player_.removeClass('vjs-still-watching');
    };

    window.continueWatching = () => {
      window.hideContinueWatchingOverlay();
      this.player_.play();
      player.trigger('ott-still-watching-continue');
      log('[still-watching] ott-still-watching-continue triggered');
    };
  }

  createEl() {
    const dialog = `
      <div class="vjs-still-watching-overlay-inner">
        <p class="dialog-title">${this.player_.localize('Are you still watching?')}</p>
        <button onclick="continueWatching()">${this.player_.localize('Continue watching')}</button>
        <a onclick="hideContinueWatchingOverlay()">${this.player_.localize('Back')}</a>
      </div>
    `;

    return super.createEl('div', {
      className: 'vjs-still-watching-overlay',
      innerHTML: dialog,
    });
  }
}

videojs.registerPlugin('StillWatching', StillWatching);
export default StillWatching;
