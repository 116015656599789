import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistIndexDisplay extends Component {
  createEl() {
    const { playlistData } = this.player_.playlist;
    return super.createEl('div', {
      className: 'vjs-playlist-index-display',
      innerHTML: `<span>${playlistData.actualIndex + 1} / ${playlistData.totalLength}</span>`,
    });
  }
}

Component.registerComponent('PlaylistIndexDisplay', PlaylistIndexDisplay);
export default PlaylistIndexDisplay;
