import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import ProductPlacement from './product-placement/component';
/* eslint-enable */

/**
 * ProductPlacement plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 */
export const productPlacementPlugin = function productPlacementPlugin(opts) {
  const player = this;

  const initProductPlacement = function initProductPlacement() {
    this.addChild(new ProductPlacement(player, opts));
  };

  // init only when isEnabled is true;
  if (opts.isEnabled) {
    player.one('loadedmetadata', initProductPlacement);
    log('product placement plugin initialized');
  }
};
