import availableEvents from '../../measuring/shared/available-events';
import omidSessionClient from './omid-session-client-v1';
import log, { isLoggingEnabled } from '../../../log';
import { videoPlayerState, creativeType } from './constants';

const { OmidSessionClient: sessionClient } = omidSessionClient;

const { AdSession } = sessionClient;

const Plugin = videojs.getPlugin('plugin');

const CONTENT_URL = window.location.href || null;
const PARTNER_NAME = 'Etneteracz';
export class OMIDManager extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.options = options;

    log('[OMID]: OMID MANAGER INICIALIZED');
  }

  createContext = (ad, type, serviceWindow, slot) => {
    const partner = new sessionClient.Partner(PARTNER_NAME, this.player.VERSION);
    const { accessMode } = this.options;

    const verificationResources = ad.adVerifications.map(
      (verification) =>
        new sessionClient.VerificationScriptResource(
          verification.resource,
          verification.vendor,
          verification.parameters,
          accessMode,
        ),
    );

    if (isLoggingEnabled()) {
      const validationScriptUrl =
        'https://s3-us-west-2.amazonaws.com/omsdk-files/compliance-js/omid-validation-verification-script-v1.js';
      const validationVendorKey = 'iabtechlab.com-omid';
      const validationParams = 'iabtechlab';

      const validationResources = new sessionClient.VerificationScriptResource(
        validationScriptUrl,
        validationVendorKey,
        validationParams,
        accessMode,
      );
      verificationResources.push(validationResources);
    }

    const context = new sessionClient.Context(partner, verificationResources, CONTENT_URL);

    context.underEvaluation = !!this.options.underEvaluation;
    context.setServiceWindow(serviceWindow);

    if (type === 'linear') {
      context.setVideoElement(this.player.children_[0]);
    }

    if (type === 'nonlinear') {
      context.setSlotElement(slot || document.querySelector('.vjs-ad-overlay'));
    }

    return context;
  };

  createAdSession = (context, type) => {
    const adSession = new AdSession(context);

    if (type === 'linear') {
      adSession.setCreativeType(creativeType.VIDEO);
      adSession.setImpressionType('beginToRender');
    }

    if (type === 'nonlinear') {
      adSession.setCreativeType(creativeType.HTML_DISPLAY);
      adSession.setImpressionType('loaded');
    }

    if (adSession.isSupported()) {
      adSession.start();
      return adSession;
    }
    return undefined;
  };

  handleEvents = (ad, adSession, type) => {
    let adEvents;
    let mediaEvents;

    try {
      adEvents = new sessionClient.AdEvents(adSession);
      mediaEvents = new sessionClient.MediaEvents(adSession);
    } catch (err) {
      log('[OMID] - problem with creating adEvents or mediaEvents. The service script is probably missing.');
      return;
    }

    if (type === 'linear') {
      adEvents.loaded();
      adEvents.impressionOccurred();
    }

    // TODO: volume is already calculate in in data.volume for VOLUME_CHANGED
    // or in data.videoInfo.volume for AD_PLAY
    const getVolume = () => (this.player.muted() ? 0 : this.player.volume());

    let isBuffering = false;

    /* eslint-disable no-use-before-define */
    const linearEventHandlers = {
      [availableEvents.BUFFER]: () => {
        isBuffering = true;
        mediaEvents.bufferStart();
        log('[OMID] - bufferStart');
      },
      [availableEvents.AD_LOADED]: () => {
        const { skipDelay } = ad.creatives[0];
        const isSkippable = !!skipDelay;
        const isAutoplay = this.player.autoplay();
        const position = this.player.adstate.currentAdtype;

        const vastProperties = new sessionClient.VastProperties(
          isSkippable,
          skipDelay,
          isAutoplay,
          position, // 'preroll' | 'midroll' | 'postroll'
        );

        adEvents.loaded(vastProperties);
        log('[OMID] - loaded');
      },
      [availableEvents.AD_PLAY]: () => {
        const { duration } = ad.creatives[0];

        if (isBuffering) {
          isBuffering = false;
          mediaEvents.bufferFinish();
        }

        // if the current time is bigger than 0, it's resume
        if (this.player.currentTime() > 0) {
          mediaEvents.resume();
          log('[OMID] - resume');
          return;
        }

        mediaEvents.start(duration, getVolume());
        log('[OMID] - start');
      },
      [availableEvents.AD_PAUSE]: () => {
        const duration = this.player.durationOfActiveMedia();
        const currentTime = this.player.currentTime();

        // pause can be emitted when ad ends
        if (duration > currentTime) {
          mediaEvents.pause();
          log('[OMID] - pause');
        }
      },
      [availableEvents.SKIP]: () => {
        mediaEvents.skipped();
        log('[OMID] - skipped');

        unregisterEvents();
        log('[OMID] - complete');

        adSession.finish();
        log('[OMID] - finish');
      },
      [availableEvents.AD_FIRSTQUARTILE]: () => {
        mediaEvents.firstQuartile();
        log('[OMID] - firstQuartile');
      },
      [availableEvents.AD_FIRSTQUARTILE]: () => {
        mediaEvents.firstQuartile();
        log('[OMID] - firstQuartile');
      },
      [availableEvents.AD_MIDPOINT]: () => {
        mediaEvents.midpoint();
        log('[OMID] - midPoint');
      },
      [availableEvents.AD_THIRDQUARTILE]: () => {
        mediaEvents.thirdQuartile();
        log('[OMID] - thirdQuartile');
      },
      [availableEvents.AD_COMPLETE]: async () => {
        mediaEvents.complete();

        unregisterEvents();
        log('[OMID] - complete');

        adSession.finish();
        log('[OMID] - finish');
      },
      [availableEvents.VOLUME_CHANGED]: () => {
        mediaEvents.volumeChange(getVolume());
        log(`[OMID] - volumeChange - ${getVolume()}`);
      },
      [availableEvents.FULLSCREEN_CHANGED]: (data) => {
        mediaEvents.playerStateChange(data.isFullscreen ? videoPlayerState.FULLSCREEN : videoPlayerState.NORMAL);
      },
      [availableEvents.LINEAR_CLICK_THROUGH]: () => {
        mediaEvents.adUserInteraction('click');
        log('[OMID] - adUserInteraction - click');
      },
    };

    const nonlinearEventHandlers = {
      [availableEvents.OVERLAY_CLICK_THROUGH]: () => {
        mediaEvents.adUserInteraction('click');
        log('[OMID] - adUserInteraction - click');
      },
      // TODO: remove when events are consolidated
      overlayended: () => {
        unregisterEvents();
        adSession.finish();
      },
      [availableEvents.OVERLAY_ENDED]: () => {
        unregisterEvents();
        log('[OMID] - complete');

        adSession.finish();
        log('[OMID] - finish');
      },
    };

    const eventHandlers = type === 'linear' ? linearEventHandlers : nonlinearEventHandlers;

    const registerEvents = () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        this.player.on(event, handler);
      });
    };

    const unregisterEvents = () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        this.player.off(event, handler);
      });
    };
    /* eslint-enable no-use-before-define */

    registerEvents();

    // when all of the ads from the block are over, remove any remaining
    // omid iframes, wait for 3 secs to give it enough time for anything
    // it can be doing before shut own
    this.player.one(availableEvents.ADS_BLOCK_END, () => {
      setTimeout(() => {
        const omidIframes = [...document.querySelectorAll('iframe')].filter((iframe) =>
          iframe.id.includes('omid-verification-script-frame-'),
        );

        omidIframes.forEach((iframe) => {
          log(`[OMID]: removing iframe with id ${iframe.id}`);
          iframe.remove();
        });
      }, 3000);
    });
  };

  initAd({ ad, type, slot, hasSimid }) {
    if (!ad.adVerifications || (ad.adVerifications && ad.adVerifications.length === 0)) {
      return;
    }
    log('[OMID]: ad initialized');

    if (type === 'nonlinear' && hasSimid) {
      const createOmsdkIframe = () => {
        const iframe = document.createElement('iframe');
        iframe.sandbox = 'allow-scripts allow-same-origin';
        iframe.style.display = 'none';
        iframe.id = `omid-verification-script-frame-${new Date().now()}`;
        iframe.srcdoc = `<script src="${
          this.player.options.omidManager.serviceScript || 'https://player.ssl.cdn.cra.cz/js/omweb-v1.js'
        }"></script>`;
        return iframe;
      };

      const omsdkIframe = createOmsdkIframe();

      const omsdkIframeDidLoad = () => {
        const context = this.createContext(ad, type, omsdkIframe.contentWindow, slot);
        const adSession = this.createAdSession(context, type);
        this.handleEvents(ad, adSession, type);
      };

      omsdkIframe.addEventListener('load', () => omsdkIframeDidLoad());
      document.body.appendChild(omsdkIframe);
    } else {
      const context = this.createContext(ad, type, window.top, slot);
      const adSession = this.createAdSession(context, type);
      this.handleEvents(ad, adSession, type);
    }
  }
}
