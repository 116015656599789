import is from 'is_js';
import log from '../log';

export const mobileControls = function mobileControls(options) {
  if (is.mobile()) {
    log('[mobile-controls] is mobile');
    if (options.inactivityTimeout) {
      this.options_.inactivityTimeout = options.inactivityTimeout;
    }
    this.player_.addClass('vjs-is-on-mobile');
  } else {
    log('[mobile-controls] no mobile');
  }
};
