/**
 * <p>Register functionality needed by video advertisement (VAST)</p>
 *
 * <h4>Linear ads:</h4>
 * <b>readyforpreroll</b> - Before each preroll ad will be played
 * <b>nextmidroll%ID%</b> {Number} cue (Index of midrolls breakpoint) - Before each midroll ad will be played
 * <b>postrollready</b> - Before each postroll ad will be played
 * <b>prerollended</b> - Fired when all prerolls are played already
 * <b>midrollended%ID%</b> {Number} cue (Index of midrolls breakpoint) - Fired when all midrolls are played already
 * <b>postrollended</b> - Fired when all postrolls are played already
 * <b>linearclickthrough</b> - User clicked on the linear ad screen
 * <b>adended</b> {Boolean=} skipped (skipped available when user click on the skip button) - Fired on every linear ad end
 *
 * <h3>Custom events tracking:</h3>
 * <h4>Companion:</h4>
 * <b>companionclickthrough</b> - When user clicked on the companion
 * <b>companionended</b> {Boolean=} skipped (skipped available when user click on the close button) - When companion ad ending
 * <b>companionstarted</b> {Number} height (Window height, overflow-y) - Fired when companion started
 *
 * <h4>Overlay:</h4>
 * <b>overlayclickthrough</b> - When user clicked on the overlay
 * <b>overlayended</b> {Boolean=} skipped (skipped available when user click on the close button) - When overlay ad ending
 *
 * <h4>Sklik:<h4>
 * <b>sklikstarted</b> - Fired after sklik show function call
 * <b>ended</b> - Fired after sklik remove function call
 *
 * <h4>Ads requesting:</h4>
 * <b>prerollnotreturned</b> - No preroll returned after request
 * <b>midrollnotreturned%ID%</b> {Number} cue (Index of midrolls breakpoint) - No midroll returned after request
 * <b>nopostroll</b> - No postroll returned after request
 * <b>overlaynotreturned%ID%</b> {Number} cue (Index of cascade breakpoint) - No overlay returned after request
 * <b>companionnotreturned%ID%</b> {Number} cue (Index of cascade breakpoint) - No companion returned after request
 *
 * @example
{
adService: {
  linear: {
    preRoll: {
      vasts: ['http://example.com/vast1.xml', 'http://example.com/vast2.xml']
    },
    midRolls: [
      {
        time: 60,
        vasts: ['http://example.com/vast1.xml', 'http://example.com/vast2.xml']
      }, {
        time: 120,
        vasts: ['http://example.com/vast1.xml', 'http://example.com/vast2.xml']
      }, {
        time: 180,
        vasts: ['http://example.com/vast1.xml', 'http://example.com/vast2.xml']
      }
    ],
    postRoll: {
      vasts: ['http://example.com/vast1.xml', 'http://example.com/vast2.xml']
    }
  },
  cascade: [
    {
      time: 70,
      overlay: {
        vast: 'http://example.com/vast.xml'
      },
      companion: {
        vast: 'http://example.com/vast.xml'
      },
      fallback: ['sklik']
    }, {
      time: 130,
      overlay: {
        vast: 'http://example.com/vast.xml'
      },
      companion: {
        vast: 'http://example.com/vast.xml'
      },
      fallback: ['sklik']
    }, {
      time: 190,
      overlay: {
        vast: 'http://example.com/vast.xml'
      },
      companion: {
        vast: 'http://example.com/vast.xml'
      },
      fallback: ['sklik']
    }
  ]
}
}
 *
 * @module plugins/adService
 */
import window from 'global/window';
import isMobile from 'ismobilejs';
import log, { isLoggingEnabled } from '../log';
import {
  AS_COMPANION_MIN_WIDTH,
  AS_OVERLAY_MIN_WIDTH,
  AS_SKIP_DELAY_MODE,
  AS_SKIP_DELAY_FALLBACK_TIME,
  AS_PREFERRED_FORMAT,
  AS_LINEAR_PREMIUM_MODE,
  AS_LINEAR_PREMIUM_TARGET,
  AS_LINEAR_PREMIUM_POSITION,
  AS_LINEAR_PRIOR_NOTIFY_TIME,
  AS_LINEAR_PRIOR_NOTIFY_MODE,
  AS_LINEAR_WATCH_TRESHOLD,
  EVT_NOPREROLL,
  EVT_NOPOSTROLL,
  PLAYER_AUTOPLAY,
} from '../constants';
import { extend } from '../utils/object-helpers';
import AdsLinear from './adservice/linear';
import Cascade from './adservice/cascade';
import AdsOverlay from './adservice/overlay';
import AdsCompanion from './adservice/companion';
import AdsSklik from './adservice/sklik';
import { showPriorNotification } from './adservice/shared/settings-helper';
import avlAdType from './adservice/shared/ad-type';
import requestAd from './adservice/shared/custom/request-ad';
import CustomMarkers from './adservice/shared/custom/ad-markers';
import getAvailableAdIndex from './adservice/shared/ad-availability';
import AntiAdBlock from './adservice/shared/anti-adblock';
import getInnerWidth from '../utils/inner-width';
import { updateVolumeControl } from '../utils/volume-helper';
import { isAdPlaying } from './adservice/shared/ad-state';
import { AS_ADERROR_EVENT_FIRED, AS_DASH_ADERROR_EVENT_FIRED } from '../errors';
import LinearWatchTreshold from './adservice/linear-watch-treshold';
import { isLiveNow } from '../utils/islive-check';
import './adservice/omid/omid-manager';
import SimidManager from './adservice/simid/simid-manager';
import { hasNonlinearSimid } from './adservice/simid/utils';
import { blockSeekDuringAds } from '../hotkeys';
import availableEvents from './measuring/shared/available-events';
import { CsaiEvents } from './adservice-csai/csai-events';
import { AutoPause } from './autoPause';

/**
 * Pre-Roll initialization object
 * @typedef PreRollObj
 * @type Object
 * @property {Array.<String>} vasts - Array of VAST URLs
 */

/**
 * Mid-Roll initialization object
 * @typedef MidRollObj
 * @type Object
 * @property {Number} time          - Time when Mid-Roll block should be called
 * @property {Array.<String>} vasts - Array of VAST URLs
 */

/**
 * Post-Roll initialization object
 * @typedef PostRollObj
 * @type Object
 * @property {Array.<String>} vasts - Array of VAST URLs
 */

/**
 * Cascade object
 * @typedef CascadeObj
 * @type Object
 * @property {Number} time                    - Time when Mid-Roll block should be called
 * @property {Object} overlay                 - Overlay initialization object. Contains array of vasts
 * @property {Array.<String>} overlay.vasts   - Array of VAST URLs
 * @property {Object} companion               - Companion initialization object. Contains array of vasts
 * @property {Array.<String>} companion.vasts - Array of VAST URLs
 */

/**
 * VPAID settings
 * @typedef VPAIDObj
 * @type Object
 * @property {Number}  [bitrate = -1]    - Optional value (default is -1). Indicates the desired bitrate as number for kilobits per second (kbps).
 * The ad unit may use this information to select appropriate bitrate for any streaming content.
 * @property {String}  friendlyIframeID  - Wrapper for the FIF. The VPAID ad unit is rendered within a friendly IFRAME (FIF) and can access the DOM
 * of the page in which the player is rendered.
 * @property {Number}  [timeout = 5000]  - Optional timeout for the returning ad unit
 * @property {Number}  [zIndex = 10]     - zIndex css value for the VPAID ad element
 */

/**
 * Ad service settigns
 * @typedef AdSettingsObj
 * @type Object
 * @property {Object}  companion                      - Companion ad settings
 * @property {Number}  companion.minWidth             - Min width of window for companion ads.
 * @property {Object}  overlay                        - Overlay ad settings
 * @property {Number}  overlay.minWidth               - Min width of window for overlay ads.
 * @property {Object}  linear                         - Linear ad settings
 * @property {Number}  linear.skipDelay               - Skip ad button settings
 * @property {Number}  linear.skipDelay.mode          - [disabled, adsCounter, adCounter, pure, countdown=default, adsCountdown]
 * @property {Number}  linear.skipDelay.time          - If VAST does not contain skip delay and ad duration is longer than this value. It will be possible to skip it
 * @property {String}  linear.preferredFormat         - Used when current tech doesn't match any media source in the VAST
 * @property {Object}  linear.premium                 - Premium plan promo settings
 * @property {String}  linear.premium.mode            - [disabled=default, icon, text, button]
 * @property {String}  linear.premium.target          - Show premium url in new tab when '_blank' is set
 * @property {String}  linear.premium.url             - Link for the premium plan promo page
 * @property {String}  linear.premium.position        - [left, right=default, middle]
 * @property {Object}  linear.priorNotification       - Notify about upcoming linear block
 * @property {String}  linear.priorNotification.mode  - [diabled=default, countdown, adsCountdown, pure]
 * @property {Number}  linear.priorNotification.time  - Notify about upcoming linear block at VAST.time - this value
 * @property {Number}  linear.watchTreshold           - The number of seconds the user has to watch at normal playback speed after one ad block is played to play the next one.
 * @param {module:plugins/adService~VPAIDObj} linear.vpaid
 */

/**
 * Ads service plugin initialization - functionality needed by video advertisement (VAST)
 *
 * @param {Object} options                                                - Plugin initialization object
 * @param {module:plugins/adService~CascadeObj} options.cascade           - Cascade wrapper Array. Contains array of overlay, cascade, .. ads.
 * @param {Object} options.linear                                         - Linear wrapper object. Contains linear ads (Pre-Roll, Mid-Roll and Post-Roll) settings
 * @param {module:plugins/adService~PreRollObj} options.linear.preRoll
 * @param {module:plugins/adService~MidRollObj[]} options.linear.midrollss
 * @param {module:plugins/adService~PostRollObj} options.linear.postRoll
 * @param {module:plugins/adService~AdSettingsObj} options.settings
 */
export const adServicePlugin = function adServicePlugin(options = {}) {
  const player = this; // Preserve player object

  if (options.omidManager) {
    const serviceScript = document.createElement('script');

    serviceScript.src = options.omidManager.serviceScript || 'https://player.ssl.cdn.cra.cz/js/omweb-v1.js';
    document.querySelector('body').appendChild(serviceScript);
    player.omidManager({ accessMode: options.omidManager.accessMode });
  }

  player.viewableImpressionTracker();

  const defaultSettings = {
    companion: {
      minWidth: AS_COMPANION_MIN_WIDTH,
    },
    overlay: {
      minWidth: AS_OVERLAY_MIN_WIDTH,
    },
    linear: {
      // defaultSkipDelay is not set by default
      skipDelay: {
        mode: AS_SKIP_DELAY_MODE,
        time: AS_SKIP_DELAY_FALLBACK_TIME,
      },
      preferredFormat: AS_PREFERRED_FORMAT,
      premium: {
        mode: AS_LINEAR_PREMIUM_MODE,
        target: AS_LINEAR_PREMIUM_TARGET,
        position: AS_LINEAR_PREMIUM_POSITION,
      },
      priorNotification: {
        time: AS_LINEAR_PRIOR_NOTIFY_TIME,
        mode: AS_LINEAR_PRIOR_NOTIFY_MODE,
      },
      watchTreshold: AS_LINEAR_WATCH_TRESHOLD,
    },
  };

  const settings = extend(true, defaultSettings, options.settings);

  // Create advertisement container object in player instance
  const adstate = {
    adPlaying: false,
    currentAdtype: '',
    currentMidrollIndex: -1,
    inventory: {},
    seekTime: -1,
    availableAdType: avlAdType,
    subtitlesShow: false,
    subtitlesLang: '',
  };
  player.adstate = adstate;

  let antiAdBlock;

  const registerCheckAdBlock = function registerCheckAdBlock() {
    player.on('ready', () => {
      if (typeof antiAdBlock !== 'undefined') {
        antiAdBlock.check(['html'], {
          http: {
            baitMode: 'import',
            baitUrl: antiAdBlock.customBaitUrl,
          },
        });
        log('[adservice] call antiAdBlock check function');
      }
    });
  };

  if (options.antiAd) {
    antiAdBlock = new AntiAdBlock(player, options.antiAd);
    // this.antiAdBlock.asyncCheck();
    registerCheckAdBlock();
  }

  // block seeking during ads
  player.on('keydown', (e) => blockSeekDuringAds(player, e));

  // HACK BPo: Player doesn't update volume component when ad is playing
  player.on(['adcanplay', 'advolumechange'], () => updateVolumeControl(this));

  /**
   * Ads linear class instance
   * @instance
   */
  const adsLinear = new AdsLinear(adstate, player, settings);

  /**
   * Ads companion class instance
   * @instance
   */
  const adsCompanion = new AdsCompanion(adstate, player);

  /**
   * Ads companion class instance
   * @instance
   */
  const adsOverlay = new AdsOverlay(adstate, player);

  /**
   * Sklik class instance
   * @instance
   */
  const adsSklik = new AdsSklik(adstate, player);

  /**
   * Linear watching treshold intstance. Reset it every time when midroll not returned, or ended!
   * @instance
   */
  const linearWatchTreshold = new LinearWatchTreshold(player, settings.linear.watchTreshold);

  /**
   * Parse linear/cascade blocks from options
   *
   * @param {Object} options                                                - Plugin initialization object
   * @param {Object} options.linear                                         - Linear wrapper object. Contains linear ads (Pre-Roll, Mid-Roll and Post-Roll) settings
   * @param {module:plugins/adService~PreRollObj} options.linear.preRoll
   * @param {module:plugins/adService~MidRollObj[]} options.linear.midrollss
   * @param {module:plugins/adService~PostRollObj} options.linear.postRoll
   * @param {module:plugins/adService~CascadeObj} options.cascade           - Cascade wrapper Array. Contains array of overlay, cascade, .. ads.
   */
  function createInventory(options) {
    // reset the inventory
    adstate.inventory = {
      preroll: {},
      midrolls: [],
      postroll: {},
      cascade: [],
    };

    // parse linear ads
    if (options.linear) {
      // Parse preroll
      const { preroll, midrolls, postroll, csaiMidrolls } = options.linear;
      if (Array.isArray(preroll?.vasts) && preroll.vasts.length > 0) {
        adstate.inventory.preroll = adsLinear.getEmptyObject(preroll);
      } else {
        log("[adservice-custom] Initialization data doesn't contain any preroll ads.");
        player.trigger(EVT_NOPREROLL);
      }

      if (csaiMidrolls) {
        adstate.inventory.csaiMidrolls = adsLinear.getEmptyObject(csaiMidrolls);
      } else {
        // parse midrolls
        if (Array.isArray(midrolls) && midrolls.length > 0) {
          adstate.inventory.midrolls = midrolls
            .filter((midroll) => Array.isArray(midroll.vasts) && midroll.vasts?.length > 0)
            .map((midroll) => adsLinear.getMidrollObject(midroll));
        } else {
          log("[adservice-custom] Initialization data doesn't contain any midroll ads.");
        }

        // Parse postroll
        if (Array.isArray(postroll?.vasts) && postroll.vasts?.length > 0) {
          adstate.inventory.postroll = adsLinear.getEmptyObject(postroll);
        } else {
          log("[adservice-custom] Initialization data doesn't contain any postroll ads.");
        }
      }
    }

    if (options.cascade) {
      // Parse cascade
      const cascadeAds = (options || {}).cascade || [];
      if (Array.isArray(cascadeAds) && cascadeAds.length > 0) {
        adstate.inventory.cascade = cascadeAds.map((item) => Cascade.getEmptyObject(item));
      } else {
        log("[adservice-custom] Cascade initialization data doesn't contain any ads.");
      }
    }

    log('[adservice-custom] Inventory already generated: ', adstate.inventory);
  }

  /**
   * Play midroll or cascade when opportunity occurs
   */
  const breakOpportunityWatching = function breakOpportunityWatching() {
    /**
     * Register midroll ready listeners and request midroll ads
     *
     * @param {Int} selectedBreak - Selected break index
     */
    function requestMidroll(selectedBreak) {
      if (!linearWatchTreshold.isAdAvailable()) {
        adstate.inventory.midrolls.forEach((ad) => {
          if (ad.time < player.currentTime()) {
            ad.skipped = true;
          }
        });
        log('[adservice-custom] Midroll at 1 position is skipped. Watching threshold was not exhausted.');
        return;
      }

      // bind event for midroll ready
      player.one(`midrollready${selectedBreak}`, (e) => {
        log(`First midroll event fired: ${e.cue}`);

        function playMidroll() {
          // check if AutoPause is enabled and not disposed
          if (player.options_.plugins?.autoPause?.beforeMidroll && !AutoPause.isDisposed) {
            // send AutoPause event, that is handled in the AutoPause plugin
            player.trigger('ott-autopause-start');
            // if AutoPause is still ready, pause the player
            player.pause();
            // "playing" event here is necessary
            player.one('playing', () => {
              // Store current midroll index in the player state
              player.adstate.currentMidrollIndex = e.cue;
              adsLinear.playAd(avlAdType.midrolls, e.cue);
            });
          } else {
            // Store current midroll index in the player state
            player.adstate.currentMidrollIndex = e.cue;
            adsLinear.playAd(avlAdType.midrolls, e.cue);
          }
        }

        // eslint-disable-next-line no-bitwise
        if (showPriorNotification(settings) && !~player.adstate.seekTime) {
          adsLinear.showAdNotification(avlAdType.midrolls, e.cue).then(
            () => playMidroll(),
            (rejectReason) => log(rejectReason),
          );
        } else {
          playMidroll();
        }
      });

      // bind event for next midroll for current break
      player.on(`nextmidroll${selectedBreak}`, (e) => {
        log(`Next midroll event fired:ß + ${e.cue}`);
        // Store current midroll index in the player state
        player.adstate.currentMidrollIndex = e.cue;
        adsLinear.playAd(avlAdType.midrolls, e.cue);
      });

      // request midroll ads
      requestAd(player, avlAdType.midrolls, selectedBreak);
    }

    /**
     * Request cascade block by an array index
     *
     * @param {Int} selectedBreak - Selected break index
     */
    const requestCascade = function requestCascade(selectedBreak) {
      // Request non linear ad when fullscreen is set to false
      if (!player.isFullscreen() && getInnerWidth(player) >= settings.companion.minWidth && !isMobile.any) {
        requestAd(player, avlAdType.companion, selectedBreak);
      } else if (window.innerWidth >= settings.overlay.minWidth) {
        requestAd(player, avlAdType.overlay, selectedBreak);
      }
    };

    // setup seek value
    player.on('seeking', () => {
      if (!adstate.adPlaying) {
        adstate.seekTime = player.currentTime();
      }
    });

    const checkBreakOnTick = () => {
      if (!adstate.adPlaying) {
        if (!player.paused()) {
          // get current time and seek time
          const checkTime = {
            checkCT: player.currentTime(),
            checkST: player.adstate.seekTime,
          };
          const hasPriorNotification = showPriorNotification(settings);

          if (hasPriorNotification) {
            checkTime.checkCT += settings.linear.priorNotification.time;
          }

          // Check ad availability
          let idx = getAvailableAdIndex(
            player,
            adstate,
            adstate.inventory.midrolls,
            checkTime,
            hasPriorNotification ? settings.linear.priorNotification.time : 0,
          );
          if (idx >= 0) {
            requestMidroll(idx);
          } else {
            if (hasPriorNotification) {
              checkTime.checkCT -= settings.linear.priorNotification.time;
            }
            // Check cascade ads availability
            idx = getAvailableAdIndex(player, adstate, adstate.inventory.cascade, checkTime);
            if (idx >= 0) {
              requestCascade(idx);
            }
          }
        }
      }
    };

    // Run midroll control on the second seeked event execution (when continue watching is forced).
    if (player.options_.startTime) {
      player.one('seeked', () => {
        adstate.seekTime = -1;
        // Mark as skipped all midroll blocks before the continue watching time.
        adstate.inventory.midrolls.forEach((ad) => {
          if (ad.time < player.options_.startTime) {
            ad.skipped = true;
          }
        });
        adstate.checkInt = player.setInterval(checkBreakOnTick, 100);
      });
    } else {
      adstate.checkInt = player.setInterval(checkBreakOnTick, 100);
    }
  };

  /**
   * Register postroll watcher
   */
  const registerPostrollWatching = function registerPostrollWatching() {
    const showPostroll = () => {
      player.on('postrollready', () => {
        if (adstate.inventory.postroll.played < adstate.inventory.postroll.ads.length) {
          adsLinear.playAd(avlAdType.postroll);
        }
      });

      // Request postroll
      if (player.ads.isWaitingForAdBreak()) {
        requestAd(player, avlAdType.postroll);
      }
    };

    // setup
    player.on('readyforpostroll', () => {
      log('[adservice-custom] readyforpostroll fired. Postroll will be shown.');

      showPostroll();
    });

    player.on('ott-postroll-force', () => {
      log('[adservice-custom] Postroll forced by another plugin then adservice.');

      showPostroll();
    });
  };

  /**
   * Register event watching for cascade elements
   */
  const registerCascadeWatching = function registerCascadeWatching() {
    // Bind event for the nonlinear overlay ad content
    player.on('overlayready', ({ cue }) => {
      if (hasNonlinearSimid(adstate, cue)) {
        const simidManager = new SimidManager(player);
        simidManager.initializeAd(adstate.inventory.cascade[cue].overlay.ads[0], false);
        simidManager.playAd();
      } else {
        adsOverlay.showAd(cue);
      }
    });

    // Bind event for the companion ad content
    player.on('companionready', (e) => {
      adsCompanion.showAd(e.cue, adstate);
    });

    // When there is no companion try to show an overlay ad
    player.on('companionnotreturned', (e) => {
      // Show overlay when the screen innerWidth is big enough
      if (window.innerWidth >= settings.overlay.minWidth) {
        requestAd(player, avlAdType.overlay, e.cue);
      }
    });

    // When there is no overlay try to show sklik Ad
    player.on('overlaynotreturned', (e) => {
      adsSklik.showSklik(e.cue);
    });
  };

  const prerollInit = function prerollInit() {
    // Request preroll as soon as possible when is there no flash HLS fallback
    requestAd(player, avlAdType.preroll);
  };

  /**
   * Create live stream snapshot
   */
  const createLiveSnapshot = function createLiveSnapshot() {
    const liveSnapshot = {
      currentSrc: player.currentSrc(),
      currentTime: 0,
      ended: false,
      src: player.src(),
      suppressedRemoteTrack: [],
      suppressedTracks: [],
      info: 'This snapshot was generated only for live stream',
    };
    player.ads.snapshot = liveSnapshot;
    log('[adservice-custom] liveSnapshot created.', liveSnapshot);
  };

  /**
   * Initialize ads
   *
   * @constructor
   * @param {object} adsOptions - Hash of options for the ad plugin
   */
  const initializeAds = function initializeAds() {
    if (settings.linear.watchTreshold > 0) {
      player.on(['prerollended', 'prerollnotreturned', 'midrollended'], () => {
        linearWatchTreshold.setTreshold();
      });
    }

    player.on('loadedmetadata', () => {
      new CustomMarkers(player, player.adstate).reset();
    });

    const adsAutoplayRecoverEvents = ['loadedMetadata', 'adsready', 'canplay'];

    if (options.linear?.csaiMidrolls) {
      adsAutoplayRecoverEvents.push('ready');
    }
    player.one(adsAutoplayRecoverEvents, () => {
      if (isLiveNow(player) && !player.ads.snapshot) {
        createLiveSnapshot();
      }
      if (player.options_.autoplayAllowed) {
        player.play();
        player.options_.autoplay = PLAYER_AUTOPLAY;
      }
    });

    // Parse all x-roll blocks from options
    createInventory(options);

    // if it's csai, handle the metadata and notify the player when the ad should be played
    if (options.linear?.csaiMidrolls) {
      player.csai().initializeCsai(options.linear.csaiJingle);

      player.on(CsaiEvents.CSAI_LIVE_AD_STARTED, (e) => {
        // request the ad, it will trigger csai-midrolls-ready when vast will be downloaded
        requestAd(player, avlAdType.csaiMidrolls, undefined, e.adLength);

        // when the vast is ready, play the dd
        const playCsaiAd = () => adsLinear.playAd(avlAdType.csaiMidrolls);
        player.on(CsaiEvents.CSAI_MIDROLLS_READY, playCsaiAd);

        player.one(availableEvents.ADS_BLOCK_END, () => {
          // reset inventory
          createInventory(options);
          // remove id fro the last ad
          player.adstate.linearAdId = undefined;
          // remove playCsaiAd, because it is created with every live ad again
          player.off(CsaiEvents.CSAI_MIDROLLS_READY, playCsaiAd);
        });
      });
    }

    // Try to play next ad or restore player snapshot when ad error occurs
    player.on('aderror', () => {
      log.error(AS_ADERROR_EVENT_FIRED.code, AS_ADERROR_EVENT_FIRED.message);
      player.trigger('adended');
    });

    player.on('dasherror', (e) => {
      if (isAdPlaying(player) && e.error === 'download') {
        log.error(AS_DASH_ADERROR_EVENT_FIRED.code, AS_DASH_ADERROR_EVENT_FIRED.message);
        player.trigger('adended');
      }
    });

    // HACK BPo: Hide all reload buttons when player is changing sources
    player.addClass('vjs-reload-disabled');
    if (player.adstate.inventory.postroll.vasts) {
      player.one([EVT_NOPOSTROLL, 'postrollended'], () => {
        player.removeClass('vjs-reload-disabled');
      });
    } else {
      player.one('readyforpostroll', () => {
        player.removeClass('vjs-reload-disabled');
      });
    }

    // Duration and current time display don't change once ad plugin is enabled (default events are suspended).
    player.on('adtimeupdate', () => {
      if (player.controlBar.durationDisplay.formattedTime_ === '-:-') {
        player.controlBar.durationDisplay.updateContent();
      }
      if (!adsLinear.adsPlayer || !adsLinear.adsPlayer.adsPlayerVisible) {
        const playlistTimeDisplay = player.controlBar.getChild('PlaylistTimeDisplay');
        if (playlistTimeDisplay) {
          playlistTimeDisplay.playlistCurrentTimeDisplay.updateAdContent();
        } else {
          player.controlBar.getChild('CurrentTimeDisplay').updateContent();
        }
      }
    });

    player.on('adstart', () => {
      setTimeout(() => {
        if (!adsLinear.adsPlayer || !adsLinear.adsPlayer.adsPlayerVisible) {
          const { adsPlayer } = adsLinear;
          const playlistTimeDisplay = player.controlBar.getChild('PlaylistTimeDisplay');
          if (playlistTimeDisplay) {
            playlistTimeDisplay.playlistDurationDisplay.updateContent();
          } else {
            adsPlayer.updateDurationOfAd();
          }
        }
      }, 500);
    });

    player.on('adplay', () => {
      setTimeout(() => {
        const playlistTimeDisplay = player.controlBar.getChild('PlaylistTimeDisplay');
        const { adsPlayer } = adsLinear;
        if (playlistTimeDisplay) {
          // FIXME: need to find out why the duration value from the main video is at the time of the event call
          playlistTimeDisplay.playlistDurationDisplay.updateContent();
        } else {
          adsPlayer.updateDurationOfAd();
        }
      }, 100);
    });

    const defaultContribAdsOptions = {
      debug: isLoggingEnabled(),
      timeout: 5000,
      // we can wait little bit longer for the postroll
      postrollTimeout: 3000,
      liveCuePoints: false,
    };
    const customContribAdsOptions = options.contribAdsOptions || {};
    const contribAdsOptions = {
      ...defaultContribAdsOptions,
      ...customContribAdsOptions,
    };
    player.ads(contribAdsOptions);

    prerollInit();

    // Setup preroll opportunity
    player.on('readyforpreroll', () => {
      adsLinear.playAd(avlAdType.preroll);
    });

    // if (player.isLivePlayer) {
    // Register midroll/cascade opportunity watcher
    breakOpportunityWatching();
    // Register postroll opportunity watcher
    registerPostrollWatching();
    // }

    // FIXME BPo: static ads are not dependent on linear ads now...
    // Register static ad opportunity watcher
    registerCascadeWatching();
  };
  initializeAds();

  log('[adservice-custom] adService plugin initialized');
};
