/**
 * <p>Creates seekable vertical and horizontal bar.</p>
 *
 * <h3>Initialization</h3>
 * See the example below to understand how to initialize this plugin.
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-seekablelive-seek</b> - seekTime {Number} seeked time in seconds
 *
 * @example
{
  plugins: {
    thumbnails: {
      url: 'http://www.example.com/thumbnail.jpg',
    },
    urlParam: 'offsetSeconds',
    horizontalSec: 7200,    // 2h
    verticalSec: 165600,    // 42h
    horizontalInterval: 10, // 10sec
    seekTime: 7200,         // start at XY position in the past
    disabled: false,        // timeshift UI is off for user gestures
  }
}
 *
 * @module plugins/timeShift
 */
import is from 'is_js';
import log from '../log';
import * as TimeShiftState from './time-shift/ts-state';
import * as UrlHelper from '../utils/url';
import TimeShiftCancelButton from './time-shift/ts-cancel-button';
import LiveProgressControl from './time-shift/horizontal/live-progresscontrol';
import TimeShiftTimeDisplay from './time-shift/ts-time-display';
import LiveVerticalSeekBar from './time-shift/vertical/live-seekbar';
import { TS_ARGS_URLPAR_CHECK, TS_ARGS_H_NUMBER_CHECK, TS_ARGS_V_NUMBER_CHECK, TS_SEEK_INVALID_ARG } from '../errors';
import { changeTSSource, getTimeshiftURL } from '../utils/source';
import { EVT_NOPREROLL } from '../constants';
import propTest from '../property-tester';

/**
 * Returns instance of the controlbar
 *
 * @param {Object} player - The player object
 */
const getControlBar = function getControlBar(player) {
  let controlBar = null;
  player.children_.forEach((children) => {
    if (children.name_ === 'ControlBar') {
      controlBar = children;
    }
  });
  return controlBar;
};

/**
 * @typedef CancelBtnInitObj
 * @type Object
 * @property {String} cssClass - Button css class
 * @property {String} name - Localized name
 * @property {Object} controlBar - Main player control bar instance
 */

/**
 * Creates live progress control initialization object
 *
 * @param {Object} opts - Plugin initialization object
 * @returns {Object} Vertical bar initialization object
 */
const getLiveProgressControlInitObj = function getLiveProgressControlInitObj(opts) {
  return opts;
};

/**
 * Creates cancel button initialization object
 *
 * @param {Object} player     - The player object
 * @param {Object} controlBar - Main player control bar instance
 * @param {Object} opts - Plugin initialization object
 * @returns {module:plugins/timeShift~CancelBtnInitObj} Time shift cancel button init object
 */
const getCancelBtnInitObj = function getCancelBtnInitObj(player, controlBar, opts) {
  const target = {};
  Object.assign(target, opts, {
    cssClass: ['cancel-timeshifting', 'vjs-icon-close'],
    name: player.localize('cancel timeshifting'),
    controlBar,
  });
  return target;
};

/**
 * Creates vertical bar initialization object
 *
 * @param {Object} player     - The player object
 * @param {Object} opts       - Plugin initialization object
 * @returns {Object} Vertical bar initialization object
 */
const getVerticalBarInitObj = function getVerticalBarInitObj(player, opts) {
  const target = {};
  Object.assign(target, opts, {
    vertical: true,
  });
  return target;
};

const initTimeshift = function initTimeshift(player, seekTime) {
  // change source
  const currentSrc = {
    src: player.currentSrc(),
    type: player.currentType(),
  };
  player.one('contentchanged', () => {
    player.trigger(EVT_NOPREROLL);
  });
  const src = getTimeshiftURL(currentSrc, player.options_.tracks, player);
  changeTSSource(player, src, seekTime);
};

const seekInCurrentTimeshiftInterval = function seekInCurrentTimeshiftInterval(player, seekTime) {
  // length of horizontal seekbar
  const { horizontalSec } = player.options_.plugins.timeShift;
  const startTime = TimeShiftState.getShiftedTime(player);

  log('Time shifted to time: ', seekTime, 'sec (within interval', startTime + horizontalSec, ' - ', startTime, ' sec)');

  if (seekTime < horizontalSec) {
    TimeShiftState.updateState(player, true, seekTime, TimeShiftState.getShiftedTime(player));
  } else {
    TimeShiftState.updateState(player, true, seekTime, seekTime - horizontalSec / 2);
  }
};

const seekInTimeshift = function goToTimeshift(player, seekTime) {
  // length of horizontal seekbar
  const { horizontalSec } = player.options_.plugins.timeShift;

  log('Time shifted to time: ', seekTime, 'sec');

  if (seekTime < horizontalSec) {
    TimeShiftState.updateState(player, true, seekTime, 0);
  } else {
    TimeShiftState.updateState(player, true, seekTime, seekTime - horizontalSec / 2);
  }

  player.addClass('vjs-timeshifted');
};

const cancelTimeshift = function cancelTimeshift(player) {
  if (!propTest(() => player.options_.plugins.liveRestartButton.eventsOnly)) {
    log('Time shift canceled');

    const { urlParam } = player.options_.plugins.timeShift;

    // FIXME BPo: Functionality has changed. Use track src from the config for this purpose!
    // change source
    const src = {
      src: UrlHelper.removeURLParameter(player.currentSrc(), urlParam),
      type: player.currentType(),
    };
    player.src(src);

    // remove timeshifted state
    TimeShiftState.resetState(player);

    player.removeClass('vjs-timeshifted');
  }
};

/**
 * Seek in the timeshift.
 *
 * @param {number} seekTime
 * @param {boolean=} noReset - if set true, the vertical seek bar won't reset and seeking
 *                             will be done in the current timeshift interval
 */
const timeshiftSeek = function timeshiftSeek(seekTime, noReset) {
  if (is.not.number(seekTime)) {
    log.error(TS_SEEK_INVALID_ARG.code, TS_SEEK_INVALID_ARG.message, seekTime);
    this.trigger({
      type: 'ott-custom-error',
      ...TS_SEEK_INVALID_ARG,
    });
    return;
  }

  if (seekTime > 0) {
    initTimeshift(this, seekTime);

    if (noReset && TimeShiftState.isTimeShifted(this)) {
      seekInCurrentTimeshiftInterval(this, seekTime);
    } else {
      seekInTimeshift(this, seekTime);
    }
  } else {
    cancelTimeshift(this);
  }

  // log and call custom event
  const eventObj = {
    type: 'ott-seekablelive-seek',
    seekTime,
  };
  this.trigger(eventObj);
  log('[timeshift]', eventObj);
};

/**
 * Time shift plugin initialization
 *
 * @param {Object} opts               - Plugin initialization object
 * @param {Object} opts.urlParam      - Name of seekable URL parameter
 * @param {Object} opts.horizontalSec - Maximum horizontal seekable time
 * @param {Object} opts.verticalSec   - Maximum vertical seekable time
 */
export const timeShiftPlugin = function timeShiftPlugin(opts) {
  const initialize = function initialize() {
    // Init only when all properties exist
    if (opts.urlParam) {
      const controlBar = getControlBar(this);
      let initCancelTSBtn = false;

      if (is.not.finite(opts.horizontalSec)) {
        log.error(TS_ARGS_H_NUMBER_CHECK.code, TS_ARGS_H_NUMBER_CHECK.message, opts.horizontalSec);
        this.trigger({
          type: 'ott-cutom-error',
          ...TS_ARGS_H_NUMBER_CHECK,
        });
      } else if (opts.horizontalSec > 0) {
        initCancelTSBtn = true;
        if (controlBar) {
          // Init player time shift state
          TimeShiftState.initState(this, opts.urlParam);

          // Register components
          controlBar.addChild(new LiveProgressControl(this, getLiveProgressControlInitObj(opts)));
        }
      } else {
        log.warn('Horizontal time-shift value is lower or equal to 0.');
      }

      if (is.not.finite(opts.verticalSec)) {
        log.error(TS_ARGS_V_NUMBER_CHECK.code, TS_ARGS_V_NUMBER_CHECK.message, opts.verticalSec);
        this.trigger({
          type: 'ott-cutom-error',
          ...TS_ARGS_V_NUMBER_CHECK,
        });
      } else if (opts.verticalSec > 0) {
        initCancelTSBtn = true;
        this.addChild(new LiveVerticalSeekBar(this, getVerticalBarInitObj(this, opts)));
      } else {
        log.warn('Vertical time-shift value is lower or equal to 0.');
      }

      // Initialize only when vertical or horizontal time-shift is available
      if (initCancelTSBtn) {
        controlBar.addChild(new TimeShiftTimeDisplay(this));

        controlBar.addChild(new TimeShiftCancelButton(this, getCancelBtnInitObj(this, controlBar, opts)));
      }

      if (is.number(opts.seekTime)) {
        timeshiftSeek.call(this, opts.seekTime);
      }

      if (opts.disabled) {
        this.addClass('vjs-timeshift-disabled');
      }

      log('timeShift plugin initialized');
    } else {
      log.error(TS_ARGS_URLPAR_CHECK.code, TS_ARGS_URLPAR_CHECK.message);
      this.trigger({
        type: 'ott-cutom-error',
        ...TS_ARGS_URLPAR_CHECK,
      });
    }
  };

  // try to initialize time shift plugin when metadata fully loaded
  this.one(this, 'loadeddata', initialize);

  // add timeShift seek method
  this.timeshiftSeek = timeshiftSeek;
};
