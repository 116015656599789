import log from '../log';

class PlayerReport {
  constructor(player, opts = {}) {
    // reference of ott-player object
    this.player = player;
    this.reportEndpointUrl = opts.reportEndpointUrl;

    this.automaticActivationProbability = opts.automaticActivationProbability || null;
    this.automaticReportingIntervalMs = opts.automaticReportingIntervalMs || 30000;
    this.automaticReportingInterval = null;
    this.apiErrorCounter = 0;

    this.playerUniqueId = this.createPlayerUniqueId();

    this.logsBuffer = [];
    this.maxLogsBufferSizeKb = opts.maxLogsBufferSizeKb || 4000;
    this.defatBufferArrayLogsSize = opts.defatBufferArrayLogsSize || 10000;

    this.lastLogIndex = 0;
    this.frequentLogsTreasholds = this.getFrequentLogsTreasholds(opts.frequentLogsTreasholds || null);

    this.pickupLogsIntervalTimeMs = opts.pickupLogsIntervalTimeMs || 10000;
    this.pickupLogsInterval = null;
    this.registerPickupLogsInterval();

    this.registerFatalEvents();
    this.registerControlEvents();

    if (this.automaticActivationProbability != null && Math.random() <= this.automaticActivationProbability) {
      window.automaticPlayerReport = true;
      if (this.player.hls && this.player.hls.config) {
        this.player.hls.config.debug = true;
      }
      this.registerAutomaticReportSending();
    }

    window.playerReport = this;

    this.sendReport = this.sendReport.bind(this);
  }

  /**
   * Method create frequent logs phrases and treashold for save each of them
   * Treashold is designated as "save every XX log containing this key"
   * @param {Object} customTreasholds
   * @returns {Object}
   */
  getFrequentLogsTreasholds(customTreasholds) {
    const defaultTreasholds = {
      BufferController: {
        storeEveryLog: 30,
        actualStoreIndex: 0,
      },
      DashHandler: {
        storeEveryLog: 20,
        actualStoreIndex: 0,
      },
      ScheduleController: {
        storeEveryLog: 30,
        actualStoreIndex: 0,
      },
      AbrController: {
        storeEveryLog: 20,
        actualStoreIndex: 0,
      },
      SegmentBaseLoader: {
        storeEveryLog: 30,
        actualStoreIndex: 0,
      },
      NextFragmentRequestRule: {
        storeEveryLog: 30,
        actualStoreIndex: 0,
      },
      setplayheadposition: {
        storeEveryLog: 30,
        actualStoreIndex: 0,
      },
      playerReport: {
        storeEveryLog: 200,
        actualStoreIndex: 0,
      },
    };

    if (customTreasholds !== null) {
      Object.keys(customTreasholds).forEach((customTreasholdKey) => {
        if (defaultTreasholds[customTreasholdKey] && customTreasholds[customTreasholdKey].storeEveryLog) {
          defaultTreasholds[customTreasholdKey].storeEveryLog = customTreasholds[customTreasholdKey].storeEveryLog;
        }
        // pokud neexistuje defaultní definice přiřadíme jí novou hodnotu
        defaultTreasholds[customTreasholdKey] = {
          storeEveryLog: customTreasholds[customTreasholdKey].storeEveryLog,
          actualStoreIndex: 0,
        };
      });
    }

    return defaultTreasholds;
  }

  /**
   * Method calculated approximate string size
   * @param {String} stringLength
   * @returns {Number} string size in kilobytes
   */
  getStringSizeKb(stringLength) {
    // return buffer size in kb
    return Math.round(stringLength * 8 * 0.000125);
  }

  /**
   * Method verifies buffer size before sending
   * Allowed size is set in this.maxLogsBufferSizeKb
   * @returns {Bool}
   */
  checkAllowedBufferSize() {
    const bufferString = JSON.stringify(this.logsBuffer);
    log(`[playerReport] allowedbuffersize check ${this.getStringSizeKb(bufferString.length)}kb`);
    return this.getStringSizeKb(bufferString.length) < this.maxLogsBufferSizeKb;
  }

  /**
   * Method checks if it is often a repeated log
   * Compare desctiption content with keys from this.frequentLogsTreasholds
   * @param {String} type // log type (log, warn, error)
   * @param {String} description // log desctiption
   * @returns {Bool}
   */
  checkLogCanBeSavedToBuffer(type, description) {
    // these types of logs must be saved every time
    if (type === 'error' || type === 'warn') {
      return true;
    }

    const frequentKeys = Object.keys(this.frequentLogsTreasholds);
    let frequentKeysResult = null;
    frequentKeys.forEach((frequentKey) => {
      if (typeof description === 'string' && description.indexOf(frequentKey) !== -1 && frequentKeysResult === null) {
        const frequentKeyData = this.frequentLogsTreasholds[frequentKey];
        if (frequentKeyData.actualStoreIndex === 0) {
          frequentKeyData.actualStoreIndex++;
          frequentKeysResult = true;
        } else {
          frequentKeyData.actualStoreIndex++;
          frequentKeysResult = false;
          if (frequentKeyData.actualStoreIndex >= 10) {
            frequentKeyData.actualStoreIndex = 0;
          }
        }
      }
    });

    if (frequentKeysResult === null) {
      return true;
    }

    return frequentKeysResult;
  }

  /**
   * Method will reduce the buffer size if it is unnecessarily large before sending
   */
  defatLogsBuffer() {
    const bufferLogsStartPartSize = Math.round(this.defatBufferArrayLogsSize / 2);
    const bufferLogsEndPartSize = Math.round(this.logsBuffer.length - bufferLogsStartPartSize);
    // this.logsBuffer = this.logsBuffer.splice(bufferLogsStartPartSize, bufferLogsEndPartSize);

    const defatedLogsBuffer = this.logsBuffer.filter((log, index) => {
      if (index < bufferLogsStartPartSize || index > bufferLogsEndPartSize) {
        return log;
      }

      if (log.type === 'error' || log.type === 'warn') {
        return log;
      }

      return false;
    });

    log(
      `[PlayerReport] defat logs buffer! Change size from ${this.logsBuffer.length} to ${defatedLogsBuffer.length} logs`,
    );
    this.logsBuffer = defatedLogsBuffer;
  }

  /**
   * Method clear buffer logs
   */
  clearLogsBuffer() {
    log(`[PlayerReport] clear logs buffer, deleted ${this.logsBuffer.length} logs`);
    this.logsBuffer = [];
  }

  /**
   * Method create unique player ID for report pairing
   * Id includes timestamp and random 10 chars hash
   * @returns {String}
   */
  createPlayerUniqueId() {
    const timeStamp = new Date().getTime();
    const hashIdCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const hashIdLength = 10;

    let playerUniqueId = `${timeStamp}_`;
    for (let i = 0; i < hashIdLength; i++) {
      playerUniqueId += hashIdCharacters.charAt(Math.floor(Math.random() * hashIdCharacters.length));
    }

    log(`[PlayerReport] created unique report ID: ${playerUniqueId}`);
    return playerUniqueId;
  }

  /**
   * Method create JSON body request for BE
   * @returns {JSON}
   */
  createReportJsonBody() {
    // if the size of the logs is higher, the records will be deleted to reduce the size
    if (!this.checkAllowedBufferSize) {
      this.defatLogsBuffer();
    }

    /* eslint-disable */
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };
    /* eslint-enable */

    const jsonBody = {
      componentName: 'player',
      logData: {
        playerId: this.playerUniqueId,
        playerVersion: this.player.VERSION,
        created: new Date().getTime(),
        automatic: !!window.automaticPlayerReport,
        logsSize: this.logsBuffer.length,
        logs: this.logsBuffer,
      },
    };

    const jsonString = JSON.stringify(jsonBody, getCircularReplacer());
    this.clearLogsBuffer();

    return jsonString;
  }

  /**
   * Method pickup logs from player.logHistory and stored in own buffer
   * Save index of last stored index from logHistory
   */
  pickupLogs() {
    log(
      `[PlayerReport] pickup logs called successfully! Total logsBuffer size:${this.logsBuffer.length} | lastSavedIndex:${this.lastLogIndex} | player.logHistory size:${this.player.logHistory.length}`,
    );
    const logHistorySize = this.player.logHistory.length;

    if (logHistorySize > this.logsBuffer.length) {
      for (let i = this.lastLogIndex; i < logHistorySize; i++) {
        const logItem = this.player.logHistory[i];
        // before saving each log, it is checked to see if it contains any keyword coming from over-repeating logs
        if (this.checkLogCanBeSavedToBuffer(logItem.type, logItem.args[0])) {
          const reportItem = {
            timestamp: logItem.created,
            type: logItem.type,
            description: logItem.args[0],
          };

          if (logItem.args.length > 1) {
            reportItem.data = [];
            logItem.args.forEach((arg, index) => {
              // first index used for log description
              if (index > 0) {
                reportItem.data.push(arg);
              }
            });
          }
          this.logsBuffer.push(reportItem);
        }
      }

      this.lastLogIndex = logHistorySize;
    }
  }

  /**
   * Method send JSON report to endpoint from configuration
   */
  sendReport() {
    this.pickupLogs();
    log('[PlayerReport] Send report');
    // not send empty logs array
    if (this.logsBuffer.length === 0) {
      return;
    }

    fetch(this.reportEndpointUrl, {
      method: 'POST',
      // mode: 'no-cors',
      body: this.createReportJsonBody(), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(() => {
      this.apiErrorCounter++;
      if (this.apiErrorCounter > 3) {
        this.stopAutomaticReportingInterval();
        log.error('[PlayerReport] stop sending automatic report. API error threshold');
      }
    });
  }

  /**
   * Method register listener for player critical events (error)
   * After recieve this event, send report and stop check logHistory
   */
  registerFatalEvents() {
    this.player.on('error', () => {
      log.error('[PlayerReport] fatal error called, sending report ...');
      this.createSendReportButton();
      // this.sendReport();
    });
  }

  /**
   * Method register time interval for pickup logs from player.logHistory
   */
  registerPickupLogsInterval() {
    this.pickupLogsInterval = setInterval(() => {
      this.pickupLogs();
    }, this.pickupLogsIntervalTimeMs);
  }

  /**
   * Method register time inteval for sending report in automatic mode
   */
  registerAutomaticReportSending() {
    this.automaticReportingInterval = setInterval(() => {
      this.sendReport();
    }, this.automaticReportingIntervalMs);
  }

  /**
   * Method register listeners for control events
   */
  registerControlEvents() {
    this.player.on('ott-player-send-report', () => {
      log('[PlayerReport] control event recieved, sending report ...');
      this.sendReport();
    });
  }

  /**
   * Method clear/delete time interval for pickup logs from player.logHistory
   */
  stopPickupLogsInterval() {
    clearInterval(this.pickupLogsInterval);
  }

  /**
   * Method clear/delete time interval for sending logs to API
   * Called automaticaly when API response 3 times sended error code
   */
  stopAutomaticReportingInterval() {
    clearInterval(this.automaticReportingInterval);
  }

  /**
   * Method create send button after player fatal error
   */
  createSendReportButton() {
    const errorContainer = document.querySelector('.vjs-error-display');
    const hasReportButton = document.querySelector('.ott-error-report-container');

    if (errorContainer && !hasReportButton) {
      const reportButtonContainer = document.createElement('div');
      reportButtonContainer.classList.add('ott-error-report-container');
      const reportButton = document.createElement('div');
      reportButton.classList.add('ott-error-report-button');
      reportButton.innerText = this.player.localize('Send user report');
      reportButton.onclick = this.sendReport;
      const reportPlayerId = document.createElement('div');
      reportPlayerId.classList.add('ott-error-report-playerid');
      reportPlayerId.innerText = `${this.player.localize('Player unique ID:')} ${this.playerUniqueId}`;

      reportButtonContainer.appendChild(reportButton);
      reportButtonContainer.appendChild(reportPlayerId);
      errorContainer.appendChild(reportButtonContainer);
    }
  }
}

/**
 * playerReport plugin initialization
 *
 */
export const playerReportPlugin = function playerReportPlugin(opts) {
  const playerReport = new PlayerReport(this, opts); // eslint-disable-line

  log('[PlayerReport] plugin initialized');
};
