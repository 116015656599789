/**
 * <p>Trigger event on every played second (except adverts). This is useful for player
 * tracking, heartbeats, ...</p>
 *
 * <h3>Initialization</h3>
 * Initialize this plugin before every child plugin (like heartbeats, measuring, ...)
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-playertick</b> - ticks {Number} really watched time in seconds
 *
 * @example
{
  plugins: {
    playedTicks: {}
  }
}
 *
 * @module plugins/playedTicksPlugin
 */
import log from '../log';
import { isAdPlaying } from './adservice/shared/ad-state';

/**
 * Played ticks plugin initialization
 */
export const playedTicksPlugin = function playedTicksPlugin() {
  const player = this;
  let lastTime = 0;
  let ticks = 0;

  const updateCounter = () => {
    const currentTimeNotRounded = this.currentTime();
    const currentTime = Math.floor(currentTimeNotRounded);
    if (lastTime === currentTime || isAdPlaying(this)) {
      return;
    }
    if (currentTime - lastTime > 1) {
      // user probably seeked
      lastTime = currentTime;
      return;
    }
    lastTime = currentTime;
    ticks++;

    // Trigger playertick event on every played second
    player.trigger({
      type: 'ott-playertick',
      ticks,
    });
  };

  player.on('timeupdate', updateCounter);
  player.on('ended', () => {
    if (isAdPlaying(this)) return;

    // Reset plugin states when end is reached
    lastTime = 0;
    ticks = 0;

    // Remove updateCounter function on player ended is OBSOLETE!
    // this.off('timeupdate', updateCounter);
  });

  log('playedTicks plugin initialized');
};
