import { videojs } from 'global/window';
import Cookies from 'js-cookie';
// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');
/**
 * Disable quality Measurement button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityMeasurementButton extends Button {
  constructor(player, options) {
    super(player, options);
  }

  createEl() {
    return super.createEl('span', {
      className: 'vjs-mx-mea-switch vjs-settings-content',
      innerHTML: `<label class="switch switch--sm"><input class="vjs-mx-mea-checkbox" type="checkbox"${
        Cookies.get('mux_mea_id') ? ' checked="checked"' : ''
      }><span class="slider round"></span></label>`,
    });
  }

  updateCheckedValue(e) {
    this.el_.getElementsByClassName('vjs-mx-mea-checkbox')[0].checked = e.checked;
  }

  getCheckedValue() {
    return this.el_.getElementsByClassName('vjs-mx-mea-checkbox')[0].checked;
  }

  handleClick(e) {
    const clipboardButtonEl = this.el_.parentElement.getElementsByClassName('vjs-copy-clipboard-button')[0];
    const checkboxEl = this.el_.getElementsByClassName('vjs-mx-mea-checkbox')[0];
    const muxCookieKey = 'mux_mea_id';
    const muxCookieVal = this.player_.options_.plugins.measuring.services.mux.property_key;
    const expandedClass = 'vjs-copy-clipboard-button--expanded';

    function triggerOn() {
      Cookies.set(muxCookieKey, muxCookieVal, { expires: 2 });
      clipboardButtonEl.classList.add(expandedClass);
      checkboxEl.checked = true;
    }

    function triggerOff() {
      Cookies.remove(muxCookieKey);
      clipboardButtonEl.classList.remove(expandedClass);
      checkboxEl.checked = false;
    }

    // TODO: change markup for toggle switches, handleClick behave differently on desktop and mobile
    if (e.type === 'click') {
      return this.getCheckedValue() ? triggerOn() : triggerOff();
    }
    if (e.type === 'tap') {
      return !Cookies.get(muxCookieKey) ? triggerOn() : triggerOff();
    }

    return false;
  }
}
Component.registerComponent('QualityMeasurementButton', QualityMeasurementButton);
export default QualityMeasurementButton;
