import { document } from 'global/window';
import { priorNotificationMode, skipDelayMode, premiumMode } from './settings-helper';
import log from '../../../log';

class DomOperations {
  constructor(player, settings) {
    this.player = player;
    this.settings = settings;
    this.classNames = {
      adMsgWrapper: 'vjs-ad-msg-wrapper',
      adMsgText: 'vjs-ad-msg-text',
      skipCounter: 'vjs-skip-ad-counter',
      skipCountdown: 'vjs-skip-countdown',
      skipButton: 'vjs-skip-button',
      skipWrapper: 'vjs-skip-countdown',
      linearAdWrapper: 'vjs-ad-link',
      linearAdLink: 'vjs-ad-clickthru',
      linearAdButton: 'vjs-ad-button',
      premiumPromoButton: 'vjs-ad-premium-button',
    };
  }

  /**
   * Try to find first ad button element (there should be only one)
   *
   * @returns {HTMLElement} HTML if exist, undefined otherwise
   */
  getAdButton() {
    return this.player.el_.getElementsByClassName(this.classNames.linearAdButton)[0];
  }

  /**
   * Try to find first ad link element (there should be only one)
   *
   * @returns {HTMLElement} HTML if exist, undefined otherwise
   */
  getAdLinkElement() {
    return this.player.el_.getElementsByClassName(this.classNames.linearAdLink)[0];
  }

  /**
   * Create UI for the linear ad
   *
   * @param {String} videoClickThroughURLTemplate Open this URL when user click on the screen
   * @param {Function} adLinkClick Ad screen click event
   * @param {Function} adButtonClick Skip button click event
   */
  createLinearAdUI(videoClickThroughURLTemplate, adLinkClick, adButtonClick, adType, adId) {
    const divAdWrapper = document.createElement('div');
    divAdWrapper.className = this.classNames.linearAdWrapper;

    // Create Ad link
    let adLinkElement = '';
    // If ad has url, make it clickable
    if (videoClickThroughURLTemplate?.url) {
      adLinkElement = document.createElement('a');
      adLinkElement.setAttribute('href', videoClickThroughURLTemplate.url);
      adLinkElement.setAttribute('target', '_blank');
    } else {
      adLinkElement = document.createElement('div');
      log(`[linear] id ${adId} type ${adType} has not click url`);
    }
    adLinkElement.onclick = adLinkClick;
    adLinkElement.className = this.classNames.linearAdLink;

    // Create span button
    const spanAdButton = document.createElement('span');
    spanAdButton.className = this.classNames.linearAdButton;
    spanAdButton.setAttribute('data-enabled', 'false');
    spanAdButton.onclick = adButtonClick;

    // Insert span into anchor
    adLinkElement.appendChild(spanAdButton);

    // Inset adLinkElement into divAdWrapper
    divAdWrapper.appendChild(adLinkElement);

    // Insert an ad wrapper to the player object
    this.player.el_.appendChild(divAdWrapper);
  }

  /**
   * Remove an old ad UI if exist
   *
   * @returns {String} ad wrapper class name
   */
  removeLinearAdUI() {
    const elements = this.player.el_.getElementsByClassName(this.classNames.linearAdWrapper);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  createAdBigPlayButton() {
    const button = document.createElement('button');
    button.className = 'vjs-big-play-button vjs-big-play-button--ad';
    button.setAttribute('title', this.player.localize('Continue to play the ad'));
    button.setAttribute('type', 'button');
    button.setAttribute('aria-disabled', 'false');
    button.innerHTML = `
        <span aria-hidden="true" class="vjs-icon-placeholder"></span>
        <span class="vjs-control-text" aria-live="polite">${this.player.localize('Continue to play the ad')}</span>
      `;

    return button;
  }

  createSkipButtonUI(adCount, adi, delay) {
    let localized = this.player.localize('Ad playing (skip ad in {countdown}s)');
    // let sufixLoc = this.player.localize('s)');

    switch (this.settings.linear.skipDelay.mode) {
      case skipDelayMode.adsCountdown:
        localized = this.player
          .localize('{curr}/{count} ad playing (skip ad in {countdown}s)')
          .replace('{curr}', adi)
          .replace('{count}', adCount);
        break;
      case skipDelayMode.adCounter:
        localized = this.player.localize('{curr}. ad playing').replace('{curr}', adi);
        // sufixLoc = '';
        break;
      case skipDelayMode.adsCounter:
        localized = this.player
          .localize('Ad playing {curr}/{count}')
          .replace('{curr}', adi)
          .replace('{count}', adCount);
        // sufixLoc = '';
        break;
      case skipDelayMode.pure:
        localized = this.player.localize('Ad playing');
        // sufixLoc = '';
        break;
    }

    // Create count down wrapper
    const spanSkipWrapper = document.createElement('span');
    spanSkipWrapper.className = this.classNames.skipWrapper;
    spanSkipWrapper.innerHTML = localized.replace(
      '{countdown}',
      `<span class="${this.classNames.skipCounter}">${delay}</span>`,
    );

    // Insert spanSkipWrapper inside spanAdButton
    this.getAdButton().appendChild(spanSkipWrapper);
  }

  /**
   * Update (set to skipDelay - currentTime) content of countdown elements.
   * question PSd: This works only for prerolls?
   *
   * @param {Number} skipDelay Linear ad can be skipped after this time
   */
  updateSkipCounter(skipDelay) {
    const counters = this.player.el_.getElementsByClassName(this.classNames.skipCounter);
    Array.prototype.forEach.call(counters, (counter) => {
      counter.innerHTML = skipDelay - Math.floor(this.player.currentTime());
    });
  }

  showVPAIDSkipButton(clickEvt) {
    this.createAdMessageWrapperHTML('');
    const skipLinkArr = this.player.el_.getElementsByClassName(this.classNames.adMsgText);
    Array.prototype.forEach.call(skipLinkArr, (skipLink) => {
      skipLink.onclick = clickEvt;
      skipLink.className = this.classNames.adMsgText;
      skipLink.innerHTML = this.player.localize('Skip ad');
    });
  }

  /**
   * Remove countdown text and update element with skip ad button text
   */
  showSkipButton(className = this.classNames.skipCountdown) {
    const countdowns = this.player.el_.getElementsByClassName(className);
    Array.prototype.forEach.call(countdowns, (countdown) => {
      countdown.className = this.classNames.skipButton;
      countdown.innerHTML = this.player.localize('Skip ad');
    });
  }

  /**
   * It will remove a html element from the player el_
   */
  removeElementsByClassName(className) {
    const elements = this.player.el_.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  /**
   * It will remove the upcoming ad message from the player el_
   */
  removeAdMessageWrapperHTML() {
    this.removeElementsByClassName(this.classNames.adMsgWrapper);
  }

  /**
   * Format the upcoming linear ad break message
   *
   * @param {Number}  adCount   Length of the ads array in the current linear break
   * @param {time}    time      Remaining time to the linear ad break
   * @returns Localized upcoming linear ad break message
   */
  getLocalizedUpcomingAdMessage(adCount, time) {
    // Default is the pure layout
    let message = this.player.localize('Ads will be played');
    switch (this.settings.linear.priorNotification.mode) {
      case priorNotificationMode.adsCountdown:
        if (adCount === 1) {
          message = this.player
            .localize('{=1adCount} ad will be played after {time}s')
            .replace('{=1adCount}', adCount)
            .replace('{time}', time);
        } else if (adCount > 1 && adCount < 5) {
          message = this.player
            .localize('{>1<5adCount} ad will be played after {time}s')
            .replace('{>1<5adCount}', adCount)
            .replace('{time}', time);
        } else {
          message = this.player
            .localize('{>=5adCount} ad will be played after {time}s')
            .replace('{>=5adCount}', adCount)
            .replace('{time}', time);
        }
        break;
      case priorNotificationMode.countdown:
        if (adCount === 1) {
          message = this.player.localize('Ad will be played after {time}s').replace('{time}', time);
        } else {
          message = this.player.localize('Ads will be played after {time}s').replace('{time}', time);
        }
        break;
    }
    return message;
  }

  /**
   * Creates the html elements for the ad messages
   *
   * @param {String}  innerHTML The HTML content (inner HTML) of an element
   */
  createAdMessageWrapperHTML(innerHTML) {
    const wrapper = document.createElement('div');
    wrapper.className = this.classNames.adMsgWrapper;
    const span = document.createElement('span');
    span.className = this.classNames.adMsgText;

    span.innerHTML = innerHTML;

    wrapper.appendChild(span);
    // Insert an ad wrapper to the player object
    this.player.el_.appendChild(wrapper);
  }

  /**
   * Creates the html elements with upcoming ad notification content
   *
   * @param {Number}  adCount   Length of the ads array in the current linear break
   * @param {time}    time      Remaining time to the linear ad break
   */
  createUpcomingAdHTML(adCount, time) {
    this.createAdMessageWrapperHTML(this.getLocalizedUpcomingAdMessage(adCount, time));
    // message is hidden, update will show it if necessary it's made this way to remove flickering of the banner
    this.hideAdMessageWrapperHTML();
  }

  /**
   * Updates HTML element with the upcoming ad notification
   *
   * @param {Number}  adCount   Length of the ads array in the current linear break
   * @param {time}    time      Remaining time to the linear ad break
   */
  updateUpcomingAdMessage(adCount, time) {
    const upcomingSpanArr = this.player.el_.getElementsByClassName(this.classNames.adMsgText);
    Array.prototype.forEach.call(upcomingSpanArr, (span) => {
      span.innerHTML = this.getLocalizedUpcomingAdMessage(adCount, time);
    });
  }

  hideAdMessageWrapperHTML() {
    const adMessageWrapperHTML = this.player.el_.querySelector(`.${this.classNames.adMsgWrapper}`);
    adMessageWrapperHTML.style.visibility = 'hidden';
  }

  showAdMessageWrapperHTML() {
    const adMessageWrapperHTML = this.player.el_.querySelector(`.${this.classNames.adMsgWrapper}`);
    adMessageWrapperHTML.style.visibility = 'visible';
  }

  /**
   * Create UI for the premium button
   *
   * @param {Function} clickEvt Behaviour for the premium button click event
   */
  createPremiumPromoUI(clickEvt) {
    // get ad ui element and create button only when element exist
    // const adLink = this.getAdLinkElement();
    // Create Ad link
    const premiumLink = document.createElement('a');

    premiumLink.setAttribute('href', this.settings.linear.premium.url);
    premiumLink.setAttribute('target', this.settings.linear.premium.target);
    premiumLink.className = `${this.classNames.premiumPromoButton} ${this.settings.linear.premium.position} ${this.settings.linear.premium.mode}`;
    premiumLink.onclick = clickEvt;
    switch (this.settings.linear.premium.mode) {
      case premiumMode.icon:
        premiumLink.innerHTML = '<span class="icon vjs-icon-close-red">&nbsp;</span>';
        break;
      case premiumMode.button:
        premiumLink.innerHTML = `<span class='icon vjs-icon-close-red'>&nbsp;</span> ${this.player.localize(
          'Turn off ads',
        )}`;
        break;
      default:
        premiumLink.innerHTML = this.player.localize('Turn off ads');
        break;
    }

    // Inset aAdLink into divAdWrapper
    // adLink.appendChild(premiumLink);

    // Insert an ad wrapper to the player object
    this.player.el_.appendChild(premiumLink);
  }
}

export default DomOperations;
