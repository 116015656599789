import is from 'is_js';
import { SKIP_COUNTDOWN_DISABLED } from '../../../constants';

/**
 * Check if premium promotion is enabled by the seetings
 * @param  {String} mode  Selected mode (e. g. 'disabled')
 * @param  {Array} modes  Array of supported modes for the plugin
 * @return {Boolean}      True when mode contains any UI value from the modes array, false otherwise
 */
const isPluginEnabled = (mode, modes) => mode !== SKIP_COUNTDOWN_DISABLED && modes.some((m) => m === mode);

export const skipDelayMode = {
  adsCounter: 'adsCounter',
  adCounter: 'adCounter',
  pure: 'pure',
  countdown: 'countdown',
  adsCountdown: 'adsCountdown',
};
export const skipDelayModes = () => Object.keys(skipDelayMode);

/**
 * Check if skip delay notification is enabled by the seetings
 * @param  {Object} settings adService settings object
 * @return {Boolean}         True when settings contains any UI value, false otherwise
 */
export const showSkipDelay = (settings) => isPluginEnabled(settings.linear.skipDelay.mode, skipDelayModes());

export const priorNotificationMode = {
  countdown: 'countdown',
  adsCountdown: 'adsCountdown',
  pure: 'pure',
};
export const priorNotificationModes = () => Object.keys(priorNotificationMode);

/**
 * Check if prior notification is enabled by the seetings
 * @param  {Object} settings adService settings object
 * @return {Boolean}         True when settings contains any UI value, false otherwise
 */
export const showPriorNotification = (settings) => {
  const { time } = settings.linear.priorNotification;
  return (
    isPluginEnabled(settings.linear.priorNotification.mode, priorNotificationModes()) && is.number(time) && time > 0
  );
};

export const premiumMode = {
  icon: 'icon',
  text: 'text',
  button: 'button',
};
export const premiumModes = () => Object.keys(premiumMode);

/**
 * Check if premium promotion is enabled by the seetings
 * @param  {Object} settings adService settings object
 * @return {Boolean}         True when settings contains any UI value, false otherwise
 */
export const showPremiumPromo = (settings) => isPluginEnabled(settings.linear.premium.mode, premiumModes());
