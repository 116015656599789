// import log from '../log';

/**
 * According to the current value in player.duration, it returns whether it is a live stream.
 * Even when playing a live stream, it may return false when the ad unit is playing, replacing the main source
 * Returns true when player.duration() is -1 or Infinity
 * @param  {Object} player The player Object
 * * @return {Boolean}
 */
export function isLiveNow(player) {
  // const result = player.duration() === -1 || player.duration() === Infinity;
  // log(`[isLiveNow] ${result}`);
  return player.duration() === -1 || player.duration() === Infinity;
}

/**
 * The first time loadedmetadata is called, the isLivePlayer value is stored in the player and is returned through this function.
 * When playing an ad unit in a live stream, the value will not be overwritten
 * @param {Object} player
 * @return {Boolean}
 */
export function isLiveGlobal(player) {
  // const result = !!player.isLivePlayer;
  // log(`[isLiveGlobal] ${result}`);
  return !!player.isLivePlayer;
}
