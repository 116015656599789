import document from 'global/document';
import window from 'global/window';
import log from '../../../log';
import {
  AS_CLIENT_DISPOSED,
  AS_VPAID_IFRAME_REMOVED,
  AS_VPAID_GET_NOT_FOUND,
  AS_VPAID_IS_NOT_VALID,
} from '../../../errors';
import { AS_VPAID_IFRAME_ID } from '../../../constants';
import {
  getAdId,
  createAdElement,
  createFIF,
  createHTML,
  removeElementHTML,
  removeElementById,
  setIframeContent,
} from './utils';
import AdUnit from './adunit';

/**
 * Prepare VPAID friendly iframe HTML. See http://www.iab.net/media/file/rich_media_ajax_best_practices.pdf
 * @param  {String} mediaFileScriptURL       URL to the VPAID script from the VAST mediaFile node
 * @param  {String} targetOrigin             Specifies what the origin of otherWindow must be for the event to be dispatched, either as the literal string "*" or as a URI
 * @return {String}                          VPAID friendly iframe HTML
 */
const getFIFContent = (mediaFileScriptURL, targetOrigin) => `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8">
    <title>title</title>
  </head>
  <body style="margin:0;padding:0">
    <script type="text/javascript">var inDapIF = true;</script>
    <script type="text/javascript" src="${mediaFileScriptURL}"></script>
    <script type="text/javascript">
      window.parent.postMessage('{"event": "ready", "id": "${AS_VPAID_IFRAME_ID}"}', '${targetOrigin}');
    </script>
  </body>
</html>`;

/**
 *
 * @param {HTMLElement} el          Will contain the iframe to load adUnit
 * @param {object} options          A set of key/value pairs that configure the FIF
 * @param {Number} options.timeout  Timeout for the returning ad unit
 * @param {String} playerID         Player HTML unique ID
 */
export default class VPAIDClient {
  constructor(el, videoEl, playerID, options) {
    this.iframeContainer = el;
    this.videoEl = videoEl;
    this.playerID = playerID;
    this.options = options;
    this.disposed = false;
  }

  /**
   * Load ad from the script URL
   * @param  {string} mediaFileScriptURL URL to the VPAID script from the VAST mediaFile node
   * @return {Promise<Object>} A promise to the VPAID ad.
   */
  loadAd(mediaFileScriptURL) {
    return new Promise((resolve, reject) => {
      // return when onLoad is defined already
      if (this.onLoad) {
        reject('VPAID loadAd was called already');
      }

      // Initialize new client after dispose
      this.throwIfDisposed();
      this.unloadPreviousAd();

      removeElementHTML(this.iframeContainer);
      this.adEl = createAdElement(document, getAdId(this.playerID), this.options);
      removeElementById(document, this.adEl.id);
      this.iframe = createFIF(document);

      // TODO: SMAZAT ADEL pri erroru a na konci!
      createHTML(document, this.iframeContainer, this.iframe, this.videoEl, this.adEl);
      setIframeContent(this.iframe, getFIFContent(mediaFileScriptURL, this.getOrigin()));

      const timeout = setTimeout(() => {
        // Remove previous content of the parent HTML element
        removeElementHTML(this.iframeContainer);
        removeElementById(document, this.adEl.id);

        this.unloadPreviousAd();
        reject('VPAID timeouted');
      }, this.options.timeout);

      this.onLoad = (e) => {
        // Reject on the wrong origin
        if (e.origin !== this.getOrigin()) return;
        try {
          const result = JSON.parse(e.data);
          // Reject on the wrong id
          if (result.id !== AS_VPAID_IFRAME_ID) return;

          // clear timeout
          clearTimeout(timeout);

          // We have what we need, stop listening for the messages
          this.removeLoadListener();

          if (!(this.iframe || {}).contentWindow) {
            reject(AS_VPAID_IFRAME_REMOVED);
          } else if (typeof this.iframe.contentWindow.getVPAIDAd !== 'function') {
            reject(AS_VPAID_GET_NOT_FOUND);
          } else {
            // const adEl = this.iframe.contentWindow.document.getElementById(`.${AS_VPAID_AD_ID}`);
            // const adEl = document.getElementById('vpaid-iframe');
            this.adUnit = new AdUnit(this.iframe.contentWindow.getVPAIDAd(), this.adEl, this.videoEl, this.iframe);
            window.adUnit = this.adUnit;
            if (this.adUnit.isValid) {
              this.adUnit.subscribe('AdStopped', this.dispose.bind(this));
              this.adUnit.subscribe('AdError', this.dispose.bind(this));
              resolve(this.adUnit);
            } else {
              reject(AS_VPAID_IS_NOT_VALID);
            }
          }
        } catch (err) {
          log('VPAID postMessage recieved some non JSON data probably. onLoad method will be skipped this time. ', err);
        }
      };

      window.addEventListener('message', this.onLoad);
    });
  }

  /**
   * Remove friendly iframe where is VPAID requested from the DOM.
   */
  removeIframe() {
    if (this.iframe && this.iframe.parentNode) {
      this.iframe.parentNode.removeChild(this.iframe);
      this.iframe = undefined;
    }
  }

  /**
   * Remove message listener and onLoad property when onLoad is defined
   */
  removeLoadListener() {
    if (this.onLoad) {
      window.removeEventListener('message', this.onLoad);
      this.onLoad = undefined;
    }
  }

  /**
   * Stop current ad. This will be executed when ad property is defined only
   */
  stopAd() {
    if (this.adUnit) {
      this.adUnit.stopAd();
      this.adUnit = undefined;
    }
  }

  /**
   * Remove HTML iframe from DOM, remove old onLoad (window message) listener and destroy old ad when exist
   */
  unloadPreviousAd() {
    this.removeIframe();
    this.removeLoadListener();
    this.stopAd();
  }

  /**
   * Check if VPAID client is disposed
   * @returns {Boolean} True when VPAID client is disposed already, false otherwise
   */
  disposed() {
    return this.disposed;
  }

  /**
   * Dispose VPAID client
   */
  dispose() {
    if (this.disposed) return;
    this.disposed = true;
    this.unloadPreviousAd();
  }

  /**
   * @throws Will break code execution when class is disposed already
   */
  throwIfDisposed() {
    if (this.disposed) {
      log.error(AS_CLIENT_DISPOSED.code, AS_CLIENT_DISPOSED.message);
      throw AS_CLIENT_DISPOSED;
    }
  }

  /**
   * @returns {String} Origin when property on location exist.
   */
  getOrigin() {
    if (window.location.origin) {
      return window.location.origin;
    }
    const attachPort = window.location.port ? `:${window.location.port}` : '';
    return `${window.location.protocol}//${window.location.hostname}${attachPort}`;
  }
}
