import { videojs } from 'global/window';
import PlaylistButtonNext from './buttons/playlist-button-next';
import PlaylistButtonPrev from './buttons/playlist-button-prev';
import PlaylistIndex from './buttons/playlist-index-display';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistControlButtons extends Component {
  constructor(player, options) {
    super(player, options);
    this.addChild(new PlaylistButtonPrev(player));
    this.addChild(new PlaylistIndex(player));
    this.addChild(new PlaylistButtonNext(player));
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-playlist-control-buttons',
    });
  }
}

Component.registerComponent('PlaylistControlButtons', PlaylistControlButtons);
export default PlaylistControlButtons;
