import window, { document } from 'global/window';
import FckAdBlock from './anti-adblock-detection';
import log from '../../../log';

/**
 * AdBlock detection
 * @param {Object} player The player object
 * @param {Object} options
 * @param {Object} options.divClass Use this class for the player element as well. AdBlock message MUST be placed after (or nested) the player element with this class
 * @param {Object} options.customBaitUrl Bait url is requested on every AdBlock check
 */
class AntiAdBlock {
  constructor(player, options) {
    this.player = player;
    this.divClass = options.divClass;
    this.customBaitUrl = options.customBaitUrl;
    this.asyncCheckTimeout = undefined;
    this.fckAdBlock = new FckAdBlock();
    this.fckAdBlock.on(true, () => this.detected());
  }

  /**
   * Check AdBlock by http protocol with custom BaitUrl (ad server endpoint)
   */
  check() {
    this.fckAdBlock.check(['http'], {
      http: {
        baitMode: 'import',
        baitUrl: this.customBaitUrl,
      },
    });
  }

  /**
   * Show message div when AdBlock is detected
   * @private
   */
  detected() {
    // Clear interval when deteceted already
    if (this.elementsExist()) {
      const div = this.getMessageDiv();

      div.setAttribute('style', 'display: block !important');
    }

    this.disposePLayer();
  }

  /**
   * Check if element is visually hidden or not
   * @returns {Boolean} True when element is shown, false otherwise
   */
  isElementHidden(el) {
    const style = window.getComputedStyle(el);
    return el.offsetParent === null || style.display === 'none';
  }

  /**
   * Check if both elements (player wrapper and the adblock message) are in the DOM
   */
  elementsExist() {
    if (document.getElementsByClassName(this.divClass).length >= 2) {
      return true;
    }
    return false;
  }

  /**
   * Find AdBlock message element. Nested div of player
   * @returns {HtmlElement} AdBlock message div element
   */
  getMessageDiv() {
    const playerElement = document.getElementById(this.player.id_);
    const playerWrapper = playerElement.closest(`.${this.divClass}`);
    let adBlockElement = null;
    for (let i = 0; i < playerWrapper.children.length; i++) {
      if (playerWrapper.children[i].classList.contains(this.divClass)) {
        adBlockElement = playerWrapper.children[i];
        break;
      }
    }
    return adBlockElement;
  }

  /**
   * Dispose player silently
   * @private
   */
  disposePLayer() {
    try {
      log('AdBlock detected! Player object will be disposed');
      this.player.dispose();
    } catch (e) {
      log.error('AdBlock detected! Player object is disposed already', e);
    }
  }
}

export default AntiAdBlock;
