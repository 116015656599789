import { videojs } from 'global/window';
import Choices from 'choices.js';

import log from '../../../../log';
import { getTheMostSuitableTracks } from '../../../../utils/source-selection';
import getLocalizedSources from '../../../../multilingual-match';
import sourceFormatter from '../../../../source-formatter';
import { changeTSSource } from '../../../../utils/source';
import { defaults } from '../../../time-shift/ts-state';
import { LAN_NOT_FOUND } from '../../../../errors';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 * Stream language can be clicked to set different audio stream
 *
 * @param {Player|Object} player
 * @param {Object=} options
 */
class LanguagesSelectMenu extends ClickableComponent {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent(options.languages);
  }

  createEl() {
    return super.createEl('select', {
      className: 'vjs-languages-select',
    });
  }

  createContent(languages = []) {
    const selectEl = this.el_;

    if (selectEl) {
      this.player_.ready(() => {
        const filteredLanguages = languages.map((language) => ({
          value: language.srclang,
          label: language.label,
        }));

        if (filteredLanguages?.length) {
          this.selectComponent = new Choices(selectEl, {
            searchEnabled: false,
            shouldSort: false,
            choices: filteredLanguages,
          });

          this.selectComponent.passedElement.element.addEventListener('change', (event) => {
            const srclang = event.detail.value;

            log('[Language control] option selected!:', srclang);

            this.player_.trigger({
              type: 'ott-change-language',
              lang: srclang,
            });

            if (this.player_.usingPlugin('uhdController')) {
              const uhdController = this.player_.uhdController();
              if (uhdController && uhdController.uhdActive) {
                return;
              }
            }

            const { tracks, preferredTech } = this.player_.options_;
            const tracksPlayableByTheBrowser = getTheMostSuitableTracks(tracks, preferredTech);
            const localizedSources = getLocalizedSources(tracksPlayableByTheBrowser, [srclang]);

            if (localizedSources.length > 0) {
              log('[Language control] Player is going to play: ', localizedSources[0]);
              const currentTime = this.player_.currentTime();
              this.player_.pause();
              const { seekTime } = this.player_[defaults.objName] || {};
              changeTSSource(this.player_, sourceFormatter(localizedSources[0]), seekTime);
              this.player_.one(['loadeddata', 'canplay'], () => {
                this.player_.currentTime(currentTime);
                this.player_.play();
                this.player_.trigger({
                  type: 'ott-language-changed',
                  source: localizedSources[0],
                });
              });

              if (!this.player_.reset.resetData.data.resetStore) {
                this.player_.reset.resetData.data.resetStore = {};
              }
              this.player_.reset.resetData.data.resetStore.lang = srclang;
            } else {
              log.error(LAN_NOT_FOUND);
              this.player_.trigger({
                type: 'ott-custom-error',
                ...LAN_NOT_FOUND,
              });
            }
          });
        }
      });
    }
  }
}

Component.registerComponent('LanguagesSelectMenu', LanguagesSelectMenu);
export default LanguagesSelectMenu;
