import { videojs } from 'global/window';
import './quality-auto-close';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class QualityAutoOverlay extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('QualityAutoClose');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    this.contentEl_ = super.createEl('div', {
      className: 'vjs-quality-auto-info',
      innerHTML: `<div class="vjs-optout-overlay-inner">
          <div class="vjs-icon-info">
          </div>
          <div class="vjs-optout-overlay-text">
            <p class="dialog-title">${this.player_.localize('Auto quality of playing')}</p>
            <p>
              ${this.player_.localize(
                'If you choose this option, the player will continuously monitor the same connection speed and automatically adapt the playback by choosing the most suitable video quality. This is the so-called adaptive video streaming.',
              )}
            </p>
          </div>
        </div>`,
    });

    return this.contentEl_;
  }
}

Component.registerComponent('QualityAutoOverlay', QualityAutoOverlay);
export default QualityAutoOverlay;
