import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The quality button can be clicked to reset adaptive streaming selection
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityAutoButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.parent_ = options.parent;

    // set auto quality option on UHD switch on
    this.player_.on('ott-uhdprofile-switch', ({ enable }) => {
      if (enable) {
        this.addClass('active');
      }
    });
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-quality-auto active ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      innerHTML: `<span>${this.player_.localize('Auto')}</span>`,
    });
  }

  /**
   * Handle click on quality auto button. Reset adaptive selection to the normal.
   */
  handleClick() {
    if (!this.hasClass('active')) {
      this.options.qualitySelector.setAutoQuality();
      this.addClass('active');
      const bar = this.parent_.getChild('QualityBar');
      bar?.removeClass('active');
    }
  }
}
// Register the new component
Component.registerComponent('QualityAutoButton', QualityAutoButton);
export default QualityAutoButton;
