/**
 * <p>Player measuring functionality</p>
 * This plugin works as measuring wrapper for other reporting services (like GTM, ..). See 'services' section in the example below
 *
 * <h3>Initialization</h3>
 * <b>Played ticks</b> plugin must be initialized already. See the example below to understand how
 * to initialize this plugin.
 *
 * <h3>Custom events tracking:</h3>
 * <p>These events were made mainly for Gemius measuring service, however they can be used anywhere.
 * Every event contains video information, like video name, video length, product id, ...</p>
 * <ul>
 * <li>[ott-mea-pause]{@link OttPlayer#event:ott-mea-pause} - fired whenever the media has been paused</li>
 * <li>[ott-mea-play]{@link OttPlayer#event:ott-mea-play} - fired whenever the media begins or resumes playback
 * <li>[ott-mea-ended]{@link OttPlayer#event:ott-mea-play} - fired when the end of the media resource is reached
 * (currentTime == duration)</li>
 * <li>[ott-mea-program-loaded]{@link OttPlayer#event:ott-mea-program-loaded} - fired after each program is loaded
 * and basic information about program video is available.</li>
 * <li>[ott-mea-ad-loaded]{@link OttPlayer#event:ott-mea-ad-loaded} - fired after each ad is loaded and basic
 * information about ad video is available.</li>
 * <li>[ott-mea-skip]{@link OttPlayer#event:ott-mea-skip} - fired when ad is skipped.</li>
 * <li>[ott-mea-buffer]{@link OttPlayer#event:ott-mea-buffer} - fired when video is buffering.</li>
 * <li>[ott-mea-break]{@link OttPlayer#event:ott-mea-break} - fired when player stops to play a program in order
 * to emit commercial block.</li>
 * <li>[ott-mea-seek]{@link OttPlayer#event:ott-mea-seek} - fired when user is seeking.</li>
 * <li>[ott-mea-close]{@link OttPlayer#event:ott-mea-close} - fired when user closed the player.</li>
 * <li>[ott-mea-player-resolution-changed]{@link OttPlayer#event:ott-mea-player-resolution-changed} - fired when
 * player's resoultion changed.</li>
 * <li>[ott-mea-volume-changed]{@link OttPlayer#event:ott-mea-volume-changed} - fired when user changed volume.</li>
 * <li>[ott-mea-quality-changed]{@link OttPlayer#event:ott-mea-quality-changed} - fired when user changed video
 * quality.</li>
 * <li>[ott-mea-ad-play]{@link OttPlayer#event:ott-mea-ad-play} - fired whenever the player begins or resumes ad.</li>
 * </ul>
 * <p>Most events will contain ad identifier (adID), if they will be emitted in context of commercial block.</p>
 *
 * @example
{
  plugins: {
   measuring: {
      streamInfo: {
        name: 'Video Name',
        id: 'a32',
        length: -1  // Value is in seconds. When no value found -1 must be set
      },
      services: {
        // Basic GTM measuring (only play, pause and end events are measured). Use this plugin for the simple player without ads.
        gtmBasic: {
          // Optional. Event names can be overridden. Values below are default.
          eventNames: {
            videoStart: 'videoStart',
            videoPlay: 'videoResume',
            videoPause: 'videoPause',
            videoFinish: 'videoFinish',
          },
        }
        // Complex GTM measuring (all possible events are pushed to the data layer)
        gtmComplex: {
          // Optional. Event names can be overridden. Values below are default.
          eventNames: {
            videoPlay: 'video.play',
            videoAutoplay: 'video.autoplay',
            videoPause: 'video.pause',
            videoResume: 'video.resume',
            videoSeeking: 'video.seeking',
            videoBuffering: 'video.buffering',
            videoEnd: 'video.end',
            videoFullscreenOn: 'video.fullscreen.on',
            videoFullscreenOff: 'video.fullscreen.off',
            videoError: 'video.error',
            adClick: 'video.ads.click',
            adImpression: 'video.ads.impression',
            adSkip: 'video.ads.skip',
            adPlay: 'video.ads.play',
            adPause: 'video.ads.pause',
            adEnd: 'video.ads.end',
          },
        }
      }
   }
  }
}
 *
 * @module plugins/measuring
 */

/**
 * Information about stream (program) for measuring services.
 *
 * @typedef {object} StreamInfo
 * @property {string} programID            - MISID
 * @property {string} programName          - Obligatory Title of the content broadcasted in the net parts of the programme.
 *                                         E.g. “The North Remembers” – title of the episode of the “Game of Thrones” series.
 * @property {number} programDuration      - Obligatory The total duration of the content broadcasted in the net parts of the
 *                                         programme in seconds. When it is not possible to evaluate duration (e.g. in live
 *                                         streaming, like direct streaming of TV channel) the value of programDuration
 *                                         should be set to -1.
 * @property {string} programType          - Obligatory Type of content. Allowed values are: ‘Audio’, ‘Video’.
 * @property {string} series               - Optional Hierarchical description of Series or other content broadcasted in
 *                                         Series/Season model, slash separated.
 *                                         E.g. ‘Game of Thrones/Season 1’ or ‘Champions League/Season 2014-2015’
 * @property {string} typology             - Optional Hierarchical categorization of the content, which can be common for the
 *                                         market aligned to TV study provider (i.e. like Nielsen’s: Sport/Football, Movie/Class B).
 * @property {string} playerType           – 'player' or 'miniplayer' (for embedded player)
 * @property {string} videoType            – BONUS|MOVIE|…
 * @property {string} videoCategory        – Recepty|Sestřihy…
 * @property {string} videoID              – PrimaPlayID
 * @property {string} seasonNumber         – Season ID/number
 * @property {string} episode              – Name of the episode (available when productCategory is equal to the "Episode")
 */

/**
 * Information about stream (ad) for measuring services.
 *
 * @typedef {object} AdStreamInfo
 * @property {string} adID                    - Id of the ad.
 * @property {string} adName                  - Title of the advertisement (i.e. ‘Ferrari test drive’). Strongly advised to define it,
 *                                            to not rely only on separation via adID.
 * @property {number} adDuration              - Total length of the advertisement in seconds, integer value.
 * @property {string} adType                  - Type of advertisement. Proposed values are: ‘promo’, ‘spot’, ‘sponsor’.
 * @property {string} campaignClassification  - Hierarchical classification of the campaign, including: campaign name, brand, producer
 *                                            (slash separated).
 */

/**
 * @typedef {object} MeasuringOptions                         - Configuration object of measuring plugin.
 * @property {module:plugins/measuring~StreamInfo} streamInfo - Video stream info (like video id, length, name, ...)
 * @property {Object} services                                - Measuring services container
 * @property {Boolean} services.gtmBasic                      - Basic GTM measuring (only play, pause and end events are measured).
 *    Use this plugin for the simple player without ads.
 * @property {Boolean} services.gtmComplex                    - Complex GTM measuring (all possible events are pushed to the data layer)
 * @property {GemiusOptions} services.gemius                  - Gemius service configuration (when Gemius should be turned on)
 */

/**
 * @typedef {object} VideoInfo    - Basic information about the stream
 * @property {number} width       - Width of the video
 * @property {number} heigth      - Height of the video
 * @property {number} quality     - Quality of the video
 * @property {number} volume      - Volume between 0-1
 */

import MeasuringBase from './measuring/measuring-base';
import log from '../log';
import { MEA_ARGS_1, MEA_ARGS_2, MEA_PLUGIN } from '../errors';
import GTMBasic from './measuring/gtm-basic';
import GTMComplex from './measuring/gtm-complex';
import Gemius from './measuring/gemius';
// TODO: Check if this has to be imported
import './measuring/nielsen';
import Mux from './measuring/mux';
import Cerebroad from './measuring/cerebroad';
import { isAdPlaying, getPlayingAd, getAdInventoryInfo, hasPostroll } from './adservice/shared/ad-state';
import getInventory from './adservice/shared/get-inventory';
import avlAdType from './adservice/shared/ad-type';
import availableEvents from './measuring/shared/available-events';
import propTest from '../property-tester';
import { EVT_NOPOSTROLL } from '../constants';
import { Conviva } from './conviva';
import { getVideoQuality } from '../utils/quality-helper';

/**
 * Register watching all available events
 *
 * @param {Object} player                                  - The player instance.
 * @param {module:plugins/measuring~MeasuringOptions} opts - Configuration of the measuring.
 */
export class MeasuringCustom extends MeasuringBase {
  constructor(player, opts = {}) {
    super(player, opts);
    this.measuringType = 'custom';

    if (!opts.streamInfo) {
      log.error(MEA_ARGS_1.code, MEA_ARGS_1.message);
    }

    if (!opts.services && !opts.autoInit) {
      log.error(MEA_ARGS_2.code, MEA_ARGS_2.message);
    }

    if (!player.options_.plugins.playedTicks) {
      log.error(MEA_PLUGIN.code, MEA_PLUGIN.message);
    }

    // Information about program (comes from player configuration object)
    this.streamInfo = opts.streamInfo;

    // Information about current (last loaded) ad, or `null` at the beginning.
    this.adStreamInfo = null;

    this.adDuration = 0;

    // Prevent multiple pause/play firing (this occurs when someone fire play/pause event manually).
    this.pauseFired = true;

    // Should be true when, the player is in the middle of playing something (may be paused, but not finished)
    this.isPlaying = false;

    // It will be true until complete event is fired
    this.streamCompleted = false;

    // For watchng ticks
    this.ticks = 0;
    this.lastTick = 0;

    // Used in ott-mea-play event, it will be true for the first play trigger of program and then false.
    this.firstProgramPlay = true;

    // We need to know time when an event happens, however some events alter current time so we need to store it.
    this.currentProgramTime = 0;

    // stored current(last) width of the player (to detect resolution changes)
    this.currentPlayerWidth = player.el_.offsetWidth;

    // stored current(last) height of the player (to detect resolution changes)
    this.currentPlayerHeight = player.el_.offsetHeight;

    // ID of currently played program, it is used to call `PROGRAM_LOADED` event just once.
    this.currentProgramID = null;

    // ID of currently played ad, it is set during `AD_LOADED` event and nullified during `PLAY` event.
    // It is used to detect transitions between playing ads and program.
    this.currentAd = null;

    this.enabledCheckReachedCredits = false;
    this.reachedCredits = false;

    this.deviceType = this.getDeviceType();

    // for each ad only one adLoaded event
    this.adLoadedTriggered = false;

    // prevent sending ad-complete before ad starts to play
    this.adPlayTriggered = false;

    // [OTTS-1676] prevention before sending timeupdate after stop event
    this.adEnded = false;

    this.adTimeUpdateLastTimeReported = 0;
    this.timeUpdateLastTimeReported = 0;

    if (propTest(() => opts.services)) {
      this.initServices(opts.services);
    }

    this.log('plugin initialized');
  }

  initServices(services) {
    if (services.gtmBasic) {
      // Start google tag manager watcher
      const gtmBasic = new GTMBasic(this.player, services.gtmBasic);
      gtmBasic.startWatching();
    } else if (services.gtmComplex) {
      // Start google tag manager watcher
      const gtmComplex = new GTMComplex(this.player, services.gtmComplex);
      gtmComplex.startWatching();
      gtmComplex.sendDataToGTMEvent({
        streamInfo: this.streamInfo,
      });
    }

    let volume;

    if (this.player.muted()) {
      volume = 0;
    } else {
      volume = this.player.volume();
    }

    if (services.gemius) {
      // Start Gemius watcher
      const gemius = new Gemius(
        this.player,
        services.gemius,
        {
          width: this.currentPlayerWidth,
          height: this.currentPlayerHeight,
        },
        volume,
      );

      gemius.startWatching();
    }

    if (services.nielsen) {
      this.player.nielsen({ nielsenOptions: services.nielsen, streamInfo: this.streamInfo });
    }

    // FIXME: move Mux.init() from constructor
    if (services.mux) {
      const mux = new Mux(this.player, services.mux); // eslint-disable-line
    }

    // FIXME: move Cerebroad.init() from constructor
    if (services.cerebroad) {
      const cerebroad = new Cerebroad(this.player, services.cerebroad, this.streamInfo); // eslint-disable-line
    }

    if (services.conviva) {
      // assign it to the variable, so the conviva session can be disposed
      // when all the measuring is disposed
      this.conviva = new Conviva(this.player, services.conviva);
    }
  }

  /**
   * Check ticks and count watched seconds from the last event
   *
   * @return {Number} Watched time in seconds
   */
  getWatchedTime() {
    const curTicks = this.ticks;
    const watched = curTicks - this.lastTick;
    this.lastTick = curTicks;

    return watched;
  }

  /**
   * Gets current basic info about video player
   *
   * @return {object} Object with information.
   */
  getVideoInfo() {
    let volume;

    if (this.player.muted()) {
      volume = 0;
    } else {
      volume = this.player.volume();
    }

    return {
      width: this.player.currentWidth(),
      height: this.player.currentHeight(),
      quality: getVideoQuality(this.player),
      volume,
    };
  }

  /**
   * Returns ad stream info if ad is playing, or null when ad is not loaded yet, or undefined if it isn't playing
   */
  getAdStreamInfo() {
    if (this.adStreamInfo !== null) return this.adStreamInfo;
    return undefined;
  }

  updateCurrentProgram() {
    if (this.streamInfo) {
      this.currentProgramID = this.streamInfo.programID;
    }
  }

  updateCurrentAd() {
    this.currentAd = getPlayingAd(this.player);
  }

  disposeCurrentAd() {
    this.currentAd = null;
  }

  checkReachedCredits(currentTime) {
    const checkedDuration = this.player.options_.durationWithoutCredits;
    if (!this.reachedCredits && currentTime >= checkedDuration) {
      this.triggerReachedCredits();
    }
  }

  /**
   * Gets informations about AD block will be played
   * name = AD block URL
   * ads_count = count ads in block
   *
   * @returns {Object}
   */
  getAdsInventory() {
    const { inventory, currentAdtype } = this.player.adstate;
    let adsInventory;

    if (currentAdtype === avlAdType.csaiMidrolls) {
      adsInventory = inventory.csaiMidrolls;
    } else if (currentAdtype !== avlAdType.midrolls) {
      adsInventory = inventory[this.player.adstate.currentAdtype];
    } else {
      adsInventory = inventory.midrolls[this.player.adstate.currentMidrollIndex];
    }

    return {
      name: adsInventory.vast,
      ads_count: adsInventory.ads.length,
    };
  }

  /**
   * Returns true, if new ad hasn't been loaded yet
   */
  isCurrentAdLoaded() {
    return this.currentAd === getPlayingAd(this.player);
  }

  /**
   * Returns true, if there was no ad loaded yet
   */
  isFirstAd() {
    return this.currentAd === null;
  }

  /**
   * Returns true, if there was no ad loaded yet
   */
  isProgramLoaded() {
    return this.currentProgramID !== null || this.currentProgramID !== undefined;
  }

  /**
   * Registers all available event.
   */
  registerEvents() {
    if (this.player.options_.durationWithoutCredits) {
      this.enabledCheckReachedCredits = true;
      this.log(`check reached credits enabled => wait for ${this.player.options_.durationWithoutCredits}s`);
    }

    // Save watched seconds to local value
    this.player.on('ott-playertick', (obj) => {
      this.ticks = obj.ticks;
      this.currentProgramTime = this.getCurrentTime();

      // register check duration without credits
      if (this.enabledCheckReachedCredits) {
        this.checkReachedCredits(this.currentProgramTime);
      }
    });

    // Register program related events
    this.player.on('loadedmetadata', () => {
      const adPlaying = isAdPlaying(this.player);
      const ad = getPlayingAd(this.player);
      this.log(`recieve loadedmetadata - in admode(${adPlaying})`);
      if (adPlaying) {
        if (ad && (this.adStreamInfo === null || !this.isCurrentAdLoaded())) {
          if (this.isCurrentProgramLoaded() && this.isFirstAd()) {
            this.triggerBreak();
          }
        }
      }
    });

    this.player.on('ready', () => {
      if (!this.isCurrentProgramLoaded()) {
        this.triggerProgramLoaded();
      }
    });

    // Force 'PROGRAM_LOADED' even if the video source has not changed. It is used, for example, if VPAID plays in its own video element in the iframe.
    this.player.on('loadedmetadata-force', () => {
      if (!isAdPlaying(this.player)) {
        this.triggerProgramLoaded();
      }
    });

    // Register ad related events
    // this.player.on('adloadedmetadata', this.triggerAdLoaded.bind(this));

    // triggerAdComplete is set twice
    // this.player.on('adended', () => {
    //   this.triggerAdComplete();
    // });

    // Register common events
    /* ✔️ */ this.player.on('playing', this.triggerPlay.bind(this));
    /* ✔️ */ this.player.on('pause', this.triggerPause.bind(this));
    /* ⨯ */ this.player.on(['ended', 'contentended'], this.triggerEnded.bind(this));
    /* ⨯ */ this.player.on('waiting', this.triggerBuffer.bind(this));
    /* ⨯ */ this.player.on('seeking', this.triggerSeek.bind(this));
    /* ✔️ */ this.player.on('timeupdate', this.triggerTimeupdate.bind(this));
    /* ⨯ */ this.player.on(['volumechange', 'advolumechange'], this.triggerVolumeChange.bind(this));
    /* ✔️ */ this.player.on('ott-quality-changed', this.triggerQualityChanged.bind(this));
    /* ✔️ */ this.player.on('ott-vhs-quality-auto', this.triggerQualityChanged.bind(this, 'auto'));
    /* ✔️ */ this.player.on('fullscreenchange', this.triggerFullscreenChanged.bind(this));
    /* ✔️ */ this.player.on('error', this.triggerError.bind(this));
    /* ✔️ */ this.player.on('linearclickthrough', this.triggerLinearClickThrough.bind(this));
    /* ⨯ */ this.player.on('companionstarted', this.triggerCompanionStarted.bind(this));
    /* ⨯ */ this.player.on('companionclickthrough', this.triggerCompanionClickThrough.bind(this));
    /* ⨯ */ this.player.on('companionended', this.triggerCompanionEnded.bind(this));
    /* ⨯ */ this.player.on('overlaycanplay', this.triggerOverlayCanPlay.bind(this));
    /* ⨯ */ this.player.on('overlayclickthrough', this.triggerOverlayClickThrough.bind(this));
    /* ⨯ */ this.player.on('overlayended', this.triggerOverlayEnded.bind(this));
    /* ✔️ */ this.player.on('adstart', this.triggerAdsBlockStart.bind(this));
    /* ✔️ */ this.player.on('adend', this.triggerAdsBlockEnd.bind(this));
    /* ✔️ */ this.player.on('adplay', this.triggerAdPlay.bind(this));
    /* ✔️ */ this.player.on('adended', this.triggerAdComplete.bind(this));
    /* ✔️ */ this.player.on('adpause', this.triggerAdPause.bind(this));
    /* ⨯ */ this.player.on(['postrollended', EVT_NOPOSTROLL], this.triggerPostrollCompleteWorkaround.bind(this));
    /* ✔️ */ this.player.on('adtimeupdate', this.triggerAdTimeupdate.bind(this));
    /* ✔️ */ this.player.on('vasttracker-firstQuartile', this.triggerAdQuartileProgress.bind(this, 'firstquartile'));
    /* ✔️ */ this.player.on('vasttracker-midpoint', this.triggerAdQuartileProgress.bind(this, 'midpoint'));
    /* ✔️ */ this.player.on('vasttracker-thirdQuartile', this.triggerAdQuartileProgress.bind(this, 'thirdquartile'));
    /* ✔️ */ this.player.on('ott-playertick', this.triggerVideoPlayed.bind(this));

    // register global window events
    this.registerGlobalWindowListener('beforeunload', this.triggerClose.bind(this));
    this.registerGlobalWindowListener('unload', this.triggerClose.bind(this));
    this.registerGlobalWindowListener('pagehide', this.triggerClose.bind(this));
    this.registerGlobalWindowListener('resize', this.triggerPlayerResolutionChanged.bind(this));
    this.registerGlobalWindowListener('playerresize', this.triggerPlayerResolutionChanged.bind(this));
  }

  triggerProgramLoaded() {
    /**
     * Program loaded event. Fired when meta information about the program stream is available.
     *
     * @event OttPlayer#ott-mea-program-loaded
     * @property {"ott-mea-program-loaded"} type
     * @property {module:plugins/measuring~StreamInfo} streamInfo  - Meta information about program stream
     * @property {module:plugins/measuring~VideoInfo} videoInfo    - Information about video
     * @property {string} deviceType                               - Device of the user, can be mobile, PC etc.
     * @requires plugins/measuring
     */
    const type = availableEvents.PROGRAM_LOADED;
    const data = {
      type,
      streamInfo: this.streamInfo,
      videoInfo: this.getVideoInfo(),
      deviceType: this.deviceType,
      // duration: Math.round(this.player.duration()), - disabling duration for now - it can be invalid
    };

    this.triggerEvent(type, data);

    this.updateCurrentProgram();
  }

  triggerVideoPlayed({ ticks }) {
    /**
     * Video played for specified seconds event. Fires when the video has played for the specified number of seconds.
     */
    if (ticks === 60) {
      const type = availableEvents.PLAY_60S;
      const data = {
        type,
        streamInfo: this.streamInfo,
        videoInfo: this.getVideoInfo(),
        deviceType: this.deviceType,
        duration: Math.round(this.player.duration()),
      };

      this.triggerEvent(type, data);
    }
  }

  /**
   * Parse Data From Ad Description parameter
   * @param {String} adDescription ad description
   * @returns {Object} parsed data or empty object
   */
  parseJSONFromAdDescription(adDescription) {
    let data = {};
    if (!adDescription) return data;

    try {
      const rawData = JSON.parse(adDescription);

      if (rawData) {
        data = rawData;
        this.log('parsed data from description', data);
      }
    } catch (error) {
      // only for testing
      // this.log('parse data from description error', error);
    }

    return data;
  }

  /**
   * Enrich Ad data with JSON parsed data from ad description
   * Existing data on AD will not be overwritten
   * [OTTS-2035]
   * @param {Object} ad
   * @returns {Object} ad
   */
  enrichAdData(ad) {
    const allowListParams = ['akaCode', 'adCategory'];

    const dataFromDesc = this.parseJSONFromAdDescription(ad.description);
    Object.keys(dataFromDesc).forEach((key) => {
      if (!ad[key] && allowListParams.includes(key)) {
        ad[key] = dataFromDesc[key];
      }
    });

    if (dataFromDesc.description) {
      ad.description = dataFromDesc.description;
    }

    return ad;
  }

  triggerAdLoaded() {
    // Prevention sending ad loadMetadata if already triggered
    if (this.adLoadedTriggered) return;

    this.adLoadedTriggered = true;
    let ad = getPlayingAd(this.player);
    ad = this.enrichAdData(ad);

    // Parsed VAST duration
    const creativeDuration = (ad.creatives[0] || {}).duration;
    this.adDuration = creativeDuration || this.player.duration();

    // this adType can be csaiMidrolls which is only a helper type
    const adType = ad.type || getAdInventoryInfo(this.player).adType;

    // measuring cares only for preroll, midroll, postroll, companion
    const measuringAdType = adType === 'csaiMidrolls' ? 'midroll' : adType;

    this.adStreamInfo = {
      adID: ad.id,
      adDesc: ad.description,
      adName: ad.title,
      adDuration: this.adDuration,
      asmeaCode: ad.akaCode,
      adCategory: ad.adCategory,
      adType: measuringAdType,
      campaignClassification: ad.advertiser || 'unclassified',
    };

    /**
     * Ad loaded event. Fired when meta information about the ad is available.
     *
     * @event OttPlayer#ott-mea-ad-loaded
     * @property {"ott-mea-ad-loaded"} type
     * @property {module:plugins/measuring~AdStreamInfo} adStreamInfo - Meta information about the ad
     * @property {module:plugins/measuring~VideoInfo} videoInfo       - Information about the video
     * @requires plugins/measuring
     */
    const type = availableEvents.AD_LOADED;
    const data = {
      type,
      adStreamInfo: this.adStreamInfo,
      currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
      currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
      videoInfo: this.getVideoInfo(),
      streamInfo: this.streamInfo,
    };

    this.triggerEvent(type, data);

    this.updateCurrentAd();
  }

  triggerAdPlay() {
    if (isAdPlaying(this.player)) {
      // Prevention sending ad loadMetadata whenever user pause and resume is handled in adLoadedTriggered
      this.triggerAdLoaded();

      // Trigger whenever ad starts to play (there could be `pauseFired` check, but until now it hasn't been neccessary)
      this.adEnded = false;

      this.adPlayTriggered = true;

      const adInventoryInfo = getAdInventoryInfo(this.player);
      // fixme
      const adStreamInfo = this.getAdStreamInfo();

      if (adInventoryInfo && adStreamInfo) {
        /**
         * Ad play event. Fired when ad starts to play, on resume or on the beginning
         *
         * @event OttPlayer#ott-mea-ad-play
         * @property {"ott-mea-ad-play"} type
         * @property {module:plugins/measuring~StreamInfo} streamInfo     - Meta information about the program stream.
         * @property {module:plugins/measuring~AdStreamInfo} adStreamInfo - Meta information about the ad.
         * @property {module:plugins/measuring~VideoInfo} videoInfo       - Information about the video.
         * @property {number} currentTime                                 - Time of the video when the event occured.
         * @property {boolean} autoplay                                   - True, when ad started to play automatically,
         *                                                                false when ad was resumed by user.
         * @requires plugins/measuring
         */
        const type = availableEvents.AD_PLAY;
        const data = {
          type,
          streamInfo: this.streamInfo,
          adStreamInfo,
          currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
          currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
          autoplay: !this.pauseFired,
          videoInfo: this.getVideoInfo(),
          ...adInventoryInfo,
        };

        this.triggerEvent(type, data);
      }
    }
  }

  triggerPlay() {
    if (!propTest(() => this.player.adstate.adPlaying) && this.isProgramLoaded() && this.pauseFired) {
      // if (this.pauseFired ||this.currentAdID !== null || this.adStreamInfo === null) {
      // Trigger PLAY event when a pause is active, or ad just has finished, or it is first play (no ad)

      /**
       * Play event. Fired when program (not ad) starts to play, on resume or on the beginning
       *
       * @event OttPlayer#ott-mea-play
       * @property {"ott-mea-play"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo     - Meta information about the program stream.
       * @property {module:plugins/measuring~VideoInfo} videoInfo       - Information about the video.
       * @property {number} currentTime                                 - Time of the video when the event occured.
       * @property {boolean} autoplay                                   - True, when program started to play automatically,
       *                                                                false when program was resumed by user.
       * @property {boolean} firstPlay                                  - True for the first trigger of the event, false
       *                                                                in the next triggers.
       * @requires plugins/measuring
       */

      const type = availableEvents.PLAY;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        autoplay: this.firstProgramPlay,
        /* TODO: add property `partID` - Position of the partial in program from 1..n. If due
        to configuration of the service, user can start program from the middle of second partial
        this number is 2 – absolute position of the part viewed. */
        videoInfo: this.getVideoInfo(),
        firstPlay: this.firstProgramPlay,
      };

      this.triggerEvent(type, data);

      this.firstProgramPlay = false;
      this.pauseFired = false;
      this.disposeCurrentAd();
      this.isPlaying = true;
    }
  }

  triggerTimeupdate() {
    if (!isAdPlaying(this.player) && this.isPlaying && !this.pauseFired) {
      const type = availableEvents.TIMEUPDATE;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
      };

      // log timeupdate once in second
      let logEnabled = false;
      const logMessage = `${type} - limited logging 1 event/second`;
      const tickTimeRounded = Math.floor(this.currentProgramTime);
      if (tickTimeRounded !== this.timeUpdateLastTimeReported) {
        this.timeUpdateLastTimeReported = tickTimeRounded;
        logEnabled = true;
      }

      this.triggerEvent(logMessage, data, logEnabled);
    }
  }

  triggerAdTimeupdate() {
    if (isAdPlaying(this.player) && !this.adEnded) {
      // Trigger whenever ad starts to play (there could be `pauseFired` check, but until now it hasn't been neccessary)
      const adInventoryInfo = getAdInventoryInfo(this.player);
      const adStreamInfo = this.getAdStreamInfo();

      if (adInventoryInfo && adStreamInfo) {
        const type = availableEvents.AD_TIMEUPDATE;
        const data = {
          type,
          streamInfo: this.streamInfo,
          adStreamInfo,
          currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
          currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
          autoplay: !this.pauseFired,
          videoInfo: this.getVideoInfo(),
          ...adInventoryInfo,
        };

        // log ad timeupdate once in second
        let logEnabled = false;
        const logMessage = `${type} - limited logging 1 event/second`;
        const tickTimeRounded = Math.floor(this.currentProgramTime);
        if (tickTimeRounded !== this.timeUpdateLastTimeReported) {
          this.adTimeUpdateLastTimeReported = tickTimeRounded;
          logEnabled = true;
        }

        this.triggerEvent(logMessage, data, logEnabled);
      }
    }
  }

  triggerPause() {
    if (this.isPlaying && !this.pauseFired && this.isProgramLoaded()) {
      /**
       * Pause event. Fired when program or ad is paused automatically or by the user.
       *
       * @event OttPlayer#ott-mea-pause
       * @property {"ott-mea-pause"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {number} lengthPlayed                                  - Length played from the last pause event.
       * @requires plugins/measuring
       */
      if (!isAdPlaying(this.player) && this.currentProgramTime === Math.floor(this.player.durationOfActiveMedia())) {
        const type = 'vodcontentend';
        const data = {
          type,
          streamInfo: this.streamInfo,
          currentTime: this.currentProgramTime,
          adStreamInfo: this.getAdStreamInfo(),
          lengthPlayed: this.getWatchedTime(),
        };

        this.triggerEvent(type, data);
      }

      const type = availableEvents.PAUSE;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adStreamInfo: this.getAdStreamInfo(),
        lengthPlayed: this.getWatchedTime(),
      };

      this.triggerEvent(type, data);
      this.pauseFired = true;
    }
  }

  triggerAdPause() {
    if (isAdPlaying(this.player) && this.isProgramLoaded()) {
      /**
       * Pause event. Fired when program or ad is paused automatically or by the user.
       *
       * @event OttPlayer#ott-mea-pause
       * @property {"ott-mea-pause"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {number} lengthPlayed                                  - Length played from the last pause event.
       * @requires plugins/measuring
       */

      const type = availableEvents.AD_PAUSE;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
        currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
        adStreamInfo: this.getAdStreamInfo(),
        lengthPlayed: this.getWatchedTime(),
      };

      this.triggerEvent(type, data);
      this.pauseFired = true;
    }
  }

  triggerPostrollCompleteWorkaround() {
    if (!this.player.ended()) return;
    if (!this.streamCompleted) {
      this.triggerComplete(this);
    }
  }

  triggerComplete(obj) {
    this.streamCompleted = true;
    const type = availableEvents.COMPLETE;
    const data = {
      type,
      streamInfo: obj.streamInfo,
      currentTime: obj.currentProgramTime,
      adStreamInfo: obj.getAdStreamInfo(),
      lengthPlayed: obj.getWatchedTime(),
    };

    this.triggerEvent(type, data);
  }

  triggerAdComplete(e) {
    if (this.adPlayTriggered) {
      this.adLoadedTriggered = false;

      this.adPlayTriggered = false;

      this.adEnded = true;

      // there are two triggers for COMPLETE so skipped property needs to be present
      if (propTest(() => e.skipped) && this.player.currentTimeOfActiveMedia() !== this.player.durationOfActiveMedia()) {
        this.triggerSkip();
        return;
      }

      const type = availableEvents.AD_COMPLETE;

      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
        currentAdTime: this.adDuration,
      };

      this.triggerEvent(type, data);

      this.adDuration = 0;
    }
  }

  triggerEnded() {
    if (this.isPlaying && !this.streamCompleted && !isAdPlaying(this.player)) {
      /**
       * Ended event. Fired when program or ad has finished playing.
       *
       * @event OttPlayer#ott-mea-ended
       * @property {"ott-mea-ended"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {number} lengthPlayed                                  - Length played from the last pause event.
       * @requires plugins/measuring
       */

      const type = availableEvents.ENDED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adStreamInfo: this.getAdStreamInfo(),
        lengthPlayed: this.getWatchedTime(),
      };

      this.triggerEvent(type, data);

      this.isPlaying = false;

      if (hasPostroll(this.player)) {
        const postrollInventory = getInventory(this.player.adstate, avlAdType.postroll);
        if (postrollInventory.requested && postrollInventory.played === postrollInventory.ads.length) {
          this.triggerComplete(this);
        }
      } else if (!isAdPlaying(this.player)) {
        this.triggerComplete(this);
      }
    }
    this.pauseFired = true;
  }

  triggerBuffer() {
    // Trigger buffer event, when there isn't enough data to play.
    // It should be triggered only if program or ad is already loaded
    if (this.isCurrentProgramLoaded() && (!isAdPlaying(this.player) || this.isCurrentAdLoaded())) {
      /**
       * Buffer event. Fired when program or ad is buffering and playing was stopped because of that.
       *
       * @event OttPlayer#ott-mea-buffer
       * @property {"ott-mea-buffer"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @requires plugins/measuring
       */

      const type = availableEvents.BUFFER;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerBreak() {
    if (this.isPlaying) {
      /**
       * Program break event. Fired when program stopped playing in order to play an commercial block.
       *
       * @event OttPlayer#ott-mea-break
       * @property {"ott-mea-ad-break"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @requires plugins/measuring
       */

      const type = availableEvents.BREAK;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
      };

      this.triggerEvent(type, data);

      this.isPlaying = false;
    }
  }

  triggerSeek() {
    // isPlaying means that player is not in ad mode and not ended - this prevent sending seek after complete event
    if (this.isPlaying && this.isProgramLoaded() && !isAdPlaying(this.player)) {
      // seeking only occurs, if program time changes
      /**
       * Seek event. Fired when program or ad is seeked by the user.
       *
       * @event OttPlayer#ott-mea-seek
       * @property {"ott-mea-seek"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @requires plugins/measuring
       */
      const type = availableEvents.SEEK;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
      };

      this.triggerEvent(type, data);
    }
    this.currentProgramTime = this.getCurrentTime();

    if (this.getCurrentTime() < this.player.options_.durationWithoutCredits && this.reachedCredits) {
      this.log('check reached credits reinit - user seek back in stream');
      this.reachedCredits = false;
    }
  }

  triggerClose() {
    /**
     * Close event. Fired when program or ad was ended because the user closed window or navigated elsewhere.
     *
     * @event OttPlayer#ott-mea-close
     * @property {"ott-mea-close"} type
     * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
     * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
     *                                                                  happened during the ad.
     * @property {number} currentTime                                   - Time of the program or ad when the event occured.
     * @requires plugins/measuring
     */

    const type = availableEvents.CLOSE;
    const data = {
      type,
      streamInfo: this.streamInfo,
      adStreamInfo: this.getAdStreamInfo(),
      currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
      currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
    };

    this.triggerEvent(type, data);
  }

  triggerSkip() {
    if (this.isProgramLoaded()) {
      /**
       * Skip event. Fired when program or is skipped by the user.
       *
       * @event OttPlayer#ott-mea-skip
       * @property {"ott-mea-skip"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @requires plugins/measuring
       */
      const type = availableEvents.SKIP;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
        currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
      };

      this.triggerEvent(type, data);
    }
  }

  triggerVolumeChange() {
    if (this.isProgramLoaded()) {
      let volume;

      if (this.player.muted()) {
        volume = 0;
      } else {
        volume = this.player.volume();
      }

      /**
       * Volume change event. Fired when user changed volume or muted the player.
       *
       * @event OttPlayer#ott-mea-volume-changed
       * @property {"ott-mea-volume-changed"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {number} volume                                        - New volume.
       * @requires plugins/measuring
       */

      const type = availableEvents.VOLUME_CHANGED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
        volume,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerPlayerResolutionChanged() {
    if (
      this.isProgramLoaded() &&
      (this.player.el_.offsetWidth !== this.currentPlayerWidth ||
        this.player.el_.offsetHeight !== this.currentPlayerHeight)
    ) {
      this.currentPlayerWidth = this.player.el_.offsetWidth;
      this.currentPlayerHeight = this.player.el_.offsetHeight;

      /**
       * Player resolution change event. Fired when resolution of the player has changed.
       *
       * @event OttPlayer#ott-mea-player-resolution-changed
       * @property {"ott-mea-player-resolution-changed"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {object} resolution                                    - New dimensions of the player.
       * @property {number} resolution.width
       * @property {number} resolution.height
       * @requires plugins/measuring
       */

      const type = availableEvents.PLAYER_RESOLUTION_CHANGED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
        resolution: {
          width: this.currentPlayerWidth,
          height: this.currentPlayerHeight,
        },
      };

      this.triggerEvent(type, data);
    }
  }

  triggerQualityChanged(ev) {
    if (this.isProgramLoaded()) {
      /**
       * Quality change event. Fired when user changed quality of the video.
       *
       * @event OttPlayer#ott-mea-quality-changed
       * @property {"ott-mea-quality-changed"} type
       * @property {module:plugins/measuring~StreamInfo} streamInfo       - Meta information about the program stream.
       * @property {module:plugins/measuring~AdStreamInfo} [adStreamInfo] - Meta information about the ad, if the event
       *                                                                  happened during the ad.
       * @property {number} currentTime                                   - Time of the program or ad when the event occured.
       * @property {string} quality                                       - New quality.
       * @requires plugins/measuring
       */

      const type = availableEvents.QUALITY_CHANGED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
        quality: ev.qualityIdx,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerFullscreenChanged() {
    if (this.isProgramLoaded()) {
      const type = availableEvents.FULLSCREEN_CHANGED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        adStreamInfo: this.getAdStreamInfo(),
        currentTime: this.currentProgramTime,
        isFullscreen: this.player.isFullscreen(),
      };

      this.triggerEvent(type, data);
    }
  }

  triggerError() {
    const error = this.player.error();
    const type = availableEvents.ERROR;
    const data = {
      type,
      streamInfo: this.streamInfo,
      adStreamInfo: this.getAdStreamInfo(),
      currentTime: this.currentProgramTime,
      error,
    };

    this.triggerEvent(type, data);
  }

  triggerLinearClickThrough() {
    if (this.isProgramLoaded()) {
      const type = availableEvents.LINEAR_CLICK_THROUGH;
      const data = {
        type,
        adStreamInfo: this.getAdStreamInfo(),
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerCompanionStarted(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.COMPANION_STARTED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adCompanionInfo: e.companion,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerCompanionClickThrough(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.COMPANION_CLICK_THROUGH;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adCompanionInfo: e.companion,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerCompanionEnded(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.COMPANION_ENDED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adCompanionInfo: e.companion,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerOverlayCanPlay(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.OVERLAY_CAN_PLAY;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adOverlayInfo: e.overlay,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerOverlayClickThrough(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.OVERLAY_CLICK_THROUGH;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adOverlayInfo: e.overlay,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerOverlayEnded(e) {
    if (this.isProgramLoaded()) {
      const type = availableEvents.OVERLAY_ENDED;
      const data = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.currentProgramTime,
        adOverlayInfo: e.overlay,
      };

      this.triggerEvent(type, data);
    }
  }

  triggerAdsBlockStart() {
    const type = availableEvents.ADS_BLOCK_START;
    const data = {
      type,
      streamInfo: this.streamInfo,
      inventory: this.getAdsInventory(),
    };

    this.triggerEvent(type, data);
  }

  triggerAdsBlockEnd() {
    const type = availableEvents.ADS_BLOCK_END;
    const data = {
      type,
      streamInfo: this.streamInfo,
      adStreamInfo: this.getAdStreamInfo(),
      currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
      currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
    };

    this.triggerEvent(type, data);
  }

  triggerReachedCredits() {
    const type = availableEvents.REACHED_CREDITS;
    const data = {
      type,
      streamInfo: this.streamInfo,
    };

    this.triggerEvent(type, data);
    this.reachedCredits = true;
  }

  triggerAdQuartileProgress(eventType) {
    let type = null;
    switch (eventType) {
      case 'firstquartile':
        type = availableEvents.AD_FIRSTQUARTILE;
        break;
      case 'midpoint':
        type = availableEvents.AD_MIDPOINT;
        break;
      case 'thirdquartile':
        type = availableEvents.AD_THIRDQUARTILE;
        break;
      default:
        break;
    }

    const data = {
      type,
      adStreamInfo: this.adStreamInfo,
      videoInfo: this.getVideoInfo(),
      streamInfo: this.streamInfo,
      currentTime: propTest(() => Math.floor(this.player.ads.snapshot.currentTime)) || 0,
      currentAdTime: Math.floor(this.player.currentTimeOfActiveMedia()),
    };

    this.triggerEvent(type, data);
  }

  dispose() {
    // there should be some beforeDisposeQueue for all the plugins to put there some callback to call before
    // the measuring is disposed, instead of if (this.service) { ... }
    if (this.conviva) {
      this.conviva.dispose();
      super.dispose();
    }
  }
}
