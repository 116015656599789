import { videojs } from 'global/window';
import log from '../log';

import PersistSettings from './settings/persist-settings';

const Plugin = videojs.getPlugin('plugin');

export class AutoPause extends Plugin {
  static isDisposed = false;

  constructor(player, options = {}) {
    super(player, options);
    this.options = options;
    this.player = player;

    this.init();
  }

  init() {
    const { beforeMidroll } = this.options;
    const persistSettingsOptions = (this.player.options_.plugins.settings || {}).persistSettings || {};
    const persistSettingsId = (persistSettingsOptions || {}).id;
    const persistSettings = new PersistSettings(this.player, persistSettingsOptions);
    const { muted } = (persistSettings.getCookieSettings() || {})[persistSettingsId];

    if (!beforeMidroll) {
      log(`[AutoPause]: autopause not enabled (beforeMidroll: ${beforeMidroll})`);
      this.dispose();
      return;
    }

    log('[AutoPause]: initializing autopause before first midroll');

    const checkAutoPause = () => {
      const player = this.player;
      log('[AutoPause]: autopause triggers, event "ott-autopause-start"');

      // "pause" of the player is triggered in "/src/js/shared/plugins/adservice-custom.js"
      // all necessary stuff do here

      player.one(['adstart', 'play'], () => {
        if (muted) {
          log(`[AutoPause]: can't unmute player (PersistSettings parameter muted="true")`);
        } else {
          // unmute player when ad started to play or video because there are no ads
          log('[AutoPause]: unmute player');
          player.muted(false);
          if (player.volume() === 0) {
            log('[AutoPause]: change volume to 1');
            // just in case - set volume to 1 if still is muted
            // this could be related to persist-settings cookie PERSIST_SETTINGS_COOKIE_NAME
            // {[ID]: { "volume":1, "muted":true }}
            // OR "muted: true" defined in player config
            player.volume(1);
          }
        }
        if (player) {
          disableAutoPause('pause');
        }
      });
    };

    const disableAutoPause = (type, keyCode) => {
      if (this.player && type && !AutoPause.isDisposed) {
        // if not keydown event or
        // 32 "space" keydown event (pause)
        // 37 "leftArrow" keydown event (seek backward)
        // 39 "rightArrow" keydown event (seek forward)
        if (!keyCode || keyCode === 32 || keyCode === 37 || keyCode === 39) {
          // change static variable
          AutoPause.isDisposed = true;
          // remove the listener from the player
          this.player.off('ott-autopause-start', checkAutoPause);
          // dispose plugin
          this.dispose();
          log(`[AutoPause]: autopause disposed on ${type}${keyCode ? `: ${keyCode}` : ''}`);
        }
      }
    };

    this.player.one('ott-autopause-start', () => {
      checkAutoPause();
    });

    this.player.one('canplay', () => {
      // could be refactored as custom SeekBar component with handleClick method and new trigger event
      // but after the answer/fix on https://github.com/videojs/video.js/issues/8171
      const seekBar = document.getElementsByClassName('vjs-progress-holder vjs-slider')[0];
      seekBar.addEventListener('click', () => {
        disableAutoPause('seek-bar-click');
      });

      this.player.one(
        [
          'ott-pause-screen-click',
          'ott-pause-toggle-click',
          'volumechange',
          'fullscreenchange',
          'ott-mea-quality-changed',
          'keydown',
        ],
        ({ type, keyCode }) => {
          disableAutoPause(type, keyCode);
        },
      );
    });
  }
}
