import { videojs, document, navigator, fetch } from 'global/window';
import log from '../../log';
/* eslint-disable */
import LiveRestartOverlay from './opt-out-overlay';
/* eslint-enable */
import { LR_PLUGIN } from '../../errors';
import { getStreamInfo } from '../../utils/source';
import { DEFAULT_HIDDEN_CLASS } from '../../constants';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The help button send event for showing customer contact form
 *self
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class LiveRestartButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.options = options;
    this.controlEventName = 'ott-liverestart-click';
    this.controlText(this.player.localize('Play from beginning'));
    this.streamInfo = getStreamInfo(this.player);
    this.isTimeshift = !this.player.options_.plugins.timeShift;
    this.player.addChild('LiveRestartOverlay');

    // TimeShift plugin is required
    if (this.isTimeshift) {
      log.error(LR_PLUGIN.code, LR_PLUGIN.message);
      return;
    }

    // Disable/hide liveRestart UI
    this.player.disableLiveRestart = () => {
      if (!this.hasClass(DEFAULT_HIDDEN_CLASS)) {
        this.addClass(DEFAULT_HIDDEN_CLASS);
      }
    };

    // Enable/show liveRestart UI
    this.player.enableLiveRestart = () => {
      if (this.hasClass(DEFAULT_HIDDEN_CLASS)) {
        this.removeClass(DEFAULT_HIDDEN_CLASS);
      }
    };

    // Timeshift to program start returned from API
    this.player.timeshiftSeekToProgramStart = () => {
      this.fetchProgramStartFromAPI();
    };
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-live-restart-button ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    const isEventsOnly = this.options_.eventsOnly;

    return this.options_.liveRestartEndpointUrl
      ? super.createEl('div', {
          className: `vjs-live-restart-button vjs-control${!this.isTimeshift || isEventsOnly ? ' vjs-hidden' : ''}`,
        })
      : '';
  }

  /**
   * Handle click on help button.
   */
  hideSettingsMenuPlugin() {
    const menu = this.player.getChild('SettingsMenu');
    if (typeof menu !== 'undefined') {
      if (!menu.hasClass('vjs-hidden')) {
        menu.addClass('vjs-hidden');
        this.player.removeClass('vjs-popup-displayed');
      }
    }
  }

  /**
   * Handle click on help button.
   */
  handleClick() {
    if (!this.options_.eventsOnly) {
      this.fetchProgramStartFromAPI();
    }
    this.triggerEvent();
  }

  showInfoWindow() {
    const cssClass = 'vjs-liverestart-showing';

    if (!this.player.hasClass(cssClass)) {
      this.player.addClass(cssClass);
      this.player.pause();
    }

    this.hideSettingsMenuPlugin();
  }

  seekToProgramStart(offset, date) {
    let seekTime = '';
    this.player.pause();

    if (offset) {
      seekTime = offset;
    } else {
      const now = Date.now();
      const time = new Date(date).getTime();
      seekTime = Math.floor((now - time) / 1000);

      log(`[Live Restart] now is ${now}ms and program start is ${time}ms`);
    }

    log(`[Live Restart] seeking back for ${seekTime} seconds.`);

    this.player.timeshiftSeek(seekTime);
  }

  fetchProgramStartFromAPI() {
    const self = this;
    const endpointUrl = self.options_.liveRestartEndpointUrl;
    const channelId = self.options_.liveRestartChannelId;
    // get data from endpoint
    const url = `${endpointUrl}?channelId=${channelId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((json) => {
        if (json.liveRestartOffsetSeconds) {
          log(`[Live restart] program starts ${json.liveRestartOffsetSeconds} seconds ago`);
          self.seekToProgramStart(json.liveRestartOffsetSeconds, null);
        } else if (json.liveRestartProgramStart) {
          log(`[Live restart] program starts at ${json.liveRestartProgramStart}`);
          self.seekToProgramStart(null, json.liveRestartProgramStart);
        } else {
          log(`[Live restart] ${json.errorCode}`);
          if (json.userMessage) {
            document.querySelectorAll('.vjs-liverestart-overlay-text-placeholder')[0].innerHTML = json.userMessage;
          }
          self.showInfoWindow();
        }
      })
      .catch((error) => {
        log(`[Live restart] ${error}`);
      });
  }

  triggerEvent() {
    const liveRestartData = {
      currentTime: this.player.currentTime(),
      videoId: this.streamInfo.videoId || this.streamInfo.programID,
      playerVersion: this.player.VERSION,
      userAgent: navigator.userAgent,
    };

    this.player.trigger({
      type: this.controlEventName,
      liveRestartData,
    });

    log(`[live-restart-button plugin] trigger event ${this.controlEventName}`, liveRestartData);
  }
}
// Register the new component
Component.registerComponent('liveRestartButton', LiveRestartButton);
export default LiveRestartButton;
