import { VASTClient, VASTTracker } from '@dailymotion/vast-client';
import { AS_TRACKING_NOT_FOUND } from '../../../errors';
import log from '../../../log';
import availableEvents from '../../measuring/shared/available-events';

const vastClient = new VASTClient();

export class LinearTracker {
  /**
   * Linear VAST tracking helper class
   *
   * @param {Object} player   The player instance
   * @param {Object} ad       Current ad
   * @param {Object} creative Concrete creative
   */
  constructor(player) {
    this.player = player;
    this.previousMuteState = player.muted();
    this.listeners = {};
    this.quartileProgress = {
      firstQuartile: false,
      midpoint: false,
      thirdQuartile: false,
    };
  }

  /**
   * Register tracking events when DMVAST exist
   *
   * @param {Object} ad       Current ad
   * @param {Object} creative Concrete creative
   */
  track(ad, creative) {
    if (!this.player) {
      log.error(AS_TRACKING_NOT_FOUND.code, AS_TRACKING_NOT_FOUND.message);
      throw AS_TRACKING_NOT_FOUND;
    }

    this.disposePlayerTracker();

    // Init VAST tracker
    // eslint-disable-next-line
    this.vastTracker = new VASTTracker(vastClient, ad, creative);
    //
    this.vastTracker.customDispose = this.dispose.bind(this);
    this.player.vastTracker = this.vastTracker;

    this.bindListeners();
    this.registerListeners();
  }

  onCanPlay() {
    try {
      this.vastTracker.trackImpression();
      this.player.trigger(availableEvents.AD_IMPRESSION);
    } catch (e) {
      log.error('VAST impression URL is required!');
    }
  }

  onTimeUpdate() {
    this.vastTracker.setProgress(this.player.currentTime());
  }

  onPlay() {
    this.vastTracker.setPaused(false);
  }

  onPause() {
    this.vastTracker.setPaused(true);
  }

  onLinearClickthrough() {
    this.vastTracker.click();
  }

  onFullscreenChange() {
    this.vastTracker.setFullscreen(this.player.isFullscreen());
  }

  onSkipped() {
    this.vastTracker.skip();
  }

  onVolumeChange() {
    if (this.player.muted() && !this.previousMuteState) {
      this.vastTracker.setMuted(true);
    } else if (!this.player.muted() && this.previousMuteState) {
      this.vastTracker.setMuted(false);
    }

    // Store current state
    this.previousMuteState = this.player.muted();
  }

  onEnded(obj = {}) {
    if (obj.skipped) {
      this.onSkipped();
    } else if (
      this.quartileProgress.firstQuartile === true &&
      this.quartileProgress.midpoint === true &&
      this.quartileProgress.thirdQuartile === true
    ) {
      this.vastTracker.complete();
      log('[vastTracker] quartile progress OK - send complete()', this.quartileProgress);
    } else {
      log('[vastTracker] quartile progress FAIL', this.quartileProgress);
    }
    this.dispose();
  }

  onTrackerFirstQuartile() {
    this.quartileProgress.firstQuartile = true;
    this.player.trigger('vasttracker-firstQuartile');
    log('[vastTracker] firstQuartile fired !', this.quartileProgress);
  }

  onTrackerMidpoint() {
    this.quartileProgress.midpoint = true;
    this.player.trigger('vasttracker-midpoint');
    log('[vastTracker] midpoint fired !', this.quartileProgress);
  }

  onTrackerThirdQuartile() {
    this.quartileProgress.thirdQuartile = true;
    this.player.trigger('vasttracker-thirdQuartile');
    log('[vastTracker] thirdQuartile fired !', this.quartileProgress);
  }

  bindListeners() {
    this.listeners.onCanPlay = this.onCanPlay.bind(this);
    this.listeners.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.listeners.onPlay = this.onPlay.bind(this);
    this.listeners.onPause = this.onPause.bind(this);
    this.listeners.onLinearClickthrough = this.onLinearClickthrough.bind(this);
    this.listeners.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.listeners.onVolumeChange = this.onVolumeChange.bind(this);
    this.listeners.onEnded = this.onEnded.bind(this);
    this.listeners.onTrackerFirstQuartile = this.onTrackerFirstQuartile.bind(this);
    this.listeners.onTrackerMidpoint = this.onTrackerMidpoint.bind(this);
    this.listeners.onTrackerThirdQuartile = this.onTrackerThirdQuartile.bind(this);
  }

  registerListeners() {
    // Register tracking events
    this.player.one('adcanplay', this.listeners.onCanPlay);
    this.player.on('adtimeupdate', this.listeners.onTimeUpdate);
    this.player.on('adplay', this.listeners.onPlay);
    this.player.on('adpause', this.listeners.onPause);
    this.player.on('linearclickthrough', this.listeners.onLinearClickthrough);
    this.player.on('fullscreenchange', this.listeners.onFullscreenChange);
    this.player.on('advolumechange', this.listeners.onVolumeChange);
    this.player.one('adended', this.listeners.onEnded);
    // check quartile progress
    this.vastTracker.on('firstQuartile', this.listeners.onTrackerFirstQuartile);
    this.vastTracker.on('midpoint', this.listeners.onTrackerMidpoint);
    this.vastTracker.on('thirdQuartile', this.listeners.onTrackerThirdQuartile);
  }

  unsubscribeAll() {
    // Delete unused tracking watchers
    this.player.off('adcanplay', this.listeners.onCanPlay);
    this.player.off('adtimeupdate', this.listeners.onTimeUpdate);
    this.player.off('adplay', this.listeners.onPlay);
    this.player.off('adpause', this.listeners.onPause);
    this.player.off('linearclickthrough', this.listeners.onLinearClickthrough);
    this.player.off('fullscreenchange', this.listeners.onFullscreenChange);
    this.player.off('advolumechange', this.listeners.onVolumeChange);
    // Try to remove ended listener as well. User can dispose this class manually
    this.player.off('adended', this.listeners.onEnded);
  }

  /**
   * Unregister all events manually
   * @param  {Boolean} [silently=false] Skip warning about already disposed tracker
   * @return {Boolean} True when disposed, false otherwise
   */
  dispose(silently = false) {
    if (this.disposed) {
      if (silently) {
        log.warn('Linear tracker disposed already.');
      }
      return false;
    }
    this.disposed = true;
    this.unsubscribeAll();
    this.player.vastTracker = null;
    return true;
  }

  /** This will dispose vastTracker reference registered to the player. */
  disposePlayerTracker() {
    if (this.player.vastTracker) {
      this.player.vastTracker.customDispose();
    }
  }
}

export class LinearVPAIDTracker extends LinearTracker {
  constructor(player, adUnit) {
    super(player);
    this.adUnit = adUnit;
    this.listeners.setTrackerDuration = this.setTrackerDuration.bind(this);
    this.listeners.onSkipped = this.onSkipped.bind(this);
    this.listeners.onAdVideoFirstQuartile = this.onAdVideoFirstQuartile.bind(this);
    this.listeners.onAdVideoMidpoint = this.onAdVideoMidpoint.bind(this);
    this.listeners.onAdVideoThirdQuartile = this.onAdVideoThirdQuartile.bind(this);
    this.listeners.onAdVideoComplete = this.onAdVideoComplete.bind(this);
    this.listeners.onAdClickThru = this.onAdClickThru.bind(this);
    this.listeners.onAdUserClose = this.onAdUserClose.bind(this);
  }

  onVolumeChange() {
    this.adUnit.getAdVolume().then((volume) => {
      this.vastTracker.setMuted(volume === 0);
    });
  }

  setTrackerDuration() {
    this.adUnit.getAdDuration().then((duration) => {
      if (duration > 0 && this.player.vastTracker) {
        this.player.vastTracker.setDuration(duration);
      }
    });
  }

  onVideoStart() {
    this.player.vastTracker.setProgress(0);
  }

  onVideoQuartile(percentage) {
    this.adUnit.getAdDuration().then((duration) => {
      if (duration > 0) {
        const emulatedQuartile = Math.round(percentage * duration) / 100;
        // vastTracker may be disposed if
        (this.player.vastTracker || {}).setProgress(emulatedQuartile);
      }
    });
  }

  onAdVideoFirstQuartile() {
    this.onVideoQuartile(25);
    this.quartileProgress.firstQuartile = true;
    log('[vastTracker] VPAID firstQuartile fired !', this.quartileProgress);
  }

  onAdVideoMidpoint() {
    this.onVideoQuartile(50);
    this.quartileProgress.midpoint = true;
    log('[vastTracker] VPAID midpoint fired !', this.quartileProgress);
  }

  onAdVideoThirdQuartile() {
    this.onVideoQuartile(75);
    this.quartileProgress.thirdQuartile = true;
    log('[vastTracker] VPAID thirdQuartile fired !', this.quartileProgress);
  }

  onAdVideoComplete() {}

  onAdClickThru() {
    this.player.vastTracker.click();
  }

  onAdUserClose() {
    this.player.vastTracker.close();
  }

  registerListeners() {
    this.adUnit.one('AdLoaded', this.listeners.setTrackerDuration);
    this.adUnit.subscribe('AdDurationChange', this.listeners.setTrackerDuration);
    this.adUnit.subscribe('AdRemainingTimeChange', this.listeners.setTrackerDuration);
    this.adUnit.subscribe('AdSkipped', this.listeners.setTrackerDuration);
    this.adUnit.one('AdStarted', this.listeners.onCanPlay);
    this.adUnit.one('AdSkipped', this.listeners.onSkipped);
    this.adUnit.subscribe('AdVolumeChange', this.listeners.onVolumeChange);
    this.adUnit.one('AdVideoFirstQuartile', this.listeners.onAdVideoFirstQuartile);
    this.adUnit.one('AdVideoMidpoint', this.listeners.onAdVideoMidpoint);
    this.adUnit.one('AdVideoThirdQuartile', this.listeners.onAdVideoThirdQuartile);
    // 'adened' event has probably been called and 'VastTracker' is already disposed.
    // this.adUnit.one('AdVideoComplete', this.listeners.onAdVideoComplete);
    this.adUnit.subscribe('AdClickThru', this.listeners.onAdClickThru);
    this.adUnit.subscribe('AdUserClose', this.listeners.onAdUserClose);
    this.adUnit.subscribe('AdPlaying', this.listeners.onPlay);
    this.adUnit.subscribe('AdPaused', this.listeners.onPause);
    // Try to remove ended listener as well. User can dispose this class manually
    this.player.on('adended', this.listeners.onEnded);
  }

  unsubscribeAll() {
    // Try to remove ended listener as well. User can dispose this class manually
    this.player.off('adended', this.listeners.onEnded);
  }
}

/**
 * Register tracking events for the companion ad
 *
 * @param {Object} player     The player instance
 * @param {object} ad         Current ad
 * @param {object} variation  Concrete creative variation
 */
export const registerCompanionTracking = (player, ad, creative, variation) => {
  // Init VAST Tracker
  // https://github.com/dailymotion/vast-client-js/blob/master/docs/api/vast-tracker.md
  const vastTracker = new VASTTracker(vastClient, ad, creative, variation);

  // Tracking events
  const companioncanplay = function companioncanplay() {
    // Call all impressions
    vastTracker.trackImpression();
    log('[vast-tracker] companion track impression');
  };
  const companionclickthrough = function companionclickthrough() {
    vastTracker.click();
    log('[vast-tracker] companion track click trought');
  };

  // Register tracking events
  player.on('companioncanplay', companioncanplay);
  player.on('companionclickthrough', companionclickthrough);

  player.one('companionended', function companionended(obj) {
    // Delete unused tracking watchers
    this.off('companioncanplay', companioncanplay);
    this.off('companionclickthrough', companionclickthrough);

    if (obj.skipped) {
      vastTracker.skip();
      log('[vast-tracker] companion completed');
    } else {
      vastTracker.complete();
      log('[vast-tracker] companion completed');
    }
  });
};

/**
 * Register tracking events for the overlay
 *
 * @param {Object} player     The player instance
 * @param {object} ad         Current ad
 * @param {object} variation  Concrete creative variation
 */
export const registerOverlayTracking = (player, ad, creative, variation) => {
  // Init VAST Tracker
  // https://github.com/dailymotion/vast-client-js/blob/master/docs/api/vast-tracker.md
  const vastTracker = new VASTTracker(vastClient, ad, creative, variation);

  // Tracking events
  const overlaycanplay = function overlaycanplay() {
    // Call all impressions
    vastTracker.trackImpression();
    log('[vast-tracker] overlay track impression');
  };
  const overlayclickthrough = function overlayclickthrough() {
    vastTracker.click();
    log('[vast-tracker] overlay track click trought');
  };

  // Register tracking events
  player.on('overlaycanplay', overlaycanplay);
  player.on('overlayclickthrough', overlayclickthrough);

  player.one('overlayended', function overlayended(obj) {
    // Delete unused tracking watchers
    this.off('overlaycanplay', overlaycanplay);
    this.off('overlayclickthrough', overlayclickthrough);

    if (obj.skipped) {
      vastTracker.skip();
      log('[vast-tracker] overlay skipped');
    } else {
      vastTracker.complete();
      log('[vast-tracker] overlay completed');
    }
  });
};

/** @return {Object} VAST tracker if exist */
export const getVastTracker = (player) => player.vastTracker;
