import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistAdMarker extends Component {
  getMarkerPosition() {
    const { playlistData } = this.player_.playlist;
    const progressBarWidth = this.player_.controlBar.progressControl.width();
    const onePercentWidth = progressBarWidth / playlistData.totalDuration;
    const markerTime = this.options_.durationBefore + this.options_.markerTime;

    return Math.floor(markerTime * onePercentWidth);
  }

  createEl() {
    return super.createEl(
      'div',
      {
        className: 'vjs-playlist-ad-marker',
      },
      { style: `left: ${this.getMarkerPosition()}px`, markerKey: this.options_.markerKey },
    );
  }
}

Component.registerComponent('PlaylistAdMarker', PlaylistAdMarker);
export default PlaylistAdMarker;
