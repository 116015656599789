export const customOttErrors = {
  // default source validation errors
  NO_SOURCE_FOUND_ERR: {
    message: 'No source found. Please contact our web support when this error occurs.',
    code: 4.01,
  },
  NO_PLAYABLE_SOURCE_FOUND_ERR: {
    message: 'No playable source found. Is your browser up to date?',
    code: 4.02,
  },
  NO_DRM_USABLE_REPRESENTATION_FOUND_ERR: {
    message: 'Required content protection level is incompatible with your system.',
    code: 4.03,
  },
};

export const genericErrorMessage =
  'The media could not be loaded, either because the server or network failed or because the format is not supported.';

export const customizedVhsErrors = [
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'HLS request aborted.',
    code: 1.01,
    pattern: 'HLS request aborted at URL: ',
  },
  {
    message: "Aborted early because there isn't enough bandwidth to complete the request without rebuffering.",
    code: 1.02,
  },
  {
    message: 'Quota exceeded error with append of a single segment of content',
    code: 1.03,
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'DASH request error',
    code: 2.01,
    pattern: 'DASH request error at URL: ',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'HLS request timed-out.',
    code: 2.02,
    pattern: 'HLS request timed-out at URL: ',
  },

  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'HLS request errored.',
    code: 2.03,
    pattern: 'HLS request errored at URL: ',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'Empty HLS response.',
    code: 2.04,
    pattern: 'Empty HLS response at URL: ',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'HLS playlist request error at URL',
    code: 2.05,
    pattern: 'HLS playlist request error at URL:',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'Excessive segment downloading detected.',
    code: 2.06,
    pattern: 'segment downloading detected.',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'XHR Failed with',
    code: 2.07,
    pattern: 'XHR Failed with a response of:',
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'Codec switching not supported',
    code: 3.01,
    pattern: 'Codec switching not supported:',
  },
  {
    message: 'Playlist no longer updating.',
    code: 3.02,
  },
  {
    message: 'Problem encountered loading the default audio track.',
    code: 4.03,
  },
  {
    message: 'No starting media returned, likely due to an unsupported media format.',
    code: 4.04,
  },
  {
    message: 'Error loading vtt.js',
    code: 4.05,
  },
  {
    message: 'Playback cannot continue. No available working or supported playlists.',
    code: 4.06,
  },
  {
    message: 'Could not determine codecs for playlist.',
    code: 4.07,
  },
  {
    message: genericErrorMessage,
    partOfOriginalMessage: 'Invalid HLS key',
    code: 5.01,
    pattern: 'Invalid HLS key at URL: ',
  },
  {
    message: 'Failed to initialize media keys for EME',
    code: 5.02,
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    message: 'DRM keystatus changed. Playlist will fail to play.',
    partOfOriginalMessage: 'DRM keystatus changed. Playlist will fail to play. Check for HDCP content.',
    code: 0.01,
    pattern: 'Playlist will fail to play. Check for HDCP content.',
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special convertToProgramTime(time, callback) function invocation
    message: genericErrorMessage,
    partOfOriginalMessage: 'Accurate programTime could not be determined.',
    code: 0.02,
    pattern: 'Accurate programTime could not be determined.',
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special seekToProgramTime(programTime, callback, pauseAfterSeek = true, retryCount = 2) function invocation
    message: genericErrorMessage,
    partOfOriginalMessage: 'programDateTime tags must be provided in the manifest',
    code: 0.03,
    pattern: 'programDateTime tags must be provided in the manifest',
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special seekToProgramTime(programTime, callback, pauseAfterSeek = true, retryCount = 2) function invocation
    message: genericErrorMessage,
    partOfOriginalMessage: 'Program time was not found in the stream',
    code: 0.04,
    pattern: 'was not found in the stream',
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special seekToProgramTime(programTime, callback, pauseAfterSeek = true, retryCount = 2) function invocation
    message: genericErrorMessage,
    partOfOriginalMessage: 'Program time is not buffered yet. Try again',
    code: 0.05,
    pattern: 'is not buffered yet. Try again',
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special convertToProgramTime(time, callback) function invocation
    message: 'getProgramTime: playlist and time must be provided',
    code: 0.08,
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special convertToProgramTime(time, callback) function invocation
    message: 'valid programTime was not found',
    code: 0.09,
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special convertToProgramTime(time, callback) function invocation
    message: 'Accurate programTime could not be determined.',
    code: 0.1,
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special seekToProgramTime(programTime, callback, pauseAfterSeek = true, retryCount = 2) function invocation
    message: 'seekToProgramTime: programTime, seekTo and playlist must be provided',
    code: 0.11,
  },
  {
    // this is not necessarily an error, keep it listed here under 0 error code
    // only in case of special seekToProgramTime(programTime, callback, pauseAfterSeek = true, retryCount = 2) function invocation
    message: 'player must be playing a live stream to start buffering',
    code: 0.12,
  },
  // --------------------------------------------------------------------
  // DRM EXTENDED ERROR MESSAGES
  // more info: https://jira.zentity.com/browse/CRASUPP-496
  // --------------------------------------------------------------------
  {
    message: 'The content license expired, please try to reload the page.',
    code: 6.01,
    pattern: 'License denied. The license expiration date must be at least 1 second greater than the current date.',
  },
  {
    message: 'Unable to pair content license. If the issue persists, please report to technical support.',
    code: 6.02,
    pattern: 'License denied. The license request contains a key ID that is not present in the entitlement message:',
  },
  {
    message: 'The content license requires security level that your device or browser does not support.',
    code: 6.03,
    pattern:
      'License denied. Your device security level (Level3) is too low for any of the keys in the Entitlement Message.',
  },
  {
    message:
      'Unable to use content license due to unknown comm id. If the issue persists, please report to technical support.',
    code: 6.04,
    pattern: 'License denied. The DRM message references the ID of an unknown communication key.',
  },
  {
    message:
      'Your browser version is too old to use content license. Please use the most recent official version of one of the major browsers.',
    code: 6.05,
    pattern:
      'Invalid license request. DRM client models with permanently revoked certificates are not allowed to receive licenses.',
  },
  {
    message:
      'Your browser version is too old to use content license. Please use the most recent official version of one of the major browsers.',
    code: 6.06,
    pattern:
      'Invalid license request. DRM client models with revoked certificates are not allowed to receive licenses.',
  },
  {
    message:
      'Unable to use content license due to invalid request structure. If the issue persists, please try to reinstall the browser.',
    code: 6.07,
    pattern:
      'Invalid license request. The license request contains an invalid encrypted client infoMessage. Ensure the license request has not been tampered with.',
  },
  {
    message: 'Unable to use content license due to invalid signature. Please try to reinstall the browser.',
    code: 6.08,
    pattern:
      'Invalid license request. The signature of the license request is invalid. Ensure the DRM client has not been tampered with.',
  },
  {
    message:
      'Unable to use content license due to development certificate. Please use the most recent official version of one of the major browsers.',
    code: 6.09,
    pattern:
      'Invalid license request. The DRM client was signed using a development certificate. A production certificate must be used.',
  },
  {
    message:
      'Unable to use content license due to invalid content id. If the issue persists, please report to technical support.',
    code: 6.1,
    pattern: 'Invalid license request. The key ID contained in the asset ID is not a valid',
  },
  {
    message:
      'Unable to use content license due to invalid message signature. If the issue persists, please report to technical support.',
    code: 6.11,
    pattern: 'Invalid DRM message. The provided JWT has an invalid signature.',
  },
  {
    message:
      'Unable to use content license due to invalid message structure. If the issue persists, please report to technical support.',
    code: 6.12,
    pattern: 'Invalid DRM message. Invalid JWT.',
  },
  {
    message:
      'Invalid license request. Please try to reload the page. Make sure you are using a recent version of a modern browser.',
    code: 6.13,
    pattern: 'Invalid Widevine request. It must be either the license request or the service certificate request.',
  },
  {
    message:
      'Invalid license request. Please try to reload the page. Make sure you are using a recent version of a modern browser.',
    code: 6.14,
    pattern: 'Invalid request. The request body is missing.',
  },
  {
    message: 'Content license is missing persistency. Please report to technical support.',
    code: 6.15,
    pattern:
      'Invalid DRM message. The playback duration in the FairPlay license configuration section can only be set when persistence is allowed.',
  },
  {
    message:
      'Content license does not allow to store the content locally. If the issue persists, please report to technical support.',
    code: 6.16,
    pattern:
      'License denied. The client requested a persistent license, but the entitlement message does not allow persisting it.',
  },
  {
    message: 'Unable to use content license due to duplicate keys. Please report to technical support.',
    code: 6.17,
    pattern: 'Invalid DRM message. Invalid content keys source. Content keys with duplicate IDs are not allowed.',
  },
  {
    message: 'The content license requires security level that your device or browser does not support.',
    code: 6.18,
    pattern:
      "License denied. Your DRM client's security level is 2000, but the entitlement message requires 3000 or higher.",
  },
  {
    message:
      'Unable to generate content license due to invalid or empty license request. If the issue persists, please try in another browser and report to technical support.',
    code: 6.19,
    pattern: 'Invalid license request. The request body is empty or is not valid XML.',
  },
  {
    message:
      'Unable to generate content license due to invalid license request signature. If the issue persists, please try in another browser and report to technical support.',
    code: 6.2,
    pattern:
      'Invalid license request. Make sure that the request body represents a valid PlayReady license request with a valid XML signature.',
  },
  {
    message:
      'Unable to use content license due to invalid message signature. If the issue persists, please report to technical support.',
    code: 6.21,
    pattern: 'Invalid DRM message. The signature is invalid.',
  },
];
