export const percentToSec = (percent, totalDuration) => {
  const onePercentInSeconds = totalDuration / 100;
  return Math.floor(parseInt(percent) * onePercentInSeconds);
};

export const hhmmssToSec = (timeString) => {
  const timeValues = timeString.split(':');
  let seconds = null;
  if (timeValues.length >= 3) {
    seconds = +timeValues[0] * 60 * 60 + +timeValues[1] * 60 + +timeValues[2];
  }
  return seconds;
};
