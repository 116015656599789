import BaseMarkers from '../../../../base-markers';

/**
 * Markers helper class. E.g. reset markers, ..
 *
 * @param {Object} player   Player object
 * @param {Object} adstate  Advertisement state container object
 */

const adsIndexPlayed = [];

export default class ImaMarkers extends BaseMarkers {
  constructor(player, cuePoints) {
    super(player);

    this.filterMarkers(cuePoints);
    this.disableAdMarkers = player.options_.plugins.adServiceIMA.disableAdMarkers || false;
  }

  filterMarkers(cuePoints) {
    const lowerCuePointsThanCurrentTime = cuePoints.filter((cue) => cue < this.player.currentTime());

    if (lowerCuePointsThanCurrentTime.length) {
      adsIndexPlayed.push(
        // filter only past ad marker cues
        lowerCuePointsThanCurrentTime
          // get the closest ad marker cue from the past to the player current time
          .reduce((a, b) =>
            Math.abs(b - this.player.currentTime()) < Math.abs(a - this.player.currentTime()) ? b : a,
          ),
      );
    }

    this.midrollsTimes = cuePoints.filter((cue) => cue && !adsIndexPlayed.includes(cue));
  }

  generateMarkers() {
    if (!this.disableAdMarkers) {
      this.adMarkers = this.midrollsTimes.map((ad) => this.adMarkerObj(ad, 'vjs-marker-ad', this.totalDuration));
    }
  }
}
