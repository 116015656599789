/**
 * <p>Creates hints menu and button as "seek hints". Menu will be shown when click event occurs.</p>
 *
 * <h3>Initialization</h3>
 * See the example below to understand how to initialize this plugin.
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-hints-seek</b> - currentTime {Number}
 *
 * @example
{
  plugins: {
    seekHints: [{
      time: 60,
      text: 'Some dummy text 1'
    }, {
      time: 180,
      text: 'Some dummy text 2'
    }, {
      time: 240,
      text: 'Some dummy text 3'
    }]
  }
}
 *
 * @module plugins/seekHints
 */
import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import SeekHintsButton from './seek-hints/button';
import SeekHintsMenu from './seek-hints/menu';
import { isAdPlaying } from './adservice/shared/ad-state';
/* eslint-enable */

/**
 * Seek hints plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 * @param {Number} opts.time - Time in seconds
 * @param {String} opts.text - Hint text will be displayed in the popup menu
 */
export const seekHintsPlugin = function seekHintsPlugin(opts) {
  const player = this;
  // const videoEl = player.el().getElementsByTagName('video')[0];

  const initSeekHintsMenu = () => {
    if (
      !isAdPlaying(this) &&
      !Object.prototype.hasOwnProperty.call(this.controlBar.childNameIndex_, 'SeekHintsButton')
    ) {
      this.off('playing', initSeekHintsMenu);
      const SeekHintsButton = this.controlBar.addChild('SeekHintsButton', opts);
      // add the new component as a default player child
      this.controlBar.el().insertBefore(SeekHintsButton.el(), this.controlBar.fullscreenToggle.el());

      this.addChild('SeekHintsMenu', opts);
    }
  };

  player.on('playing', initSeekHintsMenu);
  log('seekHints plugin initialized');
};
