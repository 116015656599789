import { google } from 'global/window';
import log from '../log';
import availableEvents from './measuring/shared/available-events';
import { getAdBreakType } from './adservice/shared/ad-state';
import MeasuringBase from './measuring/measuring-base';
import Gemius from './measuring/gemius';
import GTMBasic from './measuring/gtm-basic';
import GTMComplex from './measuring/gtm-complex';
import Mux from './measuring/mux';
import Cerebroad from './measuring/cerebroad';
import { MEA_ARGS_1, MEA_ARGS_2, MEA_PLUGIN } from '../errors';

const streamEvents = { ...(google && google.ima && google.ima.dai && google.ima.dai.api.StreamEvent.Type) };

export class MeasuringImaDai extends MeasuringBase {
  constructor(player, opts = {}) {
    super(player, opts);
    this.measuringType = 'ima-dai';

    this.streamInfo = opts.streamInfo;

    if (!opts.streamInfo) {
      log.error(MEA_ARGS_1.code, MEA_ARGS_1.message);
    }

    if (!opts.services && !opts.autoInit) {
      log.error(MEA_ARGS_2.code, MEA_ARGS_2.message);
    }

    if (!player.options_.plugins.playedTicks) {
      log.error(MEA_PLUGIN.code, MEA_PLUGIN.message);
    }

    this.initServices();
  }

  // this is here for fullscreenchange, because ima dai doesn't trigger any event
  // in theory, every event could take adInfo from here...
  adStreamInfo = undefined;

  clearAdStreamInfo() {
    this.adStreamInfo = undefined;
  }

  // this is here for fullscreenchange, because ima dai doesn't trigger any event
  // in theory, every event could take adInfo from here...
  ad = undefined;

  clearAd() {
    this.ad = undefined;
  }

  initServices() {
    const { services } = this.opts;
    if (!services) {
      return;
    }

    const volume = this.player.muted() ? 0 : this.player.volume();

    if (services.gemius) {
      const gemius = new Gemius(
        this.player,
        services.gemius,
        {
          width: this.player.currentWidth(),
          height: this.player.currentHeight(),
        },
        volume,
      );

      gemius.startWatching();
    }

    if (services.gtmBasic) {
      // Start google tag manager watcher
      const gtmBasic = new GTMBasic(this.player, services.gtmBasic);
      gtmBasic.startWatching();
    } else if (services.grmComplex) {
      // Start google tag manager watcher
      const gtmComplex = new GTMComplex(this.player, services.gtmComplex);
      gtmComplex.startWatching();
    }

    if (services.nielsen) {
      this.player.nielsen({ nielsenOptions: services.nielsen, streamInfo: this.streamInfo });
    }

    // FIXME: move Mux.init() from constructor
    if (services.mux) {
      const mux = new Mux(this.player, services.mux); // eslint-disable-line
    }

    // FIXME: move Cerebroad.init() from constructor
    if (services.cerebroad) {
      const cerebroad = new Cerebroad(this.player, services.cerebroad, this.streamInfo); // eslint-disable-line
    }
  }

  isAd() {
    return this.player.adServiceImaDai().isAdBreak;
  }

  getContentTime() {
    return this.player.currentTime();
  }

  registerEvents() {
    log('[measuring-ima-dai] registering event handlers');

    // custom content events
    this.player.on('ima-dai-content-play', (e) => this.handleContentPlay(e));
    this.player.on('ima-dai-content-pause', (e) => this.handleContentPause(e));

    // player-events
    this.player.on('volumechange', (e) => this.handleVolumeChange(e));
    this.player.on('fullscreenchange', (e) => this.handleFullscreenChange(e));
    this.player.on('error', (e) => this.handleError(e));
    this.player.on('timeupdate', (e) => this.handleTimeUpdate(e));
    this.player.on('ott-quality-changed', (e) => this.handleQualityChange(e));
    this.player.on('ott-vhs-quality-auto', (e) => this.handleQualityAuto(e));

    // ads events
    this.player.on(streamEvents.LOADED, (e) => this.handleLoaded(e));
    this.player.on(streamEvents.STARTED, (e) => this.handleAdPlay(e));
    this.player.on(streamEvents.RESUMED, (e) => this.handleAdResume(e));
    this.player.on(streamEvents.PAUSED, (e) => this.handleAdPause(e));
    this.player.on(streamEvents.FIRST_QUARTILE, (e) => this.handleAdFirstQuartile(e));
    this.player.on(streamEvents.MIDPOINT, (e) => this.handleAdMidpoint(e));
    this.player.on(streamEvents.THIRD_QUARTILE, (e) => this.handleAdThirdQuartile(e));
    this.player.on(streamEvents.COMPLETE, (e) => this.handleAdComplete(e));
    this.player.on(streamEvents.AD_PROGRESS, (e) => this.handleAdTimeProgress(e));
    this.player.on(streamEvents.AD_BREAK_STARTED, (e) => this.handleAdsBlockStart(e));
    // this.player.on(streamEvents.AD_PERIOD_STARTED, (e) => this.handleAdsBlockStart(e));
    this.player.on(streamEvents.AD_PERIOD_ENDED, (e) => this.handleAdsBlockEnds(e));
    this.player.on(streamEvents.SKIPPED, (e) => this.handleSkip(e));
    // FIXME: should this be one event?
    this.player.on([streamEvents.CLICK, streamEvents.VIDEO_CLICK], (e) => this.handleClick(e));

    this.registerGlobalWindowListener('beforeunload', (e) => this.handleClose.bind(e));
    this.registerGlobalWindowListener('unload', (e) => this.handleClose(e));
    this.registerGlobalWindowListener('pagehide', (e) => this.handleClose(e));
    this.registerGlobalWindowListener('resize', (e) => this.handlePlayerResolutionChange(e));
    this.registerGlobalWindowListener('playerresize', (e) => this.handlePlayerResolutionChange(e));
  }

  handleLoaded(event) {
    const type = availableEvents.PROGRAM_LOADED;

    const eventData = {
      ...this.getEventData(type, event),
      deviceType: this.getDeviceType(),
    };

    this.triggerEvent(type, eventData);
  }

  handleClose(event) {
    const type = availableEvents.CLOSE;

    const eventData = {
      ...this.getEventData(type, event),
      currentTime: this.getCurrentTime(),
    };

    this.triggerEvent(type, eventData);
  }

  handlePlayerResolutionChange(event) {
    if (
      this.player.el_.offsetWidth !== this.currentPlayerWidth ||
      this.player.el_.offsetHeight !== this.currentPlayerHeight
    ) {
      this.currentPlayerWidth = this.player.el_.offsetWidth;
      this.currentPlayerHeight = this.player.el_.offsetHeight;

      const type = availableEvents.PLAYER_RESOLUTION_CHANGED;

      const data = {
        ...this.getEventData(type, event),
        currentTime: this.getCurrentTime(),
        resolution: {
          width: this.currentPlayerWidth,
          height: this.currentPlayerHeight,
        },
      };

      this.triggerEvent(type, data);
    }
  }

  handleProgramLoaded(event) {
    const type = availableEvents.PROGRAM_LOADED;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleSkip(event) {
    const type = availableEvents.SKIP;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      currentTime: event.getStreamData().adProgressData.currentTime,
    };

    this.triggerEvent(type, eventData);
  }

  handleTimeUpdate() {
    if (!this.isAd()) {
      const type = availableEvents.TIMEUPDATE;
      const eventData = {
        type,
        streamInfo: this.streamInfo,
        currentTime: this.getContentTime(),
      };

      let logEnabled = false;
      const logMessage = `${type} - limited logging 1 event/second`;
      const tickTimeRounded = Math.floor(this.player.currentTime());
      if (tickTimeRounded !== this.timeUpdateLastTimeReported) {
        this.timeUpdateLastTimeReported = tickTimeRounded;
        logEnabled = true;
      }

      this.triggerEvent(logMessage, eventData, logEnabled);
    }
  }

  handleError(event) {
    const error = this.player.error();
    const type = availableEvents.ERROR;

    const eventData = {
      ...this.getEventData(type, event),
      currentTime: this.getContentTime(),
      error,
    };

    this.triggerEvent(type, eventData);
  }

  handleClick(event) {
    const type = availableEvents.LINEAR_CLICK_THROUGH;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      currentTime: this.getContentTime(),
    };

    this.triggerEvent(type, eventData);
  }

  handleAdsBlockStart(event, totalAds) {
    const type = availableEvents.ADS_BLOCK_START;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      inventory: {
        name: undefined, // FIXME: what should be here in this case?
        ads_count: totalAds,
      },
    };
    this.handleContentPause(event);

    this.triggerEvent(type, eventData);
  }

  handleAdsBlockEnds(event) {
    const type = availableEvents.ADS_BLOCK_END;

    const { videoInfo, ...eventData } = this.getEventData(type, event);

    this.triggerEvent(type, eventData);

    this.handleLoaded({ ...event });
    this.handleContentPlay({ ...event });
    this.clearAd();
    this.clearAdStreamInfo();
  }

  handleAdTimeProgress(event) {
    const type = availableEvents.AD_TIMEUPDATE;
    if (!this.ad || !this.ad.getAdPodInfo) return;

    const eventData = {
      ...this.getEventData(type, event),
      autoplay: true, // FIXME: what is this?
      currentTime: event.getStreamData().adProgressData.currentTime,
      adPosition: this.ad.getAdPodInfo().getAdPosition(),
      breakSize: this.ad.getAdPodInfo().getTotalAds(),
      adType: 'midroll', // FIXME: is this really a midroll, can we this read from the ad itself?
    };

    let logEnabled = false;
    const logMessage = `${type} - limited logging 1 event/second`;
    const tickTimeRounded = Math.floor(this.player.currentTime());
    if (tickTimeRounded !== this.timeUpdateLastTimeReported) {
      this.timeUpdateLastTimeReported = tickTimeRounded;
      logEnabled = true;
    }

    this.triggerEvent(logMessage, eventData, logEnabled);
  }

  handleQualityAuto(event) {
    const type = availableEvents.QUALITY_CHANGED;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      currentTime: this.currentProgramTime,
      quality: 'auto',
    };

    this.triggerEvent(type, eventData);
  }

  handleQualityChange(event) {
    const type = availableEvents.QUALITY_CHANGED;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      currentTime: this.currentProgramTime,
      quality: event.qualityIdx,
    };

    this.triggerEvent(type, eventData);
  }

  handleVolumeChange(event) {
    const type = availableEvents.VOLUME_CHANGED;

    // for some reason there shouldn't be video info which contains
    // info about volume, so let's take the info out and pick the volume from it
    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      volume: videoInfo.volume,
      currentTime: this.getCurrentTime(),
    };

    this.triggerEvent(type, eventData);
  }

  handleFullscreenChange(event) {
    const type = availableEvents.FULLSCREEN_CHANGED;

    const { videoInfo, ...rest } = this.getEventData(type, event);

    const eventData = {
      ...rest,
      // adStreamInfo is overwritten because it can't be picked from the event itself so it's undefined
      adStreamInfo: this.adStreamInfo,
      isFullscreen: this.player.isFullscreen(),
    };

    this.triggerEvent(type, eventData);
  }

  handleAdLoaded(event) {
    const type = availableEvents.AD_LOADED;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdPlay(event) {
    this.ad = event.getAd ? event.getAd() : undefined;

    if (this.ad.getAdPodInfo().getAdPosition()) {
      this.handleAdsBlockStart({ ...event }, this.ad.getAdPodInfo().getTotalAds());
    }

    this.adStreamInfo = this.getAdStreamInfoFromAd(this.ad);

    const type = availableEvents.AD_PLAY;
    const eventData = this.getEventData(type, event);

    this.handleAdLoaded({ ...event });

    this.triggerEvent(type, eventData);
  }

  handleAdResume(event) {
    this.ad = event.getAd();
    this.adStreamInfo = this.getAdStreamInfoFromAd(this.ad);

    const type = availableEvents.AD_PLAY;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleContentPlay(event) {
    const type = availableEvents.PLAY;
    const eventData = this.getEventData(type, event);
    this.triggerEvent(type, eventData);
  }

  handleContentPause(event) {
    const type = availableEvents.PAUSE;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdFirstQuartile(event) {
    const type = availableEvents.AD_FIRSTQUARTILE;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdMidpoint(event) {
    const type = availableEvents.AD_MIDPOINT;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdThirdQuartile(event) {
    const type = availableEvents.AD_THIRDQUARTILE;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdComplete(event) {
    const type = availableEvents.AD_COMPLETE;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  handleAdPause(event) {
    const type = availableEvents.AD_PAUSE;
    const eventData = this.getEventData(type, event);

    this.triggerEvent(type, eventData);
  }

  getAdStreamInfoFromAd(ad) {
    return {
      adID: ad.getAdId(),
      adDesc: ad.getDescription(),
      adName: ad.getTitle(),
      duration: ad.getDuration(),
      adType: getAdBreakType(ad),
      campaignClassification: ad.getAdvertiserName(),
    };
  }

  getVideoInfo() {
    return {
      volume: this.player.muted() ? 0 : this.player.volume(),
      width: this.player.videoWidth(),
      height: this.player.videoHeight(),
      quality: -1, // TODO: should return some kind of quality
    };
  }

  getEventData(type, event) {
    const ad = event.getAd ? event.getAd() : undefined;

    return {
      type,
      adStreamInfo: ad ? this.getAdStreamInfoFromAd(ad) : undefined,
      videoInfo: this.getVideoInfo(),
      streamInfo: this.streamInfo,
    };
  }
}
