import log from './log';
import { getQueryParams } from './utils/url';
import { SEEK_QUERY_PARAM_NAME } from './constants';
import { isAdPlaying } from './plugins/adservice/shared/ad-state';

export const checkUrlSeek = (player) => {
  const qs = ((window.document || {}).location || {}).search || '';
  const seekTimeFromUrl = parseInt(getQueryParams(qs)[SEEK_QUERY_PARAM_NAME]);
  let seekByOffset;

  if (seekTimeFromUrl && typeof seekTimeFromUrl === 'number') {
    const urlSeek = () => {
      if (!isAdPlaying(player)) {
        if (seekTimeFromUrl > player.duration()) {
          removeSeekCheck();
          log.error(
            `[urlSeek] ERROR! Seek time it is greater than the source duration! duration: ${player.duration()}s | seek: ${seekTimeFromUrl}s`,
          );
          return;
        }

        player.currentTime(seekTimeFromUrl);
        log(`[urlSeek] seeked to time:${seekTimeFromUrl}s`);
        removeSeekCheck();
      }
    };

    const removeSeekCheck = () => {
      log('[urlSeek] removed listener after seek');
      player.off('timeupdate', urlSeek);
    };

    player.on('timeupdate', urlSeek);
  }
};
