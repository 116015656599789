import is from 'is_js';
import { videojs } from 'global/window';
import log from '../log';

import { matchedPreferredTech } from './preferred-tech';

/**
 * getTracksPlayableByTheBrowser takes tracks and return playable sources. Pick process
 * depends on presence of drm and actual browser.
 *
 * @param { object } tracks - the whole tracks object from the options/data/initData
 * @param { object[] } [preferredTech] - array of the preferredTech that can change default selection from the (options/data/initData)
 *
 * @returns sources - basically flatten array of tracks previously sorted via category (DASH/HLS/MP4)
 */
export function getTheMostSuitableTracks(tracks, preferredTech) {
  // check if any track has drm protection
  if (!tracks) {
    return undefined;
  }

  const { os, names, ks, browser } = matchedPreferredTech(preferredTech);
  const hasPreferredTech = !!Object.keys(matchedPreferredTech(preferredTech)).length;

  const tracksHaveDrm = tracks
    ? Object.values(tracks)
        .flat()
        .some((track) => !!track.drm)
    : undefined;

  // only Safari can use FairPlay while no other browser can
  // so tracks with incompatible DRMs should be removed from the selection
  if (tracksHaveDrm) {
    // there is an exception - live video with DRM is only in HLS format, so if there is drm
    // but no dash, it probably means it's live HLS video, so it should be returned even outside of Safari

    let originalTracks = tracks;

    if (hasPreferredTech) {
      // filter tracks by preferredTech defined in player configuration
      const filteredTracksByKeySystem = ks?.length
        ? tracks[names[0]].map((track) => ({
            ...track,
            drm: [...track.drm.filter((drm) => drm.keySystem === ks[0])],
          }))
        : tracks[names[0]];

      originalTracks = {
        ...originalTracks,
        [names[0]]: filteredTracksByKeySystem,
      };

      const ksLog = ks?.length ? `, keySystem: ${ks[0]}` : '';
      log.warn(`Technology selection overridden. OS: ${os}, browser: ${browser}, technology: ${names}${ksLog}`);
    }

    if (videojs.browser.IS_SAFARI || videojs.browser.IS_IOS || !originalTracks.DASH) {
      return [originalTracks.HLS, originalTracks.DASH, originalTracks.MP4]
        .flat()
        .filter((track) => track !== undefined);
    }
    return [originalTracks.DASH, originalTracks.MP4].flat().filter((track) => track !== undefined);
  }

  // check the match with any combination of os+browser
  if (hasPreferredTech) {
    // return track in preferred if there is match of preferred os+browser and there are tracks of that tech
    if (matchedPreferredTech && tracks[names[0]]) {
      log.warn(`Technology selection overridden. OS: ${os}, browser: ${browser}, technology: ${names}`);
      return tracks[names[0]];
    }
  }

  // HLS doesn't work on ios so it should
  if (is.ios()) {
    return [tracks.HLS, tracks.MP4].flat().filter((track) => track !== undefined);
  }

  // if there is no drm and no preferred tech is matched just use tracks in this order
  return [tracks.DASH, tracks.HLS, tracks.MP4].flat().filter((track) => track !== undefined);
}
