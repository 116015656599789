export default class Subscribers {
  constructor() {
    this.subscribers = {};
  }

  get(eventName) {
    if (!this.subscribers[eventName]) {
      this.subscribers[eventName] = [];
    }
    return this.subscribers[eventName];
  }

  isHandlerAttached(handler, eventName) {
    return this.get(eventName).some((subscriber) => handler === subscriber.handler);
  }

  trigger(eventName, data) {
    const subscribers = this.get(eventName).concat(this.get('*'));

    subscribers.forEach((subscriber) => {
      setTimeout(() => {
        if (this.isHandlerAttached(subscriber.handler, subscriber.eventName)) {
          subscriber.handler.call(subscriber.context, data);
        }
      }, 0);
    });
  }

  triggerSync(eventName, data) {
    const subscribers = this.get(eventName).concat(this.get('*'));

    subscribers.forEach((subscriber) => {
      subscriber.handler.call(subscriber.context, data);
    });
  }

  subscribe(eventName, handler, context) {
    if (!this.isHandlerAttached(handler, eventName)) {
      this.get(eventName).push({
        handler,
        context,
        eventName,
      });
    }
  }

  one(eventName, handler, context) {
    const oneHandler = (data) => {
      this.unsubscribe(eventName, oneHandler);
      handler.call(context, data);
    };
    this.subscribe(eventName, oneHandler, context);
  }

  unsubscribe(eventName, handler) {
    this.subscribers[eventName] = this.get(eventName).filter((subscriber) => handler !== subscriber.handler);
  }

  unsubscribeAll() {
    this.subscribers = {};
  }
}
