import { AdTrackShow2139VideoPlayerDesktop, AdTrackHide2139VideoPlayerDesktop } from 'global/window';
import { PLAYER_ID } from '../../constants';
import log from '../../log';
import avlAdType from './shared/ad-type';
import getInventory from './shared/get-inventory';
import Cascade from './cascade';

/**
 * Cares about Sklik
 *
 * @class
 * @param {Object} adstate - Advertisement container object
 * @param {Object} player - Video.js player instance
 */
class AdsSklik {
  constructor(adstate, player) {
    this.timeout = null;
    this.adstate = adstate;
    this.player = player;
  }

  /**
   * Remove Sklik from the DOM
   */
  removeSklik() {
    if (typeof AdTrackHide2139VideoPlayerDesktop !== 'function') {
      return;
    }
    AdTrackHide2139VideoPlayerDesktop();
  }

  /**
   * Append Sklik to the DOM
   *
   * @private
   */
  appendSklik() {
    if (typeof AdTrackShow2139VideoPlayerDesktop !== 'function') {
      return;
    }
    // Try to check if wrapper is under video wrapper
    this.wrapperElementCheck();
    AdTrackShow2139VideoPlayerDesktop();
    this.player.trigger('sklikstarted');
    log('Sklik ad started');
  }

  /**
   *  Check if Sklik wrapper (#AdTrackVideoPlayer) is under the #viaplay-player wrapper element
   */
  wrapperElementCheck() {
    const playerWrapper = document.getElementById(PLAYER_ID);
    const sklikWrapperName = 'AdTrackVideoPlayer';
    if (playerWrapper.querySelector(`#${sklikWrapperName}`) === null) {
      playerWrapper.appendChild(document.getElementById(sklikWrapperName));
    }
  }

  /**
   * Show Sklik ad
   *
   * @param {Int} adi - Used when break inventory contains more than 1 object
   */
  showSklik(adi) {
    // Reset previous cascade
    Cascade.resetShownCascade(this.player, this.adstate.currentAdtype);

    // Select break inventory (this function is defined in the integration plugin)
    const sklikInventory = getInventory(this.adstate, avlAdType.sklik, adi);

    if (sklikInventory) {
      // Use timeout from the init object if exist
      const timeout = sklikInventory.timeout * 1000 || 60000;

      this.removeSklik();
      this.adstate.avlCreativeType = 'sklik';
      this.appendSklik();
      this.timeout = this.player.setTimeout(() => {
        this.player.trigger('sklikended');
        log('Sklik ad ended');
      }, timeout);

      this.player.one('sklikended', () => {
        if (this.timeout) {
          this.player.clearTimeout(this.timeout);
        }
        this.removeSklik();
        this.adstate.avlCreativeType = '';
      });
    } else {
      log('Sklik initialization skipped. Sklik init object not found.');
    }
  }
}

export default AdsSklik;
