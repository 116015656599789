/**
 * <p>Wrapper for other plugins (like qualities, subtitles, audio switching, ...)</p>
 *
 * <h3>Initialization</h3>
 * Plugin will show settings button when loadeddata event occurs (if contains any). See the example below to understand how to initialize this plugin.
 * <br><br><br><br>
 *
 * <h4>Stream audio languages plugin</h4>
 * Shows list of different stream audio languages if exists any.
 * <br><br>
 *
 * <h5>Custom events tracking:</h5>
 * <b>ott-language-changed</b> - source {Object} Localized source
 * <br><br><br><br>
 *
 * <h4>Qualities plugin</h4>
 * Allow manual quality selection for HLS or DASH adaptive stream technology
 * <br><br>
 *
 * <h5>Custom events tracking:</h5>
 * <b>ott-quality-changed</b> - qualityIdx {Number} Localized source
 * <br><br><br><br>
 *
 * <h4>Text tracks (subtitles) plugin</h4>
 * Display an clickable list from text tracks (subtitles)
 * <br><br>
 *
 * <h5>Hints:</h5>
 * <ul>
 * <li><b>default attribute</b> - This attribute indicates that the track should be enabled unless the user's preferences indicate that another track
 * is more appropriate. This may only be used on one track element per media element.</li>
 * </ul>
 * <br><br>
 *
 * <h5>Custom events tracking:</h5>
 * <b>ott-subtitles-enabled</b> - track {Object} Current text track object
 * <b>ott-subtitles-reinit</b> - when fired all text tracks will be removed and added again.
 * <br><br><br><br>
 *
 * <h4>Persistent settings plugin</h4>
 * Keep the player settings persist. Values are stored in the local storage (cookies)
 * <br><br><br><br>
 *
 * <h4>Nielsen opt-out button</h4>
 * Nielsen opt-out message box
 * <br><br>
 *
 * <h5>Custom events tracking:</h5>
 * <b>ott-nielsen-optout</b> - Opt-out button clicked
 * <br><br>
 *
 * @example <caption>languages, qualities and subtitles initialization:</caption>
 {
  plugins: {
    settings: {
      languages: {
        HLS: [{
          srclang: 'cze',
          label: 'česky'
        }, {
          srclang: 'eng',
          label: 'anglicky'
        }],
        DASH: [{
          srclang: 'cze',
          label: 'česky'
        }, {
          srclang: 'eng',
          label: 'anglicky'
        }]
      },
      qualities: {},
      subtitles: [{
        src: 'http://example.com/cze.vtt',
        srclang: 'cze',
        label: 'Česky',
        kind: 'captions',
        default: 'true' // See 'Text tracks (subtitles) plugin' section for more details how it works
      }, {
        src: 'http://example.com/eng.vtt',
        srclang: 'eng',
        label: 'Anglicky',
        kind: 'captions'
      }],
      persistSettings: {
        id: 'AAAA-BBB-CCCC-DD',
        persistSubtitlesLanguage: true,
        persistAudioLanguage: true,
        persistAutoplayNext: true,
        cookieExpiration: 30
      },
      nielsenOptOut: {
        text: '<h2>Měření Nielsen</h2><p>Tato aplikace obsahuje měřící software společnosti Nielsen, který uživatelům umožňuje přispívat k průzkumu trhu.</p><p>Chcete-li se dozvědět více o informacích, které může software Nielsen shromažďovat a o vaší možnosti měření deaktivovat, přečtěte si zásady ochrany osobních údajů <a href="">Nielsen Digital Measurement</a></p>'
      }
    }
  }
 }
 *
 * @module plugins/settingsPlugin
 */

import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import SettingsButton from './settings/button';
import SettingsMenu from './settings/menu';
import InfoButton from './settings/info/info-button';
/* eslint-enable */
import './settings/menuDropdown';
import PersistSettings from './settings/persist-settings';
import { isAdPlaying } from './adservice/shared/ad-state';

/**
 * Settings wrapper plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 * @param {Object} opts.qualities - Initialize qualities switch as empty object
 */
export const settingsPlugin = function settingsPlugin(opts) {
  const player = this;
  if (opts.persistSettings) {
    const persistSettings = new PersistSettings(player, opts.persistSettings);
    if (opts.subtitles) {
      log('[PersistPlugin] updating subtitles');
      opts.subtitles = persistSettings.updateSubtitles(opts.subtitles);
    }

    player.ready(() => {
      // volume is initialized after the player is ready
      persistSettings.updateVolume();
    });
  }

  /**
   * Initialize settings menu after prerolls and dispose this event
   */
  const initMenu = function initMenu() {
    if (
      !isAdPlaying(this) &&
      !Object.prototype.hasOwnProperty.call(this.controlBar.childNameIndex_, 'SettingsButton')
    ) {
      this.off('playing', initMenu);

      const { levels_ } = player.qualityLevels();
      const hasDropdownSettings = player.options_.styleControl?.dropdownSettings;

      let settingsButton;
      if (player.options_.html5.vhs?.overrideNative) {
        if (hasDropdownSettings) {
          settingsButton = this.controlBar.addChild('SettingsButton', { ...opts, dropdownSettings: true });
        } else {
          settingsButton = this.controlBar.addChild('SettingsButton', opts);
        }
        if (hasDropdownSettings || levels_?.length) {
          this.controlBar.addChild('InfoButton', opts);
        }
      } else {
        settingsButton = this.controlBar.addChild('SettingsButton', opts);
      }

      // add the new component as a default player child
      this.controlBar.el().insertBefore(settingsButton.el(), this.controlBar.fullscreenToggle.el());

      if (player.options_.html5.vhs?.overrideNative) {
        if (hasDropdownSettings) {
          this.addChild('SettingsMenuDropdown', { ...opts, dropdownSettings: true });
        } else {
          this.addChild('SettingsMenu', opts);
        }
      } else {
        this.addChild('SettingsMenu', opts);
      }

      const anyChildShown = this.getChild('SettingsMenu')?.children_.some(
        (children) => !children.hasClass('vjs-hidden'),
      );
      if (anyChildShown) {
        settingsButton.show();
      }

      this.trigger('ott-menu-initialized');
    }
  };

  player.on('playing', initMenu);
  log('settings plugin initialized');
};
