/**
 * <p>Creates a transparent pixel to prevent color flickering in iOS. See OTTS-1992 | CRA-3600</p>
 *
 * @module plugins/FlickeringPixel
 *
 */
import { videojs } from 'global/window';

const Component = videojs.getComponent('Component');

class FlickeringPixel extends Component {
  createEl() {
    return super.createEl('div', {
      className: 'vjs-flickering-pixel',
    });
  }
}

videojs.registerPlugin('FlickeringPixel', FlickeringPixel);
export default FlickeringPixel;
