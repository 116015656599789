import { videojs } from 'global/window';

import LanguagesSelectMenu from './languages-select-menu';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language select wrapper
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class LanguagesSelectWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent(options.languages);
    this.el_.className = 'vjs-languages-select-wrapper';
  }

  createContent(languages) {
    this.addChild(new LanguagesSelectMenu(this.player_, { ...this.options, languages }));
  }
}

Component.registerComponent('LanguagesSelectWrapper', LanguagesSelectWrapper);
export default LanguagesSelectWrapper;
