import canAutoPlay from 'can-autoplay';
import { videojs } from 'global/window';
import { PLAYER_AUTOPLAY } from '../constants';
import log from '../log';

const defaultAutoplayTestTimeoutMs = 400;
const isInline = videojs.browser.IS_IOS;

const checkMutedAutoplaySupport = (autoplayTestTimeoutMs) =>
  new Promise((resolve) => {
    canAutoPlay.video({ timeout: autoplayTestTimeoutMs, muted: true, inline: isInline }).then(({ result, error }) => {
      if (error) log.warn(`[autoplay] mute test error ${error}`);
      if (result === false) {
        // Muted autoplay is not allowed.
        resolve({
          autoplayAllowed: false,
          autoplayRequiresMute: false,
        });
      } else {
        // Muted autoplay is allowed.
        resolve({
          autoplayAllowed: true,
          autoplayRequiresMute: true,
        });
      }
    });
  });

const checkUnmutedAutoplaySupport = (autoplayTestTimeoutMs) =>
  new Promise((resolve) => {
    canAutoPlay
      .video({ timeout: autoplayTestTimeoutMs, muted: false, inline: isInline })
      .then(async ({ result, error }) => {
        if (error) log.warn(`[autoplay] unmute test error ${error}`);
        if (result === false) {
          // Unmuted autoplay is not allowed.
          const mutedTest = await checkMutedAutoplaySupport(autoplayTestTimeoutMs);
          resolve(mutedTest);
        } else {
          // Unmuted autoplay is allowed.
          resolve({
            autoplayAllowed: true,
            autoplayRequiresMute: false,
          });
        }
      });
  });

// TODO: remove this ugly thing (no-async-promise-executor)
const getAutoplayConfiguration = (initData) =>
  new Promise(async (resolve) => {
    // eslint-disable-line
    const safariCsai =
      (initData.plugins?.adService?.linear?.csaiMidrolls || initData.plugins?.adService?.csaiAdTagUrlBase) &&
      videojs.browser.IS_SAFARI;

    let autoplayTestTimeoutMs = defaultAutoplayTestTimeoutMs;

    if (safariCsai) {
      // CSAI in Safari needs to be hacked a lot to work
      // in this case we need to use a longer timeout so the player have time to setup
      // because when we set overrideNative to true in Safari, things take a lot of time
      autoplayTestTimeoutMs = 2000;
    } else if (initData.autoplayTestTimeoutMs) {
      autoplayTestTimeoutMs = initData.autoplayTestTimeoutMs;
    }

    log(
      `[autoplay] setting autoplayTestTimeoutMs to ${autoplayTestTimeoutMs} ${
        initData.autoplayTestTimeoutMs ? '(configured via options)' : '(default value)'
      }`,
    );
    let { muted, autoplay } = initData;
    let autoplayAllowed = null;

    if (initData.autoplay !== false && initData.plugins.adService) {
      await checkUnmutedAutoplaySupport(autoplayTestTimeoutMs).then((result) => {
        autoplay = false;
        // eslint-disable-next-line prefer-destructuring
        autoplayAllowed = result.autoplayAllowed;
        if (initData.muted === false || typeof initData.muted === 'undefined') {
          muted = result.autoplayRequiresMute;
        }
        log(`[autoplay] allow autoplay: ${autoplayAllowed} autoplay require mute: ${muted}`);
      });
    } else if (typeof initData.autoplay === 'undefined') {
      autoplay = PLAYER_AUTOPLAY;
      log(`[autoplay] allow autoplay: ${autoplay}`);
    }

    resolve({
      muted,
      autoplay,
      autoplayAllowed,
    });
  });

export default getAutoplayConfiguration;
