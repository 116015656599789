import { videojs } from 'global/window';

import log from '../log';
import sourceFormatter from '../source-formatter';

const Plugin = videojs.getPlugin('plugin');

export class UHDController extends Plugin {
  constructor(player, options = {}) {
    super(player, options);
    this.options = options;

    if (!this.checkSupportedFormatsExist(options.supportedFormats)) {
      return;
    }

    this.playerData = player.options_;
    this.preferredLang = this.playerData.preferredLang;

    this.tech = null;
    this.selectedLang = null;

    this.uhdSwitchAllowed = false;
    this.uhdActive = this.options.initData && this.options.initData.uhdActive ? this.options.initData.uhdActive : false;

    this.sources = [];

    player.on('loadedmetadata', () => {
      this.setPlayerTech();
    });

    player.one('ott-menu-initialized', () => {
      this.init();
    });

    window.uhdController = this;
  }

  init() {
    if (this.createLangSources()) {
      if (this.checkUhdCodecs()) {
        this.setUhdAllowedFromCurrentSource();
        this.registerControlEvents();
        this.sendControlEvent();
      }
    }

    if (this.options.initData && this.options.initData.uhdActive) {
      log('[UHD Controller] restore active state from init data');
      this.player.trigger({
        type: 'ott-uhdprofile-switch',
        enable: true,
      });
    }
  }

  getLocalizedSourceWithoutUhd() {
    return sourceFormatter(this.sources[this.selectedLang].basic);
  }

  getLocalizedSourceWithUhd() {
    return sourceFormatter(this.sources[this.selectedLang].uhd);
  }

  checkUhdCodecs() {
    if (this.tech === null || !this.checkCodecsSupport(this.options.supportedFormats)) {
      log.error('[UHD Controller] Error! Check tech or codecs support.');
      this.dispose();
      return false;
    }
    return true;
  }

  setUhdActiveState(activeState) {
    this.uhdActive = activeState;
  }

  setSelectedLang(lang) {
    this.selectedLang = lang;
    this.setUhdAllowedFromCurrentSource();
    this.sendControlEvent();
    log(`[UHD Controller] - set selected lang to ${this.selectedLang}`);
  }

  setPlayerTech() {
    switch (this.player.currentSource().type) {
      case 'application/dash+xml':
        this.tech = 'dash';
        break;
      case 'application/x-mpegURL':
        this.tech = 'hls';
        break;
      default:
        this.tech = null;
        break;
    }
    log(`[UHD Controller] set player tech: ${this.tech}`);
  }

  setUhdAllowedFromCurrentSource() {
    this.uhdSwitchAllowed = !!this.sources[this.selectedLang].uhdSwitchAvailable;
    log(`[UHD Controller] check uhd allowed for lang:${this.selectedLang} => ${this.uhdSwitchAllowed}`);
  }

  isUhdAllowedForCurrentSource() {
    return this.uhdSwitchAllowed;
  }

  createLangSources() {
    const sources = this.playerData.tracks[this.tech.toUpperCase()];
    let anyUhd = false;

    sources.forEach((source) => {
      // create basic data structure
      if (!this.sources[source.lang]) {
        // when not found any selected lang
        if (!this.preferredLang || this.preferredLang.length === 0) {
          this.selectedLang = source.lang;
        }

        this.sources[source.lang] = {
          uhdSwitchAvailable: false,
          uhd: null,
          basic: null, // without UHD
        };
      }

      if (this.isSupportedTech(source.type)) {
        if (source.uhd) {
          this.sources[source.lang].uhd = source;
        } else {
          this.sources[source.lang].basic = source;
        }
      }
    });

    Object.keys(this.sources).forEach((lang) => {
      if (this.sources[lang].uhd && this.sources[lang].basic) {
        this.sources[lang].uhdSwitchAvailable = true;
        anyUhd = true;
      }
    });

    if (!anyUhd) {
      log('[UHD Controller] Not found any UHD sources, uhd plugin disposed');
      this.uhdSwitchAllowed = false;
      this.dispose();
      return false;
    }

    this.sortAvailableSourceLanguage();
    this.setPlayerCurrentPlayInUHD();

    return true;
  }

  saveUhdActiveStateForReset() {
    if (!this.player.reset.resetData.data.plugins.uhdController.initData) {
      this.player.reset.resetData.data.plugins.uhdController.initData = {};
      this.player.reset.resetData.data.plugins.uhdController.initData.uhdActive = this.uhdActive;
    } else {
      this.player.reset.resetData.data.plugins.uhdController.initData.uhdActive = this.uhdActive;
    }
  }

  isSupportedTech(sourceType) {
    switch (this.tech) {
      case 'dash':
        return sourceType === 'application/dash+xml';
      case 'hls':
        return sourceType === 'application/x-mpegURL';
      default:
        return false;
    }
  }

  registerControlEvents() {
    this.player.on('ott-uhdprofile-switch', (e) => {
      const { enable } = e;
      this.insertSourceToPlayer(enable);
    });

    this.player.on('ott-change-language', (e) => {
      this.setSelectedLang(e.lang);
      if (this.uhdActive) {
        this.insertSourceToPlayer(true);
      }

      // sync UHD active state
      this.player.one('playing', () => {
        this.setPlayerCurrentPlayInUHD();
      });
    });
  }

  sendControlEvent() {
    this.player.trigger({
      type: 'ott-uhdprofile-control',
      uhdActive: this.uhdActive,
      uhdAllowed: this.isUhdAllowedForCurrentSource(),
    });
  }

  setPlayerCurrentPlayInUHD() {
    this.uhdActive = this.sources[this.selectedLang].uhd.src === this.player.currentSource().src;
  }

  checkSupportedFormatsExist(formats) {
    if (!formats) {
      log.error('[UHD Controller] - not find uhdController.supportedFormats -> the supported formats are missing');
      this.dispose();
      return false;
    }
    return true;
  }

  checkCodecsSupport(formats) {
    return formats[this.tech].reduce((accumulator, format) => {
      const result = this.player.tech_.el_.canPlayType(format);
      if (result !== '') {
        accumulator = true;
        log(`[UHD Controller] - video canPlayType (${format}): ${result === '' ? 'not supported!' : result}`);
        return accumulator;
      }

      if (MediaSource) {
        const experimentalResult = MediaSource.isTypeSupported(format);
        if (experimentalResult) {
          accumulator = true;
          log(`[UHD Controller] - MediaSource.isTypeSupported (${format}): ${experimentalResult}`);
          return accumulator;
        }
      }
      return accumulator;
    }, false);
  }

  isUhdPossibleForLocalizeSource() {
    return !!this.sources[this.selectedLang].uhd;
  }

  getStartTime() {
    let startTime = this.player.currentTime();
    const resetCount = this.player.reset.resetData.count || 0;
    const resetData = this.player.reset.resetData.data || {};

    if (resetData.startTime && resetCount > 0 && startTime < resetData.startTime) {
      startTime = resetData.startTime;
    }

    return startTime;
  }

  insertSourceToPlayer(enableUhd) {
    enableUhd = enableUhd && this.isUhdPossibleForLocalizeSource();
    const currentTimePrevSource = this.getStartTime();

    const selectedSrc = enableUhd ? this.getLocalizedSourceWithUhd() : this.getLocalizedSourceWithoutUhd();

    if (!this.player.paused()) {
      this.player.pause();
    }

    if (this.tech === 'hls') {
      log('[UHD Controller] - hls destroy before set new source to player');
    }

    // if there is an error remove it before changing the source
    this.player.extendedErrorDisplay.close();
    this.player.src(selectedSrc);
    this.setUhdActiveState(enableUhd);
    log(
      `[UHD Controller] - switch source (${enableUhd ? 'UHD' : 'Basic'}): ${JSON.stringify(
        selectedSrc,
      )} load to player`,
    );
    this.sendControlEvent();
    this.saveUhdActiveStateForReset();

    // prevent waiting for load source
    this.player.one('ott-playertick', () => {
      this.player.currentTime(currentTimePrevSource);
      log(`[UHD Controller] - switch source seek to ${currentTimePrevSource}`);
    });
  }

  sortAvailableSourceLanguage() {
    // sort selected langs and avaible langs
    if (this.preferredLang && this.preferredLang !== null && typeof this.preferredLang !== 'string') {
      this.preferredLang.forEach((lang) => {
        if (this.sources[lang] && this.selectedLang === null) {
          this.selectedLang = lang;
        }
      });
    } else {
      // when preferred lang not array
      this.selectedLang = this.preferredLang;
    }

    // fallback when no lang selected
    if (this.selectedLang === null) {
      this.selectedLang = Object.keys(this.sources)[0];
      log(
        `[UHD Controller] Not found any preferred langs, set selectedLang default from first source: ${this.selectedLang}`,
      );
    }
  }

  dispose() {
    super.dispose();
    log('[UHD Controller] plugin is being disposed');
  }
}
