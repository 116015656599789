import log from '../../../log';

/**
 * Try to find available ad from the inventory when normal content is playing.
 * @param  {Object}  player                The player instance
 * @param  {Object}  adstate               The ad state
 * @param  {Object}  ads                   The ad inventory
 * @param  {Object}  checkTime             Current time and seek time object
 * @param  {number?} priorNotificationTime PriorNotification time defined in adService plugin
 * @return {Number}                        Index of ad inventory array, negative value returned when any available ad found
 */
const getAvailableAdIndex = function getAvailableAdIndex(player, adstate, ads, checkTime, priorNotificationTime = 0) {
  // Get lowest from array
  Array.min = (array) => Math.min(...array);

  let returnIndex = -1;
  if (!adstate.adPlaying && !player.paused()) {
    const { checkCT, checkST } = checkTime;

    // Available active ads array
    const activeAdIdx = [];

    // priority to check ads option is when there is seek time
    // It is necessary to check 0, because when you seek to 0 every ad
    // keeps skipped flag to the next seeked event
    if (checkST >= 0) {
      log('Check for ads when user seeked. Seektime:', checkST);

      ads.forEach((ad, idx) => {
        // FIXME BPo: Periodic ad serving is currently only in livestrems.
        if (ad.period) return;

        if (ad.time > checkST) {
          if (ad.skipped) {
            ad.skipped = false;
          }
        } else if (!ad.requested && !ad.skipped) {
          activeAdIdx.push(idx);
        }
      });

      if (activeAdIdx.length > 0) {
        // Select lowest index
        returnIndex = Array.min(activeAdIdx);
        activeAdIdx.forEach((item) => {
          const ad = ads[item];
          // Check if selected index is different
          if (item !== returnIndex) {
            ad.skipped = true;
          }
        });
      }

      // reset seekTime check
      adstate.seekTime = -1;
    } else if (checkCT > 3) {
      // Check ad after preroll timeout
      ads.some((ad, idx) => {
        if (ad.period > 0 && checkCT >= ad.period && checkCT % ad.period < 1) {
          if (ad.lastTime && checkCT % ad.lastTime < 1) {
            return false;
          }
          ad.lastTime = checkCT;
          ad.lastPeriod += 1;
          activeAdIdx.push(idx);
          return true;
        }
        // Check if current time fits break time
        // Also "priorNotificationTime" takes into account! => it caused an issue where the midroll was not requested
        if (checkCT + priorNotificationTime >= ad.time - 1 && checkCT - priorNotificationTime <= ad.time + 1) {
          // if time fits check that this break isn't skipped and haven't played yet
          if (!ad.skipped && !ad.requested) {
            activeAdIdx.push(idx);
            // Break loop execution. Is no more needed when available ad found
            return true;
          }
        }
        return false;
      });

      if (activeAdIdx.length > 0) {
        // Select lowest index
        returnIndex = Array.min(activeAdIdx);
      }
    }
  }

  return returnIndex;
};

export default getAvailableAdIndex;
