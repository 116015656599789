/* eslint-disable eqeqeq */
import Cookies from 'js-cookie';
import { videojs } from 'global/window';
import log from '../../../log';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Quality measurement copy to clipboard
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityMeasurementCopyClipboard extends Button {
  /**
   * Create the Button's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      className: `vjs-an-heading vjs-copy-clipboard-button ${
        Cookies.get('mux_mea_id') ? 'vjs-copy-clipboard-button--expanded' : ''
      }`,
      innerHTML: `${
        this.getQueryParam('mux_viewer_id')
          ? `<span class='vjs-clipboard-wrapper'>${this.getQueryParam(
              'mux_viewer_id',
            )}<span class='vjs-icon-copy'></span></span>`
          : this.player_.localize('Please reload browser to view your ID')
      }`,
    });
  }

  handleClick() {
    const element = this.el_.getElementsByClassName('vjs-clipboard-wrapper')[0];

    let range;
    let selection;

    // select text range
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // try catch is for safari lower than 10
    try {
      // copy selected text to clipboard
      document.execCommand('copy');
      log('[QualityMeasurement] ID Copied to clipboard');

      // toggle copy success text for 1000ms
      let timeout;
      element.innerHTML = `${this.player_.localize('Copied to clipboard')}`;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        element.innerHTML = `${this.getQueryParam('mux_viewer_id')}<span class="vjs-icon-copy"></span>`;
      }, 1000);
    } catch (err) {
      log('[QualityMeasurement] Unable to copy text');
    }

    // clear selection from any major browser
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  getQueryParam(param) {
    const muxData = Cookies.get('muxData');

    if (typeof muxData !== 'undefined') {
      const vars = muxData.split('&');

      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] == param) {
          return pair[1];
        }
      }
      return false;
    }
  }
}
Component.registerComponent('QualityMeasurementCopyClipboard', QualityMeasurementCopyClipboard);
export default QualityMeasurementCopyClipboard;
