import { VASTTracker } from '@dailymotion/vast-client';
import log from '../../../log';
import { getPlayingAd } from '../shared/ad-state';

const TICK_LENGTH = 500;
const MINIMAL_AD_VIEW_TIME = 2000;
const MINIMAL_AD_VIEW_SIZE_RATIO = 0.5;

const macros = { PAGEURL: window.location.href, OMIDPARTNER: 'Etneteracz' };

const getVisibleRatio = (element) => {
  const { width: viewportWidth, height: viewportHeight } = window.visualViewport;

  const { width, height, top, left, bottom, right } = element.getBoundingClientRect();

  const fullArea = width * height;

  const getVisibleHeight = () => {
    let visibleHeight = height;
    if (top < 0) {
      visibleHeight += top;
    }
    if (bottom > viewportHeight) {
      visibleHeight += viewportHeight - bottom;
    }
    return visibleHeight > 0 ? visibleHeight : 0;
  };

  const getVisibleWidth = () => {
    let visibleWidth = width;
    if (left < 0) {
      visibleWidth += left;
    }
    if (right > viewportWidth) {
      visibleWidth += viewportWidth - right;
    }
    return visibleWidth > 0 ? visibleWidth : 0;
  };

  const visibleHeight = getVisibleHeight();
  const visibleWidth = getVisibleWidth();

  const visibleArea = visibleHeight * visibleWidth;

  return visibleArea / fullArea;
};

export class ImpressionTracker {
  constructor(player, ad) {
    this.player = player;
    this.ad = ad;
  }

  visibleTime = 0;

  ad = null;

  adElement = null;

  player = null;

  tracker = null;

  init() {
    const ad = getPlayingAd(this.player);
    const [creative] = this.ad.creatives || [];

    /* due to inconsistency whether viewableImpression is in inline or wrapper, we should get always array */
    const { viewableImpression } = this.ad;

    this.viewableImpression = Array.isArray(viewableImpression) ? viewableImpression : [viewableImpression];

    if (this.viewableImpression?.length > 0 && creative) {
      this.tracker = new VASTTracker(null, ad, creative);

      const playerEl = this.player.el();

      if (this.tracker.linear) {
        this.adElement = playerEl;
        this.registerTrackingHandlers();
        this.player.one('adended', () => this.handleAdEnd());
      } else {
        this.adElement = playerEl.querySelector('#simid-iframe-nonlinear') || playerEl.querySelector('.vjs-ad-overlay');
        this.registerTrackingHandlers();
        this.player.one('overlayended', () => this.handleAdEnd());
      }
    }
  }

  registerTrackingHandlers = () => {
    this.viewableImpressionReported = false;

    this.trackerInterval = setInterval(() => this.handleViewableImpressionTick(), TICK_LENGTH);

    window.addEventListener('beforeunload', this.trackNotViewable);

    log('[viewable impression]: Impression tick handler and unload handler registered');
  };

  handleViewableImpressionTick = () => {
    // SP-78794 there is an error that has player.el_ as undefined and this should give us more insight
    if (!this.adElement && !this.errorReportSent) {
      if (this.player.options_.plugins.playerReport?.automaticActivationProbability >= 0) {
        log(
          '[viewable impression]: this.adElement not found - (SP-78794 ?), for some reason there is not player.el() when the check for viewableImpression was done',
        );
        this.player.trigger('ott-player-send-report');
        this.errorReportSent = true;
      }
    }

    if (this.adElement && getVisibleRatio(this.adElement) > MINIMAL_AD_VIEW_SIZE_RATIO) {
      this.visibleTime += TICK_LENGTH;
    }

    if (this.visibleTime >= MINIMAL_AD_VIEW_TIME && !this.viewableImpressionReported) {
      this.trackViewable();
    }
  };

  trackViewable = () => {
    this.removeTrackingInterval();
    this.viewableImpression.forEach((vi) => {
      this.tracker.trackURLs(vi.viewable, macros);
      log('[viewable impression]: Tracked viewable', vi.viewable, macros);
    });
    this.viewableImpressionReported = true;
  };

  trackNotViewable = () => {
    this.removeTrackingInterval();
    this.viewableImpression.forEach((vi) => {
      this.tracker.trackURLs(vi.notviewable, macros);
      log('[viewable impression]: Tracked notViewable', vi.notViewable, macros);
    });
    this.viewableImpressionReported = true;
  };

  trackViewUndetermined = () => {
    this.removeTrackingInterval();
    this.viewableImpression.forEach((vi) => {
      this.tracker.trackURLs(vi.viewundetermined, macros);
      log('[viewable impression]: Tracked viewUndetermined', vi.viewUndetermined, macros);
    });
    this.viewableImpressionReported = true;
  };

  handleAdEnd = () => {
    if (!this.viewableImpressionReported) {
      this.trackNotViewable();
    }
  };

  removeTrackingInterval = () => {
    clearInterval(this.trackerInterval);
  };
}
