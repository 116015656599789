import { window, videojs } from 'global/window';
import is from 'is_js';
import logInternal from '../../log';

const Plugin = videojs.getPlugin('plugin');

export default class MeasuringBase extends Plugin {
  constructor(player, opts = {}) {
    super(player, opts);
    this.opts = opts;
    this.measuringType = 'base-default';
    this.registeredGlobalWindowListeners = [];

    this.registerEvents();
  }

  init() {
    logInternal.error(`[measuring-${this.measuringType}] has not been defined init method`);
  }

  registerEvents() {
    logInternal.error(`[measuring-${this.measuringType}] has not been defined registered events`);
  }

  /**
   * Returns true, if there was no ad loaded yet
   */
  isCurrentProgramLoaded() {
    return this.currentProgramID === (this.streamInfo && this.streamInfo.programID) || null;
  }

  /**
   * Gets current time of the player (ad or program) in s.
   *
   * @returns {number}
   */
  getCurrentTime() {
    return Math.floor(this.player.currentTime());
  }

  /**
   * Log custom message with prefix [measuring-{measuringType}]
   * @param {String} message
   */
  log(message, ...args) {
    logInternal(`[measuring-${this.measuringType}] ${message}`, args);
  }

  /**
   * Log Triggered event
   * @param {String} type
   * @param {Object} data
   */
  logTriggeredEvent(type, data = null) {
    logInternal(`[measuring-${this.measuringType}] trigger event ${type}`, data);
  }

  /**
   * Trigger event
   * @param {String} type
   * @param {Object} data
   * @param {Bool} logEnabled // default true
   */
  // TODO: update function params names, type param is only for log info, main event come in data param
  triggerEvent(type, data, logEnabled = true) {
    if (logEnabled) this.logTriggeredEvent(type, data);
    this.player.trigger(data);
  }

  /**
   * Get device type
   * options: "PC", "mobil", "tablet"
   * @returns {String}
   */
  getDeviceType() {
    let deviceType = 'PC';
    if (is.mobile()) {
      deviceType = 'mobil';
    } else if (is.tablet()) {
      deviceType = 'tablet';
    }
    return deviceType;
  }

  /**
   * Register global window listener for save unregisgter after plugin dispose
   * @param {String} event
   * @param {Function} method
   */
  registerGlobalWindowListener(event, method) {
    window.addEventListener(event, method);
    this.registeredGlobalWindowListeners.push({ event, method });
  }

  /**
   * Unregister all global window listener for disposed player
   */
  _unregisterGlobalWindowListers() {
    this.log('unregister global window events');
    this.registeredGlobalWindowListeners.forEach((listener) => {
      window.removeEventListener(listener.event, listener.method);
    });
  }

  /**
   * Global videojs Class Plugin dispose method
   */
  dispose() {
    this.log('plugin is being disposed');
    this._unregisterGlobalWindowListers();
    super.dispose();
  }
}
