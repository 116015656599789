import { document, videojs } from 'global/window';
import log from './log';
import { customizedVhsErrors, genericErrorMessage } from './error-messages';

const ErrorDisplay = videojs.getComponent('ErrorDisplay');
const Component = videojs.getComponent('Component');

export function findVhsError(originalError) {
  // replace original error with customize error
  const customizedError = customizedVhsErrors.find((customizedError) =>
    originalError?.message.includes(customizedError?.pattern),
  );
  if (customizedError) {
    return {
      code: customizedError.code,
      message: customizedError.message,
    };
  }

  // check if the message is something like [object Object] and if so, return generic message with original error code
  const unpredictableRawErrorStringPattern = /^\[.*\]/;
  if (unpredictableRawErrorStringPattern.test(originalError?.message)) {
    return {
      ...originalError,
      message: genericErrorMessage,
    };
  }
  return undefined;
}

class ExtendedErrorDisplay extends ErrorDisplay {
  constructor(player, options = { name: 'ExtendedErrorDisplay' }) {
    super(player, options);
    this.autoClose = player.options()?.nonCriticalErrorAutoClose;
    this.specialClass = player.options_.errorWithCode ? '' : 'vjs-hidden';
    this.timeUpdatesCounter = 0;

    this.deferredOpen = this.deferredOpen.bind(this);
    this.playerStateCheck = this.playerStateCheck.bind(this);
    this.addTimeUpdate = this.addTimeUpdate.bind(this);

    player.extendedErrorDisplay = this;
  }

  content() {
    const originalError = this.player().error();
    const error = findVhsError(originalError) || originalError;

    if (error) {
      this.player_.loadingSpinner.hide();
      // need to get it over big play button which has 1112, because of IMA ui, that has 1111...
      this.el_.style.zIndex = 1115;

      const { code, message } = error;

      const errMsgEl = document.createElement('div');
      errMsgEl.className = 'vjs-modal-dialog-content--message';
      errMsgEl.innerHTML = this.localize(message);

      if (code) {
        const errCodeEl = document.createElement('div');
        errCodeEl.className = `vjs-modal-dialog-content--code ${this.specialClass}`;
        errCodeEl.innerHTML = `ID: ${code}`;
        errMsgEl.appendChild(errCodeEl);
      }
      return errMsgEl;
    }
    return '';
  }

  close() {
    const player = this.player();
    player.removeClass('vjs-error');
    player.removeClass('vjs-controls-disabled');
    log('[ErrorDisplay] user close');
    super.close();
  }

  addTimeUpdate() {
    this.timeUpdatesCounter += 1;
  }

  playerStateCheck() {
    const player = this.player();
    player.off('timeupdate', this.addTimeUpdate);

    // with buffered content, the offline mode causes the stream to time out and timeupdates are sent, so you need to try a higher value for timeUpdatesCounter
    if (this.timeUpdatesCounter > 2 && this.autoClose) {
      log(`[ErrorDisplay] auto-hide non-critical error (time-updates: ${this.timeUpdatesCounter})`);
      this.timeUpdatesCounter = 0;
      player.removeClass('vjs-error');
      player.removeClass('vjs-controls-disabled');
      super.close();
      return;
    }

    if (this.timeUpdatesCounter > 2) {
      log(`[ErrorDisplay] non-critical error set closeable to true (time-updates: ${this.timeUpdatesCounter})`);
      this.closeable(true);
    }

    this.timeUpdatesCounter = 0;
    super.open();
  }

  deferredOpen() {
    const player = this.player();
    player.on('timeupdate', this.addTimeUpdate);
    setTimeout(this.playerStateCheck, 1200);
  }

  open() {
    const player = this.player();
    this.closeable(false);

    if (player.hasStarted() && !player.paused()) {
      this.deferredOpen();
      return;
    }

    super.open();
  }
}

Component.registerComponent('ExtendedErrorDisplay', ExtendedErrorDisplay);
export default ExtendedErrorDisplay;
