import { videojs } from 'global/window';
import propTest from '../../property-tester';

// Get a component to subclass
const SeekBar = videojs.getComponent('SeekBar');
const Component = videojs.getComponent('Component');

class PlaylistSeekBar extends SeekBar {
  constructor(player, options) {
    super(player, options);
    this.addClass('vjs-playlist-seek-bar');

    if (this.player_.playlist.playlistData.thumbnails) {
      this.initThumbnails();
    }
  }

  checkSeekInSourceRange(seek, min, max) {
    if (seek >= min && seek <= max) {
      return true;
    }

    return false;
  }

  getSeekSourceIndex(seek) {
    const { sources, actualIndex } = this.player_.playlist.playlistData;
    const sourceRange = sources[actualIndex].seekRange;
    const seekInPercent = seek * 100;
    if (this.checkSeekInSourceRange(seekInPercent, sourceRange.min, sourceRange.max)) {
      return actualIndex;
    }

    let seekSourceIndex;
    sources.forEach((source, index) => {
      if (this.checkSeekInSourceRange(seekInPercent, source.seekRange.min, source.seekRange.max)) {
        seekSourceIndex = index;
      }
    });

    // when is problem play first source
    return seekSourceIndex;
  }

  // TODO need more simply - refactoring
  calculatePlaylistDistance(rawDistance, sourceIndex) {
    const { seekRange } = this.player_.playlist.playlistData.sources[sourceIndex];
    const sourceRangeLength = seekRange.rangeLength / 100;
    const dist = rawDistance - seekRange.min / 100;
    const percentForSeek = dist / sourceRangeLength;

    // need player init all process and prepare for send ended events
    if (percentForSeek > 0.92) {
      return 0.92;
    }

    return percentForSeek;
  }

  /**
   * Get the percentage of media played so far.
   *
   * @return {number}
   *         The percentage of media played so far (0 to 1).
   */
  getPercent() {
    const { playlistData } = this.player_.playlist;
    const percent =
      (this.getCurrentTime_() + playlistData.sources[playlistData.actualIndex].durationBefore) /
      playlistData.totalDuration;

    return percent >= 1 ? 1 : percent || 0;
  }

  // method for showing thumbnails
  handleMouseMoveThumbs() {
    const el = this.el();
    el.addEventListener('mousemove', (event) => {
      const { playlistData } = this.player_.playlist;
      const cursorSourceIndex = this.getSeekSourceIndex(this.calculateDistance(event));

      // check if source have url for thumbnails image
      if (propTest(() => playlistData.sources[cursorSourceIndex].thumbnailsPlaylist.url)) {
        const seekTime =
          this.calculatePlaylistDistance(this.calculateDistance(event), cursorSourceIndex) *
          playlistData.sources[cursorSourceIndex].duration;
        this.thumbnails.update(cursorSourceIndex, seekTime);
      }
    });

    el.addEventListener('mouseover', (event) => {
      const { playlistData } = this.player_.playlist;
      const cursorSourceIndex = this.getSeekSourceIndex(this.calculateDistance(event));

      if (propTest(() => playlistData.sources[cursorSourceIndex].thumbnailsPlaylist.url)) {
        this.thumbnails.visible();
      }
    });

    el.addEventListener('mouseout', () => {
      this.thumbnails.unvisible();
    });
  }

  initThumbnails() {
    this.thumbnails = this.player().controlBar.progressControl.getChild('PlaylistThumbnails');
    this.handleMouseMoveThumbs();
  }

  /**
   * Handle mouse move on seek bar
   *
   * @param {EventTarget~Event} event
   * The `mousemove` event that caused this to run.
   *
   * @listens mousemove
   */
  handleMouseMove(event) {
    const { playlistData } = this.player_.playlist;
    const seekSourceIndex = this.getSeekSourceIndex(this.calculateDistance(event));

    if (seekSourceIndex !== playlistData.actualIndex) {
      const seekTime =
        this.calculatePlaylistDistance(this.calculateDistance(event), seekSourceIndex) *
        playlistData.sources[seekSourceIndex].duration;
      this.player_.playlist.changePlaylistSourceByIndex(seekSourceIndex, seekTime);
    } else {
      // let newTime = this.calculateDistance(event) * this.player_.duration();
      let newTime =
        this.calculatePlaylistDistance(this.calculateDistance(event), playlistData.actualIndex) *
        this.player_.duration();

      // Don't let video end while scrubbing.
      if (newTime === this.player_.duration()) {
        newTime -= 0.1;
      }

      // Set new time (tell player to seek to new time)
      this.player_.currentTime(newTime);
    }
  }
}

Component.registerComponent('PlaylistSeekBar', PlaylistSeekBar);
export default PlaylistSeekBar;
