/**
 * <p>Plugin will play a next video product automatically when url is defined</p>
 *
 * <h3>Initialization</h3>
 * See the example below to understand how to initialize this plugin.
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-playnext-start</b> - Fired when utoplay screen shown
 * <b>ott-playnext-reset</b> - The user clicked on the cancel button.
 * <b>ott-playnext-fired</b> - productId {String} next product Id. Fired before the site is redirected.
 *
 * @example
{
  plugins: {
    autoplayNext: {
      target: 'event-only', // possible variables are 'event-only', 'location'. URL will be skipped when 'event' value is preset.
      url: 'http://example.com/id=123', // Endpoint to the next product data
      startSec: 5, // Show autoplay screen at player.duration() - startSec (seconds). Default value is 0 sec
      countDown:  8 // Countdown (seconds). Default value is 8 sec
    }
  }
}
 *
 * @module plugins/autoplayNext
 */
import is from 'is_js';
import log from '../log';
import { AN_ARGS_1 } from '../errors';
import { AUTOPLAY_DEFAULT_START, AUTOPLAY_DEFAULT_COUNTDOWN } from '../constants';
import AutoplayScreen from './autoplay-next/screen';
import { validateValues } from './autoplay-next/validity';

export const autoplayNext = function autoplayNext(options) {
  if (validateValues(options.target, options.url)) {
    // use default values when not sets
    if (is.not.finite(options.startSec)) {
      options.startSec = AUTOPLAY_DEFAULT_START;
    }
    if (is.not.finite(options.countDown)) {
      options.countDown = AUTOPLAY_DEFAULT_COUNTDOWN;
    }

    this.addChild(new AutoplayScreen(this.player_, options));
    log('autoplayNext plugin initialized');
  } else {
    log.error(AN_ARGS_1.code, AN_ARGS_1.message);
    // Keep the code running, playback is possible without this plugin
  }
};
