/**
 * Be sure we are using same variables everywhere
 */
export const defaults = Object.freeze({
  objName: 'timeShiftState',
  timeShiftedVal: false,
  seekTimeVal: 0,
  urlParamVal: undefined,
  shiftedTime: 0,
});

/**
 * Create state object on the player instance if not contains property name
 * form defaults.objName
 *
 * @param {Object} player - The player instance
 */
export function initObjIfNotExist(player) {
  if (!player[defaults.objName]) {
    player[defaults.objName] = {
      timeShifted: defaults.timeShiftedVal,
      seekTime: defaults.seekTimeVal,
      urlParam: defaults.urlParamVal,
      shiftedTime: defaults.shiftedTime,
    };
  }
}

/**
 * Returns shifted time variable. This affects horizontal seek bar
 *
 * @param {Object} player - The player instance
 * @return {Number} Shifted time
 */
export function getShiftedTime(player) {
  if (player[defaults.objName]) {
    return player[defaults.objName].shiftedTime;
  }
  return defaults.shiftedTime;
}

/**
 * Returns true if a player is in time shifted state.
 *
 * @param {Object} player - The player instance
 * @return {boolean}
 */
export function isTimeShifted(player) {
  if (player[defaults.objName]) {
    return player[defaults.objName].timeShifted;
  }
  return defaults.timeShiftedVal;
}

/**
 * Initialize time shift current state
 *
 * @param {Object} player   - The player instance
 * @param {String} urlParam - Optional name of time shift query parameter
 */
export function initState(player, urlParam) {
  // Create state object with default values
  initObjIfNotExist(player);
  player[defaults.objName].urlParam = urlParam;
}

/**
 * Update time shift current state. Default values used when no args passed.
 *
 * @param {Object} player               - The player instance
 * @param {Boolean} [timeShifted=false] - Optional. True when user time shifted
 * @param {Number} [seekTime=0]         - Optional time shift current time (in seconds)
 * @param {Number} [shiftedTime=0]      - Optional shifted time
 * @param {String=} urlParam            - Optional name of time shift query parameter
 */
export function updateState(
  player,
  timeShifted = defaults.timeShiftedVal,
  seekTime = defaults.seekTimeVal,
  shiftedTime = defaults.shiftedTime,
  urlParam,
) {
  // Be sure state object is initialized already
  initObjIfNotExist(player);
  player[defaults.objName].timeShifted = timeShifted;
  player[defaults.objName].seekTime = seekTime;
  player[defaults.objName].shiftedTime = shiftedTime;

  if (urlParam) {
    player[defaults.objName].urlParam = urlParam;
  }
}

/**
 * Reset time shift state to default. See freezed 'defaults' object for
 * more details.
 *
 * @param {Object} player - The player instance
 * @param {Boolean} [resetUrlParam=false] - Optional switch
 */
export function resetState(player, resetUrlParam = false) {
  // Be sure state object is initialized already
  initObjIfNotExist(player);
  player[defaults.objName].timeShifted = defaults.timeShiftedVal;
  player[defaults.objName].seekTime = defaults.seekTimeVal;
  player[defaults.objName].shiftedTime = defaults.shiftedTime;
  if (resetUrlParam) {
    player[defaults.objName].urlParam = defaults.urlParamVal;
  }
}
