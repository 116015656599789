import log from '../../../log';
import Subscribers from './subscribers';
import { VPAIDMethods, VPAIDEvents, VPAIDGetters, setFullSizeStyle } from './utils';
import { AS_VPAID_CREATIVE_NOT_FOUND, AS_SET_VOLUME } from '../../../errors';

export default class AdUnit {
  /**
   * Ad unit object. Please check if crative is valid immidiately
   * @param  {Object} creative      Returned object from the getVPAIDAd function
   * @param  {HTMLElement} el       Value for the environmentVars.slot
   * @param  {HTMLElement} videoEl  Value for the environmentVars.videoSlot
   * @param  {HTMLElement} iframe   Iframe where is VPAID initialized
   */
  constructor(creative, el, videoEl, iframe) {
    this.registerMethods();
    this.registerGetters();

    this.isValid = this.checkValidity(creative);
    if (this.isValid) {
      this.creative = creative;
      this.el = el;
      // set click screen as fullsized
      setFullSizeStyle(this.el);
      this.videoEl = videoEl;
      this.iframe = iframe;
      this.subscribers = new Subscribers();
      this.adClickEventName = 'AdClickThru';
      this.adError = 'AdError';
      this.registerEventSubscribers();
    }
  }

  checkValidity(creative = this.creative) {
    return VPAIDMethods.every((method) => typeof creative[method] === 'function');
  }

  trigger(...args) {
    const [event] = args;
    this.subscribers.trigger(event, Array.prototype.slice(args, 1));
  }

  clickThroughHook(url, id, playerHandles) {
    this.subscribers.triggerSync(this.adClickEventName, {
      url,
      id,
      playerHandles,
    });
  }

  registerEventSubscribers() {
    VPAIDEvents.filter((e) => e !== this.adClickEventName).forEach((e) =>
      this.creative.subscribe(this.trigger.bind(this, e), e),
    );

    this.creative.subscribe(this.clickThroughHook.bind(this), this.adClickEventName);

    // TODO: iframe click propagate to the video element?
  }

  registerGetters() {
    VPAIDGetters.forEach((getter) => {
      this[getter] = () =>
        new Promise((resolve, reject) => {
          try {
            const result = this.creative[getter]();
            resolve(result);
          } catch (e) {
            reject(e);
          }
        });
    });
  }

  registerMethods() {
    const ignores = ['subscribe', 'unsubscribe', 'initAd'];
    VPAIDMethods.forEach((methodName) => {
      if (!~ignores.indexOf(methodName)) {
        this[methodName] = (...args) =>
          new Promise((resolve, reject) => {
            try {
              log('this', methodName);
              const result = this.creative[methodName].apply(this.creative, args);
              resolve(result);
            } catch (e) {
              reject(e);
            }
          });
      }
    });
  }

  one(eventName, handler, context) {
    this.subscribers.one(eventName, handler, context);
  }

  subscribe(eventName, handler, context) {
    this.subscribers.subscribe(eventName, handler, context);
  }

  unSubscribe(eventName, handler) {
    this.subscribers.unSubscribe(eventName, handler);
  }

  unsubscribeAll() {
    this.subscribers.unsubscribeAll();
  }

  /**
   * [initAd description]
   * @param  {Number}   width           Indicates the available ad display area width in pixels
   * @param  {Number}   height          Indicates the available ad display area height in pixels
   * @param  {String}   viewMode        Indicates either “normal”(default), “thumbnail”, or “fullscreen” as the view mode for the video player as defined by the publisher.
   * @param  {Number}   desiredBitrate  Indicates the desired bitrate as number for kilobits per second (kbps). The ad unit may use this information to select appropriate bitrate
   * for any streaming content
   * @param  {Object}   creativeData    Optional used for additional initialization data. In a VAST context, the ad unit should pass the value for either the Linear or Nonlinear
   * AdParameter element specified in the VAST document
   * @param  {Object}   envVars Optional used for passing implementation-specific runtime variables. Refer to the language specific API description for more details.
   * @return {Promise<Object>} A promise to the VPAID ad.
   */
  initAd(width, height, viewMode, desiredBitrate, creativeData = {}) {
    const environmentVars = {
      slot: this.el,
      videoSlot: this.videoEl,
      videoSlotCanAutoPlay: !window.videojs.browser.IS_SAFARI,
    };

    window.environmentVars = environmentVars;

    return new Promise((resolve, reject) => {
      if (!this.creative) {
        reject(AS_VPAID_CREATIVE_NOT_FOUND);
      }

      try {
        this.creative.initAd(width, height, viewMode, desiredBitrate, creativeData, environmentVars);
        window.creative = this.creative;
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  setAdVolume(volume) {
    return new Promise((resolve, reject) => {
      try {
        this.creative.setAdVolume(volume);
        const result = this.creative.getAdVolume();
        if (volume === result) {
          resolve();
        } else {
          reject(AS_SET_VOLUME);
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
