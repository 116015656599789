import { videojs } from 'global/window';
import log from '../../log';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The expand button toggle cinema mode for player
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class ExpandScreenButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.parent_ = options.parent;
    this.expandActiveClass = 'vjs-expand-screen-active';
    this.controlText(this.player_.localize('Full width'));
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-expand-screen-button ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div');
  }

  expandActive() {
    this.player_.trigger('ott-player-expand-on');
    this.player_.addClass(this.expandActiveClass);
    this.controlText(this.player_.localize('Default size'));
    log('[expand button] cinema mode turn ON');
  }

  expandDisable() {
    this.player_.trigger('ott-player-expand-off');
    this.player_.removeClass(this.expandActiveClass);
    this.controlText(this.player_.localize('Full width'));
    log('[expand button] cinema mode turn OFF');
  }

  /**
   * Handle click on expand screen button.
   */
  handleClick() {
    if (this.hasClass('active')) {
      this.expandDisable();
    } else {
      this.expandActive();
    }
    this.toggleClass('active');
  }
}
// Register the new component
Component.registerComponent('expandScreenButton', ExpandScreenButton);
export default ExpandScreenButton;
