import log from '../log';

const adServiceTypeSelect = (initData) => {
  const { adService, measuring } = initData.plugins || {};
  if (!adService) return initData;

  switch (adService.type) {
    case 'ima':
      initData.plugins.adServiceIMA = adService;
      delete initData.plugins.adService;
      if (measuring) {
        initData.plugins.measuringIMA = measuring;
        delete initData.plugins.measuring;
      }
      log('[adservice-type-select] selected type IMA');
      break;
    case 'ima-dai':
      initData.plugins.adServiceImaDai = adService;
      delete initData.plugins.adService;
      if (measuring) {
        initData.plugins.measuringImaDai = measuring;
        delete initData.plugins.measuring;
      }
      log('[adservice-type-select] selected type IMA-DAI');
      break;
    default:
      delete initData.plugins.adServiceIMA;
      log('[adservice-type-select] selected type CUSTOM');
  }

  return initData;
};

export default adServiceTypeSelect;
