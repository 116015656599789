import { videojs, Image } from 'global/window';
import { tryGetNext } from './validity';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const Button = videojs.getComponent('Button');

/**
 * Creates screen wrapper for the autoplay next plugin
 */
class PlayButton extends Button {
  constructor(player, options) {
    super(player, options);
    const buttonText = player.localize('Play next');
    const thumbnailSrc = this.options_.thumbnail;

    this.controlText(buttonText);

    const imgEl = new Image();

    imgEl.src = thumbnailSrc;
    this.el_.appendChild(imgEl);

    // eslint-disable-next-line func-names
    imgEl.onload = function () {
      if (this.width < this.height) {
        imgEl.parentElement.classList.add('vjs-an-thumb--portrait');
        imgEl.style.width = 'auto';
        imgEl.style.height = this.height < 160 ? this.height : '160px';
      } else {
        imgEl.style.width = this.width < 160 ? this.width : '160px';
        imgEl.style.height = 'auto';
      }
    };
  }

  /**
   * Allow sub components to stack CSS class names
   *
   * @return {String} The constructed class name
   */
  buildCSSClass() {
    return 'vjs-play-button-circle vjs-an-play vjs-button';
  }

  handleClick() {
    tryGetNext(this.player_, this.options_.target, this.options_.urlRedirect, 'button', this.options_.productId);
  }
}

// Register the new component
Component.registerComponent('PlayButton', PlayButton);
export default PlayButton;
