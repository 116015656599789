/**
 * <p>Displays thumbnail images over the progress bar.</p>
 *
 * <h3>Initialization</h3>
 * Consider to not initialize thumbnails when offset plugin initialized already... See the example below to understand how to initialize this plugin.
 *
 * @example
{
  plugins: {
    thumbnails: {
      url: 'http://example.com/thumb.$Num$.jpg',
      markers: [{
         time: 60,
         text: 'Some dummy text 1'
       }, {
         time: 180,
         text: 'Some dummy text 2'
       }, {
         time: 240,
         text: 'Some dummy text 3'
       }
      ]
    },
  }
}
 *
 * @module plugins/thumbnailsPlugin
 */
import log from '../log';

const extend = (...args) => {
  const argsArr = Array.prototype.slice.call(args);
  const target = argsArr.shift() || {};
  argsArr.forEach((i) => {
    if ({}.hasOwnProperty.call(argsArr, i)) {
      const object = argsArr[i];
      object.forEach((property) => {
        if (object.hasOwnProperty(property)) {
          if (typeof object[property] === 'object') {
            target[property] = extend(target[property], object[property]);
          } else {
            target[property] = object[property];
          }
        }
      });
    }
  });
  return target;
};

const getComputedStyle = (el, pseudo) => (prop) => {
  if (window.getComputedStyle) {
    return window.getComputedStyle(el, pseudo)[prop];
  }
  return el.currentStyle[prop];
};

const offsetParent = (el) => {
  if (el.nodeName !== 'HTML' && getComputedStyle(el)('position') === 'static') {
    return offsetParent(el.offsetParent);
  }
  return el;
};
const getVisibleWidth = (el, width) => {
  if (width) {
    return parseFloat(width);
  }

  let clip = getComputedStyle(el)('clip');
  if (clip !== 'auto' && clip !== 'inherit') {
    clip = clip.split(/(?:\(|\))/)[1].split(/(?:,| )/);
    if (clip.length === 4) {
      return parseFloat(clip[1]) - parseFloat(clip[3]);
    }
  }
  return 0;
};
const getScrollOffset = () => {
  if (window.pageXOffset) {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset,
    };
  }
  return {
    x: document.documentElement.scrollLeft,
    y: document.documentElement.scrollTop,
  };
};
/**
 * Returns number in three digit format
 */
const getThumbnailNumber = (number) => {
  switch (number.toString().length) {
    case 1:
      return `00${number}`;
    case 2:
      return `0${number}`;
    case 3:
      return number;
    default:
      return '000';
  }
};
/**
 * Returns text of the marker when matched
 */
const getMarkerText = (markers, start, interval, player) => {
  if (typeof markers !== 'undefined') {
    for (let i = 0; i < markers.length; i++) {
      if (start <= parseFloat(markers[i].time) && markers[i].time < start + interval) {
        return markers[i].text;
      }
    }
  }

  // Check when active seek hints plugin and search desctiption as thumnail text
  const { seekHints } = player.options_.plugins;
  if (seekHints && seekHints.length > 0) {
    for (let i = 0; i < seekHints.length; i++) {
      if (start <= parseFloat(seekHints[i].time) && seekHints[i].time < start + interval) {
        log(
          `[Thumbnails plugin] finded alternative thumbnail text from seek hints start:${start} text: ${seekHints[i].text}`,
        );
        return seekHints[i].text;
      }
    }
  }

  return null;
};

const getFormattedThumbnails = (player, opts, duration) => {
  const interval = duration / 100;
  const urlArr = opts.url.split('$');
  const url = urlArr[0];
  const extension = urlArr[urlArr.length - 1];
  const thumbnails = {};
  let start = 0;

  for (let i = 1; i <= 100; i++) {
    const thumbnail = {
      src: url + getThumbnailNumber(i) + extension,
    };
    const text = getMarkerText(opts.markers, start, interval, player);
    if (text !== null) {
      thumbnail.text = text;
    }

    thumbnails[start.toFixed(1)] = thumbnail;
    start += interval;
  }
  return thumbnails;
};
/**
 * Returns a more accurate distance
 */
const calculateDistance = (player, event) => {
  const el = player.el();
  const box = el.getBoundingClientRect();
  let boxW = el.offsetWidth;
  const { handle } = player.controlBar.progressControl.seekBar;
  let boxX = box.left;
  let pageX = 0;

  if (event.changedTouches) {
    pageX = event.changedTouches[0].pageX;
  } else {
    pageX = event.pageX;
  }

  if (handle) {
    const handleW = handle.el().offsetWidth;

    // Adjusted X and Width, so handle doesn't go outside the bar
    boxX += handleW / 2;
    boxW -= handleW;
  }

  // Percent that the click is through the adjusted area
  return Math.max(0, Math.min(1, (pageX - boxX) / boxW));
};

/**
 * Thumbnails plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 * @param {Object} opts.url - Thumbnails url
 * @param {boolean} opts.overflow - If set true, thumbnail will overflow from video element, default false.
 * @param {Array.<Object>} opts.markers - Markers array
 * @param {Number} opts.markers.time - Time in seconds
 * @param {String} opts.markers.text - Hint text will be displayed in the popup menu
 */
export const thumbnailsPlugin = function thumbnailsPlugin(opts) {
  if (!opts.url) {
    return;
  }

  let progressControl = null;
  const player = this;
  player.on('loadeddata', () => {
    // keep track of the duration to calculate correct thumbnail to display
    let duration = player.duration();
    const thumbnails = getFormattedThumbnails(player, opts, duration);

    (() => {
      // Android doesn't support :active and :hover on non-anchor and non-button elements
      // so, we need to fake the :active selector for thumbnails to show up.
      if (navigator.userAgent.toLowerCase().indexOf('android') !== -1) {
        progressControl = player.controlBar.progressControl;

        const addFakeActive = () => {
          progressControl.addClass('fake-active');
        };
        const removeFakeActive = () => {
          progressControl.removeClass('fake-active');
        };

        progressControl.on('touchstart', addFakeActive);
        progressControl.on('touchend', removeFakeActive);
        progressControl.on('touchcancel', removeFakeActive);
      }
    })();

    // create the thumbnail
    const div = document.createElement('div');
    div.className = 'vjs-thumbnail-holder';
    const wrapper = document.createElement('div');
    wrapper.className = 'vjs-thumbnail-wrapper';
    const arrow = document.createElement('div');
    arrow.className = 'vjs-thumbnail-arrow';
    wrapper.appendChild(arrow);
    const span = document.createElement('span');
    wrapper.appendChild(span);
    const img = document.createElement('img');
    wrapper.appendChild(img);
    div.appendChild(wrapper);
    img.src = '';
    img.className = 'vjs-thumbnail';
    const pTime = document.createElement('p');
    pTime.className = 'vjs-thumbnail-time';
    wrapper.appendChild(pTime);
    extend(img.style, '');

    // center the thumbnail over the cursor if an offset wasn't provided
    if (!img.style.left && !img.style.right) {
      img.onload = () => {
        const imgWrapperBounds = wrapper.getBoundingClientRect();
        wrapper.style.left = `-${imgWrapperBounds.width / 2}px`;
      };
    }

    // when the container is MP4
    player.on('durationchange', () => {
      duration = player.duration();
    });

    // when the container is HLS
    player.on('loadedmetadata', () => {
      duration = player.duration();
    });

    // add the thumbnail to the player
    progressControl = player.controlBar.progressControl;
    progressControl.el().appendChild(div);

    const moveListener = (event) => {
      let active = 0;
      const pageXOffset = getScrollOffset().x;
      const clientRect = offsetParent(progressControl.el()).getBoundingClientRect();
      const right = (clientRect.width || clientRect.right) + pageXOffset;

      let { pageX } = event;
      if (event.changedTouches) {
        pageX = event.changedTouches[0].pageX;
      }

      // find the page offset of the mouse
      let left = pageX || event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      // subtract the page offset of the positioned offset parent
      left -= offsetParent(progressControl.el()).getBoundingClientRect().left + pageXOffset;

      // apply updated styles to the thumbnail if necessary
      // mouseTime is the position of the mouse along the progress control bar
      // `left` applies to the mouse position relative to the player so we need
      // to remove the progress control's left offset to know the mouse position
      // relative to the progress control
      const mouseTime = Math.floor(((left - progressControl.el().offsetLeft) / progressControl.width()) * duration);
      Object.keys(thumbnails).forEach((time) => {
        if (mouseTime > time) {
          active = Math.max(active, time);
        }
      });

      const setting = thumbnails[active % 1 === 0 ? `${active}.0` : active];
      if (setting.src && img.src !== setting.src) {
        img.src = setting.src;
      }
      if (setting.style && img.style !== setting.style) {
        extend(img.style, setting.style);
      }
      // Remove old content
      while (span.firstChild) {
        span.removeChild(span.firstChild);
      }
      // Append child text if not empty
      if (typeof setting.text !== 'undefined') {
        span.appendChild(document.createTextNode(setting.text));
      }

      // Remove old time value
      while (pTime.firstChild) {
        pTime.removeChild(pTime.firstChild);
      }
      // Show current moouse time
      const timeInSeconds = calculateDistance(player, event) * player.duration();
      pTime.appendChild(document.createTextNode(videojs.formatTime(timeInSeconds)));

      // FIXME: JPk doesn't now the purpose of `getVisibleWidth`
      const imgWrapperBounds = wrapper.getBoundingClientRect();
      const { width } = imgWrapperBounds; // getVisibleWidth(img, 0);
      const halfWidth = width / 2;
      const arrowBounds = arrow.getBoundingClientRect();
      let arrowLeftOffset = halfWidth - arrowBounds.width / 2;

      if (!opts.overflow) {
        // make sure that the thumbnail doesn't fall off the right or left side of the player
        if (left + halfWidth > right) {
          arrowLeftOffset = Math.min(left + arrowLeftOffset - (right - halfWidth), width - arrowBounds.width);
          left = right - halfWidth;
        } else if (left < halfWidth) {
          arrowLeftOffset = Math.max(left + arrowLeftOffset - halfWidth, 0);
          left = halfWidth;
        }
      }

      // Set offset of main thumbnail element
      div.style.left = `${left}px`;

      // Set offset of small arrow
      arrow.style.left = `${arrowLeftOffset}px`;
    };

    // update the thumbnail while hovering
    progressControl.on('mousemove', moveListener);
    progressControl.on('touchmove', moveListener);

    const moveCancel = () => {
      div.style.left = '-100vw';
    };

    // move the placeholder out of the way when not hovering
    progressControl.on('mouseout', moveCancel);
    progressControl.on('touchcancel', moveCancel);
    progressControl.on('touchend', moveCancel);
    player.on('userinactive', moveCancel);
  });

  log('thumbnails plugin initialized');
};
