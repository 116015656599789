import { videojs } from 'global/window';
import { adaptiveStreams } from '../constants';
import log from '../log';

const Plugin = videojs.getPlugin('plugin');

export class AudioChannelsController extends Plugin {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.options = options;

    this.audioTracksList = [];
    this.multiChannelAvailable = false;
    this.multiChannelActive = false;

    this.multiChannelIdentifier = options.fallbackRoleMapping || 'alternative';

    this.player.one('ott-menu-initialized', () => {
      log('[audioChannels] initalizing');
      this.init();
    });
  }

  triggerChange = () => {
    this.player.trigger({
      type: 'ott-audiochannels-control',
      audioChannelsAllowed: this.multiChannelAvailable,
      multiChannelActive: this.multiChannelActive,
    });
  };

  init = () => {
    // 5.1 only works in DASH at the moment
    if (this.player.currentSource().type !== adaptiveStreams.dash) {
      super.dispose();
      return;
    }

    this.audioTracksList = this.player.audioTracks().tracks_;
    this.multiChannelAvailable = this.audioTracksList.length > 1;

    this.player.on('ott-achswitch-click', (e) => {
      log(`[audioChannels] recieved switch button event. Checked ${e.checked}`);

      if (e.checked) {
        this.enableMultiChannelAudio();
      } else {
        this.disableMultiChannelAudio();
      }
    });

    this.player.on('ott-language-changed', () => {
      this.audioTracksList = this.player.audioTracks().tracks_;
      this.multiChannelAvailable = this.audioTracksList.length > 1;

      if (this.multiChannelActive && this.multiChannelAvailable) {
        setTimeout(() => {
          this.enableMultiChannelAudio();
          this.triggerChange();
        }, 1);
      } else {
        this.disableMultiChannelAudio();
        this.triggerChange();
      }
    });

    this.triggerChange();
  };

  enableMultiChannelAudio = () => {
    this.audioTracksList.find((track) => track.kind === this.multiChannelIdentifier).enabled = true;
    this.multiChannelActive = true;
    // this.triggerChange();
  };

  disableMultiChannelAudio = () => {
    this.audioTracksList.find((track) => track.kind === 'main').enabled = true;
    this.multiChannelActive = false;
    // this.triggerChange();
  };
}
