/**
 * Multilingual tracks selection module
 * @module multilingual-match
 */
import log from './log';
import { PR_NO_LOCALIZED_SRC, PR_LANG_ARE_NOT_ARRAY } from './errors';

/**
 * Try to get sources preferred or persisted languages,
 *
 * @param {module:playback-match~PlayerSource[]} sources - Valid sources for the browser
 * @param { string[] } [preferredLanguages = []] - Array of preferred languages (preferredLang in the player config)
 * @param { String } [persistedLanguage] - Persisted language (last used by the viewer) saved by persist-settings plugin
 * @param { Boolean } [isImaDai] - there will be source fetched by the ima dai stream manager
 * @return {module:playback-match~PlayerSource[]} sources in persisted and preferred languages or if there is no match original sources
 */
export default function getLocalizedSources(sources, preferredLanguages = [], persistedLanguage, isImaDai) {
  // preferredLanguages is called preferredLang but it's actually an array, so there have to be this odd check
  if (!Array.isArray(preferredLanguages)) {
    log.error(PR_LANG_ARE_NOT_ARRAY.code, PR_LANG_ARE_NOT_ARRAY.message, preferredLanguages);
    return sources;
  }

  // get all persisted and preferred languages
  // persistedLanguage could be undefined, but it shouldn't matter
  const persistedAndPreferredLanguages = [persistedLanguage, ...preferredLanguages].filter((lang) => !!lang);

  // filter out all the sources that are not in persisted or preferred
  const localizedSources = persistedAndPreferredLanguages
    .filter(() => Array.isArray(sources))
    .map((persistedOrPreferredLanguage) => sources.find((source) => source.lang === persistedOrPreferredLanguage))
    .filter((source) => !!source);

  if (localizedSources.length === 0 && isImaDai) {
    log('IMA-DAI will get the source, but there is no fallback stream.');
    return sources || [];
  }

  // if there are no preferred or persisted languages among sources, return original sources
  if (localizedSources.length === 0) {
    log.error(PR_NO_LOCALIZED_SRC.code, PR_NO_LOCALIZED_SRC.message);
    return sources;
  }

  return localizedSources;
}
