function createQualityLabe(menuLabel, controlLabel = '') {
  return {
    menuLabel,
    controlLabel,
  };
}

export function qualityMapping(height, bitrate) {
  if (bitrate && !height) {
    return createQualityLabe(`${Math.round(bitrate / 1024)}kb`);
  }

  let qualityLabel = '';

  if (height >= 2 && height <= 576) {
    qualityLabel = 'SD';
  }
  if (height > 576 && height < 1080) {
    qualityLabel = 'HD';
  }
  if (height === 1080) {
    qualityLabel = 'FHD';
  }
  if (height > 1080 && height <= 1440) {
    qualityLabel = 'QHD';
  }
  if (height > 1440) {
    qualityLabel = 'UHD';
  }
  return createQualityLabe(`${height}p`, qualityLabel);
}

export function getVideoQuality(player) {
  return `${player.videoWidth()}x${player.videoHeight()}`;
}
