import is from 'is_js';
import log from '../log';
import { TS_NAN_SOURCE_CHANGE, TS_SRC_INVALID } from '../errors';
import { defaults } from '../plugins/time-shift/ts-state';
import * as UrlHelper from './url';
import { isAdPlaying } from '../plugins/adservice/shared/ad-state';

// using a generator function
// note PSd. this is similar as Object.values()
function getEntries(obj) {
  const keys = Object.keys(obj);

  return keys.map((key) => obj[key]);
}

/**
 * Get formatted current source object from player
 *
 * @param {Object} player The player instance
 * @returns {Object} Current source object
 */
export function getCurrentSource(player) {
  let currentSource = {
    src: player.currentSrc(),
    type: player.currentType(),
  };
  if (isAdPlaying(player) && (player.ads || {}).snapshot) {
    currentSource = {
      src: player.ads.snapshot.currentSrc,
      type: player.ads.snapshot.type,
    };
  }

  const entries = getEntries(player.options_.tracks);
  function compareEntry(entry) {
    return entry.src === currentSource.src;
  }

  for (let i = 0; i < entries.length; i++) {
    const match = entries[i].find(compareEntry);

    if (match) {
      currentSource = match;
    }
  }

  return currentSource;
}

/**
 * Change source with time shift parametr if exist
 *
 * @param {Object} player - The player instance
 * @param {Object} source - A valid videojs src format
 * @param {Number} seekTime - Time-shift seek time in seconds
 */
export function changeTSSource(player, source, seekTime = 0) {
  seekTime = parseInt(seekTime, 10);
  if (is.not.finite(seekTime)) {
    log.error(TS_NAN_SOURCE_CHANGE.code, TS_NAN_SOURCE_CHANGE.message);
    player.trigger({ type: 'ott-custom-error', ...TS_NAN_SOURCE_CHANGE });
    throw TS_NAN_SOURCE_CHANGE;
  }

  const { urlParam } = player[defaults.objName] || {};
  if (urlParam && urlParam.length > 0) {
    // Change URL only when seekTime is greater than 0
    if (seekTime > 0) {
      // When url parameter exist change source time-shifted
      let pattern = `[?&]${urlParam}=`;
      let regExp = new RegExp(pattern, 'g');

      if (regExp.test(source.src)) {
        pattern = `(${urlParam}=)[^&]+`;
        regExp = new RegExp(pattern, 'g');
        source.src = source.src.replace(regExp, `$1${seekTime}`);
      } else if (source.src.includes('?')) {
        source.src = `${source.src}&${urlParam}=${seekTime}`;
      } else {
        source.src = `${source.src}?${urlParam}=${seekTime}`;
      }
    } else {
      source.src = UrlHelper.removeURLParameter(source.src, urlParam);
    }
  }

  // there are some shenanigans going on when the source is changed in ima,
  // so we need to trick the contribAds to think that there is no change
  // and everything works just fine
  // https://videojs.github.io/videojs-contrib-ads/integrator/api.html#contentsrc
  if (player.activePlugins_?.adServiceIMA && player.activePlugins_?.csai) {
    player.ads.contentSrc = source.src;
  }
  if (!player.paused()) {
    player.one('loadeddata', () => {
      player.play();
    });
  }

  player.src(source);
}

/**
 * Try to find timeshift URL by current source passed as argument. When any timeshift URL found currentSrc will be returned
 * @param  {Object} currentSrc Current source from the tracks init object. Must contain src and type
 * @return {Object}            A timeshift URL when source matched and URL found. Current src will be returned otherwise
 * @return {Object} player     Player object
 */
export function getTimeshiftURL(currentSrc, tracks, player) {
  if (!(currentSrc || {}).src) {
    log.error(TS_SRC_INVALID.code, TS_SRC_INVALID.message);
    player.trigger({ type: 'ott-custom-error', ...TS_SRC_INVALID });
    throw TS_SRC_INVALID;
  }
  const result = currentSrc;
  const entries = getEntries(tracks);

  for (let i = 0; i < entries.length; i++) {
    const match = entries[i].find((entry) => entry.src === currentSrc.src && entry.timeshiftSrc);

    if (match) {
      result.src = match.timeshiftSrc;
      return result;
    }
  }

  return result;
}

// FIXME: better logic for method return
export function getStreamInfo(player) {
  const { plugins } = player.options_;

  if (plugins.measuringImaDai) {
    return plugins.measuringImaDai;
  }

  if (plugins.adServiceIMA) {
    return plugins.measuringIMA.streamInfo;
  }

  if (plugins.measuring.streamInfo) {
    return plugins.measuring.streamInfo;
  }

  log.error('[getStreamInfo] missing streamInfo data');
  return {};
}
