/**
 * @module plugins/VolumeControl
 *
 */
import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('VolumeControl');

class VolumeControl extends Component {
  constructor(player, options = {}) {
    super(player, { name: 'VolumeControl', ...options });
    this.player = player;
    this.isPressed = false;
  }

  handleMouseDown(event) {
    super.handleMouseDown(event);
    this.player.trigger({ type: 'ott-volume-change', volume: this.player.volume() });
    this.isPressed = true;
  }

  handleMouseUp(event) {
    super.handleMouseUp(event);
    this.isPressed = false;
  }

  handleMouseMove(event) {
    super.handleMouseMove(event);

    if (this.isPressed) {
      this.player.trigger({ type: 'ott-volume-change', volume: this.player.volume() });
    }
  }

  createEl() {
    const vertical = this.options_.vertical;

    return videojs.dom.createEl('div', {
      className: `vjs-volume-control vjs-control vjs-volume-${vertical ? 'vertical' : 'horizontal'}`,
    });
  }
}

videojs.registerPlugin('VolumeControl', VolumeControl);
export default VolumeControl;
