import window from 'global/window';
import document from 'global/document';
import VPAIDClient from './vpaid/client';
import { AS_VPAID_PLAYING_CLASS_NAME, AS_VPAID_IFRAME_ZINDEX } from '../../constants';
import log from '../../log';
import DomOperations from './shared/dom-operations';
import { LinearVPAIDTracker } from './shared/vast-tracker';
import { extend } from '../../utils/object-helpers';
import { removeElementHTML, removeElementById } from './vpaid/utils';

/**
 * Process VPAID ad unit
 * @param {Object} player                                       Video.js player instance
 * @param {module:plugins/adService~AdSettingsObjj} settings    Ad service settigns
 */
class VPAID {
  constructor(player, settings, videoEl) {
    this.options = extend(
      false,
      {
        bitrate: -1,
        viewMode: 'normal',
        timeout: 5000,
        timeCheckInterval: 500,
        retryFallbackTimeout: 1500,
        zIndex: AS_VPAID_IFRAME_ZINDEX,
        css: {
          playingState: 'vpaid-playing',
          pausedState: 'vpaid-paused',
        },
      },
      settings.linear.vpaid,
    );
    this.player = player;
    this.domOperations = new DomOperations(player, settings);
    this.videoEl = videoEl;
    this.listeners = {};
  }

  /**
   * Try to find VPAID iframe wrapper
   * @return {HTMLElement} VPAID iframe wrapper
   */
  getIframeWrapper() {
    return document.getElementById(this.options.friendlyIframeID);
  }

  /**
   * Determines whether the specified media type can be played back.
   * @param  {String} el MIME type of the media.
   * @return {String} [probably, maybe, ''] if the source is playable
   */
  updateCanPlayFunction(el) {
    const originalFunc = el.constructor.prototype.canPlayType.bind(el);
    // const currentType = this.player.currentType().toLowerCase();
    el.canPlayType = (mimeType) => {
      const type = mimeType.toLowerCase();
      // FIXME BPo: hls <-> dash switching?
      if (type.indexOf('flv') > -1) {
        return '';
      }
      return originalFunc(type);
    };
  }

  /** @private */
  onLoaded() {
    if (!this.player.paused()) {
      this.player.pause();
    }
    log('VPAID: onLoaded called.');
    this.adUnit.startAd().then(() => {
      // TODO: remove this class or x-vjs-vpaid-player-visible
      this.player.addClass(AS_VPAID_PLAYING_CLASS_NAME);

      // Set current linear ad id to the adstate player object
      this.player.adstate.linearAdId = this.adUnit.id;

      // Set adplaying status
      this.player.adstate.adPlaying = true;

      // disable video content play for safety
      this.player.play = () => {};

      // set 00:00 before ad is loaded
      const playlistTimeDisplay = this.player.controlBar.getChild('PlaylistTimeDisplay');
      if (playlistTimeDisplay) {
        playlistTimeDisplay.playlistDurationDisplay.updateTextNode_(0);
        playlistTimeDisplay.playlistCurrentTimeDisplay.updateTextNode_(0);
      } else {
        this.player.controlBar.getChild('DurationDisplay').updateTextNode_(0);
        this.player.controlBar.getChild('CurrentTimeDisplay').updateTextNode_(0);
      }
    });
  }

  onRetryFallback() {
    this.adUnit.getAdRemainingTime().then((time) => {
      const curr = this.getAdCurrentTime(time);
      if (curr === 0) {
        log.warn('VPAID: Video still not playing. Trying to load source again.');
        this.player.load();
        this.player.play();
      }
    });
  }

  /** @private */
  unsubscribeAll() {
    // Revert play function
    this.player.play = this.listeners.originalPlay;

    // Remove VPAID UI
    removeElementHTML(this.getIframeWrapper());
    removeElementById(document, this.adUnit.el.id);
    // Remove all VPAID subscribers
    this.adUnit.unsubscribeAll();
    // Remove old skip button UI
    this.domOperations.removeAdMessageWrapperHTML();
    // Remove all VPAID events on the player
    this.player.off(['resize', 'playerresize'], this.listeners.resizeAd);
    this.player.off('fullscreenchange', this.listeners.onFullscreenChange);
    this.player.off('volumechange', this.listeners.updateAdUnitVolume);
    this.player.off('vpaid-fake-pause', this.listeners.onFakePause);
    this.player.off('vpaid-fake-play', this.listeners.onFakePlay);
    this.player.off('adsplayer-button-skip', this.listeners.onAdsPlayerButtonSkip);
    this.player.off('adended', this.listeners.onEnded);
    this.player.off('aderror', this.listeners.onError);
    this.player.removeClass(AS_VPAID_PLAYING_CLASS_NAME);
    // this.player.one('playing', this.listeners.loadedmetadataFallback);
  }

  loadedmetadataFallback() {
    this.player.trigger('loadedmetadata-force');
  }

  onEnded() {
    if (this.ended) return;
    log.warn('VPAID: adended event was fired before VPAID ended ad.');
    // Call stopAd on the VPAID unit manually
    this.adUnit.stopAd();
  }

  /** @private */
  onStopped() {
    log('VPAID: onStopped called');
    if (this.ended) return;
    this.ended = true;
    this.unsubscribeAll();
    this.player.trigger('ott-vpaid-ended');
    this.adEnded();
  }

  /** @private */
  onError() {
    log('VPAID: onError called');
    log.error('VPAID error occured. VPAID end event will be called manually');
    this.onStopped();
  }

  /** @private */
  onSkipped() {
    log('VPAID: onSkipped called');
    if (this.ended) return;
    this.ended = true;
    this.unsubscribeAll();
    this.player.trigger('ott-vpaid-skip-button');
    this.adEnded();
  }

  /** @private */
  onClickThru(data) {
    if (data.playerHandles) {
      const url = data.url
        ? data.url
        : this.creative.videoClickThroughURLTemplate?.url || this.creative.videoClickThroughURLTemplate;
      if (url) {
        // Pause player when click on the ad link
        this.player.pause();

        // Trigger linear click throug
        this.player.trigger('linearclickthrough');

        // Call measurement pause event
        this.player.trigger('adpauseclicked');

        window.open(url, '_blank');
      }
    }
  }

  /** @private */
  onAdSkippableStateChange() {
    log('VPAID: onAdSkippableStateChange called');
    this.adUnit.getAdSkippableState().then((enabled) => {
      if (enabled) {
        this.domOperations.showVPAIDSkipButton(() => {
          this.adUnit.skipAd();
        });
      }
    });
  }

  /** @private */
  resizeAd() {
    this.adUnit.resizeAd(this.player.el().offsetWidth, this.player.el().offsetHeight, this.options.viewMode);
  }

  /** @private */
  onFullscreenChange() {
    if (this.player.isFullscreen()) {
      this.adUnit.resizeAd(0, 0, 'fullscreen');
    } else {
      this.resizeAd();
    }
  }

  /** @private */
  updateAdUnitVolume() {
    const vol = this.player.muted() ? 0 : this.player.volume();
    this.adUnit.setAdVolume(vol);
  }

  /** @private */
  onAdVolumeChange() {
    const lastVolume = this.player.volume();
    this.adUnit
      .getAdVolume((vol) => {
        if (lastVolume !== vol) {
          this.player.volume(vol);
        }
      })
      .catch((e) => log.error('Player getAdVolume promise failed.', e));
  }

  getAdCurrentTime(time) {
    const isNaNCross = Number.isNaN || isNaN;
    if (this.adDuration > 0 && time >= 0 && !isNaNCross(time)) {
      return this.adDuration - time;
    }
    return 0;
  }

  /**
   * Check and update duration UI
   * @returns {Promise} A promise that returns VPAID duration
   */
  onDurationChange() {
    return new Promise((resolve) => {
      this.adUnit.getAdDuration().then((duration) => {
        this.adDuration = duration;
        resolve(duration);
      });
    });
  }

  /**
   * Try to find first javascript media file (skip all flash)
   * @param  {Object} mediaFiles List of creative media files
   * @return {Object} First media file if non flas media found, undefined otherwise
   */
  getMediaFile(mediaFiles) {
    const jsMediaFiles = mediaFiles.filter((mediaFile) => mediaFile.mimeType.toLowerCase().indexOf('flash') === -1);
    return jsMediaFiles[0];
  }

  bindListeners() {
    this.listeners.updateAdUnitVolume = this.updateAdUnitVolume.bind(this);
    this.listeners.onAdVolumeChange = this.onAdVolumeChange.bind(this);
    this.listeners.onError = this.onError.bind(this);
    this.listeners.onEnded = this.onEnded.bind(this);
    this.listeners.onError = this.onError.bind(this);
    this.listeners.onLoaded = this.onLoaded.bind(this);
    this.listeners.onRetryFallback = this.onRetryFallback.bind(this);
    this.listeners.onStopped = this.onStopped.bind(this);
    this.listeners.onSkipped = this.onSkipped.bind(this);
    this.listeners.onClickThru = this.onClickThru.bind(this);
    this.listeners.onAdSkippableStateChange = this.onAdSkippableStateChange.bind(this);
    this.listeners.resizeAd = this.resizeAd.bind(this);
    this.listeners.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.listeners.onDurationChange = this.onDurationChange.bind(this);
    this.listeners.loadedmetadataFallback = this.loadedmetadataFallback.bind(this);
    this.listeners.onFakePause = this.onFakePause.bind(this);
    this.listeners.onFakePlay = this.onFakePlay.bind(this);
    this.listeners.originalPlay = this.player.play.bind(this.player);
    this.listeners.onAdsPlayerButtonSkip = this.onAdsPlayerButtonSkip.bind(this);
  }

  onFakePause() {
    this.adUnit.pauseAd();
  }

  onFakePlay() {
    this.adUnit.resumeAd();
  }

  onAdsPlayerButtonSkip() {
    this.adUnit.skipAd();
  }

  registerListeners() {
    // Wait for the adended and remove old lineartracking events
    this.player.one('adended', this.listeners.onEnded);
    // Skip ad on the player aderror as well
    this.player.one('aderror', this.listeners.onError);
    // Register subscribers
    this.adUnit.subscribe('AdLoaded', this.listeners.onLoaded);
    this.adUnit.subscribe('AdError', this.listeners.onError);
    this.adUnit.subscribe('AdStopped', this.listeners.onStopped);
    this.adUnit.subscribe('AdSkipped', this.listeners.onSkipped);
    this.adUnit.subscribe('AdClickThru', this.listeners.onClickThru);
    this.adUnit.subscribe('AdSkippableStateChange', this.listeners.onAdSkippableStateChange);
    this.adUnit.subscribe('AdVolumeChange', this.listeners.onAdVolumeChange);
    this.adUnit.subscribe('AdDurationChange', (duration) => {
      // this.player.duration(duration);
    });
    this.adUnit.subscribe('AdPaused', () => {
      this.player.trigger('vpaid.pause');
    });
    this.adUnit.subscribe('AdPlaying', () => {
      this.player.trigger('vpaid.play');
    });
    // Send info to the VPAID about resizing
    this.player.on(['resize', 'playerresize'], this.listeners.resizeAd);
    this.player.on('fullscreenchange', this.listeners.onFullscreenChange);
    this.player.on('volumechange', this.listeners.updateAdUnitVolume);
    this.player.on('vpaid-fake-pause', this.listeners.onFakePause);
    this.player.on('vpaid-fake-play', this.listeners.onFakePlay);
    this.player.on('adsplayer-button-skip', this.listeners.onAdsPlayerButtonSkip);

    // event from vpaid player
    this.player.on('ott-vpaid-player-error', () => {
      this.onError();
    });
  }

  /**
   * Initialize concrete VPAID ad
   * @param  {Object} ad          Current ad from the break inventory
   * @param  {Object} creative    Crative from the current break inventory
   * @param  {Function} adEnded   Called when VPAID ad end event occurs
   */
  playAd(ad, creative, adEnded) {
    if (!this.player.paused()) {
      this.player.pause();
    }

    this.creative = creative;
    this.adEnded = adEnded;
    const vpaidClient = new VPAIDClient(this.getIframeWrapper(), this.videoEl, this.videoEl.id, {
      timeout: this.options.timeout,
      zIndex: this.options.zIndex,
    });
    vpaidClient
      .loadAd(this.getMediaFile(creative.mediaFiles).fileURL)
      .then((adUnit) => {
        this.adUnit = adUnit;
        this.adUnit.handshakeVersion('2.0').then((version) => {
          if (version !== '2.0') {
            log.error('Version different to 2.0. VPAID will be skipped.');
            this.adEnded();
            return;
          }
          this.bindListeners();
          this.registerListeners();

          // register tracking for this linear ad
          this.linearTracker = new LinearVPAIDTracker(this.player, this.adUnit);
          this.linearTracker.track(ad, creative);

          const creativeData = {};
          if (this.creative.adParameters) {
            creativeData.AdParameters = this.creative.adParameters;
          }

          this.previousSource = this.player.src();
          // Be sure VPAID playing state is set before the startLinearAdMode
          // this.player.addClass(this.options.css.playingState);
          // Linear break initialization must be before VPAID, or the current video position is saved at 0, and after midrolls the video will return to the beginning.
          if (!this.player.ads.isAdPlaying()) {
            this.player.ads.startLinearAdMode();
          }

          this.adUnit
            .initAd(
              this.player.el().offsetWidth,
              this.player.el().offsetHeight,
              this.options.viewMode,
              this.options.bitrate,
              creativeData,
            )
            .catch((e) => {
              log.error('VPAID adUniti initialization failed.', e);
              this.linearTracker.dispose();
              this.onStopped();
            });
        });
      })
      .catch((message) => {
        log.error('VPAID load ad unit failed! ', message);
      });
  }
}

export default VPAID;
