import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistMarker extends Component {
  getMarkerPosition() {
    const { playlistData } = this.player_.playlist;
    const progressBarWidth = this.player_.controlBar.progressControl.width();
    const onePercentWidth = progressBarWidth / playlistData.totalDuration;
    const timeBeforeThisSource = playlistData.sources[this.options_.sourceIndex].durationBefore;

    return Math.floor(timeBeforeThisSource * onePercentWidth);
  }

  createEl() {
    return super.createEl(
      'div',
      {
        className: 'vjs-playlist-marker',
        innerHTML: `<span>${this.options_.sourceIndex}</span>`,
      },
      { style: `left: ${this.getMarkerPosition()}px` },
    );
  }
}

Component.registerComponent('PlaylistMarker', PlaylistMarker);
export default PlaylistMarker;
