import { adaptiveStreams, AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME } from './constants';
import { findVhsError } from './extendedErrorDisplay';

const formatDASH = (formattedSource, source, player) => {
  // all the playready stuff is here to try to try playready.recommendation first, then vanilla playready
  // in case there is not first or the other, just copy the other and make it the other key system name
  // if they are there, just put them to the formattedKeySystems object in the right order
  // com.microsoft.playready.recommendation > com.microsoft.playready > other
  const playReadyKeySystems = [];

  /*
   TODO: Make this code live when there is support of com.microsoft.playready.recommendation and com.microsoft.playready.recommendation.3000

   const playreadyRecommendation3000 = source.drm.find((drm) => drm.keySystem === 'com.microsoft.playready.recommendation.3000')
   const playreadyRecommendation = source.drm.find((drm) => drm.keySystem === 'com.microsoft.playready.recommendation')
  */

  const playready = source.drm.find((drm) => drm.keySystem === 'com.microsoft.playready');

  // const highestPlayready = playreadyRecommendation3000 || playreadyRecommendation || playready;

  /*
  TODO:  Make this code live when there is support of com.microsoft.playready.recommendation and com.microsoft.playready.recommendation.3000
  it ensures that it sort drm systems as they should be from product standpoint and provide the best fallback it if some version of playready is missing

  if (highestPlayready) {
    playReadyKeySystems.push(
      playreadyRecommendation3000 || { ...highestPlayready, keySystem: 'com.microsoft.playready.recommendation.3000' },
      playreadyRecommendation || { ...highestPlayready, keySystem: 'com.microsoft.playready.recommendation' },
      playready || { ...highestPlayready, keySystem: 'com.microsoft.playready' },
    );
  }
  */

  if (playready) {
    playReadyKeySystems.push(playready);
  }
  const keySystemsWithoutPlayready = source.drm.filter((drm) => !drm.keySystem.startsWith('com.microsoft.playready'));
  const keySystemsWithPlayreadyAtStart = [...playReadyKeySystems, ...keySystemsWithoutPlayready];

  const formattedKeySystems = {};

  keySystemsWithPlayreadyAtStart.forEach((drm) => {
    formattedKeySystems[drm.keySystem] = {
      url: drm.serverURL,
      licenseHeaders: Object.fromEntries(drm.headers.map((header) => [header.name, header.value])),
      getLicense: (emeOptions, contentId, callback) => {
        const { message } = getMessageContents(contentId);
        videojs.xhr(
          {
            url: emeOptions.keySystems[drm.keySystem].url,
            method: 'POST',
            responseType: 'arraybuffer',
            body: message,
            headers: {
              'Content-type': 'text/xml; charset=utf-8',
              ...emeOptions.keySystems[drm.keySystem].licenseHeaders,
            },
          },
          (err, response, responseBody) => {
            if (err) {
              callback(err);
              return;
            }
            if (
              response.statusCode !== 200 &&
              response.statusCode !== 0 &&
              findVhsError({ message: response.headers[AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME] })
            ) {
              player.error({
                code: response.statusCode,
                message: findVhsError({ message: response.headers[AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME] }).message,
              });
              return;
            }
            if (
              response.statusCode !== 200 &&
              response.statusCode !== 0 &&
              response.rawRequest.statusText &&
              findVhsError({ message: response.rawRequest.statusText })
            ) {
              player.error({
                code: response.statusCode,
                message: findVhsError({ message: response.rawRequest.statusText }).message,
              });
              return;
            }
            if (response.statusCode === 200) {
              callback(null, responseBody);
            } else {
              callback(new ArrayBuffer(0));
            }
          },
        );
      },
    };
  });

  formattedSource.keySystems = formattedKeySystems;
};

function arrayToString(array) {
  const uint16array = new Uint16Array(array.buffer);
  return String.fromCharCode.apply(null, uint16array);
}

const formatHLS = (formattedSource, source, player) => {
  const { keySystem, ...drm } = source.drm;

  formattedSource.keySystems = {
    [keySystem]: {
      certificateUri: drm.certificateUrl,
      licenseUri: drm.licenseUrl,
      licenseHeaders: drm.licenseRequestHeaders,
      // This is some esoteric function that somehow transform initData (which is weird array) to contentID
      // needed by the licenseServer. It's taken from https://github.com/videojs/videojs-contrib-eme/issues/95
      getContentId: (_emeOptions, initData) => arrayToString(initData).replace(/^.*:\/\//, ''),
      getLicense: (emeOptions, contentId, keyMessage, callback) => {
        videojs.xhr(
          {
            url: emeOptions.keySystems[keySystem].licenseUri,
            method: 'POST',
            responseType: 'arraybuffer',
            body: keyMessage,
            headers: {
              'Content-type': 'application/octet-stream',
              ...emeOptions.keySystems[keySystem].licenseHeaders,
            },
          },
          (err, response, responseBody) => {
            if (err) {
              callback(err);
              return;
            }
            if (
              response.statusCode !== 200 &&
              response.statusCode !== 0 &&
              findVhsError({ message: response.headers[AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME] })
            ) {
              player.error({
                code: response.statusCode,
                message: findVhsError({ message: response.headers[AXINOM_DRM_RESPONSE_HEADER_ERROR_NAME] }).message,
              });
              return;
            }
            if (
              response.statusCode !== 200 &&
              response.statusCode !== 0 &&
              response.rawRequest.statusText &&
              findVhsError({ message: response.rawRequest.statusText })
            ) {
              player.error({
                code: response.statusCode,
                message: findVhsError({ message: response.rawRequest.statusText }).message,
              });
              return;
            }
            if (response.statusCode === 200) {
              callback(null, responseBody);
            } else {
              callback(new ArrayBuffer(0));
            }
          },
        );
      },
    },
  };
};

/**
 * Format matched source to valid videojs src object. e.g. dashjs plugin has
 * own license format (hls license format not implemented yet)
 * @returns {Object} Valid videojs src format
 * @private
 */
const sourceFormatter = function sourceFormatter(source, player) {
  if (!source) {
    return undefined;
  }

  const formattedSource = {
    src: source.src || source.fileURL,
    type: source.type || source.mimeType,
  };

  if (source.drm) {
    switch (source.type.toLowerCase()) {
      case adaptiveStreams.dash:
        formatDASH(formattedSource, source, player);
        break;
      case adaptiveStreams.hls:
        formatHLS(formattedSource, source, player);
    }
  }

  return formattedSource;
};

/**
 * @param {string} message
 * @returns {object} parsed message for license request payload
 * @private
 */
const getMessageContents = (message) => {
  // please do not use OS or browser check, could be a problem as reported in:
  // https://jira.zentity.com/browse/CRASUPP-681
  // more info about the function: https://github.com/videojs/videojs-contrib-eme/issues/75
  try {
    const xml = new window.DOMParser().parseFromString(
      String.fromCharCode.apply(null, new Uint16Array(message)),
      'application/xml',
    );

    const challengeElement = xml.getElementsByTagName('Challenge')[0];
    let challenge;

    if (challengeElement) {
      challenge = window.atob(challengeElement.childNodes[0].nodeValue);
    }

    return {
      message: challenge || message,
    };
  } catch (err) {
    return { message };
  }
};

export default sourceFormatter;
