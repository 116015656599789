import { videojs } from 'global/window';
import { tryGetNext } from './validity';
import { AUTOPLAY_STATE_CLASS_NAME } from '../../constants';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Creates screen wrapper for the autoplay next plugin
 */
class CountdownTimer extends Component {
  constructor(player, options, silent) {
    super(player, options);
    this.silent = silent || false;
    // this.on(player, 'ott-thumb-horz', this.updateDataAttr);
    this.interval = 0;
    this.countdownValue = this.options_.countDown;
    this.counter = this.options_.countDown;
    this.intervalPaused = false;
    this.autoplayDisplayed = false;

    this.player_.on('ott-playnext-reset', this.dispose.bind(this));
    this.player_.on('pause', this.pauseInterval.bind(this));
    this.player_.on('play', this.resumeInterval.bind(this));
    this.update();
  }

  getCountdownHTML() {
    return this.options_.countdownTitle.replace(
      '{0}',
      `<span class='countdown${this.silent ? ' vjs-hidden' : ''}'>${this.options_.countDown}</span>`,
    );
  }

  dispose() {
    if (this.interval) {
      this.player_.clearInterval(this.interval);
    }
  }

  updateCountdownElement() {
    return this.contentEl_.getElementsByClassName('countdown')[0];
  }

  update() {
    this.autoplayDisplayed = true;
    const autoplayInterval = this.player_.setInterval(() => {
      if (!this.intervalPaused) {
        this.interval++;
        this.counter--;
        this.updateCountdownElement().innerHTML = this.counter;
        if (this.interval >= this.countdownValue) {
          tryGetNext(
            this.player_,
            this.options_.target,
            this.options_.urlRedirect,
            'countdown',
            this.options_.productId,
          );
          this.intervalPaused = true;
        }
      }
    }, 1000);

    // Clear interval once playnext button is pressed
    this.player_.on(['ott-playnext-fired', 'ott-playnext-reset'], (e) => {
      if (e.from !== 'countdown') {
        this.player_.clearInterval(autoplayInterval);
      }
    });

    this.player_.on(['ott-mea-seek'], () => {
      this.player_.removeClass(AUTOPLAY_STATE_CLASS_NAME);
      this.player_.clearInterval(autoplayInterval);
    });
  }

  /**
   * Create the component's DOM element
   */
  createEl() {
    this.contentEl_ = super.createEl('p', {
      className: 'vjs-an-countdown',
      innerHTML: `${this.getCountdownHTML()}`,
    });

    return this.contentEl_;
  }

  pauseInterval() {
    // do not pause counter after content ends
    if (!Math.floor(this.player_.currentTime()) === Math.floor(this.player_.durationOfActiveMedia())) {
      this.intervalPaused = false;
    } else if (!this.player_.ended()) {
      this.intervalPaused = true;
    }
  }

  resumeInterval() {
    this.intervalPaused = false;
  }
}
// Register the new component
Component.registerComponent('CountdownTimer', CountdownTimer);
export default CountdownTimer;
