import getInventory from './get-inventory';
import avlAdType from './ad-type';

/**
 * @typedef {Object} AdInventoryInfo
 * @property {Number} adPosition  Position of advertisement in break.
 * @property {Number} breakSize   Number of ads in break given ad belongs to.
 * @property {String} adType      Current ad type
 */

/**
 * Check if currnt source is an Ad or normal content
 * @param  {Object}  player The player Object
 * @return {Boolean}        True when current source is an Ad, false otherwise
 */
export const isAdPlaying = function isAdPlaying(player) {
  let playing = false;
  if (player && (player.ads || {}).inAdBreak) {
    playing = player.ads.inAdBreak();
  }
  // FIMXE: Remove adPlaying flag once new videojs-contrib-ads features are implemented.
  return playing || (player.adstate || {}).adPlaying || false;
};

/**
 * Get translate timeoffset value to ad break type (preroll, midroll, postroll)
 * @param  {Object} timeOffset  Timeoffset value from adPodInfo.timeOffset
 * https://developers.google.com/interactive-media-ads/docs/sdks/html5/v3/reference/js/ima.AdPodInfo.html#getTimeOffset
 */
export const getAdBreakType = function getAdBreakType(ad) {
  switch (ad.getAdPodInfo().getTimeOffset()) {
    case 0:
      return 'preroll';
    case -1:
      return 'postroll';
    default:
      return 'midroll';
  }
};

export const isLinearAd = function isLinearAd(contentType) {
  return !contentType.includes('image');
};

/**
 * Get ad if available
 * @param  {Object} player      The player object
 * @return {Object|undefined}   An ad if available, false otherwise
 */
export const getPlayingAd = function getPlayingAd(player) {
  /* IMA */
  if (isAdPlaying(player) && player.ima.getAdsManager) {
    const adsManager = player.ima.getAdsManager();
    const currentAd = adsManager.getCurrentAd(); // FIXME: this method not in SDK reference

    return currentAd;
  }
  /* CUSTOM */
  let ad;
  if (isAdPlaying(player) && player.adstate) {
    const adInventory = getInventory(player.adstate, player.adstate.currentAdtype, player.adstate.currentMidrollIndex);
    if (adInventory) {
      // played is increased after adended event. Current value is an array index
      ad = adInventory.ads[adInventory.played];
    }
  }

  return ad;
};

/**
 * Get ad break inventory if available
 * @param  {Object} e ad event from IMA
 * @return {Object|null}        The ad break informations if available, false otherwise
 */
export const getAdInventoryInfoIma = function getAdInventoryInfo(e) {
  const { ad } = e;
  if (ad.adPodInfo) {
    return {
      adPosition: ad.adPodInfo.adPosition,
      breakSize: ad.adPodInfo.totalAds,
      adType: getAdBreakType(ad),
    };
  }

  return null;
};

/**
 * Get ad break inventory if available
 * @param  {Object} player The player object
 * @return {Object|undefined}        The ad break informations if available, false otherwise
 */
export const getAdInventoryInfo = function getAdInventoryInfo(player) {
  let info;
  if (isAdPlaying(player)) {
    const adInventory = getInventory(player.adstate, player.adstate.currentAdtype, player.adstate.currentMidrollIndex);
    if (adInventory) {
      info = {
        // played is increased after adended event. Current value is an array index
        adPosition: adInventory.played + 1,
        breakSize: adInventory.ads.length,
        adType: player.adstate.currentAdtype,
      };
    }
  }
  return info;
};

/**
 * Chceck if there is a postroll commercial block with at least one ad.
 *
 * @param  {Object} player  The player object
 * @return {boolean}        True when any VAST url found, false otherwise
 */
export const hasPostroll = function isPostroll(player) {
  if (typeof player.adstate === 'undefined') return false;

  const postRollAdInventory = getInventory(player.adstate, avlAdType.postroll);

  return ((postRollAdInventory || {}).vasts || []).length > 0;
};
