import log from '../../../log';
import { AS_VPAID_AD_ID } from '../../../constants';

export const isVPAIDMediaFile = (mediaFile) =>
  !!mediaFile && mediaFile.apiFramework === 'VPAID' && mediaFile.mimeType.indexOf('flash') === -1;

export const isVPAID = (vastResponse) => vastResponse?.mediaFiles.some((mediaFile) => isVPAIDMediaFile(mediaFile));

export const VPAIDMethods = [
  'handshakeVersion',
  'initAd',
  'resizeAd',
  'startAd',
  'stopAd',
  'pauseAd',
  'resumeAd',
  'expandAd',
  'collapseAd',
  'skipAd',
  'subscribe',
  'unsubscribe',
];

export const VPAIDEvents = [
  'AdLoaded',
  'AdStarted',
  'AdStopped',
  'AdSkipped',
  'AdSkippableStateChange',
  'AdSizeChange',
  'AdLinearChange',
  'AdDurationChange',
  'AdExpandedChange',
  'AdVolumeChange',
  'AdImpression',
  'AdVideoStart',
  'AdVideoFirstQuartile',
  'AdVideoMidpoint',
  'AdVideoThirdQuartile',
  'AdVideoComplete',
  'AdClickThru',
  'AdInteraction',
  'AdUserAcceptInvitation',
  'AdUserMinimize',
  'AdUserClose',
  'AdPaused',
  'AdPlaying',
  'AdLog',
  'AdError',
];

export const VPAIDGetters = [
  'getAdLinear',
  'getAdWidth',
  'getAdHeight',
  'getAdExpanded',
  'getAdSkippableState',
  'getAdRemainingTime',
  'getAdDuration',
  'getAdVolume',
  'getAdCompanions',
  'getAdIcons',
];

/**
 * This will set styles and other necessary properties to the FIF
 * @param {HTMLElement} element This element will be styled as full size
 * @param {Number=} zIndex      Optional zIndex style property
 */
export const setFullSizeStyle = (element, zIndex) => {
  if (!element) {
    log.error('Required arguments missing');
    return;
  }
  element.marginWidth = '0';
  element.marginHeight = '0';
  element.frameBorder = '0';
  element.width = '100%';
  element.height = '100%';
  // Styles
  element.style.position = 'absolute';
  element.style.left = '0';
  element.style.top = '0';
  element.style.margin = '0px';
  element.style.padding = '0px';
  element.style.border = 'none';
  element.style.width = '100%';
  element.style.height = '100%';
  element.style.overflow = 'hidden';
  if (zIndex) {
    element.style.zIndex = zIndex;
  }
};

/**
 * Write content to the iframe element when contentWindow is accessible
 * @param {HTMLElement} iframe  Friendly iframe
 * @param {String} content      VPAID friendly iframe HTML
 */
export const setIframeContent = (iframe, content) => {
  iframe.src = 'about:self';
  iframe.contentWindow.document.write(content);
};

/**
 * Create unique VPAID ad wrapper ID. Based on unique player ID
 * @param  {String} playerId Unique player ID
 * @return {String}          VPAID ad wrapper unique ID
 */
export const getAdId = (playerId) => `${playerId}-${AS_VPAID_AD_ID}`;

/**
 * Create ad element with custom ID
 * @param  {Object} document
 * @param  {String} id            VPAID ad wrapper unique ID
 * @param  {Object} [data={}]     A set of key/value pairs that configure the FIF
 * @param  {Number=} data.zIndex  Optional zIndex style for the VPAID ad element
 * @return {HTMLElement} DIV where will be VPAID ad placed
 */
export const createAdElement = (document, id, data = {}) => {
  const div = document.createElement('div');
  div.id = id;
  setFullSizeStyle(div, data.zIndex);
  return div;
};

/**
 * Create friendly iframe
 * @param  {Object} document
 * @param  {String} content         The friendly iframe content
 * @param  {Object} [data={}]       A set of key/value pairs that configure the FIF
 * @param  {Number=} data.zIndex    Optional zIndex style for the FIF
 * @return {HTMLElement} Friendly iframe if all restriction pass (e.g. CORS), undefined otherwise
 */
export const createFIF = (document, content, data = {}) => {
  const iframe = document.createElement('iframe');
  setFullSizeStyle(iframe, data.zIndex);
  iframe.setAttribute('allowFullScreen', '');

  return iframe;
};

/**
 * Remove the HTML content of a element
 * @param {HTMLElement} el Element where the inner HTML will be removed
 */
export const removeElementHTML = (el) => {
  el.innerHTML = '';
};

export const removeElementById = (parent, id) => {
  const element = parent.getElementById(id);
  if (element) {
    element.outerHTML = '';
  }
};

/**
 * Create VPAID HTML inside the parent element
 * @param  {HTMLElement} iframeParent     A HTML element where will be iframe placed
 * @param  {HTMLElement} iframe
 * @param  {HTMLElement} adElementParent  DIV where will be VPAID ad placed
 * @param  {HTMLElement} adElement
 */
export const createHTML = (document, iframeParent, iframe, adElementParent, adElement) => {
  // Remove previous content of the parent HTML element. Maybe this is not the best way...
  iframeParent.innerHTML = '';
  iframeParent.appendChild(iframe);

  removeElementById(document, adElement.id);
  adElementParent.parentNode.appendChild(adElement);
};
