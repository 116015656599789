import { videojs } from 'global/window';
import Choices from 'choices.js';

import log from '../../../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 * The specific menu item type for selecting a language within a text track kind
 *
 * @param {Player|Object} player
 * @param {Object=} options
 */
class SubtitlesSelectMenu extends ClickableComponent {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent();
  }

  createEl() {
    return super.createEl('select', {
      className: 'vjs-subtitles-select',
    });
  }

  // --------------------------------------------------------------- //
  // ----------- also needed for native select component ----------- //
  // ----- prevention from auto closing options on select open ----- //
  // --------------------------------------------------------------- //
  // handleClick(e) {
  //   e.stopPropagation();
  // }

  createContent() {
    const selectEl = this.el_;

    const tracks = videojs.browser.IS_IOS
      ? Object.values(this.player_.el().children[0].textTracks)
      : this.player_.remoteTextTracks().tracks_;
    const defaultTrack = this.player_.options().plugins.settings.subtitles.find((subtitles) => subtitles.default);
    const defaultLanguage = defaultTrack && defaultTrack.srclang;

    if (selectEl) {
      this.player_.ready(() => {
        tracks.forEach((track) => {
          // note: persisted subtitles are converted to default subtitles (ugh)
          if (defaultLanguage === track.language) {
            // preferred language found
            // Set showing to true
            track.mode = 'showing';
            // Send event for subtitle <enabled></enabled>
            this.player_.trigger({
              type: 'ott-subtitles-enabled',
              track,
            });
          } else {
            track.mode = 'hidden';
          }
        });

        const filteredTracks = tracks
          .filter((track) => track.kind !== 'metadata' && track.language)
          .map((track) => ({
            value: track.id,
            label: track.label,
            selected: defaultTrack?.src === track.src,
          }));

        if (filteredTracks?.length) {
          this.selectComponent = new Choices(selectEl, {
            searchEnabled: false,
            shouldSort: false,
            choices: [
              {
                value: 0,
                label: this.player_.localize('Subtitles off'),
              },
              ...filteredTracks,
            ],
          });

          this.selectComponent.passedElement.element.addEventListener('change', (event) => {
            const selectedTrack = tracks.find((track) => track.id === event.detail.value) || {};

            log(
              '[Subtitles control] option selected!:',
              `language: "${selectedTrack.language || 'OFF'}"${
                selectedTrack.id ? ` with id: "${selectedTrack.id}"` : ''
              }`,
            );

            // turn off text tracks
            if (event.detail.value === 0) {
              tracks.forEach((track) => {
                if (track.kind !== 'metadata' && track.language) {
                  if (track.mode === 'showing') {
                    track.mode = 'disabled';
                  }
                }
              });

              this.player_.trigger({
                type: 'ott-subtitles-disabled',
              });
            } else {
              // equivalent to
              // super.handleClick(event);
              // if TextTrackMenuItem is extended by the class
              tracks.forEach((track) => {
                if (track === selectedTrack) {
                  if (track.mode !== 'showing') {
                    track.mode = 'showing';
                  } // If this text track is not the component's track and it is not
                  // disabled, set it to disabled.
                } else if (track.mode !== 'disabled') {
                  track.mode = 'disabled';
                }
              });

              this.player_.trigger({
                type: 'ott-subtitles-enabled',
                track: selectedTrack,
              });
            }
          });
        }

        // ------------------------------------------------------------ //
        // -------------- native select component example ------------- //
        // ---------------- in case it will be required --------------- //
        // ------------------------------------------------------------ //
        // console.log(defaultTrack);
        // console.log(filteredTracks);

        // const trackOptions = [{ value: 0, label: this.player_.localize('Subtitles off') }, ...filteredTracks].map(
        //   (item) => `<option selected="${item.selected || 'false'}" value="${item.value}">${item.label}</option>`,
        // );

        // if (filteredTracks?.length) {
        //   selectEl.innerHTML = trackOptions;

        //   selectEl.addEventListener('change', (event) => {
        //     const value = event.target.value;
        //     const selectedTrack = tracks.find((track) => track.id === value) || {};

        //     log('[Subtitles control] option selected!:', `language: "${selectedTrack.language || 'OFF'}"${selectedTrack.id ? ` with id: "${selectedTrack.id}"` : ''}`);

        //     // turn off text tracks
        //     if (value === '0') {
        //       tracks.forEach((track) => {
        //         if (track.kind !== 'metadata' && track.language) {
        //           if (track.mode === 'showing') {
        //             track.mode = 'disabled';
        //           }
        //         }
        //       });

        //       this.player_.trigger({
        //         type: 'ott-subtitles-disabled',
        //       });
        //     } else {
        //       // equivalent to
        //       // super.handleClick(event);
        //       // if TextTrackMenuItem is extended by the class
        //       tracks.forEach((track) => {
        //         if (track === selectedTrack) {
        //           if (track.mode !== 'showing') {
        //             track.mode = 'showing';
        //           } // If this text track is not the component's track and it is not
        //           // disabled, set it to disabled.
        //         } else if (track.mode !== 'disabled') {
        //           track.mode = 'disabled';
        //         }
        //       });

        //       this.player_.trigger({
        //         type: 'ott-subtitles-enabled',
        //         track: selectedTrack,
        //       });
        //     }
        //   });

        //   return;
        // ------------------------------------------------------------ //
        // -------------- native select component example ------------- //
        // ---------------- in case it will be required --------------- //
        // ------------------------------------------------------------ //
      });
    }
  }
}

Component.registerComponent('SubtitlesSelectMenu', SubtitlesSelectMenu);
export default SubtitlesSelectMenu;
