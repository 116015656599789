import { window, document } from 'global/window';
import log, { isLoggingEnabled } from './log';
import playSource from './play-source';
import Playlist from './playlist';
import updatePlayerFunctionality from './custom-functionality';
import { isAdPlaying, hasPostroll } from './plugins/adservice/shared/ad-state';
import availableEvents from './plugins/measuring/shared/available-events';
import propertyTester from './property-tester';
import { handleKeyChanges } from './handleKeyChanges';

export const BEFORE_RESET_EVENT = 'ott-before-reset';

export default class Reset {
  /**
   * class Reset constructor
   * @param {Object} player required actual player instance
   * @param {Object} init only for first initialization
   * @param {Object} config for creating new reset instace from exist instance
   */
  constructor(player, init = null, config = null) {
    // TODO what do if not sources or config
    this.resetEvents = ['ott-reset-player'];
    this.player = player;

    if (config != null) {
      this.resetData = config;
    } else {
      this.resetData = this.createResetData(init.id, init.data);
    }

    log(`[reset] player reset id: ${this.resetData.resetId}`);

    this.replayByReset = this.replayByReset.bind(this);
  }

  /**
   * Reset init method
   * added reset instance to player
   * call registerResetEvents method for reset control
   */
  init() {
    this.player.reset = this;
    this.player.instanceId = this.resetData.instanceId;
    this.registerResetEvents();
    this.registerReplayReset();
    log('[reset] initialization done !');
  }

  /**
   * Method returns user individual settings from dispose player instance
   * @returns {Object}
   */
  getUserSettings() {
    const settings = {
      // TODO: subtitle, quality, fullscreen, audio language
      volume: this.player.muted() ? 0 : this.player.volume(),
      fullscreen: this.player.isFullscreen(),
      subtitleLang: this.getActiveTextrackLanguage(),
    };
    log('[reset] save user settings on disposed player', settings);
    return settings;
  }

  /**
   * Method restore user setting after create new player instance
   */
  restorePlayerSettings() {
    // fullscreen
    if (this.userSettings.fullscreen) {
      this.player.requestFullscreen();
    }
    // volume
    this.player.volume(this.userSettings.volume);
    // subtitles
    this.enableTextrackLanguage(this.userSettings.subtitleLang);
    log('[reset] user settings from dispose player restored to new instance', this.userSettings);
  }

  /**
   * Method search actual showing textrack language
   */
  getActiveTextrackLanguage() {
    let enableLanguage;
    this.player.textTracks().tracks_.forEach((textTrack) => {
      if (textTrack.mode === 'showing') {
        enableLanguage = textTrack.language;
      }
    });

    return enableLanguage;
  }

  /**
   * Method search actual showing textrack language
   */
  enableTextrackLanguage(lang) {
    this.player.textTracks().tracks_.forEach((textTrack) => {
      if (textTrack.language === lang) {
        textTrack.mode = 'showing';
        log('[reset] restore text track settings lang:', lang);
      }
    });
  }

  /**
   * Method returns actual reset data from player instance
   * @returns {Object}
   */
  getResetData() {
    return this.resetData;
  }

  /**
   * Method returns actual reset count from player instance
   * @returns {Int}
   */
  getResetCounter() {
    return this.resetData.count;
  }

  /**
   * Method create basic unique resetId
   * @returns {String}
   */
  createResetId() {
    const hash = Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
    const uid = `rst${hash}`;
    log(`[reset] created reset id ${uid}`);
    return uid;
  }

  /**
   * Method return stored resetId from resetData
   * @returns {String}
   */
  getResetId() {
    return this.resetData.resetId;
  }

  /**
   * Method create reset data object
   * launched only for first initialization
   * @param {String} id - player id
   * @param {Object} data - player configuration
   * @returns {Object}
   */
  createResetData(id, data) {
    return {
      id,
      data,
      instanceId: this.player.instanceId,
      el: this.player.el_,
      count: 0,
      resetId: propertyTester(() => data.reset.resetId) || this.createResetId(),
    };
  }

  /**
   * Method create <video> element
   * Add parameters to element from disposed player element
   * @param {Object} resetData
   */
  createResetVideoElement(resetData) {
    // TODO reply <video> element from dispose player as copy
    const videoElement = document.createElement('video');
    videoElement.id = resetData.id;

    // copy all attributes from disposed player (video element)
    for (let i = 0; i < resetData.el.attributes.length; i++) {
      videoElement.setAttribute(resetData.el.attributes[i].name, resetData.el.attributes[i].value);
    }
    videoElement.setAttribute('controls', true);

    // ONLY FOR TESTING
    // videoElement.setAttribute('width', 768);

    log('[reset] create new video element', videoElement);

    return videoElement;
  }

  /**
   * Method push new <video> element to DOM
   * @param {Element} videoWrapper - dispose player element offsetParent
   * @param {Element} newVideoElement - new <video> element create in method createResetVideoElement
   */
  addVideoElementToDOM(videoWrapper, newVideoElement) {
    videoWrapper.appendChild(newVideoElement);
  }

  /**
   * Register reset control events on actual player instance
   */
  registerResetEvents() {
    this.player.on(this.resetEvents, () => {
      this.resetPlayer(undefined, this.seekTime);
    });

    log('[reset] register events', this.resetEvents);
  }

  /**
   * Method update reset counter in data after reset
   */
  updateResetCounter() {
    this.resetData.count++;
  }

  /**
   * Method clean measuring events before dispose player
   * Clean DashJS buffer
   */
  cleanupBeforeReset() {
    // if (this.player.dash) {
    // this.player.dash.mediaPlayer.reset();
    // }

    this.player.trigger(BEFORE_RESET_EVENT);

    if (isAdPlaying(this.player)) {
      this.player.trigger('adend');
    }

    // the video index is moving incorrectly
    // this.player.trigger('ended');
  }

  /**
   * Method reset player for replay
   */
  registerReplayReset() {
    this.player.one('postrollready', () => {
      this.player.ads.disableNextSnapshotRestore = true; // need for stop buffering full stream after end of postroll
    });

    this.player.one(availableEvents.COMPLETE, this.replayByReset);

    log('[reset] register replay reset');
  }

  replayByReset() {
    this.showReplayButton();
    // if ignoreNextSeekingEvent_ was false by, player.tech would try to get player.currentTime by the
    // time the player itself get disposed so there wouldn't be the object needed to get the time from
    // and it would log error in the console
    if (this.player.tech({ IWillNotUseThisInPlugins: true }).vhs) {
      this.player.tech({ IWillNotUseThisInPlugins: true }).vhs.ignoreNextSeekingEvent_ = true;
    }
    this.player.one(['play'], (e) => {
      if (e.type === 'seek') {
        this.resetData.data.startTime = this.player.currentTime();
      }

      if (
        propertyTester(() => this.resetData.data.reset.replayWithoutAds) &&
        propertyTester(() => this.resetData.data.plugins.adService)
      ) {
        delete this.resetData.data.plugins.adService;
        log('[reset] ads data deleted before replay');
      }

      log('[reset] user used replay');
      this.player.trigger('ott-reset-player');
    });
    log('[reset] wait for user replay');
  }

  showReplayButton() {
    this.player.addClass('vjs-ended'); // need for stop buffering full stream after end of postroll
    // this.player.controlBar.hide(); // need for stop buffering full stream after end of postroll
    const replayClickScreenEl = propertyTester(() => this.player.getChild('BigPlayButton').el_);

    if (replayClickScreenEl && hasPostroll(this.player)) {
      if (this.resetData.data.poster || this.player.el().hasAttribute('poster')) {
        this.player.removeClass('vjs-has-started');
      } else {
        replayClickScreenEl.style.backgroundColor = 'black';
      }
    }
  }

  /**
   * Method dispose actual player instance and create new player instance
   * initialize new reset instance on new player instance
   * initialize new playlist instance on new player instace (only when disposed player have playlist object)
   * @param {Object} data optional - new configuration before reset player
   * TODO: change method name for future api using
   */
  resetPlayer(data = null, seekTime = null) {
    // FIXME: better detection of videoWrapper - embed player problem
    const videoWrapper = this.player.el().parentElement;
    const videoElement = this.createResetVideoElement(this.resetData);
    const userSettings = this.getUserSettings();

    // check new player configuration
    if (data != null) {
      this.resetData.data = data;
      log('[reset] added new init data before reset');
    }

    if (seekTime != null) {
      this.resetData.data.startTime = seekTime;
    }

    // check and save playlist data on dispose player
    let playlistConfig;
    if (this.player.playlist && this.player.playlist.getPlaylistData()) {
      playlistConfig = this.player.playlist.getPlaylistData();
    }

    // Change player instance
    this.player.pause();

    this.cleanupBeforeReset();

    this.player.dispose();
    this.addVideoElementToDOM(videoWrapper, videoElement);
    const newPlayerInstance = playSource(this.resetData.id, this.resetData.data);
    // send global event with reference to new instance - need for reinit custom events
    window.dispatchEvent(new CustomEvent('ott-reset-updater', { detail: newPlayerInstance }));

    this.resetData.data.startTime = 0;
    log('[reset] fired event ott-reset-updater to window');
    this.updateResetCounter(this.resetData);

    // initialize reset instance on new player instace
    const reset = new Reset(newPlayerInstance, null, this.resetData);
    reset.userSettings = userSettings;
    reset.init();

    // initialize playlist instance on new player instace
    if (playlistConfig) {
      const playlist = new Playlist(newPlayerInstance, null, playlistConfig);
      playlist.init();
    }

    // set time function for adsPlayer on new player instance
    updatePlayerFunctionality(newPlayerInstance);

    // TODO maybe move to restorePlayerSettings method
    newPlayerInstance.ready(() => {
      newPlayerInstance.reset.restorePlayerSettings();
      // if (seekTime) { newPlayerInstance.currentTime(seekTime); }
      if (newPlayerInstance.paused()) {
        newPlayerInstance.play();
      }
      handleKeyChanges(newPlayerInstance);
    });

    log('[reset] player reset complete !');

    // For debug purpuse only!
    if (isLoggingEnabled() || window.location.href.search('localhost:8888') !== -1) {
      window.player = newPlayerInstance;
    }
  }
}
