import log, { isLoggingEnabled } from '../../log';

class AdProxy {
  constructor(config) {
    this.config = config;
    this.proxyUrl = config.proxyUrl;
    this.activationProbability = config.activationProbability || null;
    this.proxyActive = true;

    if (
      this.activationProbability != null &&
      Math.random() > this.activationProbability &&
      isLoggingEnabled() != true
    ) {
      this.proxyActive = false;
      log('[adProxy] NOT activated ! activationProbability treashold');
    } else {
      log(`[adProxy] initialized ! Proxy URL: ${this.proxyUrl}`);
    }
  }

  updateSource(source) {
    if (!this.proxyActive) {
      return source;
    }

    source.src = `${this.proxyUrl}${encodeURIComponent(source.src)}`;
    source.adProxy = true;
    log(`[adProxy] source updated ${source.src}`, source);

    return source;
  }
}

export default AdProxy;
