import is from 'is_js';
import { VASTClient } from '@dailymotion/vast-client';
import CustomMarkers from './ad-markers';
import log from '../../../../log';
import avlAdType from '../ad-type';
import getInventory from '../get-inventory';
import { EVT_NOPREROLL, EVT_NOPOSTROLL, CACHEBUSTER_PARAM_NAME } from '../../../../constants';

const csaiDurationPlaceholder = '$$DURATION$$';

const vastClient = new VASTClient();
// creating vastParser from vastClient instance
const vastParser = vastClient.getParser();

/**
 * Trigger appropriate linear event to play requested ad break
 * @private
 */
const triggerEvent = function triggerEvent(player, adType, inventory, adi = 0) {
  if (inventory.ads.length > 0) {
    switch (adType) {
      case avlAdType.preroll: {
        player.trigger('adsready');
        break;
      }
      case avlAdType.midrolls: {
        player.trigger({
          type: `midrollready${adi}`,
          cue: adi,
        });
        break;
      }
      case avlAdType.postroll: {
        player.trigger('postrollready');
        break;
      }
      case avlAdType.overlay: {
        log('Overlay ad ready to show');
        player.trigger({
          type: 'overlayready',
          cue: adi,
        });
        break;
      }
      case avlAdType.companion: {
        log('Companion ad ready to show');
        player.trigger({
          type: 'companionready',
          cue: adi,
        });
        break;
      }
    }
  } else {
    // Reset markers when no xroll returned
    new CustomMarkers(player, player.adstate).reset();

    // Adblock check
    // FIXME BPo: Not implemented yet
    // testAd();

    switch (adType) {
      case avlAdType.preroll:
        log('No preroll returned');
        // Trigger manually event, that preroll not returned
        player.trigger(EVT_NOPREROLL);
        break;
      case avlAdType.midrolls:
        log('No midroll returned.');
        // Trigger manually event, that midroll not returned
        player.trigger({
          type: 'midrollnotreturned',
          cue: adi,
        });
        break;
      case avlAdType.postroll:
        log('No postroll returned.');
        player.trigger(EVT_NOPOSTROLL);
        break;
      case avlAdType.overlay: {
        log('No overlay returned.');
        player.trigger({
          type: 'overlaynotreturned',
          cue: adi,
        });
        break;
      }
      case avlAdType.companion: {
        log('No companion returned.');
        player.trigger({
          type: 'companionnotreturned',
          cue: adi,
        });
        break;
      }
      default:
        break;
    }
  }
};

function sortAdsResponseBySequence(a, b) {
  if (a.sequence === null) {
    return 1;
  }
  if (b.sequence === null) {
    return -1;
  }
  return Number(a.sequence) - Number(b.sequence);
}

/**
 * Make DMVAST response
 *
 * @private
 * @param {string} vast - VAST URL
 * @param {Object} player - Player instance
 */
const makeVASTResponse = function makeVASTResponse(vastUrl, player) {
  return new Promise((resolve, reject) => {
    if (vastUrl) {
      vastParser.addURLTemplateFilter((vastUrl) =>
        vastUrl.replace(CACHEBUSTER_PARAM_NAME, Math.round(Math.random() * 1.0e8).toString()),
      );

      log(`VAST will be requested. URL: ${vastUrl}`);
      player.trigger({ type: 'adrequest', vastUrl });

      const settings = player.options_.plugins.adService.settings || {};
      const forceParentSequence = settings.forceParentSequence || false;
      const latestSequence = settings.latestSequence || false;
      const withCredentials = settings.withCredentials !== undefined ? settings.withCredentials : true;
      log(
        `VAST client sequence options => forceParentSequence: ${forceParentSequence}, latestSequence: ${latestSequence}`,
      );

      vastParser
        .getAndParseVAST(vastUrl, {
          withCredentials,
          wrapperLimit: 50,
          timeout: 5000,
          forceParentSequence,
          latestSequence,
        })
        .then((response) => {
          response.ads.sort(sortAdsResponseBySequence);
          log('[VASTResponse] - ads after sequence sort', response);
          player.trigger({ type: 'adresponse', vastUrl, response });
          resolve(response);
        })
        .catch((err) => {
          log(err);
          reject(new Error('VAST url cannot be null!'));
        });
    }
  });
};

/**
 * Save all ads from the response to the player adstate object
 *
 * @private
 * @param {object} response - Hash of VAST values
 */
const saveToInventory = function saveToInventory(adInventory, responses = []) {
  responses.forEach((response) => {
    if (is.array((response || {}).ads)) {
      adInventory.ads = response.ads.concat(adInventory.ads);
    }
  });
  // sort ads array order by sequence parameter
  if (is.array(adInventory.ads) && adInventory.ads.length > 1) {
    adInventory.ads = adInventory.ads.sort(sortAdsResponseBySequence);
    log('[VAST adInventory] - ads after sequence sort', adInventory.ads);
  }
};

/**
 * Make DMVAST response when VAST is an array
 *
 * @param {Array} vasts - Array of all vasts to request
 * @param {Object} player - Player instance
 * @returns {Promise}
 */
const makeVASTArrayResponse = function makeVASTArrayResponse(vasts, player) {
  // FIXME BPo: reject method is not implemented yet
  const promises = [];
  vasts.forEach((vast) => {
    promises.push(makeVASTResponse(vast, player));
  });

  return Promise.all(promises);
};

/**
 * Will request an appropriate ad
 *
 * @param {Object} player    - Player instance
 * @param {String} adType    - Ad type
 * @param {?Int} adi         - Optional midrolls / nonlinear ad index. First item will be taken When no index found
 * @param {?Int} adi         - Optional adLenth for csai midrolls */
const requestAd = function requestAd(player, adType, adi = -1, adLength) {
  const adInventory = getInventory(player.adstate, adType, adi, true);

  // Call finalize directly when no VAST available
  if ((adInventory.vast || []).length < 1 && (adInventory.vasts || []).length < 1) {
    let logMsg = `VAST request skipped, due to empty URL array. Ad type: ${adType}`;
    if (adi > 0) {
      logMsg = `${logMsg}, Ad index: ${adi}`;
    }
    log(logMsg);

    if (player.ads.inAdBreak()) {
      log('[request-ad] trigger adended');
      player.trigger('adended');
    }

    if (adType === 'preroll') {
      log(`[request-ad] trigger ${EVT_NOPREROLL}`);
      player.trigger(EVT_NOPREROLL);
    }

    if (adType === 'companion') {
      log('[request-ad] trigger companionnotreturned');
      player.trigger({
        type: 'companionnotreturned',
        cue: adi,
      });
    }

    // Execution make no sense vhen vast/s array is empty
    return;
  }

  if (adType === avlAdType.csaiMidrolls) {
    adInventory.vasts = adInventory.vasts.map((vast) => vast.replace(csaiDurationPlaceholder, adLength));
  }

  /**
   * Finalize vast requests. This call response transformatio and trigger event then
   * @param  {Array} responses Responses after VAST requests
   */
  const finalizeRequest = function finalizeRequest(responses) {
    saveToInventory(adInventory, responses);
    triggerEvent(player, adType, adInventory, adi);
    if (adType === avlAdType.csaiMidrolls) {
      player.trigger('csai-midrolls-ready');
    }
  };

  // Reset ads array before requests. This avoids the multiplication that occurs during periodic rendering in livestreams
  adInventory.ads = [];

  if (adInventory.vasts) {
    // Create variables for the getMultipleVASTResponse
    const vastCount = adInventory.vasts.length;
    if (vastCount > 0) {
      makeVASTArrayResponse(adInventory.vasts, player).then((responses) => {
        log(`Returned responses (count: ${responses.length}): `, responses);
        finalizeRequest(responses);
      });
    }
  } else {
    makeVASTResponse(adInventory.vast, player).then((response) => {
      log('Returned response: ', response);
      finalizeRequest([response]);
    });
  }
};

export default requestAd;
