import window, { videojs } from 'global/window';
import 'videojs-contrib-quality-levels';
import cloneDeep from 'lodash/cloneDeep';
import { isKeySystemAvailable, getDRMKeySystems } from './tech-detection';
import playSource from './play-source';
import updatePlayerFunctionality from './custom-functionality';
import Reset from './reset';
import Playlist from './playlist';
import getAutoplayConfiguration from './utils/autoplay-configurator';
import adServicePreprocessing from './utils/adservice-init-preprocessing';
import playlistPreprocessing from './utils/playlist-init-preprocessing';
import adServiceTypeSelect from './utils/adservice-type-select';
import pipInit from './utils/picture-in-picture';
import measuringAutoInit from './utils/measuring-init-preprocessing';
import { handleHotkeys } from './hotkeys';
import { registerPlugins } from './plugins/registerPlugins';
import { registerLanguages } from './localization';
import { handleKeyChanges } from './handleKeyChanges';

// Save our detection to the videojs instance
videojs.getDRMKeySystems = function getDRMKeySystemsIfAvailable(audioCapabilities, videoCapabilities) {
  // Call isKeySystemAvailable in the function. When called directly mobile browsers will ask for permission
  if (isKeySystemAvailable()) {
    return getDRMKeySystems(audioCapabilities, videoCapabilities);
  }
  return undefined;
};

registerPlugins();

/**
 * Player initialization data
 * @typedef PlayerData
 * @type Object
 * @property {Boolean=}  [data.autoplay=true]     Autoplay settings
 * @property {Object=}   data.plugins             Plugins to initialize
 * @property {String=}   data.preferredLang       Preferred stream language
 * @property {Array.<Object>=} data.preferredTech Override default tech detection with this object. Optional parameter
 * @property {Object}    data.tracks              Player tracks
 */

/**
 * Wrapper around VideoJS object. It has the same methods and properties plus something more.
 * This class is used for documentation purposes only. Use function [ottPlayer]{@link ottPlayer} to instantiate the player instead.
 * @class
 */
function OttPlayer() {} // eslint-disable-line

/**
 * Initialize player
 *
 * @param   {String}      id                ID of the video element
 * @param   {PlayerData}  data              Player initialization data
 * @private
 */
const initPlayer = async function initPlayer(id, data) {
  // make deep clone of the original config to be able to see what config was used when initializing the player
  // there will be player.getOriginalConfig method to get this object
  const originalConfig = cloneDeep(data);

  registerLanguages();

  // check playlist
  let initData;

  if (data instanceof Array) {
    // Adservice preprocessing for all playlist configs, before player start.

    data = await Promise.all(
      data.map(async (playlistData) => ({
        ...playlistData,
        plugins: {
          ...playlistData.plugins,
          ...(playlistData.plugins?.adService ? await adServicePreprocessing(playlistData) : {}),
        },
        ...pipInit(playlistData),
      })),
    );

    [initData] = [...data];
  } else {
    initData = { ...data, ...pipInit(data) };
    const adServiceInitPreprocessing = await adServicePreprocessing(initData);
    initData.plugins = { ...initData.plugins, ...adServiceInitPreprocessing };
  }

  const autoplayConfiguration = await getAutoplayConfiguration(initData);
  initData = { ...initData, ...autoplayConfiguration };

  initData = measuringAutoInit(initData);
  initData = adServiceTypeSelect(initData);
  initData = playlistPreprocessing(data, initData);

  // this options let the video.js/http-streaming do all the stuff around adaptive streaming
  // it can't be set up on iOS and it breaks DRM on Mac, so it needs to be disabled on these platforms
  // the CSAI check is here, because when the CSAI is used, it's live stream without DRM and at this state
  // we can't get information about start of the ad when the native is not overridden, so we are turning it on even on Mac
  const overrideNative = (function getOverrideNative() {
    if (videojs.browser.IS_IOS) {
      return false;
    }
    if (initData.plugins?.adService?.linear?.csaiMidrolls) {
      return true;
    }
    if (videojs.browser.IS_SAFARI) {
      return false;
    }
    return true;
  })();

  const nativeTextTracks = videojs.browser.IS_IOS || initData.nativeTextTracks || false;

  initData.html5 = {
    nativeAudioTracks: false,
    nativeVideoTracks: false,
    nativeTextTracks,
    vhs: {
      bandwidth: data.initialBitrate || 1500000,
      blacklistDuration: 0,
      overrideNative,
      limitRenditionByPlayerDimensions: data.limitRenditionByPlayerDimensions || false,
      useCueTags: true,
      cacheEncryptionKeys: true,
    },
  };

  const player = playSource(id, initData);

  // hide BigPlayButton while metadata are not loaded and show LoadingSpinner component
  // prevention from start playing earlier than player has metadata
  // needed for "openSession()" method in "shared/plugins/conviva.js"
  // so "openSession()" can't trigger earlier than has metadata because player needs "duration" in "getMetadata()"
  player.getChild('LoadingSpinner').el_.classList.add('vjs-loading-spinner-init');
  player.getChild('BigPlayButton').el_.classList.add('vjs-big-play-button-init');
  player.on('loadedmetadata', () => {
    player.getChild('LoadingSpinner').el_.classList.remove('vjs-loading-spinner-init');
    player.getChild('BigPlayButton').el_.classList.remove('vjs-big-play-button-init');
  });

  // get config that was used to initialize player
  // use it only for debugging purposes
  player.getOriginalConfig = () => {
    console.warn('Use player.getOriginalConfig() only for debugging!'); // eslint-disable-line
    return originalConfig;
  };

  player.getRepresentationsInfo = () =>
    player
      .tech()
      .vhs.representations()
      .map((r) => ({
        id: r.id,
        height: r.height,
        enabled: r.enabled(),
      }));

  handleKeyChanges(player);

  // Be sure player exist
  if (player) {
    updatePlayerFunctionality(player);

    handleHotkeys(player, data.hotkeys);

    // create reset instance
    // FIXME: bad pattern - change location to player.reset =
    const reset = new Reset(player, { id, data: initData });
    reset.init();

    // check init data when inside array of configurations create playlist instance
    if (Array.isArray(data)) {
      const playlist = new Playlist(player, data);
      player.ready(() => {
        playlist.init();
      });
    }

    /* HACK: NEVER EVER use this on production - testing purpose only */
    if (initData.globalMediaPlayer) {
      const mediaPlayer = player.tech({
        IWillNotUseThisInPlugins: true,
      }).sourceHandler_.mediaPlayer_;
      window.mediaPlayer_ = mediaPlayer;
    }
  }

  return player;
};

/**
 * Init player when data sended already or try to requset data from the initialization URL
 *
 * @param   {String} id       - ID of the video element
 * @param   {PlayerData} data - Player initialization data or request data for the URL request when URL exist
 * @param   {String=} url     - Url to the initialization data object
 * @returns {Promise} A promise that returns player object
 */
const handleData = function handleData(id, data) {
  return new Promise((resolve) => {
    // User already initialized custom data

    // muted attribute as fix for safari iPad IMA ads error
    // but we want to rewrite the muted attribute if is in configuration
    // more info: https://jira.zentity.com/browse/CRASUPP-581
    let muted;
    let safariData = data;
    if (videojs.browser.IS_SAFARI) {
      muted = true;

      if (data instanceof Array) {
        safariData = [...data.map((dataItem) => ({ muted, ...dataItem }))];
      } else {
        safariData = { muted, ...data };
      }
    }

    resolve(initPlayer(id, muted ? safariData : data));
  });
};

/**
 * Initialize ott player with custom data
 * @example
 * ottPlayer('player-id', {
    tracks: {
      DASH: {},
      HLS: {}
    }
  }).then(player => ... );
 *
 * @param   {String} id                         ID of the video element
 * @param   {PlayerData} data                   Player initialization data or request data for the URL request when URL exist
 * @param   {String=} url                       Url to the initialization data object
 * @returns {Promise} A promise that returns player object
 */
const ottPlayer = function ottPlayer(id, data) {
  return handleData(id, data);
};

window.ottPlayer = ottPlayer;
