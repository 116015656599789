/**
 * <p>Creates a social share buttons over the video player.</p>
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-share-button-open</b><br>
 * <b>ott-share-button-gp</b><br>
 * <b>ott-share-button-fb</b><br>
 * <b>ott-share-button-tw</b><br>
 * <b>ott-share-button-link</b><br>
 * <b>ott-share-button-close</b>
 *
 * @example
{
  plugins: {
    sharingScreen: {},
  }
}
 *
 * @module plugins/sharingScreenPlugin
 *
 */
/* eslint-disable max-classes-per-file */
import window, { videojs } from 'global/window';
import document from 'global/document';
import log from '../../shared/log';

// Get a component to subclass
const VjsButton = videojs.getComponent('Button');
const VjsComponent = videojs.getComponent('Component');

class SharingScreen extends VjsComponent {
  handleClick(e) {
    e.stopImmediatePropagation();
  }

  createEl() {
    const tpl = `<div class="vjs-share-links">
                <span class="vjs-share-fb"></span>
                <span class="vjs-share-tw"></span>
                <div class="vjs-share-linktext" title="${this.player_.localize(
                  'To copy to clipboard, click on link.',
                )}">
                <span class="vjs-share-link">
                </span><span class="clipb">
                ${window.location.toString()}
                </span></div>
                <span class="copy-clicked">${this.player_.localize('Link copied successfully.')}</span></div>`;

    return super.createEl('div', {
      'className': 'vjs-share-screen', // eslint-disable-line quote-props
      'innerHTML': tpl, // eslint-disable-line quote-props
      // let the screen reader user know that the text of the button may change
      'aria-live': 'polite',
    });
  }
}

// Subclass the component (see 'extend' doc for more info)
class SharingButton extends VjsButton {
  buildCSSClass() {
    return `vjs-share-button ${super.buildCSSClass()}`;
  }

  handleClick(e) {
    e.stopImmediatePropagation();
    const shareBtn = this;
    const player = shareBtn.player();
    let fadeTimeout;

    // Save actual state
    shareBtn.playState = shareBtn.player().paused();
    // Pause player
    player.pause();
    // Register the new component
    videojs.registerComponent('SharingScreen', SharingScreen);
    // create sharing screen element
    const sharingScreen = player.addChild('SharingScreen');
    // change button image
    shareBtn.addClass('vjs-share-close');
    shareBtn.addClass('vjs-icon-close-red');
    // hide big play button
    player.getChild('bigPlayButton').hide();

    // custom events
    player.trigger('ott-share-button-open');

    const $shareScreen = player.el().getElementsByClassName('vjs-share-screen');
    $shareScreen[0].getElementsByClassName('vjs-share-fb')[0].addEventListener('click', () => {
      player.trigger('ott-share-button-fb');
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`,
        '_blank',
        'noopener, noreferrer',
      );
    });
    $shareScreen[0].getElementsByClassName('vjs-share-tw')[0].addEventListener('click', () => {
      player.trigger('ott-share-button-tw');
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(
          document.title,
        )}`,
        '_blank',
        'noopener, noreferrer',
      );
    });

    function copyToClipboard(element) {
      const $temp = document.createElement('input');
      element[0].append($temp);
      $temp.value = element[0].getElementsByClassName('clipb')[0].textContent.trim();
      document.execCommand('copy');
      $temp.remove();
      player.el().getElementsByClassName('copy-clicked')[0].style.visibility = 'visible';
      for (let i = 0; i < 2; i++) {
        player.el().getElementsByClassName('copy-clicked')[0].style.opacity = 1;
      }

      if (typeof fadeTimeout === 'number') {
        clearInterval(fadeTimeout);
      }
      fadeTimeout = window.setTimeout(() => {
        player.el().getElementsByClassName('copy-clicked')[0].style.visibility = 'hidden';
        player.el().getElementsByClassName('copy-clicked')[0].style.opacity = 0;
      }, 1500);
    }
    $shareScreen[0].getElementsByClassName('vjs-share-linktext')[0].addEventListener('click', () => {
      player.trigger('ott-share-button-link');
      copyToClipboard($shareScreen);
    });

    shareBtn.off('click');

    // click event to close
    shareBtn.one('click', (e) => {
      e.stopImmediatePropagation();
      this.removeClass('vjs-share-close');
      this.removeClass('vjs-icon-close-red');
      sharingScreen.dispose();

      if (!shareBtn.playState) {
        shareBtn.player().play();
      }

      // custom event open
      player.trigger('ott-share-button-close');

      shareBtn.one('click', shareBtn.handleClick);
      shareBtn.player().getChild('bigPlayButton').show();
    });
  }
}

SharingButton.prototype.controlText_ = 'Sharing Screen';
// Register the new component
videojs.registerComponent('SharingButton', SharingButton);

/**
 * Sharing screen plugin initialization
 */
export const sharingScreenPlugin = function sharingScreenPlugin() {
  const player = this;
  // const videoEl = player.el().getElementsByTagName('video')[0];

  player.addChild('SharingButton');

  log('sharingScreen plugin initialized');
};
