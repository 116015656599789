import { videojs } from 'global/window';
import PlaylistCurrentTimeDisplay from './time/playlist-current-time-display';
import PlaylistDurationDisplay from './time/playlist-duration-display';
import PlaylistTimeDivider from './time/playlist-time-divider';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistTimeDisplay extends Component {
  constructor(player, options) {
    super(player, options);

    this.playlistCurrentTimeDisplay = new PlaylistCurrentTimeDisplay(player);
    this.playlistTimeDivider = new PlaylistTimeDivider(player);
    this.playlistDurationDisplay = new PlaylistDurationDisplay(player);

    this.addChild(this.playlistCurrentTimeDisplay);
    this.addChild(this.playlistTimeDivider);
    this.addChild(this.playlistDurationDisplay);
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-playlist-time-display',
    });
  }
}

Component.registerComponent('PlaylistTimeDisplay', PlaylistTimeDisplay);
export default PlaylistTimeDisplay;
