/* eslint-enable */
import { videojs } from 'global/window';
import packageJson from '../../../../../../package.json';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Quality control switch - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class VersionControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    // Send this component to the childs
    options.parent = this;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-quality-control vjs-settings-item vjs-settings-version-info',
      innerText: `${this.player_.localize('Player version')}: ${packageJson.version}`,
    });
  }
}

Component.registerComponent('VersionControl', VersionControl);
export default VersionControl;
