import xmljs from 'xml-js';
import { fetch } from 'global/window';
import log from '../../log';
import { percentToSec, hhmmssToSec } from './shared/time-helper';

const updateUrlWithMacros = (url) => {
  let updatedUrl = url;

  if (updatedUrl.includes('[CACHEBUSTING]')) {
    const hash = Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
    updatedUrl = updatedUrl.replace(/\[CACHEBUSTING\]/g, hash);
  }

  return updatedUrl;
};

const transformXMLtoJS = (xml) => {
  const options = { ignoreComment: true, alwaysChildren: true };
  return xmljs.xml2js(xml, options); // or xml2json
};

const getLinearPositionType = (timeOffset) => {
  switch (true) {
    case timeOffset === 'start':
      return 'preroll';
    case timeOffset === 'end':
      return 'postroll';
    default:
      return 'midrolls';
  }
};

// vmap:AdBreak => vmap:AdSource => vmap:AdTagURI => cdata
const findCdataUrl = (adBreakElement) => {
  let cdataUrl = null;
  const adBreakElements = adBreakElement.elements || [];
  adBreakElements.forEach((adBreakChild) => {
    if (!cdataUrl && adBreakChild.name === 'vmap:AdSource') {
      const adSourceElements = adBreakChild.elements || [];
      adSourceElements.forEach((adSourceChild) => {
        if (adSourceChild.name === 'vmap:AdTagURI') {
          const adTagUriElements = adSourceChild.elements || [];
          adTagUriElements.forEach((adTagUriChild) => {
            if (adTagUriChild.type === 'cdata') {
              cdataUrl = adTagUriChild.cdata;
            }
          });
        }
      });
    }
  });

  return cdataUrl;
};

const createAdInitData = (vmap, totalDuration) => {
  const initData = {
    linear: {
      preroll: {
        vasts: [],
      },
      midrolls: [],
      postroll: {
        vasts: [],
      },
    },
    cascade: [],
    markers: [],
  };

  vmap.elements[0].elements.forEach((element) => {
    if (element.name === 'vmap:AdBreak') {
      const { timeOffset, breakType } = element.attributes;

      const timeOffsetInSec = timeOffset.includes('%')
        ? percentToSec(timeOffset, totalDuration)
        : hhmmssToSec(timeOffset);
      const cdata = findCdataUrl(element);

      if (breakType === 'linear' && cdata) {
        const positionType = getLinearPositionType(timeOffset);
        if (positionType === 'midrolls') {
          initData.linear[positionType].push({
            time: timeOffsetInSec,
            vasts: [cdata],
          });
          initData.markers.push({
            time: timeOffsetInSec,
            class: 'vjs-marker-ad',
          });
        } else {
          initData.linear[positionType].vasts.push(cdata);
        }
      }

      if (breakType === 'nonlinear' && cdata) {
        initData.cascade.push({
          time: timeOffsetInSec,
          overlay: {
            vast: cdata,
          },
        });
      }
    }
  });

  if (initData.linear.midrolls.length > 0 && !totalDuration) {
    log.error('[vmap-transform] missing total duration value for midrolls time conversion');
  }

  if (initData.cascade.length > 0 && !totalDuration) {
    log.error('[vmap-transform] missing total duration value for overlays time conversion');
  }

  return initData;
};

// eslint-disable-next-line arrow-body-style
const vmapTransform = (adTagUrl, totalDuration = 0, timeout = 5000) => {
  return new Promise((resolve, reject) => {
    log('[vmap-transform] enabled');

    const processingTimeout = setTimeout(() => {
      log.error('[vmap-transform] timeout error');
      reject();
    }, timeout);

    fetch(updateUrlWithMacros(adTagUrl))
      .then(async (response) => {
        const text = await response.text();
        return text;
      })
      .then((data) => {
        const dataObject = transformXMLtoJS(data);
        log('[vmap-transform] vmap data', dataObject);
        const generatedAdInitData = createAdInitData(dataObject, totalDuration);
        log('[vmap-transform] generated init data', generatedAdInitData);
        clearTimeout(processingTimeout);
        resolve(generatedAdInitData);
      })
      .catch((e) => {
        log.error(`[vmap-transform] fetch error ${e.message}`, e);
        reject();
      });
  });
};

export default vmapTransform;
