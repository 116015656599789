import { videojs } from 'global/window';
import './quality-heading';
import './quality-button-wrapper';
import './quality-auto-button-wrapper';
import '../uhdProfile/uhdProfile-control';
import './quality-auto-overlay';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Registration component for all qualities sub-plugin
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityMenu extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('QualityHeading');
    this.addChild('UhdProfileControl', {
      qualityControlOpts: options.qualityControlOpts,
      dropdownSettings: options.dropdownSettings,
    });
    this.addChild('QualityButtonWrapper', options);
    this.addChild('QualityAutoButtonWrapper', { ...options, hasInfoOverlay: true });
    this.player_.addChild('QualityAutoOverlay');
    this.el_.className = 'vjs-quality-menu vjs-custom-menu vjs-hidden';
  }
}

// Register the new component
Component.registerComponent('QualityMenu', QualityMenu);
export default QualityMenu;
