import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import HelpButton from './help-button/button';
/* eslint-enable */

/**
 * Help button plugin initialization
 *
 */
export const helpButtonPlugin = function helpButtonPlugin() {
  const player = this;

  const initHelpButton = function initHelpButton() {
    this.controlBar.addChild(new HelpButton(player));
  };

  player.one('loadeddata', initHelpButton);
  log('help button plugin initialized');
};
