import { Analytics, Constants } from '@convivainc/conviva-js-coresdk';
import convivaVideojsModule from '@convivainc/conviva-js-videojs';
import availableEvents from './measuring/shared/available-events';

const DEFAULT_ENCODED_FRAMERATE = 25;

export class Conviva {
  constructor(player, options) {
    this.player = player;
    this.options = options;

    this.init();
  }

  init() {
    const {
      customerKey,
      testCustomerKey,
      touchstoneGatewayUrl, // use only in debugging for the touchstone
    } = this.options;

    // if there are options to enable testing, use them to connect to the Touchstone
    if (touchstoneGatewayUrl && testCustomerKey) {
      const testOptions = {
        [Constants.GATEWAY_URL]: touchstoneGatewayUrl,
        [Constants.LOG_LEVEL]: Constants.LogLevel.DEBUG,
      };

      Analytics.init(testCustomerKey, null, testOptions);

      // if there is customerKey (the proper one, not the one for testing)
      // run the Conviva without the Touchstone
    } else if (customerKey) {
      Analytics.init(customerKey, null);
    }

    this.videoAnalytics = Analytics.buildVideoAnalytics();
    // this.adAnalytics = Analytics.buildAdAnalytics(videoAnalytics)

    const convivaPlayerOptions = {
      // conviva videojs module has to be passed as one of the setPlayer options
      [Constants.CONVIVA_MODULE]: convivaVideojsModule,
    };

    this.videoAnalytics.setPlayer(this.player, convivaPlayerOptions);
    this.registerEvents();
  }

  registerEvents() {
    this.player.one('loadedmetadata', () => this.handleStart());
    this.player.one(availableEvents.ENDED, () => this.closeSession());

    // triggers on tab close, change navigation
    // for more information:
    // https://pulse.conviva.com/learning-center/content/sensor_developer_center/sensor_integration/javascript/javascript_stream_sensor.htm#6Cleanup
    window.addEventListener('unload', () => this.disposeSession());
  }

  handleStart() {
    this.openSession();
  }

  handleEnd() {
    this.closeSession();
  }

  getMetadata() {
    const { StreamType } = Constants;
    const { metadata } = this.options;

    // some metadata need to be added or some preprocessing must be made on them
    // like renaming in from playerName to Constants.ASSET_NAME, which could be whatever
    const duration = this.player.duration();
    const contentAndPlayerInfo = {
      [Constants.DURATION]: duration,
      [Constants.IS_LIVE]: duration < 0 || duration === Infinity ? StreamType.LIVE : StreamType.VOD,
      [Constants.PLAYER_NAME]: 'OTT Player',
      [Constants.ENCODED_FRAMERATE]: this.options.encodedFramerate || DEFAULT_ENCODED_FRAMERATE,
      [Constants.ASSET_NAME]: metadata.assetName,
      'c3.app.version': this.player.VERSION,
    };

    // add viewerId only when it's mentioned and if it's mentioned, change it's name to Constants.VIEWER_ID
    if (metadata.viewerId) {
      contentAndPlayerInfo[Constants.VIEWER_ID] = metadata.viewerId;
    }

    return {
      ...metadata, // custom and predefined metadata straight out of plugin options
      ...contentAndPlayerInfo,
    };
  }

  openSession() {
    const deviceMetadata = {
      // set the corresponding Conviva.Constants.DeviceType
      [Constants.DeviceMetadata.TYPE]: Constants.DeviceType.DESKTOP,
      [Constants.DeviceMetadata.CATEGORY]: Constants.DeviceCategory.WEB,
    };
    Analytics.setDeviceMetadata(deviceMetadata);

    const metadata = this.getMetadata(); // sometimes this is called "contentInfo"/"content info" in the Conviva docs

    this.videoAnalytics.reportPlaybackRequested(metadata);
  }

  closeSession() {
    this.videoAnalytics.reportPlaybackEnded();
  }

  disposeSession() {
    this.videoAnalytics.release();
    Analytics.release();
  }

  dispose() {
    this.closeSession();
    this.disposeSession();
  }
}
