import availableEvents from './shared/available-events';
import { extend } from '../../utils/object-helpers';
import GoogleTagManager from './gtm';

/**
 * Google Tag Manager measuring service. The basic plugin measures only basic events such as play, pause, and end for video content.
 * If you need more complex measurements, use the complex plugin.
 *
 * @param {Object} player           - The player instance
 */
class GTMBasic extends GoogleTagManager {
  constructor(player, options = {}) {
    super(player);
    const defaultEventNames = {
      videoStart: 'videoStart',
      videoPlay: 'videoResume',
      videoPause: 'videoPause',
      videoFinish: 'videoFinish',
    };
    this.event = extend(false, defaultEventNames, options.eventNames);
    // Register events to watch
    this.registerEvents();
  }

  registerEvents() {
    const that = this;

    function sendPlay(obj) {
      if (obj.firstPlay) {
        that.push({
          event: that.event.videoStart,
          videoName: obj.streamInfo.programName,
          videoId: obj.streamInfo.programID,
        });
      } else {
        that.push({
          event: that.event.videoPlay,
          videoName: obj.streamInfo.programName,
          videoId: obj.streamInfo.programID,
          videoResumeTime: obj.currentTime,
        });
      }
    }

    function sendPause(obj) {
      that.push({
        event: that.event.videoPause,
        videoName: obj.streamInfo.programName,
        videoId: obj.streamInfo.programID,
        videoPauseTime: obj.currentTime,
        videoLengthPlayed: obj.lengthPlayed,
      });
    }

    function sendEnded(obj) {
      that.push({
        event: that.event.videoFinish,
        videoName: obj.streamInfo.programName,
        videoId: obj.streamInfo.programID,
        videoLengthPlayed: obj.lengthPlayed,
      });
    }

    this.player.on(availableEvents.PLAY, sendPlay);
    this.player.on(availableEvents.PAUSE, sendPause);
    this.player.on(availableEvents.ENDED, sendEnded);
  }
}

export default GTMBasic;
