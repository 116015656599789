import { videojs } from 'global/window';
import log from '../../log';
import adEvents from '../measuring/shared/ad-events';
import { CsaiEvents } from './csai-events';

const Component = videojs.getComponent('Component');

class JinglePlayer extends Component {
  constructor(player, options) {
    super(player, options);
    this.player = player;
    this.options = options;

    this.player.one([CsaiEvents.CSAI_MIDROLLS_READY, adEvents.CONTENTPAUSEREQUESTED], () => this.initializeJingle());
  }

  createEl() {
    return videojs.dom.createEl('video', {
      id: 'csai-jingle-player',
      name: 'csai-jingle-player',
      className: 'csai-jingle-player',
    });
  }

  showJinglePlayer() {
    const volume = this.player.volume();
    const muted = this.player.muted();

    log('[csai-jingle-player] showing jingle player, hiding main player');
    this.player.muted(true);
    this.jinglePlayer.el().classList.add('visible');
    this.jinglePlayer.volume(volume);
    this.jinglePlayer.muted(muted);
    this.jinglePlayer.play();
    this.jinglePlayer.one('ended', () => this.hideAndRemoveJinglePlayer());
  }

  hideAndRemoveJinglePlayer() {
    log('[csai-jingle-player] hiding jingle player, showing main player');
    const volume = this.jinglePlayer.volume();
    const muted = this.jinglePlayer.muted();
    this.player.volume(volume);
    this.player.muted(muted);
    this.jinglePlayer.el().classList.remove('visible');
    this.removeJinglePlayer();
  }

  removeJinglePlayer() {
    log('[csai-jingle-player] removing jingle player');
    this.jinglePlayer.dispose();
    this.dispose();
    this.player.removeChild('JinglePlayer');
  }

  initializeJingle() {
    log('[csai-jingle-player] - initializing jingle player');

    this.jinglePlayer = videojs('csai-jingle-player', {
      autoplay: false,
      controls: true,
      disablePictureInPicture: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      preload: 'auto',
      sources: [
        {
          src: this.options.csaiJingle.url,
          type: 'video/mp4',
        },
      ],
    });

    this.player.one([CsaiEvents.CSAI_MIDROLLS_ENDED, adEvents.CONTENTRESUMEREQUESTED], () => {
      if (!this.preventJinglePlayer) {
        this.showJinglePlayer();
      } else {
        this.removeJinglePlayer();
      }
    });

    this.player.one(CsaiEvents.CSAI_NO_TIME_FOR_JINGLE, () => {
      this.preventJinglePlayer = true;
    });
  }
}

export function createJinglePlayer(player, csaiJingle) {
  videojs.registerComponent('JinglePlayer', JinglePlayer);
  player.addChild('JinglePlayer', { csaiJingle });
}
