import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import LiveRestartButton from './live-restart/button';
/* eslint-enable */

/**
 * Help button plugin initialization
 *
 * @param {Array.<Object>} opts - Plugin initialization object
 */
export const liveRestartButtonPlugin = function liveRestartButtonPlugin(opts) {
  const player = this;
  opts.liveRestartEndpointUrl = opts.liveRestartEndpointUrl || null;
  opts.eventsOnly = opts.eventsOnly || false;

  const initLiveRestartButton = function initLiveRestartButton() {
    this.controlBar.addChild(new LiveRestartButton(player, opts));
  };

  player.one('loadeddata', initLiveRestartButton);
  log('live restart plugin initialized');
};
