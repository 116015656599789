import { videojs } from 'global/window';
/* eslint-disable */
import SubtitlesHeading from './subtitles-heading';
import SubtitlesButtonsWrapper from './subtitles-buttons-wrapper';
/* eslint-enable */
import './subtitles-select/subtitles-select-wrapper';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Registration component for all settings sub-plugin
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SubtitlesMenu extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('SubtitlesHeading');
    this.addChild('SubtitlesButtonsWrapper');
    this.el_.className = 'vjs-subtitles-menu vjs-custom-menu vjs-hidden';
  }
}

// Register the new component
Component.registerComponent('SubtitlesMenu', SubtitlesMenu);
export default SubtitlesMenu;
