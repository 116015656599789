import { videojs } from 'global/window';

import './info-menu';
import hideOpenMenus from '../../../utils/hide-menus';
import cancelActiveButtons from '../../../utils/cancel-active-buttons';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The info button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class InfoButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.player.addChild('InfoMenu', options);

    this.controlText(this.player_.localize('Info'));

    this.qualityControlOpts = this.player.options_?.responsiveControl?.quality;
    this.dropdownSettings = this.player.options_?.styleControl?.dropdownSettings;

    if (this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings' && !this.dropdownSettings) visibility = 'hidden';
          if (entry[0] === 'alone') visibility = 'visible';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    } else if (!this.qualityControlOpts && !this.dropdownSettings) {
      this.addClass('vjs-hidden');
    }
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-info-button ${super.buildCSSClass()}`;
  }

  /**
   * Handle click on info button.
   */
  handleClick() {
    const menu = this.player_.getChild('InfoMenu');
    if (typeof menu !== 'undefined') {
      if (menu.hasClass('vjs-hidden')) {
        hideOpenMenus(this.player_);
        cancelActiveButtons(this.player_);
        menu.removeClass('vjs-hidden');
        this.addClass('vjs-button-active');
        this.player_.addClass('vjs-popup-displayed');
      } else {
        menu.addClass('vjs-hidden');
        this.removeClass('vjs-button-active');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }
}
// Register the new component
Component.registerComponent('InfoButton', InfoButton);
export default InfoButton;
