import is from 'is_js';
import log from './log';

// list of ott custom hotkey option defaults, that can be modified from outside
const overridableDefaultOptions = {
  volumeStep: 0.1,
  seekStep: 5,
  enableVolumeScroll: false,
  enableHoverScroll: false,
  enableNumbers: false,
  skipInitialFocus: true,
};

// options that can't be overridden
const nonOverridableDefaultOptions = {
  enableModifiersForNumbers: false,
};

// NOTE: it is possible to control player with hotkeys even when the player is not
// in focus. The captureDocumentHotkeys and documentHotkeysFocusElementFilter had to be setup then.
// It could maybe get some custom property like mainPlayer (because there can be more players on the page),
// that would tell to the player this is the player that should be controlled globally when the let say body is in focus

export function handleHotkeys(player, customHotkeysOptions) {
  if (customHotkeysOptions === false) {
    log('[hotkeys] hotkeys are disabled');
    return;
  }

  // copy nonOverridableDefaultOptions and merge them with custom options
  const mergedOptions = { ...nonOverridableDefaultOptions };
  Object.entries(overridableDefaultOptions).forEach(([key, defaultValue]) => {
    mergedOptions[key] = customHotkeysOptions?.[key] ?? defaultValue;
  });

  // after the first time the player is played, bind the focus so the focus won't go away from player to buttons when interacting with them
  player.one('play', () => {
    // focusing is necessary for hotkeys to work without captureDocumentHotkeys
    const focusPlayer = () => player.el_.focus({ preventScroll: true });
    if (is.not.safari && mergedOptions.skipInitialFocus === false) {
      log('[hotkeys] skipInitialFocus set to false, focusing player');
      focusPlayer();
    }
    // this is here, because ClickScreen component is blocking propagation of the click event so it won't go all the way to player
    // but it's triggering it's own event to let player know it has been clicked on, so we catch this event instead
    player.on('click-screen-clicked', focusPlayer);
    player.on('click', focusPlayer);
  });

  // focus is handled manually
  player.hotkeys({ ...mergedOptions, skipInitialFocus: true });
  log('[hotkeys] hotkeys are enabled with custom settings');
}

export function blockSeekDuringAds(player, event) {
  if (player.ads.isAdPlaying()) {
    if (event.keyCode === 37 || event.keyCode === 39) {
      event.stopImmediatePropagation();
    }
  }
}
