import Cookies from 'js-cookie';
import { PERSIST_SETTINGS_COOKIE_NAME } from '../../constants';

export const SUBT_DISABLED_VAL = 'disabled';

/**
 * Persist settings helper class
 *
 * @param {Object} player                     - Player object
 * @param {Object} options                    - Options object
 * @param {String} options.id                 - Unique id for this settings
 * @param {Boolean} options.persistSubtitlesLanguage
 *  - Save current subbtitle language to the cookie when subtitle change event occurs
 * @param {Boolean} options.persistAudioLanguage
 *  - Save current audio language to the cookie when audio change event occurs
 * @param {Number} options.cookieExpiration   - Cookie expire after x days
 */
class PersistSettings {
  /**
   * Find user settings
   * if ID defined, find user settings by ID
   *
   * @param {String} id - Unique id for persist settings
   * @return {Object|False} Matched user settings, false otherwise
   */
  static getUserSettings(id) {
    const data = JSON.parse(Cookies.get(PERSIST_SETTINGS_COOKIE_NAME) || '{}');
    if (id && data[id]) {
      // User settings found matched
      return data[id];
    }
    if (data) {
      return data;
    }
    return false;
  }

  /**
   * Try to find persist audio settings value
   *
   * @param {String} id - Unique id for persist settings
   * @return {String|False} Matched language, false otherwise
   */
  static getStoredLang(id) {
    const settings = PersistSettings.getUserSettings(id);
    return settings.audioLanguage;
  }

  static getStoredSubtitlesLanguage(id) {
    const settings = PersistSettings.getUserSettings(id);
    return settings.subtitlesLanguage;
  }

  constructor(player, options) {
    // create cookie when doesn't exist
    this.player_ = player;
    this.options_ = options;

    if (options.persistSubtitlesLanguage) {
      player.on('ott-subtitles-enabled', (obj) => {
        this.saveSubtitlesLanguage(obj);
      });
      player.on('ott-subtitles-disabled', (obj) => {
        this.disableSubtitlesLanguage(obj);
      });
    }
    if (options.persistAudioLanguage) {
      player.on('ott-language-changed', (obj) => {
        this.saveAudioLanguage(obj);
      });
    }
    if (options.persistAutoplayNext) {
      player.on('ott-answitch-click', (obj) => {
        this.saveAutoplayNext(obj);
      });
    }
    if (options.persistVolume) {
      player.one('ready', () => {
        player.on('ott-mute-click', ({ muted }) => {
          this.saveVolume(muted);
        });
        player.on('ott-volume-change', ({ volume }) => {
          this.saveVolume(false, volume);
        });
        player.on('keydown', (e) => {
          // 40 keyCode (down arrow)
          // 38 keyCode (up arrow)
          if (e.keyCode === 40 || e.keyCode === 38) {
            this.saveVolume(player.muted(), player.volume());
          }
          // 77 keyCode ("m" key) => mute
          if (e.keyCode === 77) {
            this.saveVolume(player.muted());
          }
        });
      });
    }
  }

  /**
   * Try to find cookie and return stored value
   *
   * @return {Object} Cookie value, new object to store otherwise
   */
  getCookieSettings() {
    const data = JSON.parse(Cookies.get(PERSIST_SETTINGS_COOKIE_NAME) || '{}');

    if (data[this.options_.id]) {
      // User settings found matched
      return data;
    }

    // Create cookie settings with key for this user
    return {
      [this.options_.id]: {
        audioLanguage: null,
        subtitlesLanguage: null,
        autoplayNext: null,
        volume: null,
      },
    };
  }

  /**
   * Merge current user settings
   *
   * @param {Object} obj - user settings ubject (e.g. {audioLang: 'cze'})
   * @param {[String]} removeKeys - cookie settings to remove (e.g. {removeKeys: ['muted']})
   */
  updateCookieContent(obj, { removeKeys = [] }) {
    // This object contains user id as key
    const savedCookieSettings = this.getCookieSettings();
    const newCookieSettings = {
      ...savedCookieSettings[this.options_.id],
      ...obj,
    };
    // get cookie for all players that differs by ID on "plugins.settings.persistSettings.id"
    const settings = PersistSettings.getUserSettings();
    const mergedSettings = { ...settings, ...savedCookieSettings, [this.options_.id]: newCookieSettings };
    if (removeKeys && removeKeys.length) {
      // remove cookie by the key name
      // required for "muted" behaviour
      // more info: https://jira.zentity.com/browse/CRASUPP-633
      removeKeys.map((key) => delete mergedSettings[this.options_.id][key]);
    }

    Cookies.set(PERSIST_SETTINGS_COOKIE_NAME, JSON.stringify(mergedSettings), {
      expires: this.options_.cookieExpiration,
      SameSite: 'None',
      Secure: true, // required if "SameSite=None"
    });
  }

  /**
   * Save subtitle language to the cookie
   *
   * @param {Object} obj - Event object with selected text track
   */
  saveSubtitlesLanguage(obj) {
    this.updateCookieContent(
      {
        subtitlesLanguage: obj.track.language,
      },
      {},
    );
  }

  /*
   * Remove subtitle cookie
   */
  clearSubtitlesLanguage() {
    this.updateCookieContent(
      {
        subtitlesLanguages: null,
      },
      {},
    );
  }

  /**
   * Update subtitle cookie
   */
  disableSubtitlesLanguage() {
    this.updateCookieContent(
      {
        subtitlesLanguage: SUBT_DISABLED_VAL,
      },
      {},
    );
  }

  /**
   * Save audio language to the cookie
   *
   * @param {Object} obj - Event object with selected audio source
   */
  saveAudioLanguage(obj) {
    this.updateCookieContent(
      {
        audioLanguage: obj.source.lang,
      },
      {},
    );
  }

  /**
   * Remove subtitle cookie value
   */
  clearAudioLanguage() {
    // TODO: It's not possible to remove selected language now
    this.updateCookieContent(
      {
        audioLanguage: null,
      },
      {},
    );
  }

  /**
   * Save autoplay switch checked value to the cookie
   *
   * @param {Object} obj - Event object with checked value
   */
  saveAutoplayNext(obj) {
    this.updateCookieContent(
      {
        autoplayNext: obj.checked,
      },
      {},
    );
  }

  /**
   * Remove autoplay state value
   */
  clearAutoplayNext() {
    this.updateCookieContent(
      {
        autoplayNext: null,
      },
      {},
    );
  }

  /**
   * Save volume value to the cookie
   *
   * @param {Object} obj - Event object with volume and muted value
   */
  saveVolume(muted, volume) {
    this.updateCookieContent(
      {
        volume: volume || this.player_.volume(),
        ...(muted && { muted: true }),
      },
      { removeKeys: muted ? [] : ['muted'] },
    );
  }

  isPersistAutoplayNext() {
    return this.getCookieSettings()[this.options_.id].autoplayNext;
  }

  /**
   * Select persist text track in original subtitle array
   *
   * @param {Array.<Object>} subtitles - original subtitles object
   * @return {Array.<Object>} Original object with selected subtitles
   */
  updateSubtitles(subtitles) {
    const userSettings = PersistSettings.getUserSettings(this.options_.id);
    if (!userSettings || !userSettings.subtitlesLanguage || !this.options_.persistSubtitlesLanguage) {
      // return original subtitles object when no persist settings found
      return subtitles;
    }

    if (userSettings.subtitlesLanguage === SUBT_DISABLED_VAL) {
      subtitles.forEach((subtitle) => {
        delete subtitle.default;
      });
    }

    const matchedElement = subtitles.filter((item) => item.srclang === userSettings.subtitlesLanguage);

    if (matchedElement.length === 0) {
      // When no matched element found (for this language) return original object
      return subtitles;
    }

    // FIXME: There's something really weird going on
    // it should work like this

    /*
      return subtitles.map((item) => {
      if (item.srclang === userSettings.subtitlesLanguage) {
        {...item, default: true}
      }
      return {...item, default: false};
    });

    but it doesn't, so it probably works by an accident via making some side effect
    */

    return subtitles.map((item) => {
      const rItem = item;
      rItem.default = false;
      if (item.srclang === userSettings.subtitlesLanguage) {
        rItem.default = true;
      }
      return rItem;
    });
  }

  updateVolume() {
    if (this.options_.persistVolume) {
      const settings = PersistSettings.getUserSettings(this.options_.id);

      this.player_.one('loadedmetadata', () => {
        this.player_.volume(settings.volume);
        this.player_.muted(settings.muted);
      });
    }
  }
}

export default PersistSettings;
