import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Shows settings heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SettingsHeading extends Component {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      className: 'vjs-settings-heading',
      innerHTML: this.player_.localize('Settings'),
    });
  }
}
Component.registerComponent('SettingsHeading', SettingsHeading);
export default SettingsHeading;
