import getInventory from './shared/get-inventory';
import avlAdType from './shared/ad-type';
import Cascade from './cascade';
import { registerOverlayTracking } from './shared/vast-tracker';
import log from '../../log';
import { OVERLAY_SUPPORTED_TYPES } from '../../constants';

/**
 * Cares about all non linear process
 *
 * @class
 * @param {Object} adstate - Advertisement container object
 * @param {Object} player - Video.js player instance
 */
class AdsOverlay {
  constructor(adstate, player) {
    this.adstate = adstate;
    this.player = player;
    this.divAdOverlayClass = 'vjs-ad-overlay';
  }

  /**
   * Parse the minimum suggested duration to seconds
   *
   * @param {String} duration - The minimum suggested duration that<br>
   * the creative should be displayed. Duration is in the format HH:MM:SS.mmm
   * @return {int} Minimum suggested duration in seconds
   */
  formatMinSuggestedDuration(duration) {
    // split it at the colons
    const a = duration.split(':');

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  }

  /**
   * Remove overlayer from the DOM
   */
  removeOverlayer() {
    const elements = document.getElementsByClassName(this.divAdOverlayClass);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  /**
   * Append overlayer to the DOM
   *
   * @private
   * @param {Object} ad - non linear creative variation
   * @param {Object} [duration=-1] - duration for the overlay. When -1 player will show ad permanently
   */
  appendOverlayer(ad, duration = -1) {
    const playerElement = document.getElementById(this.player.id_);
    const divAdOverlay = document.createElement('div');
    const divAdOverlayWrap = document.createElement('div');
    const spanClose = document.createElement('span');
    let adLinkElement = '';
    const elemOverlay = document.createElement(ad.type.indexOf('javascript') !== -1 ? 'script' : 'img');
    const divAdOverlayWrapClass = 'vjs-ad-overlay-wrap';
    const spanCloseClass = 'vjs-ad-button-close vjs-icon-close';
    const adLinkClass = 'vjs-ad-overlayer-link';
    const that = this;

    // Set ad overlay attributes and values
    divAdOverlay.className = this.divAdOverlayClass;

    // Set ad overlay wrapper attributes and values
    divAdOverlayWrap.className = divAdOverlayWrapClass;

    // Set close button attributes and values
    spanClose.className = spanCloseClass;
    spanClose.setAttribute('title', this.player.localize('Close Ad'));
    const spanClickEvt = function spanClickEvt(e) {
      e.stopPropagation();
      that.player.trigger({
        type: 'overlayended',
        skipped: true,
        overlay: ad,
      });
    };
    spanClose.onclick = spanClickEvt;

    // If ad has url, make it clickable
    if (ad.nonlinearClickThroughURLTemplate) {
      adLinkElement = document.createElement('a');
      adLinkElement.href = ad.nonlinearClickThroughURLTemplate;
      adLinkElement.setAttribute('target', '_blank');

      const aClickEvt = function aClickEvt(e) {
        e.preventDefault();
        // Pause player when click on the ad link
        that.player.pause();
        // Trigger non linear click throug
        that.player.trigger({
          type: 'overlayclickthrough',
          overlay: ad,
        });
        // call measurement pause event
        that.player.trigger('pauseclicked');
        window.open(ad.nonlinearClickThroughURLTemplate);
      };
      adLinkElement.onclick = aClickEvt;
    } else {
      adLinkElement = document.createElement('div');
      log(`[overlay] ${ad.id ? `id ${ad.id} ` : ''}type ${ad.type} has not click url`);
    }

    // Set ad link class
    adLinkElement.className = adLinkClass;

    // Set ad image attributes and values
    elemOverlay.src = ad.staticResource;

    log(`[overlay] type ${ad.type}`);
    if (ad.type.indexOf('javascript') !== -1) {
      divAdOverlayWrap.appendChild(elemOverlay);
    } else {
      adLinkElement.appendChild(elemOverlay);
      divAdOverlayWrap.appendChild(adLinkElement);
    }

    divAdOverlayWrap.appendChild(spanClose);
    this.divAdOverlayWrap = divAdOverlay.appendChild(divAdOverlayWrap);
    playerElement.appendChild(divAdOverlay);

    // when player overlay duration is set to infinity skip this timeout
    if (~duration) {
      // eslint-disable-line no-bitwise
      let timeoutDuration = this.formatMinSuggestedDuration(ad.minSuggestedDuration);
      if (!timeoutDuration) {
        timeoutDuration = duration;
      }
      this.adstate.overlayerTimeout = setTimeout(() => {
        that.player.trigger({
          type: 'overlayended',
          overlay: ad,
        });
      }, timeoutDuration * 1000);
    }
    this.player.trigger('overlaystarted');
  }

  /**
   * Register events for the overlayer and append it to the DOM
   *
   * @private
   * @param {object} ad - current ad
   * @param {object} variation - Concrete creative variation
   * @param {Object} duration - duration for the overlay. When -1 player will show ad permanently
   */
  registerOverlayer(ad, creative, variation, duration) {
    // Set that we are currently showing overlay
    this.adstate.currentAdtype = avlAdType.overlay;

    // Register tracking events
    registerOverlayTracking(this.player, ad, creative, variation);

    // When state set to overlay trigger this event
    this.player.trigger({
      type: 'overlaycanplay',
      overlay: variation,
    });

    // Preserve this for the event
    const that = this;

    this.appendOverlayer(variation, duration);

    // Handle ended event
    this.player.one('overlayended', function overlayended() {
      // Clear timeout when exist
      if (this.adstate.overlayerTimeout) {
        clearTimeout(this.adstate.overlayerTimeout);
      }

      that.removeOverlayer();
      // Reset overlay adstate
      this.adstate.currentAdtype = '';
    });
  }

  /**
   * Show appropriate non linear ad
   *
   * @param {Int} adi - Used when break inventory contains more than 1 object
   */
  showAd(adi) {
    // Reset previous cascade
    Cascade.resetShownCascade(this.player, this.adstate.currentAdtype);

    // Select break inventory (this function is defined in the integration plugin)
    // eslint-disable-next-line
    const overlayInventory = getInventory(this.adstate, avlAdType.overlay, adi);
    // Loop through ads
    for (let adIdx = 0, adLen = overlayInventory.ads.length; adIdx < adLen; adIdx++) {
      // Selected ad
      const ad = overlayInventory.ads[adIdx];

      // Loop through creatives
      for (let creaIdx = 0, creaLen = ad.creatives.length; creaIdx < creaLen; creaIdx++) {
        // Selected creative
        const creative = ad.creatives[creaIdx];

        // Loop through variations
        for (let cpIdx = 0, cpLen = creative.variations.length; cpIdx < cpLen; cpIdx++) {
          // Selected non linear ad
          const variation = creative.variations[cpIdx];
          const type = variation.type ? variation.type.toLowerCase() : null;

          if (OVERLAY_SUPPORTED_TYPES.includes(type)) {
            this.registerOverlayer(ad, creative, variation, overlayInventory.duration);
          } else {
            log.warn('[overlay] type of overlay not supported', type);
          }
        }
      }

      if (this.player.options_.plugins.adService.omidManager) {
        this.player.omidManager().initAd({ ad, type: 'nonlinear', slot: this.divAdOverlayWrap });
      }
    }
  }
}

export default AdsOverlay;
