/**
 * <p>Load the script remotely. I.e. Sentry plugin use this</p>
 *
 * @module utils/script-loader
 */

/**
 * Remotely load the script
 *
 * @param {String} url - Script url
 * @param {Function} cb - Callback: function will be binded on the ready event
 */
const scriptLoader = (url, cb) => {
  // Adding the script tag to the head as suggested before
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;

  // There are several events for cross browser compatibility.
  if (cb) {
    script.onreadystatechange = cb;
    script.onload = cb;
  }

  // Fire the loading
  head.appendChild(script);
};

export default scriptLoader;
