const pipInit = (initData) => {
  const { controlBar = {} } = initData;

  return {
    controlBar: {
      ...controlBar,
      pictureInPictureToggle: controlBar.pictureInPictureToggle || false,
    },
  };
};

export default pipInit;
