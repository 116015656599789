import * as Dom from '../../../utils/dom';
import ThumbnailsHolder from './thumbnails-holder';
import LiveVerticalProgressBar from './live-progressbar';
// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;
// Get a component to subclass
const Component = videojs.getComponent('Component');
const Slider = videojs.getComponent('Slider');

/**
 * Live vertical seek Bar and holder for the progress bars
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Slider
 */
class LiveVerticalSeekBar extends Slider {
  constructor(player, options) {
    super(player, options);

    this.horizontalSec = options.horizontalSec;
    this.verticalSec = options.verticalSec;
    this.disabled = options.disabled || false;

    this.addChild(
      new LiveVerticalProgressBar(player, {
        vertical: true,
      }),
    );
    // Maximum seek information
    const maxSeek = document.createElement('div');
    maxSeek.className = 'vert-maximum';
    maxSeek.innerHTML = this.player_.localize('48h ago');
    this.el().appendChild(maxSeek);
    // Renge information displayer before hover
    const vertPromoDiv = document.createElement('div');
    vertPromoDiv.className = 'vert-promo';
    vertPromoDiv.innerHTML = this.player_.localize('- 2 .. 48h');
    this.el().appendChild(vertPromoDiv);

    if ((options.thumbnails || {}).url) {
      this.thumbnailsHolder = new ThumbnailsHolder(player, options);
      this.addChild(this.thumbnailsHolder);
    }

    // Watch events
    this.on(player, 'timeupdate', this.updateProgress);
    this.on(player, 'ended', this.updateProgress);
    this.on(this.el_, 'mousemove', this.handleMouseMove);
    this.on(player, 'ott-seekablelive-seek', this.updateSeekbar);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl(
      'div',
      {
        className: 'vjs-live-vert-progress-holder vjs-progress-holder',
      },
      {
        'aria-label': 'live progress bar',
      },
    );
  }

  /**
   * Update bar progress
   */
  updateProgress() {
    this.updateAriaAttributes(this.el_);
  }

  /**
   * Update ARIA accessibility attributes
   */
  updateAriaAttributes(el) {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    el.setAttribute('aria-valuenow', (this.getPercent() * 100).toFixed(2)); // machine readable value of progress bar (percentage complete)
    el.setAttribute('aria-valuetext', videojs.formatTime(time, this.player_.duration())); // human readable value of progress bar (time complete)
  }

  /**
   * Get percentage of video played
   *
   * @return {Number} Percentage played
   */
  getPercent() {
    const percent = this.player_.currentTime() / this.player_.duration();
    return percent >= 1 ? 1 : percent;
  }

  /**
   * Round the seektime to the closest hour
   *
   * @param {Number} y - y coordinate corresponding to the mouse position
   * @return {Number} Rounded the seektime to the closest hour (in seconds)
   */
  getRoundedSeekTime(y) {
    let seekTime = (this.horizontalSec + this.verticalSec * y).toFixed();
    seekTime = parseInt(seekTime / 3600, 10) * 3600;

    return seekTime;
  }

  /**
   * Handle mouse move on seek bar
   */
  handleMouseMove(event) {
    if (!this.disabled) {
      const position = Dom.getPointerPosition(this.el_, event);
      if (position.y.toFixed(2) < 1) {
        // const seekTime = (this.horizontalSec + (this.verticalSec * (position.y))).toFixed();
        const seekTime = this.getRoundedSeekTime(position.y);

        this.setMousePosition(seekTime, position.y);
        if (this.thumbnailsHolder) {
          this.thumbnailsHolder.updateImage(seekTime);
        }
      }
    }
  }

  /**
   * Handle mouse click on seek bar
   */
  handleClick(event) {
    if (!this.disabled) {
      const position = Dom.getPointerPosition(this.el_, event);
      if (position.y.toFixed(2) < 1) {
        const seekTime = this.getRoundedSeekTime(position.y);
        this.player_.timeshiftSeek(seekTime);
      }
    }
  }

  /**
   * Update seekbar's HTML/CSS based on new seekTime (Y coordinate)
   */
  updateSeekbar(/* event */) {
    // for vertical seekbar this is a noop
  }

  /**
   * Set timeshifted position
   *
   * @param {Number} seekTime  - Timeshift time in seconds
   * @param {Number} yPosition - y coordinate corresponding to the mouse position
   */
  setMousePosition(seekTime, yPosition) {
    this.children_[0].el_.innerHTML = '';
    const cssTriangle = document.createElement('div');
    cssTriangle.className = 'vjs-live-vertical-triangle';
    const timeContent = document.createElement('div');
    timeContent.className = 'vjs-live-time-content';
    timeContent.innerHTML = videojs.formatTime(seekTime).split(':').shift() + this.player_.localize('h ago');
    this.children_[0].el_.appendChild(timeContent);
    this.children_[0].el_.appendChild(cssTriangle);
    this.children_[0].el_.style.height = `${((1 - yPosition) * 100).toFixed()}%`;
  }

  /* Handle mouse down on seek bar */
  handleMouseDown() {}

  /* Handle mouse up on seek bar */
  handleMouseUp() {}

  /* Handle on focus for slider */
  handleFocus() {}

  /* Handle on blur for slider */
  handleBlur() {}

  /* Move more quickly fast forward for keyboard-only users */
  stepForward() {}

  /* Move more quickly rewind for keyboard-only users */
  stepBack() {}
}

LiveVerticalSeekBar.prototype.playerEvent = 'timeupdate';

Component.registerComponent('LiveVerticalSeekBar', LiveVerticalSeekBar);
export default LiveVerticalSeekBar;
