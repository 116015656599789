import Cookies from 'js-cookie';
import { videojs } from 'global/window';
import log from '../../log';
import { getStreamInfo } from '../../utils/source';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * The help button send event for showing customer contact form
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class HelpButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.parent_ = options.parent;
    this.controlEventName = 'ott-help-button-click';
    this.controlText(this.player_.localize('Help button'));
    this.streamInfo = getStreamInfo(player);
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    return `vjs-help-button ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div');
  }

  /**
   * Search mux viewer id from cookies
   */
  getMuxViewerId() {
    const muxCookie = Cookies.get('muxData');

    const muxData = muxCookie
      ? JSON.parse(`{"${decodeURI(muxCookie.replace(/&/g, '","')).replace(/=/g, '":"')}"}`)
      : {};

    if (!muxData.mux_viewer_id) {
      return null;
    }

    return muxData.mux_viewer_id;
  }

  /**
   * Handle click on help button.
   */
  handleClick() {
    if (!this.player_.paused()) {
      this.player_.pause();
    }

    const helpData = {
      currentTime: this.player_.currentTime(),
      videoId: this.streamInfo.videoId || this.streamInfo.programID,
      muxViewerId: this.getMuxViewerId(),
      playerVersion: this.player_.VERSION,
      userAgent: navigator.userAgent,
    };

    this.player_.trigger({
      type: this.controlEventName,
      helpData,
    });

    log(`[help-button plugin] trigger event ${this.controlEventName}`, helpData);
  }
}
// Register the new component
Component.registerComponent('helpButton', HelpButton);
export default HelpButton;
