import { videojs } from 'global/window';

const OptOutButton = videojs.getComponent('OptOutButton');
const Component = videojs.getComponent('Component');

/**
 * Stream language heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class LiveRestartClose extends OptOutButton {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return videojs.dom.createEl('span', {
      className: 'vjs-icon-close-red vjs-liverestart-close',
      innerHTML: '',
    });
  }

  handleClick() {
    const cssClass = 'vjs-liverestart-showing';

    if (this.player_.hasClass(cssClass)) {
      this.player_.removeClass(cssClass);
      this.player_.play();
    }
  }
}

Component.registerComponent('liveRestartClose', LiveRestartClose);
export default LiveRestartClose;
