import { videojs } from 'global/window';
import log from '../../log';
// Get a component to subclass
const Component = videojs.getComponent('Component');
const Button = videojs.getComponent('Button');

/**
 * Will cancel autoplay next control
 */
class CancelButton extends Button {
  constructor(player, options) {
    options.buttonText = player.localize('Cancel autoplay');
    super(player, options);
    this.el_.setAttribute('aria-label', this.options_.buttonText);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-an-cancel vjs-button',
      innerHTML: `<span>${this.options_.buttonText}</span>`,
    });
  }

  handleClick() {
    this.player_.trigger({
      type: 'ott-playnext-reset',
      from: 'button',
    });
    // TODO BPo: Reset the countdown
    this.player_.removeClass(this.options_.autoplayClass);
    log('[autoplay-next] cancel by button');
  }
}

// Register the new component
Component.registerComponent('CancelButton', CancelButton);
export default CancelButton;
