import { videojs } from 'global/window';
import Choices from 'choices.js';

import { qualityMapping } from '../../../../utils/quality-helper';
import log from '../../../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 * The specific menu item type for selecting a video quality
 *
 * @param {Player|Object} player
 * @param {Object=} options
 */
class QualitySelectMenu extends ClickableComponent {
  constructor(player, options = {}) {
    super(player, options);
    this.qualitySelector = options.qualitySelector;

    this.createContent();

    // set auto quality option on UHD switch on
    player.on('ott-uhdprofile-switch', () => {
      this.selectComponent.setChoiceByValue(0);
    });

    // update selected quality label on AUTO quality select (e.g. "AUTO - 720p")
    const { levels_ } = player.qualityLevels();
    player.qualityLevels().on('change', ({ selectedIndex }) => {
      if (levels_.length) {
        const selectedValue = this.selectComponent?.getValue(0);

        if (selectedValue?.value === 0) {
          const { height, bitrate } = levels_?.find((_, index) => index === selectedIndex);
          const selectedQuality = qualityMapping(height, bitrate).menuLabel;

          const selectedValueElement = this.selectComponent.containerInner.element.getElementsByClassName(
            'choices__item choices__item--selectable',
          )[0];
          selectedValueElement.textContent = `${selectedValue.label} - ${selectedQuality}`;
        }
      }
    });
  }

  createEl() {
    return super.createEl('select', {
      className: 'vjs-quality-select',
      id: 'vjs-quality-select',
    });
  }

  createContent() {
    const selectEl = this.el_;

    const { levels_ } = this.player_.qualityLevels();

    if (selectEl) {
      this.player_.ready(() => {
        const sortedQualities = [...levels_]
          .filter((level) => !!level.width)
          .map((item, index) => ({ ...item, index }))
          .sort((a, b) => b.height - a.height);

        if (sortedQualities?.length) {
          this.selectComponent = new Choices(selectEl, {
            searchEnabled: false,
            shouldSort: false,
            choices: [
              {
                value: 0,
                label: this.player_?.localize('Auto'),
              },
              ...sortedQualities.map((quality) => ({
                value: quality.id,
                label: qualityMapping(quality.height, quality.bitrate).menuLabel,
              })),
            ],
          });

          this.selectComponent.passedElement.element.addEventListener('change', (event) => {
            // turn off UHD switch
            if (this.player_.uhdController().uhdActive) {
              this.player_.trigger({
                type: 'ott-uhdprofile-switch',
                enable: false,
              });
            }

            const selectedQualityId = event.detail.value;
            const selectedQualityIndex = levels_
              .sort((a, b) => a.height - b.height)
              .findIndex((quality) => quality.id === selectedQualityId);
            const selectedValue = this.selectComponent.getValue(0);

            // set auto quality
            if (selectedQualityId === 0) {
              this.qualitySelector.setAutoQuality().then(({ selectedIndex }) => {
                const { height, bitrate } = levels_?.find((_, index) => index === selectedIndex);
                const selectedQuality = qualityMapping(height, bitrate).menuLabel;

                // update selected quality label on AUTO quality select (e.g. "AUTO - 720p")
                const selectedValueElement = this.selectComponent.containerInner.element.getElementsByClassName(
                  'choices__item choices__item--selectable',
                )[0];
                selectedValueElement.textContent = `${selectedValue.label} - ${selectedQuality}`;
              });
            } else {
              this.qualitySelector.setQuality(selectedQualityIndex);
            }
            log('[Quality control] option clicked!: ', selectedQualityId === 0 ? 'auto' : selectedQualityId);
          });
        }
      });
    }
  }
}

Component.registerComponent('QualitySelectMenu', QualitySelectMenu);
export default QualitySelectMenu;
