import { videojs } from 'global/window';
import log from '../../../log';
import { adaptiveStreams } from '../../../constants';
import { getTheMostSuitableTracks } from '../../../utils/source-selection';
import getLocalizedSources from '../../../multilingual-match';
import sourceFormatter from '../../../source-formatter';
import { changeTSSource } from '../../../utils/source';
import { defaults } from '../../time-shift/ts-state';
import { LAN_NOT_FOUND } from '../../../errors';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Stream language can be clicked to set different audio stream
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class LanguagesMenuItem extends Button {
  /**
   * Set default classes to the language button
   */
  buildCSSClass() {
    return `vjs-languages-name vjs-button${this.getActiveSourceClass()}`;
  }

  /**
   * When currentSrc matched append 'active' class for it
   */
  getActiveSourceClass() {
    let sourceArray = [];
    switch (this.player_.currentType().toLowerCase()) {
      case adaptiveStreams.dash:
        sourceArray = this.player_.options_.tracks.DASH;
        break;
      case adaptiveStreams.hls:
        sourceArray = this.player_.options_.tracks.HLS;
        break;
    }
    const matchedArray = sourceArray.filter((source) => source.lang === this.options_.srclang);

    if (matchedArray.length > 0 && matchedArray[0].src === this.player_.currentSrc()) {
      return ' active';
    }
    return '';
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span', {
      innerHTML: `${this.options_.label}`,
    });
  }

  /**
   * Handle click on language button. When localized source found play it.
   */
  handleClick() {
    log('[Language control] button clicked!: ', this.options_.srclang);
    this.setActiveClass(this.el_);

    this.player_.trigger({
      type: 'ott-change-language',
      lang: this.options_.srclang,
    });

    if (this.player_.usingPlugin('uhdController')) {
      const uhdController = this.player_.uhdController();
      if (uhdController && uhdController.uhdActive) {
        return;
      }
    }
    const { tracks, preferredTech } = this.player_.options_;
    const tracksPlayableByTheBrowser = getTheMostSuitableTracks(tracks, preferredTech);
    const localizedSources = getLocalizedSources(tracksPlayableByTheBrowser, [this.options_.srclang]);

    if (localizedSources.length > 0) {
      log('[Language control] Player is going to play: ', localizedSources[0]);
      const currentTime = this.player_.currentTime();
      this.player_.pause();
      const { seekTime } = this.player_[defaults.objName] || {};
      changeTSSource(this.player_, sourceFormatter(localizedSources[0]), seekTime);
      this.player_.one(['loadeddata', 'canplay'], () => {
        this.player_.currentTime(currentTime);
        this.player_.play();
        this.player_.trigger({
          type: 'ott-language-changed',
          source: localizedSources[0],
        });
      });

      if (!this.player_.reset.resetData.data.resetStore) {
        this.player_.reset.resetData.data.resetStore = {};
      }
      this.player_.reset.resetData.data.resetStore.lang = this.options_.srclang;
    } else {
      log.error(LAN_NOT_FOUND);
      this.player_.trigger({
        type: 'ott-custom-error',
        ...LAN_NOT_FOUND,
      });
    }
  }

  /**
   * Remove old active classes and set the new one to the current element
   *
   * @param {Object} element - HTML button element
   */
  setActiveClass(element) {
    this.removeOldActiveClasses(element.parentNode);
    element.className += ' active';
  }

  /**
   * Iterate through all language buttons and remove active classes
   *
   * @param {Object} parentElm - Contains all html button elements
   */
  removeOldActiveClasses(parentElm) {
    [].forEach.call(parentElm.childNodes, (btn) => {
      btn.className = btn.className.replace(/\bactive\b/, '');
    });
  }
}
// Register the new component
Component.registerComponent('LanguagesMenuItem', LanguagesMenuItem);
export default LanguagesMenuItem;
