import objectAssign from 'object-assign';
import { videojs, DOMParser, fetch, Image } from 'global/window';
import log from '../../log';
import ProductTitle from './product-title';
import CountdownTimer from './countdown-timer';
import PlayButton from './play-button';
import CancelButton from './cancel-button';
import * as Dom from '../../utils/dom';
import { AUTOPLAY_STATE_CLASS_NAME } from '../../constants';
import { AN_RESPONSE_ERROR } from '../../errors';
import { isPersistAutoplayNext } from './persist-reader';
// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Creates screen wrapper for the autoplay next plugin
 */
class AutoplayScreen extends Component {
  constructor(player, options) {
    // Autoplay state
    options.autoplayClass = AUTOPLAY_STATE_CLASS_NAME;
    super(player, options);
    // Preserve listeners for the dispose
    this.bindListeners();
    this.getPluginState();

    this.autoplaySilent = false;
    this.pluginDisplayed = false;
    this.autoplayRequested = false;
    this.apnInitialized = false;
    this.mode = options.mode || 'same-origin';
    this.credentials = options.credentials || 'same-origin';

    // Register watching events
    this.watchPlayerState();

    this.player_.on('ott-mea-seek', this.listeners.reinitAutoplayNext);
    this.player_.on('ott-playnext-reset', this.listeners.dispose);
    this.player_.on('ott-playnext-reset', this.listeners.restorePostrollSnapshot);
    this.player_.on('ott-playnext-reset', () => {
      // disable autoplay next on cancel button click
      // more info: https://jira.zentity.com/browse/CRASUPP-644
      this.removeAllChildren();
      this.dispose();
    });
    this.player_.one('play', this.listeners.getPostrollSnapshot);

    // create public method that accepts product data
    player.playNextData = (data) => {
      this.showAutoplay(null, data);
    };
  }

  /**
   * Take care of response when some problem occurs
   *
   * @param {Object} error - Represents a problem with fetch operation
   */
  handleResponse(error) {
    this.restorePostrollSnapshot();
    log.error(AN_RESPONSE_ERROR.code, AN_RESPONSE_ERROR.message, error);
  }

  /**
   * Turn off this feature to the next playing event
   */
  onAdPlay() {
    this.persistAutoplayNext = false;

    this.player_.one('playing', this.listeners.onEnableAutoplay);
  }

  /**
   * Turn autoplay on when persist settings if this feature is not turned off in the persist settings.
   */
  onEnableAutoplay() {
    if (isPersistAutoplayNext(this.player_)) {
      this.persistAutoplayNext = true;
    }
  }

  getXmlElementText(node) {
    if (node) {
      let innerText = node.innerHTML;
      if (innerText) {
        return innerText;
      }

      if (node.nodeType === 1) {
        const nodeChildren = node.childNodes;
        innerText = '';

        for (let i = 0; i < nodeChildren.length; i++) {
          innerText += nodeChildren.item(i).nodeValue;
        }
        return innerText;
      }
    }

    return null;
  }

  getPostrollInventory() {
    return ((this.player_.adstate || {}).inventory || {}).postroll;
  }

  /**
   * Store a postroll inventory locally and remove the original one
   */
  getPostrollSnapshot() {
    const snapshot = this.getPostrollInventory();
    if (snapshot) {
      this.postrollSnapshot = {
        ...snapshot,
      };
      snapshot.vasts = [];
    }
  }

  getPluginState() {
    this.persistAutoplayNext = isPersistAutoplayNext(this.player_);
    this.player_.on('ott-answitch-click', this.listeners.updatePluginState);
  }

  updatePluginState(e) {
    this.persistAutoplayNext = e.checked;
  }

  reinitAutoplayNext() {
    if (this.apnInitialized) {
      this.removeAllChildren();
      this.player_.addChild(new AutoplayScreen(this.player_, this.options_));
    }
  }

  bindListeners() {
    this.listeners = {};
    this.listeners.showIfStartReached = this.showIfStartReached.bind(this);
    this.listeners.onAdPlay = this.onAdPlay.bind(this);
    this.listeners.onEnableAutoplay = this.onEnableAutoplay.bind(this);
    this.listeners.dispose = this.dispose.bind(this);
    this.listeners.restorePostrollSnapshot = this.restorePostrollSnapshot.bind(this);
    this.listeners.getPostrollSnapshot = this.getPostrollSnapshot.bind(this);
    this.listeners.updatePluginState = this.updatePluginState.bind(this);
    this.listeners.reinitAutoplayNext = this.reinitAutoplayNext.bind(this);
  }

  /**
   * Restore your inventory to its original state.
   */
  restorePostrollSnapshot() {
    if (this.getPostrollInventory()) {
      this.player_.adstate.inventory.postroll = this.postrollSnapshot;
      if (this.player_.ended()) {
        this.player_.trigger('ott-postroll-force');
      }
    }
  }

  /**
   * Reset plugin state when replay button is fired
   */
  dispose() {
    this.player_.off('ott-playertick', this.listeners.showIfStartReached);
    this.player_.off('adplay', this.listeners.adplay);
    // this.player_.off('playing', this.listeners.playing);
    this.pluginDisplayed = false;
    this.player_.removeClass(this.options_.autoplayClass);
  }

  /**
   * Check if player current time is greater then duration - startSec. If so show autoplay next screen
   */
  showIfStartReached() {
    if (this.player_.playlist) {
      this.persistAutoplayNext = false;
      this.dispose();
    }
    const duration = this.player_.duration();
    const currentTime = this.player_.currentTime();
    const startSecReached = currentTime >= duration - this.options_.startSec;
    const startPercentReached = currentTime / duration >= this.options_.startPercent;

    if (this.persistAutoplayNext && !this.pluginDisplayed && !this.autoplayRequested) {
      if (
        (this.options_.startSec && startSecReached) ||
        (this.options_.startPercent && !this.options_.startSec && startPercentReached)
      ) {
        this.apnInitialized = true;
        if (this.options_.target === 'event-only') {
          this.requestNextData();
        } else {
          this.callAutoplayEndpoint();
        }
      }
    }
  }

  requestNextData() {
    this.player_.trigger('request-play-next-data');
    log('[autoplay-next] request-play-next-data fired');
  }

  /**
   * Register watching events
   */
  watchPlayerState() {
    // one of the triggers is required to set watcher
    if (this.options_.startSec || this.options_.startPercent) {
      this.player_.on('ott-playertick', this.listeners.showIfStartReached);

      // Turn off this feature to the next playing event
      this.player_.on('adplay', this.listeners.onAdPlay);
    } else {
      this.player_.one('ended', () => {
        if (this.persistAutoplayNext) {
          this.callAutoplayEndpoint();
        }
      });
    }
  }

  /**
   * Parse response body as array of product objects
   *
   * @param {String} xmlString - Response body
   * @return {Array.<Object>} Array of parsed product objects when status is OK
   */
  parseNextProducts(xmlString) {
    const dom = new DOMParser().parseFromString(xmlString, 'application/xml');
    const statusArr = Array.prototype.slice.call(dom.getElementsByTagName('status'));
    // IE doesn't support innerHTML property on the XML element so firstChild.nodeValue is essential
    const status =
      statusArr.length > 0 ? statusArr[0].innerHTML || (statusArr[0].firstChild || {}).nodeValue : 'FAILED';
    const products = [];
    if (status === 'OK') {
      const domProducts = Array.prototype.slice.call(dom.getElementsByTagName('product'));
      domProducts.forEach((product) => {
        const urlRedirect = product.getElementsByTagName('urlRedirect')[0];
        const urlInit = product.getElementsByTagName('urlInit')[0];
        const productId = product.getElementsByTagName('productId')[0];
        const titleTag = product.getElementsByTagName('title')[0];
        const subTitleTag = product.getElementsByTagName('subTitle')[0];
        const countdownTitle = product.getElementsByTagName('countdownTitle')[0];
        const subTitleOld = product.getElementsByTagName('sub_title')[0];
        const durationTag = product.getElementsByTagName('duration')[0];
        const thumbnail = product.getElementsByTagName('thumbnail')[0];
        const descriptionTag = product.getElementsByTagName('description')[0];
        const seriesTag = product.getElementsByTagName('series')[0];

        // important data
        products.push({
          urlRedirect: urlRedirect ? this.getXmlElementText(urlRedirect) : '',
          urlInit: urlInit ? this.getXmlElementText(urlInit) : urlInit,
          productId: productId ? this.getXmlElementText(productId) : productId,
          title: titleTag ? this.getXmlElementText(titleTag) : '',
          subTitle: subTitleTag ? this.getXmlElementText(subTitleTag) : '',
          // countdownTitle back compatibility, if not defined, check for old tag, default is {0} to display at least counter
          countdownTitle: countdownTitle
            ? this.getXmlElementText(countdownTitle)
            : subTitleOld
            ? this.getXmlElementText(subTitleOld)
            : '{0}',
          duration: durationTag ? this.getXmlElementText(durationTag) : '',
          thumbnail: thumbnail ? this.getXmlElementText(thumbnail) : '',
          description: descriptionTag ? this.getXmlElementText(descriptionTag) : '',
          series: seriesTag ? this.getXmlElementText(seriesTag) : '',
        });
      });
    }
    return products;
  }

  /**
   * Remove child elements if exist any
   */
  removeAllChildren() {
    if (this.children_.length) {
      this.apnInitialized = false;
      this.el_.remove();
    }
  }

  /**
   * Initialize all sub items of this plugin (like next / cancel button, title..)
   *
   * @param {Array.<Object>} products - Array of parsed product objects
   */
  initSubComponents(product) {
    // Parse xmlString or data as a first thing
    const options = product ? objectAssign(this.options_, product) : this.options_;

    // Remove child elements if exist any
    this.removeAllChildren();

    // Will show countdown timer
    this.countdownTimer = new CountdownTimer(this.player_, options, false);
    this.addChild(this.countdownTimer);

    const wrapper = new Component(this.player_);
    wrapper.el().className = 'flex-row vjs-an-thumb';
    this.addChild(wrapper);

    // Will show product title
    this.productTitle = new ProductTitle(this.player_, options);
    wrapper.addChild(this.productTitle);

    // Will play next product on click
    this.playButton = new PlayButton(this.player_, options);
    wrapper.addChild(this.playButton);

    // will hide autoplay screen
    this.cancelButton = new CancelButton(this.player_, options);
    this.addChild(this.cancelButton);
  }

  /**
   * Missing data so try to initialize silently
   */
  initSilently(product) {
    // Parse xmlString or data as a first thing
    const options = product ? objectAssign(this.options_, product) : this.options_;

    // Remove child elements if exist any
    this.removeAllChildren();

    const wrapper = new Component(this.player_);

    this.countdownTimer = new CountdownTimer(this.player_, options, true);
    wrapper.addChild(this.countdownTimer);
  }

  /**
   * Create the component's DOM element
   */
  createEl() {
    const el = super.createEl('div', {
      className: 'vjs-an-screen',
    });

    this.contentEl_ = Dom.createEl('div', {
      className: 'vjs-an-wrapper flex-col flex-align-end',
    });

    el.appendChild(this.contentEl_);
    return el;
  }

  showAutoplay(xmlString, jsonData) {
    let product = null;
    if (xmlString) {
      [product] = this.parseNextProducts(xmlString);
    } else {
      product = jsonData;
    }

    if (product) {
      const self = this;
      this.player_.trigger('nopostroll');

      const apnStartEvent = {
        type: 'ott-playnext-start',
        autplayNextProduct: product,
      };
      this.player_.trigger(apnStartEvent);

      this.pluginDisplayed = true;

      if (!product.urlRedirect) {
        log('[autoplay-next] No redirect url found');
        return;
      }

      if (!product.thumbnail || !product.title) {
        log('[autoplay-next] Crucial data missing. APN is running silently');
        this.initSilently(product);
        return;
      }

      const thumbnailImg = new Image();

      thumbnailImg.onload = () => {
        if (!this.autoplaySilent) {
          this.initSubComponents(product);
          this.player_.addClass(this.options_.autoplayClass);
        }
      };

      thumbnailImg.onerror = (e) => {
        self.autoplaySilent = true;
        log('[autoplay-next] Could not retrieve thumbnail - APN is running silently', e);
        this.initSilently(product);
      };

      thumbnailImg.src = product.thumbnail;
    } else {
      this.dispose();
      this.restorePostrollSnapshot();
      log.warn('[autoplay-next] No product found');
    }
  }

  /**
   * Call autoplay next endpoint and handle response
   */
  callAutoplayEndpoint() {
    this.autoplayRequested = true;
    this.performRequest(this.options_.url);
  }

  /**
   * Perform an asynchronous HTTP (Ajax) request.
   *
   * @param {String} url - The next product endpoint
   */
  performRequest(url) {
    const fetchOpts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: this.credentials,
      mode: this.mode,
    };

    fetch(url, fetchOpts)
      .then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            this.showAutoplay(text);
          });
        } else {
          this.handleResponse(response.status);
        }
      })
      .catch((error) => this.handleResponse.call(this, error.message));
  }
}
// Register the new component
Component.registerComponent('AutoplayScreen', AutoplayScreen);
export default AutoplayScreen;
