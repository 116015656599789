import { videojs } from 'global/window';
/* eslint-disable */
import OptOutButton from './opt-out-button';
import OptOutOverlay from './opt-out-overlay';
/* eslint-enable */

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Opt out - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class OptOutControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('OptOutButton');
    this.player_.addChild('OptOutOverlay');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-optout-control vjs-settings-item',
    });
  }
}

export default OptOutControl;
