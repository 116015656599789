import { videojs } from 'global/window';
import './quality-menu';
import hideOpenMenus from '../../../utils/hide-menus';
import cancelActiveButtons from '../../../utils/cancel-active-buttons';

// Get a component to subclass
const ClickableComponent = videojs.getComponent('ClickableComponent');
const Component = videojs.getComponent('Component');

/**
 * The subtitles button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityButton extends ClickableComponent {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    this.options = options;

    this.controlText(this.player_.localize('Quality'));
    this.qualityControlOpts = this.player.options_?.responsiveControl?.quality;

    this.player.addChild('QualityMenu', { ...options, qualityControlOpts: this.qualityControlOpts });

    if (this.qualityControlOpts) {
      Object.entries(this.qualityControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'hidden';
          if (entry[0] === 'alone') visibility = 'visible';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }
  }

  /**
   * Default CSS component settings
   */
  buildCSSClass() {
    const visibilityClass = this.player_.qualityLevels().levels_.length ? '' : 'vjs-hidden';
    return `vjs-quality-control-button ${visibilityClass} ${super.buildCSSClass()}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    const selectedQualityTpl =
      '<span id="vjs-control-button-quality-selected" class="vjs-control-button-quality-selected vjs-hidden" />';
    return super.createEl('div', {
      innerHTML: selectedQualityTpl,
    });
  }

  /**
   * Handle click on help button.
   */
  handleClick() {
    const menu = this.player.getChild('QualityMenu');
    if (typeof menu !== 'undefined') {
      if (menu.hasClass('vjs-hidden')) {
        hideOpenMenus(this.player_);
        cancelActiveButtons(this.player_);
        menu.removeClass('vjs-hidden');
        this.addClass('vjs-button-active');
        this.player_.addClass('vjs-popup-displayed');
      } else {
        menu.addClass('vjs-hidden');
        this.removeClass('vjs-button-active');
        this.player_.removeClass('vjs-popup-displayed');
      }
    }
  }
}
// Register the new component
Component.registerComponent('QualityButton', QualityButton);
export default QualityButton;
