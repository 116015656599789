import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language heading
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SeekHintsMenuHeading extends Component {
  constructor(player, options = {}) {
    super(player, options);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-hints-heading',
      innerHTML: this.player_.localize('Chapters'),
    });
  }
}
Component.registerComponent('SeekHintsMenuHeading', SeekHintsMenuHeading);
export default SeekHintsMenuHeading;
