/**
 * <p>Real-time error tracking to the sentry service</p>
 *
 * <h3>Initialization</h3>
 * Initialize this plugin as soon as possible. See the example below to understand how to initialize this plugin.
 *
 * @example
{
  plugins: {
    sentryReports: {
      dsn: 'https://a307d129557b49cd9428dd1ae51934d4@sentry.etnetera.cz/22',
      ravenUrl: 'https://cdn.ravenjs.com/2.2.0/raven.min.js', // Optional
      extra: { // Optional
        userID: 'a123456' // Any key-value pairs you want
      }
  }
}
 *
 * @module plugins/sentryReports
 */
import log from '../log';
import scriptLoader from '../utils/script-loader';

/**
 * Error code messages
 */
const errorMessages = Object.freeze({
  1: 'MEDIA_ERR_ABORTED',
  2: 'MEDIA_ERR_NETWORK',
  3: 'MEDIA_ERR_DECODE',
  4: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
  5: 'MEDIA_ERR_ENCRYPTED',
  adtimeout: 'MEDIA_ERR_ADTIMEOUT',
  unknown: 'MEDIA_ERR_UNKNOWN',
});

/**
 * Player initialization data
 * @typedef PlayerData
 * @type Object
 * @property {String} dsn - Data Source Name (Raven client configuration)
 * @property {Number=} ravenUrl - When set, script will be injected into the page
 * @property {Array.<Object>} extra - Extra informations for the report message.
 * Can contains what you want...
 */

/**
 * Initialize setry reports for the player
 *
 * @param {PlayerData} - Plugin initialization object
 */
const initializeSentry = (player, opts) => {
  /**
   * A function that allows mutation of the data payload right before being sent to Sentry.
   */
  const ravenCallback = (data) => {
    const notFound = 'not found';
    data.extra = data.extra || {};

    // FIXME BPo: Not tested
    // Try to detect adBlock
    let adBlockDetected = false;
    if (typeof window.thatThingDetect !== 'undefined') {
      adBlockDetected = window.thatThingDetect;
    }
    data.extra.adBlockDetected = adBlockDetected;

    // Merge extra data sended to the plugin when exists
    if (opts.extra) {
      Object.assign(data.extra, data.opts);
    }

    data.extra.contentType = 'video';
    // When adstate object exists and ad playing send data about ad
    if (player.adstate && player.adstate.adPlaying) {
      data.extra.contentType = 'ad';
      data.extra.adId = player.adstate.linearAdId || notFound;
    }

    try {
      data.extra.currentTime = player.currentTime();
    } catch (error) {
      data.extra.currentTime = notFound;
    }

    try {
      data.extra.currentType = player.currentType();
    } catch (error) {
      data.extra.currentType = notFound;
    }

    // FIXME BPo: Add 'from' ([cw|embed]) information to the report
    return data;
  };

  /**
   * A callback function that allows you to apply your own filters to determine
   * if the message should be sent to Sentry.
   */
  const shouldSendCallback = (data) => {
    let shouldWe = false;

    // Workaround: Do not send error code 4 for the DASH untill next plugin
    // update. https://github.com/Dash-Industry-Forum/dash.js/issues/1054
    if (data.message === errorMessages['4'] && player.currentType().toLowerCase() === 'application/dash+xml') {
      return shouldWe;
    }

    Object.keys(errorMessages).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(errorMessages, key) && errorMessages[key] === data.message) {
        shouldWe = true;
      }
    });
    return shouldWe;
  };

  try {
    window.Raven.config(opts.dsn, {
      dataCallback: ravenCallback,
      // Disable automatic logging
      shouldSendCallback,
    });
  } catch (e) {
    log.error(e.message);
  }
};

/**
 * Sentry reports plugin initialization
 *
 * @param {PlayerData} opts - Plugin initialization object
 */
export const sentryReportsPlugin = function sentryReportsPlugin(opts) {
  const player = this;

  if (opts.ravenUrl) {
    // Load raven script and then handle
    scriptLoader(opts.ravenUrl, initializeSentry(player, opts));
  } else {
    initializeSentry(player, opts);
  }

  log('sentry plugin initialized');
};
