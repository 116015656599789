import LiveSeekBar from './live-seekbar';
// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;
// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * The Progress Control component contains the time-shift seek bar
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Component
 */
class LiveProgressControl extends Component {
  constructor(player, options) {
    super(player, options);
    this.name_ = 'LiveProgressControl';
    this.addChild(new LiveSeekBar(player, options));
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-live-progress-control vjs-progress-control vjs-control',
    });
  }
}

Component.registerComponent('LiveProgressControl', LiveProgressControl);
export default LiveProgressControl;
