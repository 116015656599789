// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;
const Component = videojs.getComponent('Component');

/**
 * Shows play progress
 *
 * @param {Object} player - The player object
 * @param {Object=} options - An optional options object
 * @extends Component
 */
class LiveVerticalProgressBar extends Component {
  constructor(player, options) {
    super(player, options);
    this.updateDataAttr();
    this.on(player, 'timeupdate', this.updateDataAttr);

    // this is just CSS thing... (triangle on the right site of the progress bar)
    const cssTriangle = document.createElement('div');
    cssTriangle.className = 'vjs-live-vertical-triangle';
    this.el().appendChild(cssTriangle);
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-play-progress vjs-live-vertical-bar vjs-slider-bar',
      innerHTML: `<span class="vjs-control-text"><span>${this.localize('Progress')}</span>: 0%</span>`,
    });
  }

  updateDataAttr() {
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    this.el_.setAttribute('data-current-time', videojs.formatTime(time, this.player_.duration()));
  }
}

Component.registerComponent('LiveVerticalProgressBar', LiveVerticalProgressBar);
export default LiveVerticalProgressBar;
