import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

class PlaylistButtonNext extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlTextEl_.innerHTML = this.player_.localize('Next');
  }

  buildCSSClass() {
    return 'vjs-playlist-buttons vjs-playlist-next vjs-control vjs-button';
  }

  handleClick() {
    this.player_.playlist.changePlaylistSourceNext();
  }
}

// ControlBar.prototype.options_.children.push('PlaylistButtonNext');
Component.registerComponent('PlaylistButtonNext', PlaylistButtonNext);
export default PlaylistButtonNext;
