import { videojs } from 'global/window';
import log from '../../log';
import { createJinglePlayer } from './csai-jingle-player';
import { CsaiEvents } from './csai-events';

const Plugin = videojs.getPlugin('plugin');

const EVENT_LOADED_PLAYLIST = 'loadedplaylist';
const EVENT_CUE_CHANGE = 'cuechange';

const defaultCsaiState = {
  csaiAlreadyStartedForThisLiveAdBlock: false,
  originalAdLength: undefined,
  csaiRunning: false,
};

export class CSAI extends Plugin {
  adRelevantSegments = new Map();

  csaiState = defaultCsaiState;

  constructor(player, options) {
    super(player, options);
    this.player = player;
    this.options = options;

    // the play() have to be called manually in case of autoplay failure
    // because of some issues with overrideNative that is enabled for CSAI
    // that causes player not to start playing when clicked on play
    // player only triggers 'play' event
    if (videojs.browser.IS_SAFARI) {
      this.player.one('play', () => {
        this.player.play();
      });
    }

    this.player.on('playing', () => this.listenForAdRelevantSegments());

    player.ready(() => this.handleRetry());
  }

  handleRetry() {
    const player = this.player;
    // https://github.com/videojs/video.js/issues/7232
    // remove csai and return player back to live if player tries to fetch playlist segment repeatedly
    // e.g. on 401 error response
    player.tech().on('retryplaylist', () => {
      this.resetCsaiState();
      player.trigger('adended');
      player.trigger(CsaiEvents.CSAI_NO_TIME_FOR_JINGLE);
      log('[csai-adservice] Player tries to fetch playlist segment repeatedly, csai canceled');
    });
  }

  resetCsaiState() {
    this.csaiState = defaultCsaiState;
  }

  initializeCsai(csaiJingle) {
    this.csaiJingle = csaiJingle;

    this.player.textTracks().on('addtrack', (e) => {
      if (e.track.label === 'segment-metadata') {
        e.track.one(EVENT_CUE_CHANGE, () => {
          this.masterPlaylistLoader_ = this.player.tech_.vhs.masterPlaylistController_.masterPlaylistLoader_;
          this.checkSegmentsForAdTagsAndSaveThem();
          this.listenForAdRelevantSegments();
          this.checkCurrentSegmentMetadataAgainstAdRelevantSegments();
        });
      }
    });
  }

  checkSegmentsForAdTagsAndSaveThem() {
    this.masterPlaylistLoader_.media_.segments.forEach((segment) => {
      if (segment.cueOut || segment.cueOutCont) {
        this.adRelevantSegments.set(segment.resolvedUri, segment);
      }
    });
  }

  listenForAdRelevantSegments() {
    this.masterPlaylistLoader_?.on(EVENT_LOADED_PLAYLIST, () => this.checkSegmentsForAdTagsAndSaveThem());
  }

  checkCurrentSegmentMetadataAgainstAdRelevantSegments() {
    this.player.textTracks()?.tracks_?.forEach((track) => {
      if (track?.label === 'segment-metadata') {
        track.on('cuechange', () => {
          if (track.activeCues_.length > 0) {
            this.checkSegmentsForAdTagsAndSaveThem();

            const relevantSegment = this.adRelevantSegments.get(track.activeCues_[0].value.uri);
            const { cueOutCont, cueOut } = relevantSegment || {};
            let adLength = 0;

            if (cueOut) {
              // cueOut can be in format of "DURATION=360" (#EXT-X-CUE-OUT:DURATION=360) or "360" ( #EXT-X-CUE-OUT:360)
              const duration = cueOut.includes('DURATION') ? Number(cueOut.split('=')[1]) : Number(cueOut);
              if (!Number.isNaN(duration)) {
                adLength = duration;
              }
            } else if (cueOutCont) {
              // cueOutCont should have format of "4/360" (#EXT-X-CUE-OUT-CONT:10/30)
              const [adOffset, adTotal] = cueOutCont.split('/').map((time) => Number(time));

              const remainingAdLength = adTotal - adOffset;
              adLength = remainingAdLength;
            }

            if (adLength) {
              this.handleLiveAdStart(adLength);
            }
          }
        });
      }
    });
  }

  // get adLength and tell the player that csai ad should be played and give it info about the length of the ad
  handleLiveAdStart(adLength) {
    if (this.csaiState.csaiAlreadyStartedForThisLiveAdBlock) {
      log(`[csai-adservice] ${new Date().toLocaleTimeString()} csai ad is already running`, adLength);
      return;
    }

    log(`[csai-adservice] Ad should start right now and it should be ~${adLength}s long`);

    if (this.csaiJingle) {
      createJinglePlayer(this.player, this.csaiJingle);
    }

    this.player.trigger({
      type: CsaiEvents.CSAI_LIVE_AD_STARTED,
      adLength,
    });

    this.adRelevantSegments.clear();

    this.csaiState = {
      csaiAlreadyStartedForThisLiveAdBlock: true,
      liveAdBlockOriginalLength: adLength,
    };

    setTimeout(() => {
      log('[csai-adservice] ad should be over right now, resetting csai state');
      this.resetCsaiState();
      // the 4 seconds (~chung length) are there so the ad is not started again at the very end of the live ad
    }, (adLength + 4) * 1000);

    if (this.csaiJingle) {
      setTimeout(() => {
        this.player.trigger(CsaiEvents.CSAI_NO_TIME_FOR_JINGLE);
        log('[csai-adservice] not enough time for jingle now');
      }, (adLength - this.csaiJingle.lengthS) * 1000);
    }
  }
}
