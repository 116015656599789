/**
 * Application error messages
 * @module errors
 */

/**
 * Custom error event, fired, when player instance is ready.
 *
 * @event OttPlayer#ott-custom-error
 * @property {"ott-custom-error"} type
 * @property {number} code          - code of the error
 * @property {string} message       - message
 */

/**
 * Any preferred or original language matched. Player is goint to play
 * first stream in sequence
 * code: 102001
 * module: multilingual-match.js
 */
export const PR_NO_LOCALIZED_SRC = {
  message: 'Any localized source not found',
  code: 102001,
};

/**
 * preferredLang argument found but it's not an array
 * code: 102002
 * module: multilingual-match.js
 */
export const PR_LANG_ARE_NOT_ARRAY = {
  message: "preferredLang argument found but it's not an array. Requested value: ",
  code: 102002,
};

/**
 * Global video.js object not found. Are you sure a vjs-pack.js is included?
 * code: 103001
 * module: play-source.js
 */
export const PR_VJS_NOT_FOUND = {
  message: 'Videojs object not found. Make sure that the vjs-pack.js file is included in the page',
  code: 103001,
};

/**
 * Session transfer failed.
 * code: 103003
 * module: play-source.js
 */
export const PR_TRANSFER_FAILED = {
  message: 'Fetch to session transfer failed.',
  code: 103003,
};

/**
 * Player recieved time as different value than number
 * code: 104001
 * module: continue-watching.js
 */
export const CW_ARGS_NUMBER_CHECK = {
  message: 'Continue watching time should be a number. Requested value: ',
  code: 104001,
};

/**
 * Passed sources argument must be an object with original, matched and localized arrays.
 * code: 1054001
 * module: playback-validation.js
 */
export const PV_ARGS_SOURCES_INVALID = {
  message: 'Passed sources argument must be an object with original, matched and localized arrays.',
  code: 105001,
};

/**
 * No input source found. This occurs when initialization object with stream is empty or doesn't exist
 * code: 1054002
 * module: playback-validation.js
 */
export const PV_SOURCES_ORIGINAL_INVALID = {
  message: 'No input source found.',
  code: 105002,
};

/**
 * No matched source found. This occurs when browser doesn't have support for adaptive streaming technology or DRM
 * code: 1054003
 * module: playback-validation.js
 */
export const PV_SOURCES_MATCHED_INVALID = {
  message: 'No matched source found.',
  code: 105003,
};

/**
 * Localized sources array is empty or invalid
 * code: 1054004
 * module: playback-validation.js
 */
export const PV_SOURCES_LOCALIZED_INVALID = {
  message: 'Localized sources array is empty or invalid',
  code: 105004,
};

/**
 * Beat class constructor obtain non numeric arguments
 * code: 201001
 * module: plugins/heartbeats.js
 */
export const HB_ARGS_NUMBER_CHECK = {
  message: 'The value should be a number.',
  code: 201001,
};

/**
 * Perform request failed
 * code: 201002
 * module: plugins/heartbeats.js
 */
export const HB_PERFORM_REQUEST = {
  message: '',
  code: 201002,
};

/**
 * Heartbeat response returned 404
 * code: 201003
 * module: plugins/heartbeats.js
 */
export const HB_RESPONSE_404 = {
  message: 'Heartbeat response 404',
  code: 201003,
};

/**
 * Heartbeat response returned 403
 * code: 201004
 * module: plugins/heartbeats.js
 */
export const HB_RESPONSE_403 = {
  message: 'Heartbeat response 403',
  code: 201004,
};

/**
 * The playedTicks plugin not found in the init data object
 * code: 201005
 * module: plugins/heartbeats.js
 */
export const HB_PLUGIN = {
  message: 'The playedTicks plugin is required for the heartBeat plugin.',
  code: 201005,
};

/**
 * Seek time is not a number
 * code: 202001
 * module: utils/source.js
 */
export const TS_NAN_SOURCE_CHANGE = {
  message: 'The value should be a number.',
  code: 202001,
};

/**
 * Passed argument is undefined or not valid URL
 * code: 202002
 * module: utils/source.js
 */
export const TS_SRC_INVALID = {
  message: 'Player source cannot be undefined.',
  code: 202002,
};

/**
 * Argument of `timeshiftSeek` method must be a number.
 * code: 202004
 * module: plugins/time-shift.js
 */
export const TS_SEEK_INVALID_ARG = {
  message: 'Argument of `timeshiftSeek` method must be a number. Provided value: ',
  code: 204004,
};

/**
 * The timeShift plugin not found in the init data object.
 * code: 204005
 * module: plugins/live-restart-button.js
 */
export const LR_PLUGIN = {
  message: 'The timeShift plugin is required for the liveRestart plugin.',
  code: 204005,
};

/**
 * Language not matched any source in source array
 * code: 203001
 * module: plugins/settings/languages/Language-button.js
 */
export const LAN_NOT_FOUND = {
  message: 'Stream with this language not found.',
  code: 203001,
};

/**
 * Url query parameter is undefined
 * code: 204001
 * module: plugins/time-shift.js
 */
export const TS_ARGS_URLPAR_CHECK = {
  message: 'URL query parameter cannot be undefined.',
  code: 204001,
};

/**
 * Horizontal time-shift value is not finite number
 * code: 204002
 * module: plugins/time-shift.js
 */
export const TS_ARGS_H_NUMBER_CHECK = {
  message: 'Horizontal time-shift value is not finite number. Requested value: ',
  code: 204002,
};

/**
 * Vertical time-shift value is not finite number
 * code: 204003
 * module: plugins/time-shift.js
 */
export const TS_ARGS_V_NUMBER_CHECK = {
  message: 'Vertical time-shift value is not finite number. Requested value: ',
  code: 204003,
};

/**
 * Measuring plugin is initialized but any stream infp found. Stream info value is undefined.
 * code: 205001
 * module: plugins/measuring.js
 */
export const MEA_ARGS_1 = {
  message: 'Stream info cannot be undefined.',
  code: 205001,
};

/**
 * Measuring plugin is initialized but any service found. Services value is undefined.
 * code: 205002
 * module: plugins/measuring.js
 */
export const MEA_ARGS_2 = {
  message: 'Measuring plugin is initialized but any service found.',
  code: 205002,
};

/**
 * The playedTicks plugin not found in the init data object.
 * code: 205003
 * module: plugins/measuring.js
 */
export const MEA_PLUGIN = {
  message: 'The playedTicks plugin is required for the measuring plugin.',
  code: 205003,
};

/**
 * GemiusPlayer global class is missing (Gemius script must be externally loaded)
 * code: 205004
 * module: plugins/measuring/gemius.js
 */
export const MEA_GEMIUS_MISSING = {
  message: 'Gemius measuring service enabled but Gemius external JavaScript file is missing.',
  code: 205004,
};

/**
 * Gemius configuration object is incorrect (probably some mandatory property is missing)
 * code: 205005
 * module: plugins/measuring/gemius.js
 */
export const MEA_GEMIUS_INVALID_CONFIGURATION = {
  message: 'Gemius initialization configuration is invalid. Missing argument: ',
  code: 205005,
};

/**
 * Program info is missing for Gemius
 * code: 205006
 * module: plugins/measuring/gemius.js
 */
export const MEA_GEMIUS_INVALID_PROGRAM_INFO = {
  message: 'Some mandatory property is not present in stream info. Missing property: ',
  code: 205006,
};

/**
 * Ad info is missing for Gemius
 * code: 205007
 * module: plugins/measuring/gemius.js
 */
export const MEA_GEMIUS_INVALID_AD_INFO = {
  message: 'Some mandatory property is not present in ad info. Missing property: ',
  code: 205007,
};

/**
 * Nielsen plugin is initialized but the global measuring nielsen object id undefined. Probably the Nielsen snippet is missing.
 * code: 205008
 * module: plugins/measuring/nielsen.js
 */
export const MEA_NIELSEN_MISSING = {
  message:
    'Nielsenn Digital Content Ratings plugin is enabled but the global nielsen (NOLBUNDLE) object not found. Are you sure the Nielsen snippet was attached to the head?',
  code: 205008,
};

/**
 * Nielsen configuration object is incorrect (probably some mandatory property is missing)
 * code: 205009
 * module: plugins/measuring/nielsen.js
 */
export const MEA_NIELSEN_CONFIGURATION = {
  message: 'Nielsen initialization configuration is invalid. Missing argument: ',
  code: 205009,
};

/**
 * Missing property_key for MUX service.
 * code: 205010
 * module: plugins/measuring/nielsen.js
 */
export const MEA_MUX_NO_KEY = {
  message: 'Missing property_key for MUX service.',
  code: 205010,
};

/**
 * The playedTicks plugin not found in the init data object.
 * code: 205011
 * module: plugins/product-placement/component.js
 */
export const PP_PLUGIN = {
  message: 'The playedTicks plugin is required for the product placement plugin.',
  code: 205011,
};

/**
 * Autoplay next plugin is not initialized because options are not valid. Target should contains 'event-only' or 'location'
 * value only. URL should be defined as well when 'location' value is preset.
 * code: 206001
 * module: plugins/autoplay-next.js
 */
export const AN_ARGS_1 = {
  message: 'Autoplay-next plugin is not initialized. Some mandatory fields are not valid or missing.',
  code: 206001,
};
/**
 * Autoplay next try to perform request failed
 * code: 206002
 * module: plugins/autoplay-next.js
 */
export const AN_PERFORM_REQUEST = {
  message: 'Autoplay-next request to the URL failed. Fetch error message: ',
  code: 206002,
};

/**
 * Autoplay next response returned error code
 * code: 206003
 * module: plugins/autoplay-next.js
 */
export const AN_RESPONSE_ERROR = {
  message: 'Autoplay-next response code: ',
  code: 206003,
};

/**
 * Ad service doesn't found any preroll vasts in the initalization data
 * code: 207001
 * module: plugins/adservice/shared/preferred-format.js
 */
export const AS_FORMAT_ATR_NOT_FOUND = {
  message: 'Preferred format cannot be undefined or different type than String.',
  code: 207001,
};

/**
 * Ad service tried to check if ad button is enabled, but no button was found
 * code: 207002
 * module: plugins/adservice/linear.js
 */
export const AS_AD_BUTTON_NOT_FOUND = {
  message: "Ad button doesn't exist in the player element.",
  code: 207002,
};

/**
 * Ad service tried to reinit VPAID client request which is disposed already (ad was showed probably or timeouted).
 * code: 207003
 * module: plugins/adservice/vpaid/client.js
 */
export const AS_CLIENT_DISPOSED = {
  message: 'VPAID client is disposed already.',
  code: 207003,
};

/**
 * VPAID ad returned in the friendly iframe probably. But some of required functions on the ad object are missing. Ad is not fully complaint with VPAD specification.
 * code: 207004
 * module: plugins/adservice/vpaid/client.js
 */
export const AS_VPAID_IS_NOT_VALID = {
  message: 'VPAID is not valid. Some of required functions are not set on the VPAID object.',
  code: 207004,
};

/**
 * Iframe contentWindow doesn't exist. Iframe is probably removed from the DOM
 * code: 207005
 * module: plugins/adservice/vpaid/client.js
 */
export const AS_VPAID_IFRAME_REMOVED = {
  message: 'VPAID iframe is not in the DOM probably',
  code: 207005,
};

/**
 * VPAID iframe has contentWindow member but getVPAIDAd is not a function. VPAID requesting failed probably..
 * code: 207006
 * module: plugins/adservice/vpaid/client.js
 */
export const AS_VPAID_GET_NOT_FOUND = {
  message: "VPAID iframe didn' return getVPAIDAd function",
  code: 207006,
};

/**
 * Loading ad cannot be executed. Creating AdUnit reference probably failed during creative validation (Ad is not fully complaint with VPAD specification).
 * code: 207007
 * module: plugins/adservice/vpaid/adunit.js
 */
export const AS_VPAID_CREATIVE_NOT_FOUND = {
  message: 'VPAID creative is missing. Please, check if the VPAID is valid!',
  code: 207007,
};

/**
 * Set volume to the VPAID ad failed (getAdVolume returned different value).
 * code: 207008
 * module: plugins/adservice/vpaid/adunit.js
 */
export const AS_SET_VOLUME = {
  message: 'VPAID failed to apply volume',
  code: 207008,
};

/**
 * DMVAST is undefined in the global scope, is the vjs-pack linked properly
 * code: 207009
 * module: plugins/adservice/shared/vast-tracker.js
 */
export const AS_TRACKING_NOT_FOUND = {
  message: 'VPAID tracking module not found',
  code: 207009,
};

/**
 * An error occured during linear ad break
 * code: 207010
 * module: plugins/adservice.js
 */
export const AS_ADERROR_EVENT_FIRED = {
  message: 'An error occurred during ad.',
  code: 207010,
};

/**
 * An error occured during linear ad break. 208011 error occurs when DASH mediaPlayer instace fire download error (like CORS, 404, ...)
 * code: 208011
 * module: plugins/adservice.js
 */
export const AS_DASH_ADERROR_EVENT_FIRED = {
  message: 'An DASH error occurred during ad.',
  code: 208011,
};

/**
 * getEmptyObject was called with undefined parameter
 * code: 208012
 * module: plugins/adservice/cascade.js
 */
export const AS_COMPANION_EMPTY_OBJECT = {
  message: 'Empty object initialization failed. Ad cannot be null.',
  code: 208012,
};

/**
 * Companion object should contain time or period property
 * code: 208013
 * module: plugins/adservice/cascade.js
 */
export const AS_COMPANION_FIELDS_MISSING = {
  message: 'Companion time or period is missing!',
  code: 208013,
};
