import window from 'global/window';
import { getQueryParams } from './utils/url';
import { LOG_QUERY_PARAM_NAME } from './constants';

/**
 * Logging module
 * @module log
 */

/**
 * Define the logging level<br>
 * disabled - logging disabled<br>
 * low - only console.error will be shown<br>
 * medium - console.error and warn will be shown<br>
 * maximum - every log event will be shown<br>
 * @readonly
 * @enum {Number}
 */
const logLevel = Object.freeze({
  disabled: 0,
  low: 1,
  medium: 2,
  maximum: 3,
  automatic: 99,
});

/**
 * Check if url has enabled logging parameter
 *
 * @return {Boolean} True when logging query parameter is set to '1'
 */
export const isLoggingEnabled = function isLoggingEnabled() {
  const qs = ((window.document || {}).location || {}).search || ''; // Node.js hack
  const qp = getQueryParams(qs)[LOG_QUERY_PARAM_NAME];

  // eslint-disable-next-line
  return (typeof qp !== 'undefined' && qp == 1) || window.automaticPlayerReport ? true : false;
};

/**
 * Returns logging level
 *
 * @param {Number} [level=0] - logLevel number. Logging is disabled by default
 * @return {Number} Value from the logLevel enum
 */
function getLogLevel() {
  let level = logLevel.disabled;
  // TODO: We are using only maximum or disabled logging level now
  if (log.debug) {
    // When debug is set use it before query parameter check
    level = log.debug;
  } else if (isLoggingEnabled() && !window.automaticPlayerReport) {
    // set debug to true (singleton)
    level = log.debug = logLevel.maximum;
  } else if (window.automaticPlayerReport) {
    level = logLevel.automatic;
  }

  return level;
}

/**
 * Log messages to the console and history based on the type of message
 *
 * @param  {String} type The type of message, or `null` for `log`
 * @param  {Object} args The args to be passed to the log
 */
function logType(type, args) {
  const level = getLogLevel();

  // convert args to an array to get array functions
  const argsArray = Array.prototype.slice.call(args);
  // if there's no console then don't try to output messages
  // they will still be stored in log.history
  // Was setting these once outside of this function, but containing them
  // in the function makes it easier to test cases where console doesn't exist
  const noop = function noop() {};

  const console = window.console || {
    log: noop,
    warn: noop,
    error: noop,
  };

  if (type) {
    // add the type to the front of the message
    argsArray.unshift(`${type.toUpperCase()}:`);
  } else {
    // default to log with no prefix
    type = 'log';
  }

  // add to history everything
  log.history.push({
    created: new Date().getTime(),
    date: `${new Date().toUTCString()} | ${new Date().getTime()}`,
    type,
    args: argsArray.slice(),
  });

  // log to the console only if log level is > than some volue
  switch (type) {
    case 'error':
      if (level === 0) {
        return;
      }
      break;
    case 'warn':
      if (level < 2) {
        return;
      }
      break;
    case 'log':
      if (level < 3) {
        return;
      }
      break;
    default:
      return;
  }

  // add console prefix after adding to history
  argsArray.unshift('OTT-player:');

  // call appropriate log function
  /* eslint-disable */
  // disable eslint console warn, settings is up to us
  if (level !== 99) {
    if (console[type].apply) {
      console[type].apply(console, argsArray);
    } else {
      // ie8 doesn't allow error.apply, but it will just join() the array anyway
      console[type](argsArray.join(' '));
    }
  }
  /* eslint-enable */
}

/**
 * Log plain debug messages
 */
const log = function log(...args) {
  logType(null, args);
};

/**
 * Debug is disabled by default
 */
log.debug = false;

/**
 * Keep a history of log messages
 * @type {Array}
 * @method history
 */
log.history = [];

/**
 * Log error messages
 * @method error
 */
log.error = function error(...args) {
  logType('error', args);
};

/**
 * Log warning messages
 * @method warn
 */
log.warn = function warn(...args) {
  logType('warn', args);
};

export default log;
