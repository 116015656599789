/**
 * <p>This is a timeshift sub-plugin. Displays thumbnail images over the progress bar.</p>
 *
 * @example
{
  plugins: {
    timeShift: {
      liveThumbnails: {
        url: 'www.example.com/image.jpg'
      }
      // ...
    }
  }
}
 *
 * @module plugins/timeShift/liveThumbnails
 */

// No ES6 import availabale. Videojs is bundled separately
const { videojs } = window;

// Get a component to subclass
const Component = videojs.getComponent('Component');

class ThumbnailsHolder extends Component {
  /**
   * Round and format T - seekTime as ZULU
   *
   * @param {Number} seekTime - current position on the seekbar in seconds
   * @return {String} Rounded and formatted date in ZULU (ISO date)
   */
  getFormattedDate(seekTime) {
    seekTime *= 1000;
    const date = new Date(Date.now() - seekTime);
    date.setMilliseconds(0);
    date.setSeconds(0);

    return date.toISOString();
  }

  /**
   * Change the thumbnail image source
   *
   * @param {Number} seekTime - current position on the seekbar in seconds
   */
  updateImage(seekTime) {
    this.el_.getElementsByTagName('img')[0].src = `${this.options_.thumbnails.url}?time=${this.getFormattedDate(
      seekTime,
    )}`;
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    // ${this.localize('Progress')}
    return super.createEl('div', {
      className: 'ott-thumb-vert-holder vjs-thumbnail-holder',
      innerHTML: `
           <div class="vjs-thumbnail-wrapper">
              <img src="" class="vjs-thumbnail">
              <p class="vjs-thumbnail-time"></p>
           </div>`,
    });
  }
}
// Register the new component
Component.registerComponent('ThumbnailsHolder', ThumbnailsHolder);
export default ThumbnailsHolder;
