import { videojs } from 'global/window';
import log from '../../../log';
import { qualityMapping } from '../../../utils/quality-helper';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class QualityMenuItem extends Button {
  constructor(player, options) {
    super(player, options);
    this.qualitySelector = options.qualitySelector;

    player.on('ott-uhdprofile-switch', ({ enable }) => {
      if (enable) {
        this.removeOldActiveClasses(this.el_.parentNode);
      }
    });
  }

  /**
   * Set default classes to the quality button
   */
  buildCSSClass() {
    return 'vjs-quality-name vjs-button';
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    const { quality } = this.options_;
    const { menuLabel } = qualityMapping(quality.height, quality.bitrate);
    return super.createEl('span', {
      id: quality.index,
      innerHTML: quality.name || menuLabel,
    });
  }

  /**
   * Handle click on quality button. When localized source found play it.
   */
  handleClick() {
    const { qualities, quality } = this.options_;
    const autoButton = this.player_
      .getChild('QualityMenu')
      .getChild('QualityAutoButtonWrapper')
      .getChild('QualityAutoButton');

    // turn off UHD switch
    if (this.player_.uhdController().uhdActive) {
      this.player_.trigger({
        type: 'ott-uhdprofile-switch',
        enable: false,
      });
      autoButton.addClass('active');
    } else {
      autoButton.removeClass('active');
    }

    log('[Quality control] button clicked!: ', quality.id);
    this.setActiveClass(this.el_);

    const selectedQualityId = quality.id;
    const selectedQualityIndex = [...qualities]
      .sort((a, b) => a.height - b.height)
      .findIndex((quality) => quality.id === selectedQualityId);

    this.qualitySelector.setQuality(selectedQualityIndex);

    const { height, bitrate } = [...qualities].find((quality) => quality.id === selectedQualityId);
    const selectedQualityEl = document.getElementById('vjs-control-button-quality-selected');
    const selectedQualityTpl = `<span>${qualityMapping(height, bitrate).controlLabel}</span>`;
    selectedQualityEl.innerHTML = selectedQualityTpl;
    if (!qualityMapping(height, bitrate).controlLabel) {
      selectedQualityEl.classList.add('vjs-hidden');
    } else {
      selectedQualityEl.classList.remove('vjs-hidden');
    }
  }

  /**
   * Remove old active classes and set the new one to the current element
   *
   * @param {Object} element - HTML button element
   */
  setActiveClass(element) {
    this.removeOldActiveClasses(element.parentNode);
    if (!element.classList.contains('active')) {
      element.classList.add('active');
    }
  }

  /**
   * Iterate through all quality buttons and remove active classes
   *
   * @param {Object} parentElm - Contains all html button elements
   */
  removeOldActiveClasses(parentElm) {
    [].forEach.call(parentElm.childNodes, (btn) => {
      btn.className = btn.className.replace(/\bactive\b/, '');
    });
  }
}

// Register the new component
Component.registerComponent('QualityMenuItem', QualityMenuItem);
export default QualityMenuItem;
