import log from '../../../log';

export default class DOMController {
  constructor(player) {
    this.player = player;

    this.mainPlayerVideoEl = this.player.tech().el();

    this.elements = {
      mainPlayerVideoEl: this.mainPlayerVideoEl,
      adsPlayerContainerEl: this.createAdsPlayerContainerEl.call(this),
      adsPlayerVideoEl: this.createVideoEl.call(this),
    };
  }

  getElements() {
    return this.elements;
  }

  /**
   * appendElementsIntoMainPlayer
   */
  appendElementsIntoMainPlayer() {
    const { adsPlayerContainerEl, adsPlayerVideoEl } = this.elements;
    // add vpaid player to DOM container
    adsPlayerContainerEl.appendChild(adsPlayerVideoEl);
    this.player.el().appendChild(adsPlayerContainerEl);
    log(
      `[adsPlayer] [domctrl] elements appended into main player. Ads video elem id: ${adsPlayerVideoEl.id}`,
      this.elements,
    );
  }

  // TODO desc
  createAdsPlayerContainerEl() {
    const adsPlayerContainer = document.createElement('div');
    adsPlayerContainer.id = 'adsPlayerContainer';

    return adsPlayerContainer;
  }

  /**
   * Method reate separate video element for vpaid ads play
   * @returns html video element
   */
  createVideoEl() {
    const videoElement = document.createElement('video');

    videoElement.id = `adsPlayer-${Date.now()}`; // make unique id for player - for the ability to play each ad separately
    videoElement.classList.add('adsPlayer');
    videoElement.setAttribute('muted', 'muted');
    videoElement.setAttribute('preload', 'auto');
    videoElement.setAttribute('tabindex', '-1');
    videoElement.setAttribute('playsinline', 'playsinline');

    videoElement.width = this.mainPlayerVideoEl.offsetWidth;
    videoElement.height = this.mainPlayerVideoEl.offsetHeight;

    return videoElement;
  }
}
