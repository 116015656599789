import { videojs } from 'global/window';
// eslint-disable-next-line no-unused-vars
import OptOutClose from './opt-out-close';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class OptOutOverlay extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('OptOutClose');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    this.contentEl_ = super.createEl('div', {
      className: 'vjs-optout-overlay',
      innerHTML: `<div class="vjs-optout-overlay-inner">
          <div class="vjs-icon-info">
          </div>
          <div class="vjs-optout-overlay-text">
            <p class="dialog-title">${this.player_.localize('Nielsen Measurement')}</p>
            <p class="mobile-only">
              ${this.player_.localize(
                'This application includes Nielsen Digital Measurement software. For more information or for deactivation continue to <a target="_blank" href="http://cdn-gl.imrworldwide.com/priv/browser/cz/cs/optout.html">Nielsen Digital Measurement</a>',
              )}
            </p>
            <p>
              ${this.player_.localize(
                'This application includes Nielsen Digital Measurement software, which allows users to contribute to market research.',
              )}
            </p>
            <p>
              ${this.player_.localize(
                "If you want to know more about data which can be Nielsen software gathering and about your possibilities of deactivating the measurement, read please rules of personal information protection from <a target='_blank' href='http://cdn-gl.imrworldwide.com/priv/browser/cz/cs/optout.html'>Nielsen Digital Measurement</a>.",
              )}
            </p>
          </div>
        </div>`,
    });

    return this.contentEl_;
  }
}

Component.registerComponent('OptOutOverlay', OptOutOverlay);
export default OptOutOverlay;
