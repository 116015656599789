import { videojs } from 'global/window';
import { tryGetNext } from './validity';
import { truncate } from '../../utils/dom';

// Get a component to subclass
const ClickableComponent = videojs.getComponent('ClickableComponent');

/**
 * Creates screen wrapper for the autoplay next plugin
 */
class ProductTitle extends ClickableComponent {
  createEl() {
    const title = `<p><strong>${this.options_.title}</strong></p>`;
    const subTitle = this.options_.subTitle ? `<p>${this.options_.subTitle}</p>` : '';
    const description = this.options_.description
      ? `<p class="vjs-an-desc">${truncate(this.options_.description, 110, true)}</p>`
      : '';
    const duration = this.options_.duration ? `<p><span class="icon-clock"></span>${this.options_.duration}</p>` : '';
    const series = this.options_.series ? `<p>${this.options_.series}</p>` : '';

    this.contentEl_ = super.createEl('div', {
      className: 'vjs-an-title',
      innerHTML: `${title} ${subTitle} ${duration} ${series} ${description}`,
    });

    return this.contentEl_;
  }

  handleClick() {
    tryGetNext(this.player_, this.options_.target, this.options_.urlRedirect, 'button', this.options_.productId);
  }
}
// Register the new component
ClickableComponent.registerComponent('ProductTitle', ProductTitle);
export default ProductTitle;
