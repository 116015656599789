import log from '../log';
/* eslint-disable */
// we dont want to import those modules in the webpack config (they are self invoking)
import ExpandScreenButton from './expand-screen/button';
/* eslint-enable */

/**
 * Expand screen wrapper plugin initialization
 *
 */
export const expandScreenPlugin = function expandScreenPlugin() {
  const player = this;

  const initExpandScreenButton = function initExpandScreenButton() {
    this.controlBar.addChild(new ExpandScreenButton(player));
  };

  player.one('loadeddata', initExpandScreenButton);
  log('expand screen plugin initialized');
};
