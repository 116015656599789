import { videojs } from 'global/window';
import log from '../../log';
import propTest from '../../property-tester';
import { PP_PLUGIN } from '../../errors';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * The help button send event for showing customer contact form
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class ProductPlacement extends Component {
  constructor(player, opts = {}) {
    super(player, opts);
    this.player = player;

    // playedTicks plugin is required
    if (!this.player.options_.plugins.playedTicks) {
      log.error(PP_PLUGIN.code, PP_PLUGIN.message);
      return;
    }

    // helper properties
    this.currentTime = 0;
    this.ticks = 0;
    this.imaAdType = 'preroll'; // default
    this.adType = 'preroll'; // default
    this.showClass = 'vjs-pp-shown';
    this.defaultLocation = 'top-right';
    this.isIma = false;

    // extracted values from opts
    this.isEnabled = opts.isEnabled || false;
    this.startShowDelay = opts.startShowDelay || null;
    this.midrollShowDelay = opts.midrollShowDelay || null;
    this.endShowOffset = opts.endShowOffset || null;
    this.displayTime = opts.displayTime || 10;
    this.location = opts.location ? opts.location.toLowerCase().replace('_', '-') : this.defaultLocation;

    // set class to position logo correctly
    this.el_.classList.add(`vjs-product-placement--${this.location}`);

    this.registerEvents();
  }

  setImaState() {
    this.isIma = true;
  }

  getCurrentAdBreakType(e) {
    if (this.isIma) {
      this.imaAdType = e.adStreamInfo.adType;
    }
  }

  checkState() {
    this.currentTime = Math.floor(this.player.currentTime());

    // show starting or ending pp
    const endPPOffset = this.player.duration() - this.endShowOffset;
    const showFixedStartPP =
      this.currentTime >= this.startShowDelay && this.currentTime < this.startShowDelay + this.displayTime;
    const showFixedEndPP = this.currentTime >= endPPOffset && this.currentTime < endPPOffset + this.displayTime;

    if (this.startShowDelay || this.endShowOffset) {
      if (showFixedStartPP || showFixedEndPP) {
        this.showProductPlacement();
      } else {
        this.hideProductPlacement();
      }
    }

    if (this.adType === 'midroll') {
      this.ticks += 1;
      if (this.ticks >= this.midrollShowDelay && this.ticks < this.midrollShowDelay + this.displayTime) {
        log(`[product placement] ${this.ticks - this.midrollShowDelay} of ${this.displayTime}`);
        this.showProductPlacement();
      } else {
        this.hideProductPlacement();

        if (this.ticks > this.midrollShowDelay + this.displayTime) {
          log(
            `[product placement] ${this.ticks - this.midrollShowDelay} of ${
              this.displayTime
            } midroll PP reaches the end - resetting state, waiting for next midroll`,
          );
          this.adType = '';
        }
      }
    }
  }

  showProductPlacement() {
    if (!this.player.hasClass(this.showClass)) {
      this.player.addClass(this.showClass);
    }
  }

  hideProductPlacement() {
    if (this.player.hasClass(this.showClass)) {
      this.player.removeClass(this.showClass);
    }
  }

  showMidrollPP() {
    if (this.imaAdType === 'midroll' || propTest(() => this.player.adstate.currentAdtype) === 'midrolls') {
      this.ticks = 0;
      this.adType = 'midroll';
      log('[product-placement] Content interupted by playing midroll. It is able to show PP');
    }
  }

  registerEvents() {
    this.player.on('ott-mea-ads-block-end', this.showMidrollPP.bind(this));
    this.player.on('ott-playertick', this.checkState.bind(this));
    this.player.on('ott-ima-ready', this.setImaState.bind(this));
    this.player.on('ott-mea-ad-play', this.getCurrentAdBreakType.bind(this));
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-product-placement',
    });
  }
}

// Register the new component
Component.registerComponent('productPlacement', ProductPlacement);
export default ProductPlacement;
