/**
 * Quality selector module. It handles selection of vhs quality.
 *
 * @module utils/quality-selector
 */

import log from '../log';
import { adaptiveStreams } from '../constants';

/**
 * Parsed video bitrate info from the adaptive stream manifest
 * @typedef VideoBitrateInfo
 * @type Object
 * @property {Number} bitrate
 * @property {String} mediaType
 * @property {Number} qualityIndex
 * @property {Number} width
 * @property {Number} height
 */

/**
 * Quality selector helper. Will work only when adaptive stream initialized.
 */
export default class QualitySelector {
  constructor(player) {
    this.player = player;

    // This variable keeps audio-only array index when profile found.
    this.audioOnlyProfileIdx = -1;

    this.currentType = this.player.currentType().toLowerCase();

    if (!this.isAdaptiveStreamAvailable()) {
      log.warn('No adaptive stream available');
    }
  }

  /**
   * Checks if adaptive stream available.
   *
   * @private
   * @return { boolean } Return true when adaptive stream is available.
   */
  isAdaptiveStreamAvailable = () => Object.values(adaptiveStreams).includes(this.currentStreamType);

  /**
   * Returns number of available qualities (without audio only version, hence the filter)
   * @return { number } Count of available qualities
   */
  // getNumberOfQualities = () => this.player.qualityLevels().filter((q) => q.width).length
  getNumberOfQualities = () => this.player.qualityLevels().levels_.filter((level) => !!level.width).length;

  /**
   * Change quality by index
   * @param { number } qualityIndex - The index of chosen quality.
   */
  setQuality = (qualityIndex) => {
    const { levels_ } = this.player.qualityLevels();

    const sortedLevelsWithoutAO = levels_.filter((level) => !!level.width).sort((a, b) => a.width - b.width);

    // enable only desired quality level so player is forced to chose it
    sortedLevelsWithoutAO.forEach((level, i) => {
      if (i === qualityIndex) {
        level.enabled = true;
        const { width, height, bitrate } = level;
        log(
          `[quality-selector] Manually selected quality quality - ${width}×${height} - ${Number(
            bitrate,
          ).toLocaleString()}bit/s`,
        );
      } else {
        level.enabled = false;
      }
    });

    this.player.trigger({
      type: 'ott-quality-changed',
      qualityIdx: qualityIndex,
    });
  };

  /**
   * Set quality of adaptive stream to automatic
   */
  setAutoQuality = async () => {
    const { levels_ } = this.player.qualityLevels();

    // enable all quality levels so player can automatically select from any of them
    levels_.forEach((level) => {
      level.enabled = !!level.width;
    });

    log('[quality-selector] Automatic quality selected');

    this.player.trigger('ott-vhs-quality-auto');

    return { selectedIndex: this.player.qualityLevels().selectedIndex_ };
  };
}
