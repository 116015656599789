import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

class PlaylistButtonPrev extends Button {
  constructor(player, options) {
    super(player, options);
    this.controlTextEl_.innerHTML = this.player_.localize('Prev');
  }

  buildCSSClass() {
    return 'vjs-playlist-buttons vjs-playlist-prev vjs-control vjs-button';
  }

  handleClick() {
    this.player_.playlist.changePlaylistSourcePrev();
  }
}

Component.registerComponent('PlaylistButtonPrev', PlaylistButtonPrev);
export default PlaylistButtonPrev;
