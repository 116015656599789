import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const TextTrackMenuItem = videojs.getComponent('TextTrackMenuItem');

/**
 * The specific menu item type for selecting a language within a text track kind
 *
 * @param {Object} player   - Player object
 * @param {Object=} options - Optional options
 */
class SubtitlesMenuItem extends TextTrackMenuItem {
  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('span');
  }

  /**
   * Handle click on text track, it basically triggers event which
   * is caught by the persist-setting plugin
   */
  handleClick(event) {
    super.handleClick(event);

    this.player_.trigger({
      type: 'ott-subtitles-enabled',
      track: this.track,
    });
  }
}

Component.registerComponent('SubtitlesMenuItem', SubtitlesMenuItem);
export default SubtitlesMenuItem;
