import { videojs } from 'global/window';
import log from '../../log';

// Get a component to subclass
const Button = videojs.getComponent('Button');

/**
 * The big unmute button on player screen
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Button
 */
class BigUnmuteButton extends Button {
  constructor(player, options = {}) {
    super(player, options);
    this.parent_ = options.parent;
    this.controlEventName = 'ott-big-unmute-button-click';
    this.defaultHideTimeoutMs = 5000;
    this.controlText(this.player_.localize('Turn on the sound'));
    this.hideTimeout = this.setHideTimeout();
    this.fadeEffectTimeout = null;
    log(
      `[Big unmute button] button show for ${
        this.options_.hideTimeMs ? this.options_.hideTimeMs : this.defaultHideTimeoutMs
      }ms`,
    );
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    const tpl = `<div class="vjs-big-unmute-button__icon">
    <div class="vjs-big-unmute-button__title">
      <span>${this.player_.localize('Turn on the sound')}</span>
    </div>
    </div>`;

    return super.createEl('div', {
      'className': 'vjs-big-unmute-button',
      'innerHTML': tpl,
      // let the screen reader user know that the text of the button may change
      'aria-live': 'polite',
    });
  }

  setHideTimeout() {
    return setTimeout(() => {
      this.addClass('hideAnimation');
      this.fadeEffectTimeout = setTimeout(() => {
        this.addClass('hidden');
        log('[Big unmute button] auto hidden');
      }, 1000);
    }, this.options_.hideTimeMs || this.defaultHideTimeoutMs);
  }

  dispose() {
    clearTimeout(this.hideTimeout);
    clearTimeout(this.fadeEffectTimeout);
    super.dispose();
  }

  /**
   * Handle click on help button.
   */
  handleClick() {
    this.addClass('hidden');
    this.player_.muted(false);
    this.player_.volume(0.5);
    this.player_.trigger({ type: this.controlEventName });
    log(`[Big unmute button] trigger event ${this.controlEventName} - button hidden`);
  }
}
// Register the new component
// Component.registerComponent('unmuteButton', UnmuteButton);
export default BigUnmuteButton;
