import { videojs } from 'global/window';
import QualityMeasurementHeading from './quality-measurement-heading';
import QualityMeasurementCopyClipboard from './quality-measurement-copy-clipboard';
import QualityMeasurementButtons from './quality-measurement-button';
// eslint-disable-next-line no-unused-vars
import QualityMeasurementOverlay from './quality-measurement-overlay';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class QualityMeasurementControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild(new QualityMeasurementButtons(player, options));
    this.addChild(new QualityMeasurementHeading(player, options));
    this.addChild(new QualityMeasurementCopyClipboard(player, options));
    this.player_.addChild('QualityMeasurementOverlay');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-an-control vjs-settings-item',
    });
  }
}

export default QualityMeasurementControl;
