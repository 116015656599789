import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Disable autoplay button
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class AudioChannelsButton extends Button {
  createEl() {
    return super.createEl('span', {
      className: 'vjs-an-switch vjs-settings-content',
      innerHTML:
        '<label class="switch"><input class="vjs-ach-checkbox" type="checkbox"><span class="slider round"></span></label>',
    });
  }

  setInputCheckedState(value) {
    this.el_.getElementsByClassName('vjs-ach-checkbox')[0].checked = value;
  }

  getInputCheckedState() {
    return this.el_.getElementsByClassName('vjs-ach-checkbox')[0].checked;
  }

  handleClick(e) {
    e.preventDefault();
    this.setInputCheckedState(!this.getInputCheckedState());
    this.player_.trigger({
      type: 'ott-achswitch-click',
      checked: this.getInputCheckedState(),
    });
  }
}
Component.registerComponent('AudioChannelsButton', AudioChannelsButton);
export default AudioChannelsButton;
