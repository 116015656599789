import { PLAYER_INNER_WIDTH_FUNC_NAME } from '../constants.js';

/**
 * Get window (or parent window when available) width
 * @param  {Object} player The player object-assign
 * @return {Number|Boolean}        Window widh or false when not available
 */
const getInnerWidth = function getInnerWidth(player) {
  if (window.top !== window) {
    if (player[PLAYER_INNER_WIDTH_FUNC_NAME]) {
      return player[PLAYER_INNER_WIDTH_FUNC_NAME];
    }
    return false;
  }
  return window.innerWidth;
};

export default getInnerWidth;
