/**
 * Pass in the objects to merge as arguments. For a deep extend, set the first argument to `true`.
 * @return {Object} New merged object
 */
export const extend = (deep, ...args) => {
  const extended = {};

  // Merge the object into the extended object
  const merge = (obj) => {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && typeof obj[prop] === 'object') {
          extended[prop] = extend(true, extended[prop], obj[prop]);
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for (let i = 0; i < args.length; i++) {
    const obj = args[i];
    merge(obj);
  }

  return extended;
};

export const assign = (...args) => extend(false, args);
