/**
 * Browser technology detection module
 * @module tech-detection
 */
import window, { navigator } from 'global/window';

/**
 * Check if browser has EME API
 *
 * @return {Boolean} True when browser has recent EME API
 */
export const isKeySystemAvailable = function isKeySystemAvailable() {
  /* eslint no-mixed-operators: 0 */
  const supported =
    !!window.MSMediaKeys ||
    (!!window.MediaKeys &&
      !!window.navigator &&
      !!window.navigator.requestMediaKeySystemAccess &&
      !!window.MediaKeySystemAccess &&
      !!window.MediaKeySystemAccess.prototype.getConfiguration);

  return supported;
};

/**
 * Returns a Promise to a map of EME support for well-known key systems.
 *
 * @param {Array.<Object>} audioCapabilities A list of supported audio type and capability pairs. The audio capability of this object is considered supported if the list
 is empty or contains one or more values that are supported with all other members (as determined by the algorithm). When there is a conflict between values, the earlier
 value will be selected. An empty list indicates that no audio capabilities are supported. In this case, the videoCapabilities element must not be empty.
 * @param {Array.<Object>} videoCapabilities A list of supported video type and capability pairs. The video capability of this object is considered supported if the list
 is empty or contains one or more values that are supported with all other members (as determined by the algorithm). When there is a conflict between values, the earlier
 value will be selected. An empty list indicates that no video capabilities are supported. In this case, the audioCapabilities element must not be empty.
 * @return {!Promise.<!Array>} Supprted drm key systems
 */
export const getDRMKeySystems = function getDRMKeySystems(audioCapabilities = [], videoCapabilities = []) {
  // Thanks to shaka player.. ;)
  const tests = [];
  const testKeySystems = ['org.w3.clearkey', 'com.widevine.alpha', 'com.microsoft.playready', 'com.adobe.primetime'];

  const config = {};
  if (audioCapabilities.length) {
    config.audioCapabilities = audioCapabilities;
  }
  if (videoCapabilities.length) {
    config.videoCapabilities = videoCapabilities;
  }
  const support = [];

  testKeySystems.forEach((keySystem) => {
    if (window.MSMediaKeys) {
      if (window.MSMediaKeys.isTypeSupported(keySystem)) {
        support.push(keySystem);
      }
    } else {
      const p = navigator
        .requestMediaKeySystemAccess(keySystem, [config, {}])
        .then(() => {
          support.push(keySystem);
        })
        .catch(() => {});
      tests.push(p);
    }
  });

  return Promise.all(tests).then(() => support);
};
