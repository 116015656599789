/**
 * <p>UI menu helper module</p>
 *
 * @module utils/hide-menus
 */

/**
 * Array of all custom player menu components
 */
export const menuComponents = Object.freeze([
  'SeekHintsMenu',
  'SettingsMenu',
  'SettingsMenuDropdown',
  'SubtitlesMenu',
  'LanguagesMenu',
  'QualityMenu',
  'InfoMenu',
]);

/**
 * Will hide all player popup menu elements
 *
 * @param {Object} player - Player instance
 * @param {Array.<String>=} components - Array of all custom player menu components
 * @return {Boolean} True when
 */
const hideOpenMenus = (player, components = menuComponents) => {
  let hidden = false;
  components.forEach((component) => {
    const menu = player.getChild(component);
    if (menu && !menu.hasClass('vjs-hidden')) {
      menu.addClass('vjs-hidden');
      hidden = true;
    }
  });

  // Remove vjs-popup-displayed state from the player object
  player.removeClass('vjs-popup-displayed');

  return hidden;
};

export default hideOpenMenus;
