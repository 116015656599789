import { videojs } from 'global/window';

// Get a component to subclass
const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

/**
 * Button to toggle between play and pause when VPAID is playing.
 *
 * @extends Button
 */
class PlayToggleFake extends Button {
  /**
   * Creates an instance of this class.
   *
   * @param {Player} player
   *        The `Player` that this class should be attached to.
   *
   * @param {Object} [options]
   *        The key/value store of player options.
   */
  constructor(player, options) {
    super(player, options);
    // this.handlePlay();
    this.on(player, 'vpaid.play', this.handlePlay);
    this.on(player, 'vpaid.pause', this.handlePause);

    player.on('linearclickthrough', () => {
      if (this.hasClass('vjs-paused')) {
        this.addPlayingClasses();
      } else {
        this.addPausedClasses();
      }
    });
  }

  addPausedClasses() {
    this.removeClass('vjs-playing');
    this.addClass('vjs-paused');
  }

  addPlayingClasses() {
    this.removeClass('vjs-paused');
    this.addClass('vjs-playing');
  }

  /**
   * Builds the default DOM `className`.
   *
   * @return {string}
   *         The DOM `className` for this object.
   */
  buildCSSClass() {
    return `vjs-play-control vjs-play-control-vpaid vjs-playing ${super.buildCSSClass()}`;
  }

  /**
   * This gets called when an `PlayToggle` is "clicked". See
   * {@link ClickableComponent} for more detailed information on what a click can be.
   *
   * @param {EventTarget~Event} [event]
   *        The `keydown`, `tap`, or `click` event that caused this function to be
   *        called.
   *
   * @listens tap
   * @listens click
   */
  handleClick() {
    if (this.hasClass('vjs-paused')) {
      this.player_.trigger('vpaid-fake-play');
    } else {
      this.player_.trigger('vpaid-fake-pause');
    }
  }

  /**
   * Add the vjs-playing class to the element so it can change appearance.
   *
   * @listens Player#play
   */
  handlePlay() {
    this.removeClass('vjs-ended');
    this.addPlayingClasses();
    // change the button text to "Pause"
    this.controlText('Pause');
  }

  /**
   * Add the vjs-paused class to the element so it can change appearance.
   *
   * @listens Player#pause
   */
  handlePause() {
    this.addPausedClasses();
    // change the button text to "Play"
    this.controlText('Play');
  }
}

Component.registerComponent('PlayToggleFake', PlayToggleFake);
export default PlayToggleFake;
