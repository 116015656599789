import { videojs } from 'global/window';
import './quality-level';

// Get a component to subclass
const Slider = videojs.getComponent('Slider');
const Component = videojs.getComponent('Component');

/**
 * The bar that contains the quality level and can be clicked on to adjust the level
 *
 * @param { Object } player - Player object
 * @param { Object= } options - Optional options
 * @extends Slider
 */
class QualityBar extends Slider {
  constructor(player, options) {
    super(player, options);
    this.parent_ = options.parent;
    this.qualitySelector = options.qualitySelector;
    this.qualityIntervals = this.getSliderIntervals(this.qualitySelector.getNumberOfQualities());
    if (this.qualityIntervals.length === 0) {
      this.parent_.hide();
    }
  }

  /**
   * Create the component's DOM element
   */
  createEl() {
    return super.createEl(
      'div',
      {
        className: 'vjs-quality-bar vjs-slider-bar',
      },
      {
        'aria-label': this.player_.localize('quality level'),
      },
    );
  }

  /**
   * Handle mouse move on quality bar
   */
  handleMouseMove(event) {
    this.distance = this.calculateDistance(event);
  }

  /**
   * Get percent of quality level
   *
   * @return {Number} Quality level percent
   */
  getPercent() {
    let { distance } = this;
    this.qualityIntervals.forEach((interval) => {
      if (interval.start <= this.distance && this.distance <= interval.end) {
        distance = parseFloat(interval.end);
        this.qualitySelector.setQuality(interval.id);
      }
    });
    if (!this.hasClass('active')) {
      this.addClass('active');
    }

    const autoButton = this.parent_.getChild('QualityAutoButtonWrapper').getChild('QualityAutoButton');
    if (autoButton.hasClass('active')) {
      autoButton.removeClass('active');
    }

    this.player_.on('ott-uhdprofile-switch', () => {
      this.removeClass('active');
      autoButton.addClass('active');
    });

    return distance;
  }

  /**
   * Get intervals of the quality Slider
   *
   * @param {Object} intervals - Object with intervals of quality slider
   * @param {Number} intervals.start - Interval percentage start
   * @param {Number} intervals.end - Interval percentage end
   * @param {Number} intervals.id - View beat end-point
   */
  getSliderIntervals(n) {
    const intervals = [];
    const min = 0;
    const max = 100;
    const lengthOfRange = (max - min + 1) / n;
    for (let i = 1; i <= n; i++) {
      const startOfRange = lengthOfRange * (i - 1) + min;
      const endOfRange = startOfRange + lengthOfRange - 1;
      intervals.push({
        start: (startOfRange / 100).toFixed(2),
        end: (endOfRange / 100).toFixed(2),
        id: i - 1,
      });
    }
    return intervals;
  }
}

QualityBar.prototype.options_ = {
  children: ['qualityLevel'],
  barName: 'qualityLevel',
};

QualityBar.prototype.playerEvent = 'qualitychange';

Component.registerComponent('QualityBar', QualityBar);
export default QualityBar;
