import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class PlaylistThumbnails extends Component {
  constructor(player, options) {
    super(player, options);
    this.imgElement = this.el().querySelector('img');
    this.timeElement = this.el().querySelector('.time span');
    this.playlistData = this.player_.playlist.playlistData;
  }

  /**
   * Helper return formated percent for using in url
   * @param {Number} percent
   */
  getThumbnailNumber(percent) {
    if (percent === 0) {
      percent = 1;
    }
    let thumbNumber;
    switch (String(percent).length) {
      case 1:
        thumbNumber = `00${percent}`;
        break;
      case 2:
        thumbNumber = `0${percent}`;
        break;
      case 3:
        thumbNumber = `${percent}`;
        break;
      default:
        thumbNumber = '000';
        break;
    }

    return thumbNumber;
  }

  /**
   * Method update thumbnails component
   */
  update(sourceIndex, cursorTime) {
    const source = this.playlistData.sources[sourceIndex];
    const onePercent = source.duration / 100;
    this.changeThumbnailImage(source, Math.floor(cursorTime / onePercent));
    this.changeThumbnailTime(cursorTime + source.durationBefore);
    this.changeThumbnailPosition(source, cursorTime);
  }

  createEl() {
    return super.createEl('div', {
      className: 'vjs-playlist-thumbnails',
      innerHTML: '<img src=""><div class="time"><span>0:00</span></div>',
    });
  }

  /**
   * Method change img src on thumbnail component
   * @param {*} source - cursor position source
   * @param {*} percent - cursor position percent of source
   */
  changeThumbnailImage(source, percent) {
    this.imgElement.src = source.thumbnailsPlaylist.url.replace('$Num$', this.getThumbnailNumber(percent));
  }

  /**
   * Method print time of cursor on source
   * @param {Number} time
   */
  changeThumbnailTime(time) {
    this.timeElement.innerText = videojs.formatTime(time);
  }

  /**
   * Method change component position
   * @param {*} source
   * @param {*} cursorTime
   */
  changeThumbnailPosition(source, cursorTime) {
    const progressBarWidth = this.player_.controlBar.progressControl.width();
    const thumbnailWidth = this.el().offsetWidth;
    const onePercentWidth = progressBarWidth / this.playlistData.totalDuration;
    const timeBeforeThisSource = source.durationBefore + cursorTime;
    let leftPosition = Math.floor(timeBeforeThisSource * onePercentWidth) - Math.floor(thumbnailWidth / 2);

    if (leftPosition < 0) {
      leftPosition = 0;
    }

    if (leftPosition + thumbnailWidth > progressBarWidth) {
      leftPosition = progressBarWidth - thumbnailWidth;
    }

    this.el().style.left = `${leftPosition}px`;
  }

  visible() {
    this.el().style.display = 'block';
  }

  unvisible() {
    this.el().style.display = 'none';
  }
}

Component.registerComponent('PlaylistThumbnails', PlaylistThumbnails);
export default PlaylistThumbnails;
