import window from 'global/window';
import { getUrlWithQueryParam } from '../../utils/url';

/**
 * Check if the target value is equal to 'location' and URL exist
 * @param  {String}  target Auto-play target location
 * @param  {String}  url    Url for the location redirect
 * @return {Boolean}        True when both values are valid
 */
export const isLocationValid = (target, url) => {
  // TODO BPo: Maybe url validation before redirect?
  // PCi: had to remove check of target === 'location', because it did not pass this condition after autoplay is initiated externally and clickthrough doesn't work
  if (target && url) return true;
  return false;
};

/**
 * Check if auto-play has valid target and url properties
 * @param  {String}  target Auto-play target location
 * @param  {String}  url    Url for the location redirect
 * @return {Boolean}        True when all test pass
 */
export const validateValues = (target, url) => {
  if (isLocationValid(target, url) || target === 'event-only') {
    return true;
  }
  return false;
};

/**
 * Check if the target value is equal to 'location' and URL exist, than redirect
 * @param  {Object}  player    The player instance
 * @param  {String}  target    Auto-play target location
 * @param  {String}  url       Url for the location redirect
 * @param  {String}  from      Url for the location redirect
 * @param  {String}  productId ProductId of the next product
 */
export const tryGetNext = (player, target, url, from, productId) => {
  player.trigger({
    type: 'ott-playnext-fired',
    from,
    productId,
  });
  if (isLocationValid(target, url)) {
    // Custom redirect target for iframes, read from config. If not defined, default is 'self'.
    const t = player.options_.plugins.autoplayNext.redirectTarget || 'self';
    // Redirect when 'autoplay next' plugin is shown

    const currentAutoplayQuery = new URL(url).searchParams.get('autoplay');

    window[t].location.href = getUrlWithQueryParam(url, 'autoplay', currentAutoplayQuery ?? 'any');
  }
};
