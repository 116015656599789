import { videojs } from 'global/window';
/* eslint-disable */
import LanguagesMenuItem from './languages-menu-item.js';
/* eslint-enable */

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Stream language buttons wrapper
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class LanguagesButtonsWrapper extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.createContent(options.languages);
    this.el_.className = 'vjs-languages-content vjs-settings-content';
  }

  /*
   * Register 'LanguageMenuItem' for each language
   *
   * @param {Array} languages - Array of languages
   */
  createContent(languages) {
    languages.forEach((lang) => {
      this.addChild('LanguagesMenuItem', lang);
    });
  }
}

Component.registerComponent('LanguagesButtonsWrapper', LanguagesButtonsWrapper);
export default LanguagesButtonsWrapper;
