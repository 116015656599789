/**
 * <p>Manages the heart beat functionality</p>
 *
 * <h3>Initialization</h3>
 * <b>Played ticks and measuring</b> plugins must be initialized already. See the example below to understand how to initialize this plugin.
 *
 * <h3>Custom events tracking:</h3>
 * <b>ott-startbeat-sended</b> - when start beat sended occurs<br>
 * <b>ott-viewbeat-sended</b> - when view beat sended occurs<br>
 * <b>ott-heartbeat-sended</b> - when heart beat sended occurs<br>
 *
 * @example
{
  plugins: {
   heartBeats: {
     productId: 'p158494',
     playAccessToken: 'exampleToken',
     startBeatUrl: 'example.com/start-beat-url',
     viewBeatUrl: 'example.com/view-beat-url',
     heartBeatUrl: 'example.com/heart-beat-url',
     preRollBeatUrl: 'example.com/pre-roll-beat',
     requestHeaders: {}, // optional - will be added to every heartbeat request
     sameOrigin: false, // optional
     sendISODuration: true,
     customData: {} // optional - Custom data sent with beats
     viewBeatInterval: 60,
     heartBeatInterval: 30,
     from: 'cw',  // [embed|cw|undefined]
     errors: {    // Optional
       '403': {
         className: 'forbidden-error-class'
       }
     }
   }
  }
}
 *
 * @module plugins/heartBeats
 */
import document from 'global/document';
import log from '../log';
import { HB_PERFORM_REQUEST, HB_RESPONSE_404, HB_RESPONSE_403, HB_PLUGIN } from '../errors';
import { HB_VISIBLE_CLASS_NAME } from '../constants';
import availableEvents from './measuring/shared/available-events';
import { isAdPlaying } from './adservice/shared/ad-state';

/**
 * Beat object (used when view or heartbeat event occurs)
 *
 * @class
 */
class Beat {
  /**
   * The beat object constructor
   *
   * @param {string} productId  - The product ID
   * @param {Number|string} position   - The current time (in seconds or ISO duration). Can be infinity
   * @param {Number} viewed     - The viewed time from the previous beat (in seconds)
   * @param {string=} from      - [embed|cw|undefined]
   * @param {string=} playAccessToken - anonymous user playaccesstoken for viewbeat
   * @param {object} customData - Custom data
   */
  constructor(productId, from, customData, position, viewed, playAccessToken, playerSourceState) {
    this.productId = productId;
    this.from = from;
    this.customData = customData;
    this.position = position;
    this.viewed = viewed;
    this.playAccessToken = playAccessToken;
    this.playerSourceState = playerSourceState;
  }

  /**
   * Returns formatted json with beat info
   *
   * @param {string=} [from=json.from]  - [embed|cw|undefined]
   * @returns {JSON} beat               - The beat in JSON format
   */
  getBaseJson() {
    let json = {
      productId: this.productId,
      product: this.productId,
    };

    if (typeof this.from !== 'undefined') {
      json.from = this.from;
    }

    if (this.customData) {
      const arr = this.customData;

      json = Object.assign(arr, json);
    }

    return json;
  }

  /**
   * Returns formatted json with beat info
   *
   * @param {string=} [from=json.from]  - [embed|cw|undefined]
   * @returns {JSON} beat               - The beat in JSON format
   */
  getJson() {
    const json = this.getBaseJson();
    json.position = this.position;
    json.viewed = this.viewed;
    json.playerSourceState = this.playerSourceState;
    // sended only for anonymous user
    if (this.playAccessToken) {
      json.playAccessToken = this.playAccessToken;
    }

    return json;
  }
}

/**
 * Dispose player on 403 status code
 *
 * @param {Object} player - Player instance
 */
const handleForbiddenResponse = function handleForbiddenResponse(player) {
  const options = player.options_.plugins.heartBeats;
  // Remove the HTML associated with videojs player
  player.dispose();
  // Show element about this error if exist
  const { className } = (options.errors || {})['403'] || {};
  if (className) {
    const elements = document.getElementsByClassName(className);
    Array.prototype.forEach.call(elements, (el) => {
      const classes = el.classList;
      if (!classes.contains(HB_VISIBLE_CLASS_NAME)) {
        classes.add(HB_VISIBLE_CLASS_NAME);
      }
    });
  }
};

/**
 * Take care of response when some problem occurs
 *
 * @param {Number} player   - Player instance
 * @param {Object} response - Represents the response to a request
 */
const handleResponse = (player, response) => {
  // FIXME BPo: handleResponse not implemented yet
  if (response.status === 403) {
    log.error(HB_RESPONSE_403.code, HB_RESPONSE_403.message);
    player.trigger({
      type: 'ott-custom-error',
      ...HB_RESPONSE_403,
    });
    // Dispose player on 403 code
    handleForbiddenResponse(player);
  } else if (response.status === 404) {
    log.error(HB_RESPONSE_404.code, HB_RESPONSE_404.message);
    player.trigger({
      type: 'ott-custom-error',
      ...HB_RESPONSE_404,
    });
  }
};

/**
 * Heart beat watcher plugin initialization
 *
 * @param {object} opts
 * @param {Number} opts.productId           - The product id
 * @param {string} opts.startBeatUrl        - Start beat end-point
 * @param {string} opts.viewBeatUrl         - View beat end-point
 * @param {string} opts.heartBeatUrl        - Heart beat end-point
 * @param {string} opts.preRollBeatUrl      - Heart beat end-point
 * @param {Number} opts.viewBeatInterval    - View beat interval
 * @param {Number} opts.heartBeatInterval   - Heart beat interval
 * @param {Number} opts.heartBeatFirstExtra - First Extra Heart beat trigger interval
 * @param {object} opts.requestHeaders      - Headers sent with requests
 * @param {object} opts.sameOrigin          - If set true, fetch requests will be made with `credentials: 'same-origin', mode: 'same-origin'`.
 * @param {object} opts.sendISODuration     - If set true, position will be send as ISO 8601 duration.
 * @param {string=} opts.from               - [embed|cw|undefined]
 * @param {string=} opts.playAccessToken    - [string|undefined] anonymous user playaccesstoken for viewbeat
 * @param {object} opts.customData          - Custom data sent with beats
 * @param {string} opts.type                - Player state when beat sent
 *
 */
export const heartBeatsPlugin = function heartBeatsPlugin(opts) {
  const player = this;
  const {
    productId,
    startBeatUrl,
    viewBeatUrl,
    heartBeatUrl,
    preRollBeatUrl,
    viewBeatInterval,
    heartBeatInterval,
    heartBeatFirstExtra,
    preRollBeatInterval = 2,
    requestHeaders = {},
    sameOrigin = false,
    sendISODuration = true,
    from,
    playAccessToken,
    customData,
  } = opts;
  let heartBeatTime = opts.heartBeatInterval + opts.viewBeatInterval;
  let viewed = 0;
  let lastHeartBeatTime = 0;
  let preRollBeatSended = !preRollBeatUrl; // if not defined
  let videoPaused = false;

  /**
   * Perform an asynchronous HTTP (Ajax) request.
   *
   * @param {Object} beat   - The BaseBeat or Beat instance
   * @param {String} url    - The beat end-point
   */
  const performRequest = (beatJson, url) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...requestHeaders,
      },
      body: JSON.stringify(beatJson),
      ...(sameOrigin ? { credentials: 'same-origin', mode: 'same-origin' } : {}),
    })
      .then((response) => {
        /* Is not necessary to handle 'ok' requests
      if (response.ok) {
      } */

        handleResponse(player, response);
      })
      .catch((error) => {
        log.error(HB_PERFORM_REQUEST.code, error.message);
        player.trigger({
          type: 'ott-custom-error',
          ...HB_PERFORM_REQUEST,
        });
        // Throw error is not necessary
      });
  };

  /**
   * Send start beat when user click on play. Static method
   */
  const sendStartBeat = () => {
    const beat = new Beat(productId, from, customData);
    performRequest(beat.getBaseJson(), startBeatUrl);
  };

  /**
   * Format beat and perform request
   *
   * @param {String} url        - Start beat end-point
   * @param {Number} viewed     - The viewed time from the previous beat (in seconds)
   */
  const sendBeat = (url, viewed, playerSourceState) => {
    let currentTime = Math.round(player.currentTime());
    if (sendISODuration) {
      currentTime = `PT${currentTime}S`;
    }
    const beat = new Beat(productId, from, customData, currentTime, viewed, playAccessToken, playerSourceState);
    performRequest(beat.getJson(), url);
  };

  /**
   * Send beat after pre-roll ends (when content starts playing). Static method
   */
  const sendPreRollBeat = () => {
    if (!preRollBeatSended) {
      preRollBeatSended = true;

      const beat = new Beat(productId, from, customData);
      performRequest(beat.getBaseJson(), preRollBeatUrl);
      log('[heartbeats] preroll beat sended');
    }
  };

  if (!player.options_.plugins.playedTicks) {
    log.error(HB_PLUGIN.code, HB_PLUGIN.message);
    // Keep the code running we can send start beat still..
  }

  /**
   * Handle playedticks plugin event
   */
  const sendBeatOnTick = (obj) => {
    viewed = obj.ticks;

    if (!preRollBeatSended && !isAdPlaying(player) && viewed >= preRollBeatInterval) {
      // send beat after specified seconds, when ad is not playing
      sendPreRollBeat();
      preRollBeatSended = true;
    }

    // do not send first extra heartBeat if user pauses the video before
    if (!videoPaused && heartBeatTime && viewed === heartBeatFirstExtra) {
      // send first extra heartBeat
      sendBeat(heartBeatUrl, viewed, obj.type);
    }

    if (viewed === viewBeatInterval) {
      lastHeartBeatTime = viewed;
      // send viewBeat
      sendBeat(viewBeatUrl, viewed);
      player.trigger('ott-viewbeat-sended');
      log('[beat] ott-viewbeat-sended');
    } else if (viewed >= heartBeatTime) {
      heartBeatTime = viewed + heartBeatInterval;
      // send heartBeat
      sendBeat(heartBeatUrl, viewed - lastHeartBeatTime, obj.type);
      player.trigger('ott-heartbeat-sended');
      log('[beat] ott-heartbeat-sended');
      lastHeartBeatTime = viewed;
    }
  };

  /**
   * Send heart beat when some event fire
   */
  const sendEventHeartBeat = (playerSourceState) => {
    if (viewed > viewBeatInterval) {
      sendBeat(heartBeatUrl, viewed - lastHeartBeatTime, playerSourceState);
      lastHeartBeatTime = viewed;
      heartBeatTime = viewed + heartBeatInterval;
    }
  };

  /**
   * Send start beat when loadedmetadata occurs
   */
  const startBeatEvent = function startBeatEvent() {
    player.off([availableEvents.AD_LOADED, availableEvents.PROGRAM_LOADED], startBeatEvent);
    sendStartBeat();
    this.trigger('ott-startbeat-sended');
    log('[beat] ott-startbeat-sended');
  };

  player.one([availableEvents.AD_LOADED, availableEvents.PROGRAM_LOADED], startBeatEvent);

  player.on([availableEvents.PLAY, availableEvents.PAUSE, availableEvents.ENDED], (data) =>
    sendEventHeartBeat(data.type),
  );
  player.one(availableEvents.PAUSE, () => {
    videoPaused = true;
  });

  // Beat timer event (each second)
  // this require playedticks plugin
  player.on('ott-playertick', sendBeatOnTick);

  player.one(availableEvents.AD_PLAY, ({ adStreamInfo }) => {
    if (adStreamInfo.adType === 'preroll') {
      sendPreRollBeat();
    }
  });

  log('[heartBeats] plugin initialized');
};
