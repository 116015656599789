import { videojs } from 'global/window';
import './languages-heading';
import './languages-buttons-wrapper';
import './languages-select/languages-select-wrapper';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Registration component for all settings sub-plugin
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class LanguagesMenu extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('LanguagesHeading');
    this.addChild('LanguagesButtonsWrapper', options);
    this.el_.className = 'vjs-languages-menu vjs-custom-menu vjs-hidden';
  }
}

// Register the new component
Component.registerComponent('LanguagesMenu', LanguagesMenu);
export default LanguagesMenu;
