import { videojs } from 'global/window';
import './opt-out-close';

// Get a component to subclass
const Component = videojs.getComponent('Component');

class LiveRestartOverlay extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.addChild('LiveRestartClose');
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    this.contentEl_ = super.createEl('div', {
      className: 'vjs-liverestart-overlay',
      innerHTML: `<div class="vjs-liverestart-overlay-inner">
            <div class="vjs-icon-info">
            </div>
            <div class="vjs-liverestart-overlay-text">
              <p class="dialog-title">${this.player_.localize('Live Restart')}</p>
              <p class="vjs-liverestart-overlay-text-placeholder">
                ${this.player_.localize(
                  'The provided user cannot use the timeshift feature, the product does not allow the timeshift feature or the timeshift feature cannot be used at the specific moment.',
                )}
              </p>
            </div>
          </div>`,
    });

    return this.contentEl_;
  }
}

Component.registerComponent('liveRestartOverlay', LiveRestartOverlay);
export default LiveRestartOverlay;
