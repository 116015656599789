import avlAdType from './shared/ad-type';
import log from '../../log';
import { AS_COMPANION_EMPTY_OBJECT, AS_COMPANION_FIELDS_MISSING } from '../../errors';

/**
 * Cares about cascade init ibject
 */
export default class Cascade {
  /**
   * Returns initial ad object
   *
   * @param {Object=} ad - Ad cascade object. Contains overlay, companion, sklik definition..
   */
  static getEmptyObject(ad) {
    if (!ad) {
      log.error(AS_COMPANION_EMPTY_OBJECT.code, AS_COMPANION_EMPTY_OBJECT.message);
    }

    if (typeof ad.period === 'undefined' && typeof ad.time === 'undefined') {
      log.error(AS_COMPANION_FIELDS_MISSING.code, AS_COMPANION_FIELDS_MISSING.message);
    }

    const data = {
      requested: false,
      period: ad.period,
      time: ad.time,
      overlay: {
        ads: [],
        vast: (ad.overlay || {}).vast || void 0,
        requested: false,
      },
      companion: {
        ads: [],
        vast: (ad.companion || {}).vast || void 0,
        requested: false,
      },
      sklik: ad.sklik,
    };
    return data;
  }

  /**
   * Trigger ended event for the companion, overlayer or sklik
   * @param {String} currentAdtype Name of the current ad type
   */
  static resetShownCascade(player, currentAdtype) {
    switch (currentAdtype) {
      case avlAdType.companion:
        player.trigger('companionended');
        break;
      case avlAdType.overlay:
        player.trigger('overlayended');
        break;
      default:
        // FIXME BPo: Should we call the ended event for the sklik all the time (no)?
        player.trigger('sklikended');
        break;
    }
  }
}
