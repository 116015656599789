import { google, videojs } from 'global';
import is from 'is_js';
import log from '../log';

const Plugin = videojs.getPlugin('plugin');

const streamEvents = { ...(google && google.ima && google.ima.dai && google.ima.dai.api.StreamEvent.Type) };

const IMA_DAI_CONTENT_PAUSE = 'ima-dai-content-pause';
const IMA_DAI_CONTENT_PLAY = 'ima-dai-content-play';

export class AdServiceImaDai extends Plugin {
  constructor(player, options) {
    super(player, options);

    this.player = player;
    this.options = options;
    this.isAdBreak = false;
    this.videoElement = null;

    this.imaAdUiElement = null;
    this.backupStream = null;

    this.bigPlayButton = null;
    this.clickScreen = null;
  }

  initializeIma = (backupStream) => {
    this.backupStream = backupStream;
    this.videoElement = this.player.children()[0]; // eslint-disable-line
    this.imaAdUiElement = document.createElement('div');
    this.imaAdUiElement.id = 'ad-ui';
    this.imaAdUiElement.style.width = '100%';
    this.imaAdUiElement.style.height = '100%';
    this.imaAdUiElement.style.position = 'absolute';
    this.player.el().insertBefore(this.imaAdUiElement, this.player.children()[0].nextSibling);

    const settings = new google.ima.dai.api.UiSettings();

    if (this.options.imaUiLocale) {
      settings.setLocale(this.options.imaUiLocale);
    }

    this.streamManager = new google.ima.dai.api.StreamManager(this.videoElement, this.imaAdUiElement, settings);

    this.addEventListeners();

    const { assetKey, apiKey } = this.options;

    if (assetKey) {
      this.requestLiveStream(assetKey, apiKey);
    }
  };

  addEventListeners = () => {
    this.streamManager.addEventListener(streamEvents.LOADED, this.handleLoaded);
    this.streamManager.addEventListener(streamEvents.ERROR, this.handleError);

    this.handleTextTracksMetadata();

    this.player.on('pause', this.onStreamPause);
    this.player.on('play', this.onStreamPlay);

    this.streamManager.addEventListener(streamEvents.AD_PERIOD_STARTED, this.handleAdPeriodStarted);
    this.streamManager.addEventListener(streamEvents.AD_PERIOD_ENDED, this.handleAdPeriodEnded);

    this.streamManager.addEventListener(
      [
        streamEvents.STARTED,
        streamEvents.FIRST_QUARTILE,
        streamEvents.MIDPOINT,
        streamEvents.THIRD_QUARTILE,
        streamEvents.COMPLETE,
        streamEvents.RESUMED,
        streamEvents.PAUSED,
      ],
      this.handleAdLifeCycles,
      false,
    );

    this.streamManager.addEventListener(streamEvents.AD_PROGRESS, this.handleAdProgress);

    // these events could be consolidated in theory, but they seemed quite different in principal
    this.streamManager.addEventListener(
      [streamEvents.AD_BREAK_STARTED, streamEvents.AD_BREAK_ENDED],
      this.handleAdBreak,
    );
    this.streamManager.addEventListener([streamEvents.CLICK, streamEvents.VIDEO_CLICKED], this.handleAdBreak);
    this.streamManager.addEventListener(streamEvents.SKIPPED, this.handleSkipped);
  };

  // to handle events that are not firing
  // https://stackoverflow.com/questions/53584454/google-dai-mid-roll-events-not-triggering-with-videojs
  handleTextTracksMetadata = () => {
    const getMetadataFromCues = (track) => {
      const elemTrack = track.activeCues[0];

      if (elemTrack && elemTrack.value?.data) {
        const metadata = {};

        metadata[elemTrack.value.key] = elemTrack.value.data;
        metadata.duration = Infinity;
        this.streamManager.onTimedMetadata(metadata);
      }
    };

    if (is.safari()) {
      const textTracks = this.player.el().children[0].textTracks;

      textTracks.onaddtrack = ({ track }) => {
        // find out if the new track is metadata
        if (track.kind === 'metadata') {
          track.mode = 'hidden';
          // a cuechange event fires when the player crosses over an ID3 tag
          track.oncuechange = () => getMetadataFromCues(track);
        }
      };
    } else {
      const textTracks = this.player.textTracks();
      textTracks.on('addtrack', ({ track }) => {
        // find out if the new track is metadata
        if (track.kind === 'metadata') {
          track.mode = 'hidden';
          // a cuechange event fires when the player crosses over an ID3 tag
          track.on('cuechange', () => getMetadataFromCues(track));
        }
      });
    }
  };

  handleLoaded = (event) => {
    log('[adservice-ima-dai]: Stream loaded');

    this.player.src({
      src: event.getStreamData().url,
      type: 'application/x-mpegURL',
    });

    if (this.player.options_.autoplayAllowed) {
      this.player.play();
    }

    this.player.trigger(streamEvents.LOADED);
  };

  handleError = () => {
    log('[adservice-ima-dai]: Error loading stream, playing backup stream.');

    this.player.src(this.backupStream);

    if (this.player.options_.autoplayAllowed) {
      this.player.play();
    }
  };

  handleAdProgress = (event) => {
    log(`[adservice-ima-dai] Ad ${event.type}`);
    this.player.trigger(event);
  };

  /**
   * This handler handles all the ad progress events, because they are very simple
   * @param { string } type is type of event ie firstquartile/midpoint/...
   */
  handleAdLifeCycles = (event) => {
    log(`[adservice-ima-dai] Ad ${event.type}`);
    this.player.trigger(event);
  };

  handleAdPeriodStarted = (event) => {
    log('[adservice-ima-dai]: Ad period started - changing from ad to content');
    this.isAdBreak = true;
    if (this.options.disableAdUi !== true) {
      this.hidePlayerControls();
      this.showImaUi();
    }
    this.player.trigger(event);
  };

  handleAdPeriodEnded = (event) => {
    log('[adservice-ima-dai]: Ad period ended - changing from content to ad');
    this.isAdBreak = false;
    this.showPlayerControls();
    this.hideImaUi();
    this.player.trigger(event);
  };

  // next events handlers could be consolidated in theory, but they seemed quite different in principal
  handleAdBreak = (event) => {
    log(`[adservice-ima-dai]: ${event.type}`);
    this.player.trigger(event);

    // AD_PERIOD events are not fired on the ios safari, so we trigger them manually
    if (is.ios()) {
      if (event.type === streamEvents.AD_BREAK_STARTED) {
        this.handleAdPeriodStarted({ type: streamEvents.AD_PERIOD_STARTED });
      } else if (event.type === streamEvents.AD_BREAK_ENDED) {
        this.handleAdPeriodEnded({ type: streamEvents.AD_PERIOD_ENDED });
      }
    }
  };

  handleClick = (event) => {
    log(`[adservice-ima-dai]: ${event.type}`);
    this.player.trigger(event);
  };

  handleSkipped = (event) => {
    log(`[adservice-ima-dai]: ${event.type}`);
    this.player.trigger(event);
  };

  /**
   * Requests a VOD stream with ads.
   * @param  {string} cmsId
   * @param  {string} videoId
   * @param  {?string} apiKey
   */
  requestVODStream = (contentSourceId, videoId, apiKey) => {
    const streamRequest = new google.ima.dai.api.VODStreamRequest();
    this.streamRequest.contentSourceId = contentSourceId;
    this.streamRequest.videoId = videoId;
    this.streamRequest.apiKey = apiKey;
    this.streamManager.requestStream(streamRequest);
  };

  /**
   * Requests a Live stream with ads.
   * @param  {string} assetKey
   * @param  {?string} apiKey
   */
  requestLiveStream = (assetKey, apiKey = '') => {
    const streamRequest = new google.ima.dai.api.LiveStreamRequest();
    streamRequest.assetKey = assetKey;
    streamRequest.apiKey = apiKey;
    this.streamManager.requestStream(streamRequest);
  };

  hidePlayerControls = () => {
    log('[adservice-ima-dai]: Hiding player control');
    this.player.controls(false);
  };

  showPlayerControls = () => {
    log('[adservice-ima-dai]: Showing player controls');
    this.player.controls(true);
  };

  hideImaUi = () => {
    log('[adservice-ima-dai]: Hiding ima ui');
    this.imaAdUiElement.style.display = 'none';
  };

  showImaUi = () => {
    log('[adservice-ima-dai]: Showing ima ui');
    this.imaAdUiElement.style.display = 'block';
  };

  onStreamPause = () => {
    if (this.isAdBreak) {
      this.showPlayerControls();
      this.imaAdUiElement.style.display = 'none';
    } else {
      this.triggerContentPause();
    }
  };

  onStreamPlay = () => {
    if (this.isAdBreak) {
      if (this.options.disableAdUi !== true) {
        this.hidePlayerControls();
        this.imaAdUiElement.style.display = 'block';
      }
    } else {
      this.triggerContentPlay();
    }
  };

  triggerContentPlay = () => this.player.trigger(IMA_DAI_CONTENT_PLAY);

  triggerContentPause = () => this.player.trigger(IMA_DAI_CONTENT_PAUSE);
}

/* eslint-disable */

/* * * * LIST OF IMA DAI STREAM EVENTS * * * *\

LOADED - Fired when the stream manifest is available.

AD_BREAK_STARTED - Fired first time each ad break begins playback. If an ad break is watched subsequent times this will not be fired. Applications must disable seeking when this occurs.

AD_BREAK_ENDED - Fired the first time each ad break ends. Applications must reenable seeking when this occurs.

AD_PERIOD_STARTED - Fired every time the stream switches from content to advertising or slate. This will be fired even when an ad is played a second time or when seeking into an ad.

AD_PERIOD_ENDED - Fired every time the stream switches from advertising or slate to content. This will be fired even when an ad is played a second time or when seeking into an ad.

AD_PROGRESS - Fired when there is an update to an ad's progress.

CUEPOINTS_CHANGED - Dispatched for on-demand streams when the cuepoints change.

CLICK - Dispatched when the click element is clicked or tapped while an ad is being played.

ERROR - Fired when an error occurs.

STARTED - Fired when an ad starts.

FIRST_QUARTILE - Fired when an ad reaches its first quartile.

MIDPOINT - Fired when an ad reaches its midpoint.

STREAM_INITIALIZED - Fired when the stream is initialized.

THIRD_QUARTILE - Fired when an ad reaches its third quartile.

COMPLETE - Fired when an ad is complete.

SKIPPABLE_STATE_CHANGED - Fired when the displayed ad's skippable state is changed.

SKIPPED - Fired when the ad is skipped by the user.

VIDEO_CLICKED - Fired when a user clicks on the video without triggering clickthrough. When a "Learn More" button is shown, such as when on mobile web,
                the CLICK event is only fired when clicking the button. Other clicks will fire this event.

PAUSED - Fired when the ad is paused by the user.

RESUMED - Fired when the ad is resumed by the user.
*/
