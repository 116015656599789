import window, { NOLBUNDLE, videojs } from 'global/window';
import log, { isLoggingEnabled } from '../../log';
import { MEA_NIELSEN_MISSING } from '../../errors';
import { isAdPlaying } from '../adservice/shared/ad-state';
import availableEvents from './shared/available-events';
import { isLiveGlobal, isLiveNow } from '../../utils/islive-check';
import propTest from '../../property-tester';
import { BEFORE_RESET_EVENT } from '../../reset';

const Plugin = videojs.getPlugin('plugin');
/* eslint-enable */

/* eslint-enable */
/**
 * Options for the Nielsenn Digital Content Ratings
 *
 * @typedef {Object}  NielsenOptions
 * @property {String} parent
 * @property {String} brand
 * @property {String} channel
 * @property {String=} clientid
 * @property {String=} vcid
 * @property {String} platform
 * @property {String} apid
 */

// events
function onProgramLoaded() {
  this.playheadPositionEnabled = true;
  this.endReached = false;
  this.loadContentDataEnabled = false;
  this.sdkInstance.ggPM('loadMetadata', this.contentMetadata);
  log('[Nielsen] SDK Event content loadMetadata called Data: ', this.contentMetadata);

  if (this.adEnded) {
    this.loadMetadataAfterAdCalled = true;
  }
}

function onPlay(opts) {
  if (opts.firstPlay) {
    this.player.on(availableEvents.PAUSE, this.startPauseCounting);
    this.player.on('seeking', this.onSeeking);
  }
  this.stopPauseCounting();
  if (!this.loadContentDataEnabled) return;

  this.playheadPositionEnabled = true;
  this.endReached = false;
  this.loadContentDataEnabled = false;

  if (this.adEnded && !this.loadMetadataAfterAdCalled) {
    // load metadata should be also called after ads
    this.sdkInstance.ggPM('loadMetadata', this.contentMetadata);
    log('[Nielsen] SDK Event content loadMetadata (after play) called. Data: ', this.contentMetadata);
    this.loadMetadataAfterAdCalled = true;
  }
  this.seeked = false;
  this.skipped = false;
}

function onAdPlay(opts) {
  if (opts.firstPlay) {
    this.player.on(availableEvents.PAUSE, this.startPauseCounting);
    this.player.on('seeking', this.onSeeking);
  }
  this.stopPauseCounting();
  if (!this.loadContentDataEnabled) return;

  this.playheadPositionEnabled = true;
  this.endReached = false;
  this.loadContentDataEnabled = false;

  if (this.adEnded && !this.loadMetadataAfterAdCalled) {
    // load metadata should be also called after ads
    this.sdkInstance.ggPM('loadMetadata', this.contentMetadata);
    log('[Nielsen] SDK Event content loadMetadata (after play) called. Data: ', this.contentMetadata);
    this.loadMetadataAfterAdCalled = true;
  }
  this.skipped = false;
}

function onSeeking() {
  this.seeked = true;
  this.pausedTime = 0;
}

function onAdLoaded({ adStreamInfo }) {
  this.playheadPositionEnabled = true;
  this.seeked = false;
  this.isAdLoaded = true;
  this.adEnded = false;
  this.sdkInstance.ggPM('loadMetadata', this.getAdObject(adStreamInfo));
  log('[Nielsen] SDK Event ad loadMetadata called. Data: ', this.getAdObject(adStreamInfo));
}

function onSkip() {
  this.skipped = true;
  this.playheadPositionEnabled = false;
  this.loadContentDataEnabled = true;

  if (!this.stopCalled) {
    const currentTime = Math.floor(this.player.currentTimeOfActiveMedia());
    this.sdkInstance.ggPM('stop', currentTime);
    log('[Nielsen] SDK Event ad stop called (Skip). Time: ', currentTime);
    this.stopCalled = true;
    this.adEnded = true;
    this.prevAdPosition = 0;
  }
}

function onAdEnded(e) {
  this.playheadPositionEnabled = false;
  this.loadContentDataEnabled = true;
  this.stopCalled = true;

  if (!this.adEnded) {
    this.prevAdPosition = 0;
    const currentTime = e.adStreamInfo.adDuration || Math.floor(Math.floor(this.player.currentTimeOfActiveMedia()));
    this.sdkInstance.ggPM('stop', currentTime);
    log('[Nielsen] SDK Event ad stop (ended). Duration: ', currentTime);
  }
  this.adEnded = true;
}

function onEnded() {
  if (!this.endReached) {
    const currentTime = Math.floor(Math.floor(this.player.currentTimeOfActiveMedia()));
    this.playheadPositionEnabled = false;
    this.endReached = true;
    this.sdkInstance.ggPM('end', Math.floor(currentTime));
    log('[Nielsen] SDK Event end called. Duration: ', Math.floor(currentTime));
  }
}

function onAdError({ error }) {
  if (error.code === 4 && isAdPlaying(this.player)) {
    this.sdkInstance.ggPM('stop', null);
    log('[Nielsen] SDK Event ad stop (error)');
    this.stopCalled = true;
    this.adEnded = true;
  }
}

function onComplete() {
  // todo
}

function getUnixTimestamp(time) {
  return Math.floor(Date.now() / 1000) - time;
}

function sendStop(time) {
  // instead of "this.seeked" use "this.player.seeking()" to prevent stop event trigger problems
  if (
    (!this.player.seeking() &&
      !this.skipped &&
      this.player.currentTimeOfActiveMedia() !== this.player.durationOfActiveMedia()) ||
    isAdPlaying(this.player)
  ) {
    this.sdkInstance.ggPM('stop', time);
    log('[Nielsen] SDK Event stop called (Pause). Time: ', time);
  }
}

function onPause(e) {
  let { currentTime } = e;

  // during preroll is currentTime always 0
  if (currentTime === 0) {
    sendStop.call(this, currentTime);
    // prevent log event stop twice in any case
    return;
  }

  if (currentTime !== 0) {
    // if currentTime === 0, action has been probably caused by preroll ads, and won't be sent.
    if (this.player.isInPausedState()) {
      if (isLiveGlobal(this.player) && !isAdPlaying(this.player)) {
        currentTime = getUnixTimestamp(this.pausedTime);
      }
      sendStop.call(this, currentTime);
    }
  }
}

function sendPlayhead(time) {
  const validateTime = (timeToValid) =>
    typeof timeToValid === 'number' && timeToValid !== time && time > 0 && !this.player.scrubbing();

  const setPlayheadposition = (showTimestamp) => {
    if (!this.playheadPositionEnabled) return;
    this.stopEventAvailable = true;
    let position = time;

    if (showTimestamp) {
      position = getUnixTimestamp(this.pausedTime);
    }
    this.sdkInstance.ggPM('setPlayheadPosition', position);
    log('[Nielsen] SDK Event setplayheadposition called', position);
    this.stopCalled = false;
    this.loadMetadataAfterAdCalled = false;
  };

  if (!this.loadContentDataEnabled && !this.endReached && validateTime(this.prevPosition)) {
    this.prevPosition = time;
    this.isAdLoaded = false;
    setPlayheadposition(isLiveNow(this.player));
  }

  this.seeked = false;
}

function sendAdPlayhead(time) {
  const validateTime = (timeToValid) =>
    typeof timeToValid === 'number' &&
    timeToValid !== time &&
    time > 0 &&
    !this.player.scrubbing() &&
    time >= this.prevAdPosition;

  const setPlayheadposition = (showTimestamp) => {
    if (!this.playheadPositionEnabled) return;
    this.stopEventAvailable = true;
    let position = time;

    if (showTimestamp) {
      position = getUnixTimestamp(this.pausedTime);
    }
    this.sdkInstance.ggPM('setPlayheadPosition', position);
    log('[Nielsen] SDK Event ad setplayheadposition called', position);
    this.stopCalled = false;
    this.loadMetadataAfterAdCalled = false;
  };

  if (validateTime(this.prevAdPosition) && this.isAdLoaded) {
    this.prevAdPosition = time;
    setPlayheadposition();
  }
  this.adEnded = false;
}

function onTimeUpdate() {
  const time = Math.floor(this.player.currentTimeOfActiveMedia());
  sendPlayhead.call(this, time);
}

function onAdTimeUpdate(e) {
  const time = Math.floor(e.currentAdTime);
  // fix last e.currentTime after postroll has length of content duration?
  // time should be larger then last ad tick by 1
  if (time === this.prevAdPosition + 1) {
    sendAdPlayhead.call(this, time);
  }
}

function onBeforeunload() {
  const currentTime = Math.floor(this.player.currentTimeOfActiveMedia());

  if (isAdPlaying(this.player) && !this.adEnded) {
    this.playheadPositionEnabled = false;
    if (!this.stopCalled) {
      this.sdkInstance.ggPM('stop', currentTime);
      log('[Nielsen] SDK Event ad stop (ended). Time: ', currentTime);
      this.stopCalled = true;
      this.adEnded = true;
    }
  }
  if (!this.endReached) {
    this.playheadPositionEnabled = false;
    this.endReached = true;
    let time = currentTime;
    if (isLiveGlobal(this.player)) {
      time = getUnixTimestamp(this.pausedTime);
    }
    this.sdkInstance.ggPM('end', time);
    log('[Nielsen] SDK Event end called. Time: ', time);
  }
}

/**
 * Nielsenn Digital Content Ratings
 * {@link https://engineeringforum.nielsen.com/sdk/developers/dcr.php Nielsenn Forum}
 * {@link https://engineeringforum.nielsen.com/sdk/developers/product-dcr-video-implementation-bbsdk.php Nielsenn Digital Content Ratings}
 */
class Nielsen extends Plugin {
  /**
   * @param  {Object} player
   * @param {Object} options
   * @param {NielsenOptions} options.nielsenOptions
   * @param  {module:plugins/measuring~StreamInfo} options.streamInfo - Video stream info (like video id, length, name, ...)
   */
  constructor(player, options) {
    super(player, options);
    const { nielsenOptions, streamInfo } = options;

    this.apid = null;

    if (!NOLBUNDLE) {
      log.error(MEA_NIELSEN_MISSING.code, MEA_NIELSEN_MISSING.message);
      return;
    }

    const { apid } = nielsenOptions;
    this.apid = nielsenOptions.apid;
    // We don't want to send "apid" in every Nielsen request
    delete nielsenOptions.apid;

    if (!apid) {
      log.error('[Nielsen] Missing apid!');
      return;
    }

    const initOptions = {};
    if (isLoggingEnabled()) {
      initOptions.nol_sdkDebug = 'debug';
    }

    this.sdkInstance = NOLBUNDLE.nlsQ(apid, player.id(), initOptions);

    this.player = player;
    this.contentMetadata = this.getMetadata(nielsenOptions, streamInfo);

    // FIXME BPo: Needs refactoring. Use for this flags some object wrapper.
    this.loadContentDataEnabled = true;
    this.endReached = false;
    this.stopEventAvailable = false;
    this.isAdLoaded = false;
    // HACK: When VPAID is playing inside the IFRAME. Let's be sure playheads fakes are send correctly.
    this.playheadPositionEnabled = false;
    this.prevPosition = 0;
    this.prevAdPosition = 0;
    this.pausedTime = 0;

    // Call stop just once
    this.stopCalled = false;
    this.loadMetadataAfterAdCalled = false;
    this.adEnded = false;

    this.bindFunctions();
    this.registerEvents();

    this.player.on('ready', () => {
      if (propTest(() => player.reset.resetData.data.plugins.measuring.services.nielsen)) {
        player.reset.resetData.data.plugins.measuring.services.nielsen.apid = this.apid;
        log('[Nielsen] Apid save for reset');
      }

      if (propTest(() => player.reset.resetData.data.plugins.measuringIMA.services.nielsen)) {
        player.reset.resetData.data.plugins.measuringIMA.services.nielsen.apid = this.apid;
        log('[Nielsen] Apid save for reset');
      }
    });
  }

  stopPauseCounting() {
    this.player.clearInterval(this.secondsInterval);
  }

  increment() {
    this.pausedTime += 1;
  }

  startPauseCounting() {
    this.secondsInterval = this.player.setInterval(() => this.increment(), 1000);
  }

  bindFunctions() {
    this.onProgramLoaded = onProgramLoaded.bind(this);
    this.onPlay = onPlay.bind(this);
    this.onAdPlay = onAdPlay.bind(this);
    this.startPauseCounting = this.startPauseCounting.bind(this);
    this.onSeeking = onSeeking.bind(this);
    this.onAdLoaded = onAdLoaded.bind(this);
    this.onSkip = onSkip.bind(this);
    this.onEnded = onEnded.bind(this);
    this.onAdEnded = onAdEnded.bind(this);
    this.onComplete = onComplete.bind(this);
    this.onTimeUpdate = onTimeUpdate.bind(this);
    this.onAdTimeUpdate = onAdTimeUpdate.bind(this);
    this.onBeforeunload = onBeforeunload.bind(this);
    this.onPause = onPause.bind(this);
    this.onError = onAdError.bind(this);
    this.disposeEvents = this.disposeEvents.bind(this);
  }

  getMetadata(nielsenOptions, streamInfo) {
    const data = {
      length:
        streamInfo.programDuration === -1 || this.player.duration() === Infinity ? 86400 : streamInfo.programDuration,
      assetid: streamInfo.videoID,
      title: streamInfo.programName,
      ...nielsenOptions,
    };

    if (streamInfo.series) {
      data.program = streamInfo.series;
    }

    return data;
  }

  getAdObject(adStreamInfo) {
    const type = adStreamInfo.adType === 'midrolls' ? 'midroll' : adStreamInfo.adType;
    return {
      length: Math.floor(adStreamInfo.adDuration),
      assetid: adStreamInfo.adID,
      title: adStreamInfo.adName,
      type,
      nol_c4: `p4,${adStreamInfo.asmeaCode || ''}`,
      nol_c5: `p5,${adStreamInfo.adCategory || ''}`,
      nol_c6: `p6,${type}`,
    };
  }

  registerEvents() {
    this.player.on(availableEvents.PROGRAM_LOADED, this.onProgramLoaded);
    this.player.on(availableEvents.PLAY, this.onPlay);
    this.player.on(availableEvents.AD_PLAY, this.onAdPlay);
    this.player.on(availableEvents.AD_LOADED, this.onAdLoaded);
    this.player.on(availableEvents.SKIP, this.onSkip);
    this.player.on(availableEvents.ENDED, this.onEnded);
    this.player.on(availableEvents.AD_COMPLETE, this.onAdEnded);
    this.player.on(availableEvents.ERROR, this.onError);
    this.player.on(availableEvents.COMPLETE, this.onComplete);
    this.player.on([availableEvents.PAUSE, availableEvents.AD_PAUSE], this.onPause);
    this.player.on(availableEvents.TIMEUPDATE, this.onTimeUpdate);
    this.player.on(availableEvents.AD_TIMEUPDATE, this.onAdTimeUpdate);
    // this.player.on('vodcontentend', this.onContentEnd);
    window.addEventListener('beforeunload', this.onBeforeunload);
    window.addEventListener('pagehide', this.onBeforeunload);
    this.player.on(BEFORE_RESET_EVENT, this.disposeEvents);
  }

  disposeEvents() {
    this.player.off(availableEvents.PROGRAM_LOADED, this.onProgramLoaded);
    this.player.off(availableEvents.PLAY, this.onPlay);
    this.player.off(availableEvents.AD_PLAY, this.onAdPlay);
    this.player.off(availableEvents.AD_LOADED, this.onAdLoaded);
    this.player.off(availableEvents.SKIP, this.onSkip);
    this.player.off(availableEvents.ENDED, this.onEnded);
    this.player.off(availableEvents.AD_COMPLETE, this.onAdEnded);
    this.player.off(availableEvents.ERROR, this.onError);
    this.player.off(availableEvents.COMPLETE, this.onComplete);
    this.player.off([availableEvents.PAUSE, availableEvents.AD_PAUSE], this.onPause);
    this.player.off(availableEvents.TIMEUPDATE, this.onTimeUpdate);
    this.player.off(availableEvents.AD_TIMEUPDATE, this.onAdTimeUpdate);
    window.removeEventListener('beforeunload', this.onBeforeunload);
    window.removeEventListener('pagehide', this.onBeforeunload);
    log('[Nielsen] Events disposed');
  }

  dispose() {
    this.disposeEvents();
    super.dispose();
    log('[Nielsen] Plugin is being disposed');
  }
}

videojs.registerPlugin('nielsen', Nielsen);
