/**
 * Play source module
 * @module play-source
 */
import { videojs, FormData } from 'global/window';
import log from './log';
import { getTheMostSuitableTracks } from './utils/source-selection';
import getLocalizedSources from './multilingual-match';
import sourceFormatter from './source-formatter';
import continueWatching from './continue-watching';
import PersistSettings from './plugins/settings/persist-settings';
import { PR_VJS_NOT_FOUND, PR_TRANSFER_FAILED } from './errors';
import isPlaybackValid from './playback-validation';
import { setMutedState } from './utils/volume-helper';
import { checkUrlSeek } from './url-seek';
import { disableAudioOnly } from './utils/disable-audio-only';

/**
 * Transfer session before the source is append to the video element
 * @param  {String}   ur
 * @param  {String}   token
 * @param  {Function} cb    Called when the fetch succesfully done
 */
const transferSession = (url, token, cb) => {
  const formData = new FormData();
  formData.append('token', token);

  fetch(url, {
    method: 'POST',
    body: formData,
    credentials: 'include',
    mode: 'cors',
  })
    .then(() => {
      cb();
    })
    .catch((error) => {
      log.error(PR_TRANSFER_FAILED.code, PR_TRANSFER_FAILED.message, error.message);
    });
};

/**
 * Send an authorized request when source object has 'witchCredenetials'
 *
 * @param {Object} data - Player initialization object from the init request. See
 * 'Player initialization object' part at the project documentation
 * @param {Boolean} [credentials = false] - Optional credentials settings. When true all request will be authorized
 * @return {Object} Same input data with authorized flag for HLS tech
 */
export const authorizeRequests = function authorizeRequests(data, credentials = false) {
  if (credentials) {
    data.html5 = {
      // Original contrib HLS
      hls: {
        withCredentials: credentials,
      },
      // Dailymoon contrib HLS
      hlsjsConfig: {
        xhrSetup: (xhr) => {
          xhr.withCredentials = credentials;
        },
      },
    };
  }
  return data;
};

/**
 * Will load the appropriate source when available otherwise throw error
 *
 * @param {String} id - ID of the video element
 * @param {Object} data - Player initialization object from the init request. See
 * 'Player initialization object' part at the project documentation
 * @throws Will throw an error if no source available
 * @return {Object} A videojs player instance when source matched, false otherwise
 */
const playSource = function playSource(id, data) {
  log(`[Location] ${window.location.href}`);

  log(
    '[User agent data]',
    window.navigator.userAgent,
    `client lang: ${window.navigator.language}`,
    `client appName: ${window.navigator.appName}`,
    `client platform: ${window.navigator.platform}`,
    `client cookies enabled: ${window.navigator.cookieEnabled}`,
  );

  if (!videojs) {
    log.error(PR_VJS_NOT_FOUND.code, PR_VJS_NOT_FOUND.message);
  }

  const isImaDai = !!data.plugins.adServiceImaDai;

  const tracksPlayableByTheBrowser = getTheMostSuitableTracks(data.tracks, data.preferredTech);

  // Try to read persisted stored language
  const { persistSettings } = data.plugins.settings || {};
  let persistedLanguage = false;

  if (persistSettings && persistSettings.persistAudioLanguage) {
    persistedLanguage = PersistSettings.getStoredLang(persistSettings.id);
  }

  if (data.resetStore && data.resetStore.lang) {
    persistedLanguage = data.resetStore.lang;
  }

  const localizedSources = getLocalizedSources(
    tracksPlayableByTheBrowser,
    data.preferredLang,
    persistedLanguage,
    isImaDai,
  );

  // Send an authorized request when source object has 'witchCredentials'
  data = localizedSources?.[0]?.withCredentials ? authorizeRequests(data, localizedSources[0].withCredentials) : data;

  const player = videojs(id, data);

  player.on('error', (e) => {
    log.error('[Player error]', e);
  });

  player.ready(() => {
    setMutedState(player, data);
    // Save global log variable to player
    player.logHistory = log.history;
    // seek when URL contains url params t=
    checkUrlSeek(player);

    function handlePlayback() {
      // Try to play only when playback is valid (user have browser support).
      const sources = {
        original: data.tracks,
        matched: tracksPlayableByTheBrowser,
        localized: localizedSources,
      };

      if (isPlaybackValid(player, sources)) {
        const playSource = () => {
          // filter out UHD sources from initial play
          const filteredSources = localizedSources.filter((src) => !src.uhd);
          const formattedSource = sourceFormatter(filteredSources[0], player);
          log('Player is going to play: ', formattedSource);
          // TODO BPo: Maybe play next matched source on error?
          // TODO BPo: Technology order selection - DASH > HLS

          if (typeof player.eme === 'function') {
            player.eme();
          }

          disableAudioOnly(player);

          if (isImaDai) {
            player.adServiceImaDai().initializeIma(formattedSource);
          } else {
            player.src({ ...formattedSource });
          }

          // Try to play from continue watching time
          continueWatching(player, data.startTime);
        };

        if (data.tracks && data.tracks.transferSession) {
          transferSession(data.tracks.transferSession.url, data.tracks.transferSession.token, playSource);
        } else {
          playSource();
        }
      }
    }
    handlePlayback();
  });

  return player;
};

export default playSource;
