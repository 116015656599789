import log from './log';

/**
 * Property tester - optional chaining
 ** https://silvantroxler.ch/2017/avoid-cannot-read-property-of-undefined/
 * @param {Function} - function with chaning property
 * @param {Value} - optional overide default null value for catch return
 * DEMO USE
 * - if (propertyTester(() => initData.plugins.measuring.services.gemius)) {...
 */
export default function propertyTester(fn, defaultVal = null) {
  if (typeof fn !== 'function') {
    log.error('[property tester] first argument not function!');
  }
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}
