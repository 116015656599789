import { videojs } from 'global/window';

import SettingsMenu from './menu';
import './settings-heading';

// import log from '../../log';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Registration component for all settings sub-plugin
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SettingsMenuDropdown extends SettingsMenu {
  constructor(player, options = {}) {
    super(player, options);
  }

  /* create wrapper that enables scrolling */
  createWrapper() {
    this.wrapper = this.addChild('component');
    this.wrapper.el_.className = 'vjs-settings-menu-dropdown--wrapper';
  }

  createContent() {
    const { settings } = this.player_.options_.plugins;

    this.wrapper.addChild('SettingsHeading');
    this.registerAudioChannelsSwitch(settings);
    this.registerLanguagesPlugin(settings, { dropdownSettings: this.options_.dropdownSettings });
    this.registerSubtitlesPlugin(settings, { dropdownSettings: this.options_.dropdownSettings });
    this.registerUhdProfileSwitch(settings, { dropdownSettings: this.options_.dropdownSettings });
    this.registerQualityPlugin(settings, { dropdownSettings: this.options_.dropdownSettings });
  }
}

// Register the new component
Component.registerComponent('SettingsMenuDropdown', SettingsMenuDropdown);
export default SettingsMenuDropdown;
