import { videojs } from 'global/window';

// Get a component to subclass
const Component = videojs.getComponent('Component');
const OffTextTrackMenuItem = videojs.getComponent('OffTextTrackMenuItem');

/**
 * A special menu item for turning of subtitles
 *
 * @param {Player|Object} player
 * @param {Object=} options
 */
class OffSubtitlesMenuItem extends OffTextTrackMenuItem {
  /**
   * Handle click on text track
   *
   * @method handleClick
   */
  handleClick(event) {
    super.handleClick(event);

    this.player_.trigger({
      type: 'ott-subtitles-disabled',
    });
  }

  createEl() {
    return super.createEl('span', {
      className: 'vjs-menu-item',
    });
  }
}

Component.registerComponent('OffSubtitlesMenuItem', OffSubtitlesMenuItem);
export default OffSubtitlesMenuItem;
