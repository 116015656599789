import createVmap from './vmap-creator';
import log, { isLoggingEnabled } from '../../log';

/**
 * Initialize Google IMA SDK
 * https://github.com/googleads/videojs-ima
 * @param {object} player - player instance
 * @param {object} adconfig - full adservice init object
 */
const initializeIma = (player, adconfig = {}) => {
  player.addClass('vjs-waiting-for-ima');
  log('[ima] init ima start - added class vjs-waiting-for-ima');
  /**
   * imaReady function
   * used as adsManagerLoadedCallback
   * trigger event ott-ima-ready
   */
  const imaReady = () => {
    player.trigger('ott-ima-ready');
  };

  const defaultOptions = {
    debug: isLoggingEnabled(),
    adLabel: player.localize('Advertisement'),
    adLabelNofN: '/',
    contribAdsSettings: {
      prerollTimeout: 5000,
      debug: isLoggingEnabled(),
    },
    locale: player.language(),
    numRedirects: 20,
    vastLoadTimeout: 5000,
    // vpaidMode: 2, // insecure
    adsManagerLoadedCallback: imaReady, // trigger ott-ima-ready ima-ready event after ima initialized
    adsRenderingSettings: {
      // enablePreloading: true, // call ad request 4 sec before play
      useStyledNonLinearAds: true, // show close button for overlay
    },
    disableCustomPlaybackForIOS10Plus: true,
    // prerollTimeout: 5000,
  };

  const settings = adconfig.settings || {};
  const imaOptions = settings.imaOptions || {};
  const options = { ...defaultOptions, ...imaOptions };

  if (settings.linear && settings.linear.preferredFormat) {
    options.adsRenderingSettings.mimeTypes = [];
    options.adsRenderingSettings.mimeTypes.push(settings.linear.preferredFormat);
  }

  log('[ima] options from player', adconfig);
  log('[ima] final options', options);

  if (adconfig.adsResponse !== undefined) {
    options.adsResponse = adconfig.adsResponse;
  } else if (adconfig.adTagUrl) {
    options.adTagUrl = adconfig.adTagUrl;
    log(`[ima] use vmap url ${adconfig.adTagUrl}`);
  } else if (adconfig.csaiAdTagUrlBase || adconfig.csaiPrerollAdTagUrl) {
    // this is non public option
    options.requestMode = 'onPlay';
    options.disableAdControls = true;
    options.showCountdown = false;
    options.showControlsForJSAds = false;
    // when there is preroll, use it as adTagUrl so ima sdk can work
    // if there is no valid vast (which will happen without adTagUrl when preroll is not present)
    // it will throw error 1010 (no valid parsable vast), but all is good otherwise
    if (adconfig.csaiPrerollAdTagUrl) {
      options.adTagUrl = adconfig.csaiPrerollAdTagUrl;
    }
  } else {
    options.adsResponse = createVmap(adconfig);
    log('[ima] create vmap from player init data');
  }

  player.on('adserror', (event) => {
    log('[ima] adserror event fallback triggered', event);
    if (event.data.AdError.getErrorCode() === 1009) {
      if (player.options_?.autoplayAllowed) {
        log(`[ima] no valid ad, autoplayAllowed is set to '${player.options_?.autoplayAllowed}', playing the content`);
        player.play();
      } else {
        log(
          `[ima] no valid ad, autoplayAllowed is set to '${player.options_?.autoplayAllowed}', so the video cannot be played`, // eslint-disable-line max-len
        );
      }
    }
  });
  player.ima(options);
};

export default initializeIma;
