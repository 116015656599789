import { videojs } from 'global/window';

// Get a component to subclass
const CurrentTimeDisplay = videojs.getComponent('CurrentTimeDisplay');
const Component = videojs.getComponent('Component');

class PlaylistCurrentTimeDisplay extends CurrentTimeDisplay {
  getTimeTotalDurationBeforeThisSource() {
    return this.player_.playlist.playlistData.sources[this.player_.playlist.playlistData.actualIndex].durationBefore;
  }

  /**
   * Builds the default DOM `className`.
   *
   * @return {string}
   *         The DOM `className` for this object.
   */
  buildCSSClass() {
    return 'vjs-current-time vjs-playlist-current-time';
  }

  /**
   * Update current time display in AD break
   * @param {EventTarget~Event} [event]
   *        The `timeupdate` event that caused this function to run.
   *
   * @listens Player#timeupdate
   */
  updateAdContent() {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    this.updateTextNode_(time);
  }

  /**
   * Update current time display
   * ! Extend method changed for playlist !
   * @param {EventTarget~Event} [event]
   *        The `timeupdate` event that caused this function to run.
   *
   * @listens Player#timeupdate
   */
  updateContent() {
    // Allows for smooth scrubbing, when player can't keep up.
    const time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
    const result = time + this.getTimeTotalDurationBeforeThisSource();

    this.updateTextNode_(result);
  }
}

Component.registerComponent('PlaylistCurrentTimeDisplay', PlaylistCurrentTimeDisplay);
export default PlaylistCurrentTimeDisplay;
